import { observer } from 'mobx-react';
import Button from 'components/UI/Button';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FeatureCreateStep } from './FeatureCreateFormProperties';

const Container = styled(Grid)`
  width: 100%;
  position: relative;
`;

const CirclesContainer = styled(Grid)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.color.white};
`;

const Circle = styled.span<{ active: boolean }>`
  width: 28px;
  line-height: 28px;
  text-align: center;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : theme.color.border};

  color: ${({ active, theme }) =>
    active ? theme.color.white : theme.color.text};
  border-radius: 50%;
`;

interface SystemsCreateDialogFooter {
  step: FeatureCreateStep;
  steps: FeatureCreateStep[];
  onBackClick(): void;
  onNextClick(): void;
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
}

const FeatureCreateDialogFooter: FC<SystemsCreateDialogFooter> = observer(
  ({ step, steps, onBackClick, onNextClick, isSubmitDisabled, isLoading }) => {
    const { t } = useTranslation();
    const isFirstStep = step === FeatureCreateStep.TYPE;
    const isLastStep = step === FeatureCreateStep.SUMMARY;

    return (
      <Container direction="row" justify={!isFirstStep ? 'between' : 'end'}>
        {!isFirstStep && (
          <Button onClick={onBackClick}>
            <StyledText size="md">{t('general:back')}</StyledText>
          </Button>
        )}
        <CirclesContainer direction="row" justify="center" gap="0.3rem">
          {steps.map((item, index) => (
            <Circle key={item} active={step === item}>
              {index + 1}
            </Circle>
          ))}
        </CirclesContainer>
        <Grid direction="row" gap="0.5rem">
          <Button
            onClick={onNextClick}
            disabled={isSubmitDisabled}
            loading={isLoading}
            type="submit"
          >
            <StyledText size="md">
              {isLastStep ? t('general:finish') : t('general:next')}
            </StyledText>
          </Button>
        </Grid>
      </Container>
    );
  },
);

export default FeatureCreateDialogFooter;
