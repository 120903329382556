import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';
import { HALF_SCREEN_TABLE_TOP_HEIGHT } from 'core/constants';
import { Paper } from '@mui/material';
import { Column } from 'components/shared/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import {
  DataTableFilterMeta,
  DataTableRowClickEventParams,
} from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import DataTable from 'components/shared/DataTable';
import { WebPlot } from 'models/plot';

const Container = styled(Grid)`
  height: 100%;
`;

const DataTableContainer = styled(Paper)`
  margin: 1rem;
  overflow: hidden;
  max-height: ${HALF_SCREEN_TABLE_TOP_HEIGHT};
`;

const FeaturesModels: FC = observer(() => {
  const { featuresStore, resellersStore } = useStores();
  const { selectedFeatures } = featuresStore;
  const { t } = useTranslation('feature');
  const tableFilters: DataTableFilterMeta = {
    id: { value: '', matchMode: FilterMatchMode.CONTAINS },
    name: { value: '', matchMode: FilterMatchMode.CONTAINS },
    latitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
    longitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
    'connectedSensors.length': {
      value: '',
      matchMode: FilterMatchMode.CONTAINS,
    },
  };

  const getAllFeatures = useCallback(
    async (plots: WebPlot[]) => {
      await featuresStore.getAllFeatures(
        resellersStore.selectedGrowerId,
        plots,
      );
    },
    [featuresStore, resellersStore.selectedGrowerId],
  );

  useEffect(() => {
    if (resellersStore.resellerPlots.length) {
      getAllFeatures(resellersStore.resellerPlots);
    } else {
      featuresStore.resetFeatures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resellersStore.resellerPlots, resellersStore.selectedGrowerId]);

  const tableRows = useMemo(
    () => featuresStore.features,
    [featuresStore.features],
  );

  const onRowClick = useCallback(
    ({ data }: DataTableRowClickEventParams) => {
      featuresStore.setSelectedFeatures(data);
    },
    [featuresStore],
  );

  return (
    <Container flex={1}>
      <DataTableContainer elevation={3}>
        <DataTable
          id="FeatureTable"
          showGridlines
          resizableColumns
          value={JSON.parse(JSON.stringify(tableRows))}
          dataKey="id"
          emptyMessage={t('empty_message')}
          stripedRows
          filters={tableFilters}
          filterDisplay="row"
          selection={selectedFeatures}
          selectionMode="single"
          onRowClick={onRowClick}
        >
          <Column field="id" header={t('id')} sortable filter />
          <Column field="name" header={t('name')} sortable filter />
          <Column field="latitude" header={t('latitude')} sortable filter />
          <Column field="longitude" header={t('longitude')} sortable filter />
          <Column field="mlModelId.name" header={t('model')} sortable filter />
          <Column field="plotName" header={t('plot')} sortable filter />
        </DataTable>
      </DataTableContainer>
    </Container>
  );
});

export default FeaturesModels;
