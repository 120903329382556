import he from 'locales/he.json';
import en from 'locales/en.json';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import veryLocalStorage, { storageKeys } from 'utils/vls';

const { LANG } = storageKeys;
i18next.use(initReactI18next).init({
  resources: {
    en,
    he,
  },
  fallbackLng: ['en', 'he'],
  lng: (veryLocalStorage.get(LANG) as string) || 'en',
  interpolation: { escapeValue: false },
  returnNull: false,
});

document.body.dir = i18next.dir();

i18next.on('languageChanged', (lng: string) => {
  veryLocalStorage.set(LANG, lng);
  const { body } = document;
  body.dir = i18next.dir();
});

export default i18next;
