import ReactJson from 'react-json-view';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';

const Container = styled(Grid)`
  .react-json-view {
    max-height: 35vh;
  }
`;

const FeatureDetails: FC = observer(() => {
  const { featuresStore } = useStores();
  const selectedFeature = featuresStore.selectedFeatures[0];

  return (
    <Container direction="row" justify="start">
      {!!selectedFeature && <ReactJson src={selectedFeature} />}
    </Container>
  );
});

export default FeatureDetails;
