import React, { FC } from 'react';
import { observer } from 'mobx-react';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { useStores } from 'stores/hooks/hooks';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { displayFileOrDownload } from 'utils/fileHelpers';

const StyledLinkButton = styled(Button)`
  &:hover {
    background-color: transparent;
  }
`;

const Bold = styled.b``;

const UserUploadComplete: FC = () => {
  const { membersStore } = useStores();
  const { t } = useTranslation('users');
  const unSelectedUsers = membersStore.userUploadExcel.filter(
    (el) => !el.selected,
  );

  const saveAsExcelFile = () => {
    displayFileOrDownload(
      membersStore.invalidUsersFileBlob,
      membersStore.invalidUsersFileName,
    );
  };

  return (
    <Grid>
      <Text>
        <Bold>{t('selected_users_uploaded')}</Bold>
      </Text>
      {unSelectedUsers.length > 0 && (
        <>
          <Text>
            {t('users_not_saved', { number: unSelectedUsers.length })}
          </Text>
          <StyledLinkButton
            variant="text"
            disableRipple
            onClick={saveAsExcelFile}
          >
            <Text textcolor={theme.color.link} fontWeight={700}>
              {t('download_file')}
            </Text>
          </StyledLinkButton>
        </>
      )}
      {unSelectedUsers.length === 0 && (
        <Text textcolor={theme.color.link} fontWeight={700}>
          {t('no_invalid_users')}
        </Text>
      )}
    </Grid>
  );
};

export default observer(UserUploadComplete);
