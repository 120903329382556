import { observer } from 'mobx-react';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from 'stores/hooks/hooks';
import { TabViewTabChangeParams } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { TabPanel, TabView } from 'components/UI/TabView';
import SystemSensorsTable from './SystemSensorsTable';
import SystemDetails from './SystemDetails';

const Container = styled.div`
  width: 100%;

  .p-tabview-panels {
    height: 100%;
  }
`;

const StyledTabView = styled(TabView)`
  .p-tabview-nav {
    margin: 0.5rem 1rem;
  }
`;

enum TabKey {
  Details = 0,
  Sensors = 1,
}

const SystemSensors: FC = observer(() => {
  const { t } = useTranslation('grower');
  const [tabIndex, setTabIndex] = useState(TabKey.Sensors);
  const {
    resellersStore: { growerSystemSensors },
  } = useStores();

  const onTabChange = useCallback(({ index }: TabViewTabChangeParams) => {
    setTabIndex(index);
  }, []);

  return (
    <Container>
      <StyledTabView
        activeIndex={tabIndex}
        renderActiveOnly
        onTabChange={onTabChange}
      >
        <TabPanel header={t('details')}>
          <SystemDetails />
        </TabPanel>
        <TabPanel header={t('sensors')}>
          {growerSystemSensors.loading ? <Loading /> : <SystemSensorsTable />}
        </TabPanel>
      </StyledTabView>
    </Container>
  );
});

export default SystemSensors;
