import api from 'Api/ApiMethods';
import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import i18next from 'i18next';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { PolygonItem } from 'utils/uploadPolygonsHelpers';
import { getSafeArray } from 'utils/arrayUtils';
import { GrowingType, PhenologicalStage } from 'models/protocol';
import { CropType, ErrorResponse } from 'models/shared';
import { CreatePlotParams } from 'models/api/plot';
import { DEFAULT_COMPANY_OPTIONS, IRRIGATION_METHODS } from 'core/constants';
import {
  ServiceLevel,
  PlotModel,
  PlotManagementValidation,
  WebPlot,
  SoilType,
  MassEditSettings,
  PlotParams,
} from 'models/plot';
import { MassEditPlotSettings } from 'components/pages/Resellers/Plots/ResellerMassEditPlots/ResellerPlotMassEdit';
import { getDataTableMultiSelection } from 'utils/datatableUtils';
import {
  generateDefaults,
  getMassEditTableRows,
  MassEditPlotData,
} from 'components/pages/Resellers/Plots/ResellerMassEditPlots/ResellerMassEdit.utils';
import { RootStore } from './rootStore';
import { JSONSchema, TableMultiSelection } from './types/types';

export class PlotsStore {
  rootStore?: RootStore = undefined;
  plotAction: PlotModel = {} as PlotModel;
  irrigationMethods: SelectItemOptionsType = [];
  growingType: GrowingType[] = [];
  plotParams: PlotParams | undefined = undefined;
  plotsMassEditTableMultiSelection: MassEditPlotSettings[] = [];
  plotMassSettings: MassEditSettings = {} as MassEditSettings;
  soilType: SoilType[] = [];
  serviceLevel: ServiceLevel[] = [];
  cropTypes: CropType[] = [];
  varieties: string[] = [];
  categories: string[] = [];
  phenologicalStages: PhenologicalStage[] = [];
  growingMethods: string[] = [];
  selectedPolygon: PolygonItem | undefined;
  plotManagementValidation: PlotManagementValidation = {
    location: false,
  };

  private _plots: WebPlot[] = [];
  private _selectedRows: WebPlot[] = [];
  public get selectedRows(): WebPlot[] {
    return this._selectedRows;
  }

  public set selectedRows(value: WebPlot[]) {
    this._selectedRows = value;
  }

  public get plots(): WebPlot[] {
    return this._plots;
  }

  public set plots(value: WebPlot[]) {
    this._plots = value;
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setSelectedPolygon = (polygon: PolygonItem | null) => {
    this.selectedPolygon = polygon || undefined;
    this.plotAction.geojson = {
      type: 'FeatureCollection',
      features: this.selectedPolygon ? [this.selectedPolygon.value] : null,
    };
  };

  updatePlotStatus = async (status: string) => {
    const plotId = this.selectedRows[0].id;
    // await api.editPlotStatus(plotId, status);
  };

  setPlots = (plots: WebPlot[]) => {
    this.plots = plots;
  };

  setSelectedRows = (plots: WebPlot[]) => {
    this.selectedRows = plots;
  };

  setPlotAction = (plot: PlotModel) => {
    this.plotAction = plot;
  };

  getGrowingType = async () => {
    const res = await api.getGrowingTypes();
    if (res) {
      this.growingType = res;
    }
  };

  getPlotParams = async (plotId: number) => {
    const res = await api.getPlotParams(plotId);
    // Change order of schema to have NominalFlow first
    if (res?.schema?.properties?.NominalFlow) {
      const copyProperties = JSON.parse(JSON.stringify(res.schema.properties));
      const copyWaterPressure = JSON.parse(
        JSON.stringify(copyProperties.NominalFlow),
      );

      delete copyProperties.NominalFlow;
      const newProperties = {
        NominalFlow: copyWaterPressure,
        ...copyProperties,
      };

      res.schema.properties = newProperties;
    }

    if (res) {
      this.setPlotParams(res);
    }
  };

  setPlotParams = (params: PlotParams | undefined) => {
    this.plotParams = params;
  };

  setSelectedMassEditRows = (selection: MassEditPlotSettings[]) => {
    this.plotsMassEditTableMultiSelection = selection;
  };

  get selectedMassEditRows(): MassEditPlotSettings[] {
    return this.plotsMassEditTableMultiSelection;
  }

  setPlotMassSettings = (settings: MassEditSettings) => {
    this.plotMassSettings = settings;
  };

  getGrowerMassPlotSettings = async (
    plotIds: { id: number; plotName: string }[],
    controller: AbortController,
  ) => {
    try {
      const dssValidation = await api.getPlotRecommendationValidation(
        controller,
      );

      const massSettingsPromiseArray = plotIds.map(async (plotItem) => {
        return new Promise((resolve, reject) => {
          try {
            const recommandationPromise = api.getPlotRecommendationParams(
              plotItem.id,
              controller,
            );

            const settingsPromise = api.getPlotParams(plotItem.id, controller);
            Promise.all([recommandationPromise, settingsPromise])
              .then((values) => {
                const settingsObject = values[1];
                // Adding new default values for missing params
                const newDefaultsParams = generateDefaults(
                  settingsObject?.schema?.properties as JSONSchema,
                  JSON.parse(JSON.stringify(settingsObject?.params)),
                );

                settingsObject.params = newDefaultsParams;
                resolve({
                  plotId: plotItem.id,
                  plotName: plotItem.plotName,
                  settings: {
                    dssSettings: values[0],
                    plotSettings: settingsObject,
                  },
                });
              })
              .catch((e) => {
                reject(e);
              });
          } catch (e) {
            reject(e);
          }
        });
      });

      const massSettings = await Promise.all(massSettingsPromiseArray);
      const allData = { dssValidation, massSettings } as MassEditSettings;
      this.setPlotMassSettings(allData);
    } catch (e) {
      console.log('e', e);
    }
  };

  postPlotParams = async (
    plotId: number | string,
    comment: string,
    params: PlotParams,
  ) => {
    try {
      const res = await api.postPlotParams(plotId, comment, params);
      return res;
    } catch (e) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(e)
          ? (e.response?.data as ErrorResponse)?.message
          : ``,
      });

      return false;
    }
  };

  postPlotsMassEdit = async (
    dssData: { [key: string]: string | number | boolean },
    settingsData: { [key: string]: string | number | boolean },
    selectedPlots: MassEditPlotSettings[],
  ) => {
    try {
      if (!this.plotMassSettings?.massSettings?.length) {
        return [];
      }

      const plotIds = selectedPlots.map((plotObject) => plotObject.id);

      const massSettingsPromiseArray = plotIds.map(async (plotId) => {
        return new Promise((resolve, reject) => {
          try {
            const previousSettingsIndex =
              this.plotMassSettings.massSettings.findIndex(
                (plotObject) => plotObject.plotId === plotId,
              );

            const previousSettings = JSON.parse(
              JSON.stringify({
                ...this.plotMassSettings?.massSettings?.[previousSettingsIndex],
              }),
            );

            const previousDssSettings = JSON.parse(
              JSON.stringify({
                ...previousSettings?.settings?.dssSettings?.params,
              }),
            );

            const previousSettingsSettings = JSON.parse(
              JSON.stringify({
                ...previousSettings?.settings?.plotSettings?.params,
              }),
            );

            if (this?.plotMassSettings?.massSettings?.[previousSettingsIndex]) {
              previousSettings.settings.dssSettings.params = JSON.parse(
                JSON.stringify({
                  ...previousDssSettings,
                  ...dssData,
                }),
              );

              previousSettings.settings.plotSettings.params = JSON.parse(
                JSON.stringify({
                  ...previousSettingsSettings,
                  ...settingsData,
                }),
              );

              const dssRes = api.postRecommendationParams(plotId, {
                comment: 'massEdit',
                params: previousSettings.settings.dssSettings.params,
              });

              const settingsRes = api.postPlotParams(
                plotId,
                'massEdit',
                previousSettings.settings.plotSettings.params,
              );

              Promise.allSettled([dssRes, settingsRes])
                .then((values) => {
                  const dssSuccess = values?.[0]?.status === 'fulfilled';
                  const settingsSuccess = values?.[1]?.status === 'fulfilled';
                  resolve({
                    plotId,
                    dssSuccess,
                    settingsSuccess,
                    dssParams: previousSettings.settings.dssSettings.params,
                    settingsParams:
                      previousSettings.settings.plotSettings.params,
                  });
                })
                .catch((e) => {
                  reject(e);
                });
            }
          } catch (e) {
            reject(e);
          }
        });
      });

      const results = await Promise.all(massSettingsPromiseArray);
      const newArray: MassEditSettings['massSettings'] = JSON.parse(
        JSON.stringify([...this.plotMassSettings.massSettings]),
      );

      const newSelectedRowsIds = this.selectedMassEditRows.map(
        (massEditObject) => massEditObject.id,
      );

      results.forEach((result: any) => {
        if (result?.dssSuccess || result?.settingsSuccess) {
          const previousSettingsIndex = newArray.findIndex(
            (plotObject) => plotObject.plotId === result.plotId,
          );

          const previousSettings = JSON.parse(
            JSON.stringify({
              ...newArray?.[previousSettingsIndex],
            }),
          );

          if (result?.dssSuccess) {
            previousSettings.settings.dssSettings.params = result.dssParams;
          }

          if (result?.settingsSuccess) {
            previousSettings.settings.plotSettings.params =
              result.settingsParams;
          }

          newArray[previousSettingsIndex] = previousSettings;
        }
      });

      const newMassSettings: MassEditSettings = {
        dssValidation: this.plotMassSettings.dssValidation,
        massSettings: newArray,
      };

      this.setPlotMassSettings(newMassSettings);
      const newSelectedRows = getMassEditTableRows(
        newArray.filter((plotObject) =>
          newSelectedRowsIds.includes(plotObject.plotId),
        ),
      );

      this.setSelectedMassEditRows(newSelectedRows);
      return results;
    } catch (e) {
      return [];
    }
  };

  resetPlotWaterConsumption = async (
    plotId: number | string,
    growerId: number | string,
  ) => {
    try {
      const res = await api.putResetWaterConsumption(plotId, growerId);
      if (res?.message) {
        this.rootStore?.snackBarStore.showError(res?.message);
      }
    } catch (e) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(e)
          ? (e.response?.data as ErrorResponse)?.message
          : ``,
      });
    }
  };

  getIrrigationMethods = async () => {
    const response = await api.getIrrigationMethods();
    this.irrigationMethods = getSafeArray(response).map((value) => {
      const label = IRRIGATION_METHODS.find(
        (method) => method.value === value,
      )?.label;

      return { value, label: label ?? value };
    });
  };

  getSoilType = async () => {
    const res = await api.getSoilTypes();
    if (res) {
      this.soilType = res;
    }
  };

  getServiceLevel = async () => {
    const res = await api.getServiceLevels();
    if (res) {
      this.serviceLevel = res;
    }
  };

  getCropTypes = async (growerId: number) => {
    const res = await api.getGrowerCropTypes(growerId);
    if (res) {
      this.cropTypes = res;
    }
  };

  getCropTypesGBI = async () => {
    const res = await api.getCropTypes();
    if (res) {
      this.cropTypes = res;
    }
  };

  getCategories = async (growerId: number | null, cropId: number | null) => {
    const res = await api.getCropCategories(growerId, cropId);
    if (res) {
      this.categories = res;
    }
  };

  getVarieties = async (growerId: number | null, cropId: number | null) => {
    const res = await api.getCropVarieties(growerId, cropId);
    if (res) {
      this.varieties = res;
    }
  };

  getprotocolId = async () => {
    try {
      const response = await api.getCropProtocolId(
        this.plotAction.growerId,
        this.plotAction.crop,
        Number(this.plotAction.latitude),
        Number(this.plotAction.longitude),
        this.plotAction.variety,
        this.plotAction.category,
      );

      if (response?.message) {
        this.rootStore?.snackBarStore.showError(response.message);
        return;
      }

      if (!response) {
        this.rootStore?.snackBarStore.showToast({
          detail: `Cannot get protocol id`,
        });
      }

      this.plotAction.cropProtocolId = response.id;
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(error)
          ? (error.response?.data as ErrorResponse)?.message
          : `Cannot get protocol id`,
      });
    }
  };

  getPenologicalStages = async () => {
    const res = await api.getPhenologicalStages(
      this.plotAction.cropProtocolId,
      this.plotAction.plantTime,
      this.plotAction.plotId,
    );

    if (res) {
      this.phenologicalStages = res;
    }
  };

  getGrowingMethod = async () => {
    const res = await api.getGrowingMethods(
      this.plotAction.cropProtocolId,
      this.plotAction.plantTime,
      this.plotAction.plotId,
    );

    if (res) {
      this.growingMethods = res;
    }
  };

  createPlot = async (createPlotParams: CreatePlotParams) => {
    try {
      const plotParams: CreatePlotParams = {
        ...createPlotParams,
        companyUuid: createPlotParams.attachIWSensor
          ? DEFAULT_COMPANY_OPTIONS?.[0].value
          : null,
      };

      const plot = await api.postCreatePlot(
        this.plotAction.growerId,
        this.plotAction,
        plotParams,
      );

      if (plot) {
        this.setSelectedRows([plot]);
        this.setPlots([plot, ...this.plots]);
        this.rootStore?.snackBarStore.showToast({
          detail: `Plot created successfully`,
          severity: 'success',
          summary: 'Success',
        });

        return this.selectedRows.length > 0;
      }

      this.rootStore?.snackBarStore.showToast({
        detail: `Not get plot properties from the server`,
      });
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(error)
          ? (error.response?.data as ErrorResponse)?.message
          : i18next.t<string>('plot:create_plot_error'),
      });

      if (axios.isAxiosError(error)) {
        this.plotManagementValidation.location = (
          error.response?.data as ErrorResponse
        )?.message
          .toUpperCase()
          .includes('LOCATION');
      } else if (error instanceof Error) {
        this.plotManagementValidation.location = error.message
          .toUpperCase()
          .includes('LOCATION');
      }
    }

    return false;
  };

  editPlot = async () => {
    try {
      return await api.putUpdatePlot(
        this.plotAction.growerId,
        this.plotAction.plotId,
        this.plotAction,
      );
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(error)
          ? (error.response?.data as ErrorResponse)?.message
          : i18next.t<string>('plot:edit_plot_error'),
      });
    }

    return null;
  };

  resetCropDetails = (): void => {
    this.categories = [];
    this.varieties = [];
    this.plotAction.variety = '';
    this.plotAction.category = '';
  };

  resetStore = (): void => {
    this.setSelectedPolygon(null);
    this.setSelectedMassEditRows([]);
    this.plotMassSettings = {} as MassEditSettings;
    this.plotAction = {} as PlotModel;
    this.cropTypes = [];
    this.phenologicalStages = [];
    this.growingMethods = [];
    this.categories = [];
    this.varieties = [];
    this.growingType = [];
    this.irrigationMethods = [];
    this.serviceLevel = [];
    this.soilType = [];
    this.plotManagementValidation.location = false;
  };
}
