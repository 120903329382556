import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

const WrapGrid = styled(Grid)`
  flex-wrap: wrap;
  column-gap: 1rem;
`;

const StyledLegend = styled.legend`
  padding: 0.5rem;
`;

const NoWrappingText = styled(Text)`
  white-space: nowrap;
`;

const Details: FC = observer(() => {
  const { membersStore } = useStores();
  const { t } = useTranslation('users');
  const selectedUser = membersStore.userTableMultiSelection.activeRow;
  const userApi = selectedUser?.userApi;
  if (!selectedUser) {
    return <Text weight="bold">{t('details_multi_user_error')}</Text>;
  }

  return (
    <FullHeightGrid
      align="start"
      gap="1rem"
      justify="start"
      direction="row"
      flex={1}
    >
      <FullHeightGrid direction="row" align="start" justify="start" flex={3}>
        <WrapGrid
          align="start"
          gap="0.5rem"
          justify="start"
          display="grid"
          grid-template-columns="auto auto"
        >
          <NoWrappingText>{t('first_name')}:</NoWrappingText>
          <Text weight="bold">{selectedUser?.firstName}</Text>
          <NoWrappingText>{t('last_name')}:</NoWrappingText>
          <Text weight="bold">{selectedUser?.lastName}</Text>
          <NoWrappingText>{t('email')}:</NoWrappingText>
          <Text weight="bold">{selectedUser?.email}</Text>
          <NoWrappingText>{t('role')}:</NoWrappingText>
          <Text weight="bold">{selectedUser?.role}</Text>
          <NoWrappingText>{t('units')}:</NoWrappingText>
          <Text weight="bold">{selectedUser?.units}</Text>
          <NoWrappingText>{t('language')}:</NoWrappingText>
          <Text weight="bold">{selectedUser?.locale}</Text>
          <NoWrappingText>{t('status')}:</NoWrappingText>
          <Text weight="bold">
            {selectedUser?.active ? 'Active' : 'Inactive'}
          </Text>
          <NoWrappingText>{t('google_analytics')}:</NoWrappingText>
          <Text weight="bold">
            {selectedUser?.gaRelevant ? 'Active' : 'Inactive'}
          </Text>
        </WrapGrid>
      </FullHeightGrid>
      <FullHeightGrid flex={1} align="stretch">
        <fieldset>
          <StyledLegend>
            <Grid direction="row">
              <Text>{t('api_user')}:</Text>
              <Text weight="bold">
                {userApi?.apiKey ? t('active') : t('inactive')}
              </Text>
            </Grid>
          </StyledLegend>
          <Grid direction="row">
            <Grid
              align="start"
              gap="0.5rem"
              grid-template-columns="auto auto"
              display="grid"
            >
              <NoWrappingText>{t('clientId')}</NoWrappingText>
              <Text singleline weight="bold">
                {userApi?.clientId || t('not_available')}
              </Text>
              <NoWrappingText>{t('growers_limit')}</NoWrappingText>
              <Text weight="bold">
                {userApi?.limits?.maxNumOfGrowersAllowed
                  ?.toLocaleString()
                  ?.replaceAll(',', ' ') || t('not_available')}
              </Text>
              <NoWrappingText>{t('plots_limit')}</NoWrappingText>
              <Text weight="bold">
                {userApi?.limits?.maxNumOfPlotsAllowed
                  ?.toLocaleString()
                  ?.replaceAll(',', ' ') || t('not_available')}
              </Text>
              <NoWrappingText>{t('plots_per_growers_limit')}</NoWrappingText>
              <Text weight="bold">
                {userApi?.limits?.maxNumOfPlotPerGrowersAllowed
                  ?.toLocaleString()
                  ?.replaceAll(',', ' ') || t('not_available')}
              </Text>
            </Grid>
          </Grid>
        </fieldset>
      </FullHeightGrid>
    </FullHeightGrid>
  );
});

export default Details;
