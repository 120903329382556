import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogContent,
  Divider,
  Step,
  StepLabel,
  Stepper as MuiStepper,
} from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import {
  faLongArrowAltRight,
  faLongArrowAltLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grow from 'components/UI/Grow';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import { useStores } from 'stores/hooks/hooks';
import { observer } from 'mobx-react';
import Text from 'components/UI/Text';
import axios from 'axios';
import { ExtendedIProtocol, ProtocolProgress } from 'models/protocol';
import { DialogOnCloseReason, ErrorResponse } from 'models/shared';
import ValidationPage from './ValidationPart';
import ParametersPage from './ParametersPart';
import PhenStagePanel from './PhenStagePart';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: 100%;
  }
`;

const Stepper = styled(MuiStepper)`
  width: 50%;
  align-self: center;
  .MuiStepConnector-line {
    border-top-width: 3px;
  }

  .Mui-active .MuiStepConnector-line,
  .Mui-completed .MuiStepConnector-line {
    border-top-color: green;
  }
`;

const StyledStepLabel = styled(StepLabel)<{ iserror?: string }>`
  .MuiStepLabel-label {
    color: white;
    padding: 1rem;
    background-color: #bdbdbd;
    border-radius: 0.5rem;
    font-weight: bold;
  }

  .MuiStepLabel-label.Mui-completed {
    background-color: green;
  }

  .MuiStepLabel-label.Mui-active {
    background-color: ${({ iserror }) =>
      !iserror || iserror === 'false' ? theme.color.link : theme.color.error};
  }

  .MuiStepLabel-iconContainer {
    margin-right: 0.3rem;
    padding: 0;
  }
`;

const StyledStep = styled(Step)`
  padding: 0;
`;

const StyledDialogContent = styled(DialogContent)`
  /* padding: 0.5rem 1rem; */
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface IMapDialog {
  visible: boolean;
  onHide: () => void;
  protocols: ExtendedIProtocol[] | null;
  startingErrors: string[];
  uploadFilename: string;
}

const EmptyComponent = null;

const UploadDialog: FC<IMapDialog> = ({
  visible = false,
  onHide,
  protocols = null,
  startingErrors = [],
  uploadFilename = '',
}) => {
  const { cropModelStore } = useStores();
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation('crop_models');
  const [errors, setErrors] = useState<string[]>(startingErrors);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [protocolProgress, setProtocolProgress] = useState<
    ProtocolProgress[] | undefined
  >(protocols?.map((el) => ({ protocol: el, status: t('incomplete') })));

  const [selectedRow, setSelectedRow] = useState<ProtocolProgress | null>(
    protocolProgress?.[0] || null,
  );

  const handleSelectionChange = useCallback(
    (e: DataTableSelectionChangeParams) => {
      setSelectedRow(e.value);
    },
    [setSelectedRow],
  );

  useEffect(() => {
    if (protocols) {
      const replacementProtocolProgress = protocols?.map((el) => ({
        protocol: el,
        status: t('incomplete'),
      }));

      setProtocolProgress(replacementProtocolProgress);
      setSelectedRow(replacementProtocolProgress[0]);
    }
  }, [protocols, t]);

  const nextPageButtonText = useMemo(() => {
    switch (activeStep) {
      case 0:
        return t('parameters');
      case 1:
        return t('phen_stages_and_kc');
      case 2:
        return t('add_and_select_another_model');
      default:
        return '';
    }
  }, [activeStep, t]);

  const handleMoveToNextPage = useCallback(() => {
    if (selectedRow) {
      if (activeStep < 2) {
        setActiveStep((p) => Math.min(p + 1, 2));
      } else {
        const index = protocolProgress?.findIndex((el) => el === selectedRow);
        if (index !== undefined && index !== -1) {
          // snackBarStore.showToast({
          //   severity: 'success',
          //   summary: 'Success',
          //   detail: `Model: "${selectedRow.protocol.protocolHeader.protocolId}" Uploaded successfully!`,
          // });

          setSelectedRow(protocolProgress?.[index + 1] || null);
          selectedRow.status = t('complete');
          setProtocolProgress((p) => (p ? [...p] : undefined));
          setActiveStep(0);
        }
      }
    }
  }, [
    selectedRow,
    activeStep,
    setActiveStep,
    protocolProgress,
    setProtocolProgress,
    t,
  ]);

  const handleProtocolsApprove = useCallback(() => {
    if (protocolProgress && protocolProgress.length) {
      setApproveLoading(true);
      cropModelStore
        .approveProtocolsJson(protocolProgress.map((el) => el.protocol))
        .then(() => {
          setApproveLoading(false);
          onHide();
        })
        .catch((err) => {
          if (axios.isAxiosError(err)) {
            setErrors([
              (err.response?.data as ErrorResponse)?.message || err.message,
            ]);
          } else if (err instanceof Error) {
            setErrors([err.message]);
          }

          setApproveLoading(false);
        });
    }
  }, [protocolProgress, setApproveLoading, setErrors, onHide, cropModelStore]);

  const resetUploadModal = useCallback(() => {
    setActiveStep(0);
    onHide();
  }, [setActiveStep, onHide]);

  const handleCloseDialog = useCallback(
    (event: any, reason: DialogOnCloseReason) => {
      if (reason && reason === DialogOnCloseReason.BackDropClick) {
        return;
      }

      resetUploadModal();
    },
    [resetUploadModal],
  );

  useEffect(() => {
    setErrors(startingErrors);
    setWarnings([]);
  }, [protocolProgress, setErrors, setWarnings, startingErrors]);

  if (!protocolProgress) {
    return null;
  }

  return (
    <StyledDialog
      open={visible}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="lg"
      dialogTitle={t('upload_review', { filename: uploadFilename })}
      disableEscapeKeyDown
    >
      <StyledDialogContent>
        <Stepper activeStep={activeStep}>
          <StyledStep completed={activeStep > 0} active={activeStep === 0}>
            <StyledStepLabel
              icon={EmptyComponent}
              iserror={`${Boolean(errors.length)}`}
            >
              {t('validation')}
            </StyledStepLabel>
          </StyledStep>
          <StyledStep completed={activeStep > 1} active={activeStep === 1}>
            <StyledStepLabel icon={EmptyComponent}>
              {t('parameters')}
            </StyledStepLabel>
          </StyledStep>
          <StyledStep completed={activeStep > 2} active={activeStep === 2}>
            <StyledStepLabel icon={EmptyComponent}>
              {t('phen_stages_and_kc')}
            </StyledStepLabel>
          </StyledStep>
        </Stepper>
        {activeStep === 0 && (
          <ValidationPage
            errors={errors}
            warnings={warnings}
            protocolProgress={protocolProgress}
            handleSelectionChange={handleSelectionChange}
            selectedRow={selectedRow}
          />
        )}
        {selectedRow && activeStep === 1 && (
          <ParametersPage selectedRow={selectedRow} />
        )}
        {selectedRow && activeStep === 2 && (
          <PhenStagePanel selectedRow={selectedRow.protocol} />
        )}
      </StyledDialogContent>
      <Divider />
      <DialogActions>
        {!(activeStep === 0) && (
          <Button
            color="primary"
            disabled={activeStep === 0}
            onClick={() => setActiveStep((p) => Math.max(p - 1, 0))}
          >
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            &nbsp; {t('general:back')}
          </Button>
        )}
        <Grow />
        <Button onClick={resetUploadModal}>
          <Text textcolor={theme.color.white} size="sm">
            {t('general:cancel')}
          </Text>
        </Button>
        {!(Boolean(errors.length) || !selectedRow) && (
          <Button
            disabled={Boolean(errors.length) || !selectedRow}
            color="primary"
            onClick={handleMoveToNextPage}
          >
            {nextPageButtonText}&nbsp;
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </Button>
        )}
        {Boolean(protocolProgress.length) &&
          protocolProgress.every((el) => el.status === t('complete')) && (
            <Button
              color="primary"
              loading={approveLoading}
              loadingSize="1.5rem"
              onClick={handleProtocolsApprove}
              disabled={approveLoading || Boolean(errors.length)}
            >
              {t('approve_review')}
            </Button>
          )}
      </DialogActions>
    </StyledDialog>
  );
};

export default observer(UploadDialog);
