export const storageKeys = {
  LANG: 'LANG',
  accessTokenBlock: 'accessTokenBlock',
  '@USER_STORAGE_KEY': '@USER_STORAGE_KEY',
};

const veryLocalStorage = {
  set(key: string, value: unknown): void {
    const toSave = JSON.stringify(value);
    localStorage.setItem(key, toSave);
  },
  get<T>(key: string): T | false {
    try {
      const data = localStorage.getItem(key);
      if (!data) return false;
      const json = JSON.parse(data);
      return json;
    } catch {
      return false;
    }
  },
  remove(key: string): void {
    localStorage.removeItem(key);
  },
  clear(): void {
    Object.values(storageKeys).forEach((key) => veryLocalStorage.remove(key));
  },
};

export default veryLocalStorage;
