import React, { FC, memo } from 'react';
import styled from 'styled-components';
import Input from 'components/UI/Input';
import { InputProps } from 'components/UI/Input/Input';

const StyledOutlinedInput = styled(Input)`
  .MuiOutlinedInput-root {
    height: 40px;
  }

  .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }

    &.Mui-focused fieldset {
      border-color: rgba(0, 0, 0, 0.23);
      border-width: 1px;
    }
  }

  ${(p) => `
    .MuiOutlinedInput-input {
    background-color: ${p.theme.color.white};
    width: 274px;
    height: 40px;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    font-style: normal;
    padding: 0 0.25rem;

    ::-webkit-input-placeholder {
      /* Edge */
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      opacity: 1;
    }

    ::placeholder {
      opacity: 1;
    }
  }
  `}
`;

const OutlinedInput: FC<InputProps> = ({ ...rest }) => (
  <StyledOutlinedInput variant="outlined" {...rest} />
);

export default memo(OutlinedInput);
