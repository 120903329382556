import { MapRef } from 'components/shared/Map/Map.types';
import { useIsInViewport } from 'hooks/useIsInViewport';
import { RefObject, useEffect } from 'react';

interface UseAgronomicAlertsMapProps {
  mapContainerRef: RefObject<HTMLElement>;
  mapRef: RefObject<MapRef>;
}

const useMapInvalidateSize = ({
  mapContainerRef,
  mapRef,
}: UseAgronomicAlertsMapProps): void => {
  const isMapVisible = useIsInViewport(mapContainerRef);

  useEffect(() => {
    if (isMapVisible) {
      mapRef.current?.map.invalidateSize();
    }
  }, [isMapVisible, mapRef]);
};

export default useMapInvalidateSize;
