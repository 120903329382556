import React, {
  FC,
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import Text from 'components/UI/Text';
import Grid from 'components/UI/Grid';
import {
  Column,
  PrimeReactDataTable,
} from 'components/shared/DataTable/DataTable';
import DataTable from 'components/shared/DataTable';
import { useStores } from 'stores/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import {
  DataTablePageParams,
  DataTableProps,
  DataTableRowClickEventParams,
} from 'primereact/datatable';
import { UserGrower } from 'models/user';
import { Checkbox } from 'primereact/checkbox';
import { FilterMatchMode } from 'primereact/api';

interface userGrowersTableProps {
  title: string;
  isAssigned: boolean;
}

const GrowersContainer = styled(Grid)`
  height: 100%;

  .p-datatable-table {
    .column-checkbox {
      width: 4rem;
      text-align: center;

      .p-checkbox {
        display: inline-block;
      }
    }
  }
`;

const TextInline = styled(Text)`
  display: inline-flex;
  font-weight: bold;
`;

const allGrowersTableFilters: DataTableProps['filters'] = {
  name: { value: '', matchMode: FilterMatchMode.CONTAINS },
  resellerName: { value: '', matchMode: FilterMatchMode.CONTAINS },
};

const DEFAULT_MAX_ROWS = 10;

const UserGrowersTable: FC<userGrowersTableProps> = observer(
  ({ title, isAssigned }) => {
    const { t } = useTranslation('users');
    const { membersStore, snackBarStore, resellersStore } = useStores();
    const [loading, setLoading] = useState(false);
    const [loadLazyTimeout, setLoadLazyTimeout] =
      useState<NodeJS.Timeout | null>(null);

    const [totalRecords, setTotalRecords] = useState(0);
    const dataTable = useRef<PrimeReactDataTable | null>(null);
    const [lazyParams, setLazyParams] = useState({
      first: 0,
      rows: 10,
      page: 0,
    });

    const tableValue: UserGrower[] = isAssigned
      ? membersStore.memberGrowers
      : resellersStore.getResellersTreeGrowers(
          new Set(membersStore.memberGrowers.map((grower) => grower.id)),
        );

    const selection: UserGrower[] = isAssigned
      ? membersStore.selectedRowsMemberGrower
      : membersStore.selectedRowsMembersGrowersAvailable;

    const selectedRowIds: Set<number> = useMemo(
      () => new Set(selection.map((user) => user.id)),
      [selection],
    );

    useEffect(() => {
      if (!resellersStore.dataTree.length) {
        resellersStore.initData();
      }
    }, [resellersStore]);

    const loadLazyData = useCallback(() => {
      setLoading(true);
      if (loadLazyTimeout) {
        clearTimeout(loadLazyTimeout);
      }

      setLoadLazyTimeout(
        setTimeout(() => {
          const userGrowers = isAssigned
            ? membersStore.getMembersGrowersAssigned(
                lazyParams.first,
                lazyParams.rows,
              )
            : membersStore.getMembersGrowersAvailable(
                lazyParams.first,
                lazyParams.rows,
              );

          userGrowers
            .then((data) => {
              setTotalRecords(data);
              setLoading(false);
            })
            .catch((err) => {
              if (err instanceof Error) {
                snackBarStore.showToast({ detail: err.message });
              }

              setLoading(false);
            });
        }),
      );
    }, [
      isAssigned,
      lazyParams.first,
      lazyParams.rows,
      loadLazyTimeout,
      membersStore,
      snackBarStore,
    ]);

    useEffect(() => {
      return () => {
        membersStore.setSelectedRowsMemberGrower({
          selection: [],
          activeRow: null,
        });

        membersStore.setSelectedRowsMembersGrowersAvailable([]);
      };
    }, [membersStore]);

    useEffect(() => {
      loadLazyData();
    }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

    const onPage = useCallback(
      async (e: DataTablePageParams) => {
        if (e.page === lazyParams.page) {
          return;
        }

        setLazyParams((p) => ({ ...p, ...e }));
      },
      [lazyParams],
    );

    const setSelectedRows = useCallback(
      (data: UserGrower[]) => {
        if (isAssigned) {
          const { activeRow } = membersStore.memberGrowerTableMultiSelection;
          membersStore.setSelectedRowsMemberGrower({
            selection: data,
            activeRow,
          });
        } else {
          membersStore.setSelectedRowsMembersGrowersAvailable(data);
        }
      },
      [isAssigned, membersStore],
    );

    const updateSelection = useCallback(
      ({ data }: DataTableRowClickEventParams) => {
        if (isAssigned) {
          membersStore.setSelectedRowsMembersGrowersAvailable([]);
        } else {
          membersStore.setSelectedRowsMemberGrower({
            selection: [],
            activeRow: null,
          });
        }

        if (selectedRowIds.has(data.id)) {
          setSelectedRows(selection.filter((user) => user.id !== data.id));
        } else {
          setSelectedRows([...selection, data]);
        }
      },
      [isAssigned, membersStore, setSelectedRows, selection, selectedRowIds],
    );

    const getCheckboxBody = useCallback(
      (data: UserGrower) => (
        <Checkbox
          checked={selectedRowIds.has(data.id)}
          onChange={() =>
            updateSelection({ data } as DataTableRowClickEventParams)
          }
        />
      ),
      [updateSelection, selectedRowIds],
    );

    const dataTableProps: Partial<DataTableProps> = useMemo(
      () =>
        isAssigned
          ? {
              id: 'UserGrowersAssignedTable',
              loading,
              lazy: true,
              onPage,
              first: lazyParams.first,
              rows: lazyParams.rows,
              totalRecords,
            }
          : {
              id: 'UserGrowersAvailableTable',
              rows: DEFAULT_MAX_ROWS,
              totalRecords: tableValue.length,
              filterDisplay: 'row',
              filters: allGrowersTableFilters,
            },
      [
        isAssigned,
        lazyParams,
        loading,
        onPage,
        tableValue.length,
        totalRecords,
      ],
    );

    return (
      <GrowersContainer flex="3" alignSelf="stretch">
        <TextInline>{title}</TextInline>
        <DataTable
          id="UserGrowersAssignedTable"
          ref={dataTable}
          resizableColumns
          value={tableValue}
          dataKey="id"
          paginator
          showGridlines
          stripedRows
          selectionMode="multiple"
          selection={selection}
          onRowClick={updateSelection}
          {...dataTableProps}
        >
          <Column
            header=""
            body={getCheckboxBody}
            className="column-checkbox"
          />
          <Column field="name" header={t('grower')} filter={!isAssigned} />
          <Column
            field="resellerName"
            header={t('reseller')}
            filter={!isAssigned}
          />
          {isAssigned && (
            <Column field="userGrowerRole" header={t('supplant_role')} />
          )}
        </DataTable>
      </GrowersContainer>
    );
  },
);

export default UserGrowersTable;
