import React, { FC } from 'react';
import styled from 'styled-components';

const BodyLayout = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex: 1;
  background-color: ${(p) => p.theme.color.cascadingWhite};
  flex-direction: row;
`;

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => (
  <BodyLayout>{children}</BodyLayout>
);

export default Layout;
