import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Button';
import Text from 'components/UI/Text';
import { theme } from 'components/UI';
import { useStores } from 'stores/hooks/hooks';

function onlyNumbers(str: string) {
  return str?.length === 0 || /^\d+$/.test(str);
}

interface SmsCodeLoginProps {
  phoneNumber: string;
  smsRequestId: string;
}

interface InputRefs {
  inputRef: HTMLInputElement | null;
}

const StyledButton = styled(Button)`
  background-color: ${(p) => p.theme.color.leafGreen};

  &:hover {
    background-color: ${(p) => p.theme.color.leafGreen};
  }
`;

const SmsOtcInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 20px;
  margin-top: 20px;

  body[dir='rtl'] & {
    flex-direction: row-reverse;
  }
`;

const SmsInputBox = styled.div`
  display: flex;
  height: 2em;
  width: 300px;
  border-style: solid;
  border-width: 1px;
  align-items: center;
  justify-content: center;
`;

const SmsNumber = styled.input`
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
  padding: 0;
  font-weight: normal;
  font-size: 20px;
  color: #112a32;
  text-align: center;
`;

const ResendSpan = styled.span`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledResendText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
`;

const StyledResendTextUnderlined = styled(StyledResendText)`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 3px 0px 3px;
`;

const SmsCodeLogin: FC<SmsCodeLoginProps> = ({ phoneNumber, smsRequestId }) => {
  const { t } = useTranslation('auth');
  const { userStore } = useStores();
  const [requestId, setRequestId] = useState(smsRequestId || '');
  const [smsInput, setSmsInput] = useState<string>('');

  const inputRefs = useRef<InputRefs>({
    inputRef: null,
  });

  const inputResult = useMemo(() => `${smsInput}`, [smsInput]);

  const handleChangeSmsInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const newString = event.target.value;
      if (!onlyNumbers(newString)) {
        return;
      }

      setSmsInput(newString);
    },
    [],
  );

  const handleSubmitOtc = useCallback(async () => {
    await userStore.verifyOtcLogin(requestId, inputResult);
  }, [inputResult, requestId, userStore]);

  const handleClickResendLink = useCallback(async () => {
    if (userStore) {
      const phoneSendResult = await userStore.getSmsPhoneCode(phoneNumber);
      setRequestId(phoneSendResult);
    }
  }, [phoneNumber, userStore]);

  return (
    <>
      <SmsOtcInputContainer>
        <SmsInputBox>
          <SmsNumber
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            type="number"
            value={smsInput}
            onChange={(e) => handleChangeSmsInput(e)}
            autoComplete="one-time-code"
            autoCorrect="off"
            spellCheck="false"
            maxLength={4}
            ref={(ref) => {
              inputRefs.current.inputRef = ref;
            }}
          />
        </SmsInputBox>
      </SmsOtcInputContainer>
      <br />
      <ResendSpan>
        <StyledResendText>{`${t('did_not_receive_sms')}`}</StyledResendText>
        <StyledResendTextUnderlined onClick={handleClickResendLink}>
          {t('resend')}
        </StyledResendTextUnderlined>
      </ResendSpan>
      <br />
      <StyledButton type="submit" onClick={handleSubmitOtc} fullWidth>
        <Text textcolor={theme.color.white} size="sm" weight="bold">
          {t('login')}
        </Text>
      </StyledButton>
    </>
  );
};

export default SmsCodeLogin;
