import React, { FC } from 'react';
import styled from 'styled-components';
import Text from 'components/UI/Text';
import { Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import i18next from 'i18next';
import { IProtocol } from 'models/protocol';

const StyledTextCategoryText = styled(Text)`
  width: 100%;
`;

const CropCategoryBodyContainer: FC<{ data: IProtocol }> = observer(
  ({ data }) => {
    const { classification } = data;
    const fullText = classification.cropCategory
      ? classification.cropCategory.toString().replaceAll(',', ', ')
      : i18next.t('crop_models:not_available');

    return (
      <Tooltip arrow title={fullText} placement="top">
        <StyledTextCategoryText inline singleline size="md">
          {fullText}
        </StyledTextCategoryText>
      </Tooltip>
    );
  },
);

const CropCategoryBody = (props: IProtocol) => {
  return <CropCategoryBodyContainer data={props} />;
};

export default CropCategoryBody;
