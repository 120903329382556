import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { observer } from 'mobx-react';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { checkIfValidLatitudeAndLongitude } from 'utils/functions';
import { ActionMode, DialogOnCloseReason } from 'models/shared';
import styled from 'styled-components';
import PlotFormProperties, {
  PlotFormPropertiesRef,
} from './PlotFormProperties';
import { validatePlotCreatePayload } from './PlotManagementDialog.utils';

interface PlotManagementDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const StyledDialogContent = styled(DialogContent)`
  padding: 0 1rem;
`;

const PlotManagementDialog: FC<PlotManagementDialogProps> = observer(
  ({ visible = false, onHide, title, mode }) => {
    const { t } = useTranslation('plot');
    const { plotsStore, resellersStore, snackBarStore } = useStores();
    const plotFormPropertiesRef = useRef<PlotFormPropertiesRef>(null);

    const [initialUserActionString, setInitialUserActionString] = useState('');
    const [location, setLocation] = useState(
      mode === ActionMode.CREATE
        ? ''
        : `${plotsStore.plotAction.latitude},${plotsStore.plotAction.longitude}`,
    );

    const [isAttachVirtualCurrentSensor, setIsAttachVirtualCurrentSensor] =
      useState(true);

    const [isAttachVirtualForecastSensor, setIsAttachVirtualForecastSensor] =
      useState(true);

    const [isAttachIWSensor, setIsAttachIWSensor] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [isDuplicating, setIsDuplicating] = useState(false);

    const isValidLocation = useMemo(
      () => checkIfValidLatitudeAndLongitude(location),
      [location],
    );

    useEffect(() => {
      if (
        initialUserActionString ||
        JSON.stringify(plotsStore.plotAction) === '{}'
      ) {
        return;
      }

      setInitialUserActionString(JSON.stringify(plotsStore.plotAction));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plotsStore.plotAction]);

    const isDirty =
      initialUserActionString !== JSON.stringify(plotsStore.plotAction);

    const isFormValid =
      validatePlotCreatePayload(plotsStore.plotAction) && isValidLocation;

    const closeDialog = useCallback(() => {
      setInitialUserActionString('');
      onHide();
    }, [onHide]);

    const handleCloseDialog = useCallback(
      (event: unknown, reason: DialogOnCloseReason) => {
        if (reason && reason === DialogOnCloseReason.BackDropClick) {
          return;
        }

        closeDialog();
      },
      [closeDialog],
    );

    const handleCreatePlot = useCallback(async () => {
      const isPlotCreated = await plotsStore.createPlot({
        attachVirtualCurrentSensor: isAttachVirtualCurrentSensor,
        attachVirtualForecastSensor: isAttachVirtualForecastSensor,
        attachIWSensor: isAttachIWSensor,
      });

      if (isPlotCreated) {
        resellersStore.addNewPlot(plotsStore.selectedRows[0]);
        onHide();
      }
    }, [
      isAttachVirtualCurrentSensor,
      isAttachVirtualForecastSensor,
      isAttachIWSensor,
      onHide,
      plotsStore,
      resellersStore,
    ]);

    const handleEditPlot = useCallback(async () => {
      const editedPlot = await plotsStore.editPlot();

      if (editedPlot) {
        resellersStore.editPlot(editedPlot);
        setInitialUserActionString('');
        snackBarStore.showToast({
          detail: `plot ${editedPlot.name}, Edited successfully`,
          severity: 'success',
          summary: 'Success',
        });

        onHide();
      }
    }, [onHide, plotsStore, resellersStore, snackBarStore]);

    const handleSubmitClick = useCallback(async () => {
      setIsSaving(true);
      if (mode === ActionMode.CREATE) {
        await handleCreatePlot();
      } else {
        await handleEditPlot();
      }

      setIsSaving(false);
    }, [mode, handleCreatePlot, handleEditPlot]);

    const handleCreateAndDuplicateClick = useCallback(async () => {
      setIsDuplicating(true);
      const isPlotCreated = await plotsStore.createPlot({
        attachVirtualCurrentSensor: isAttachVirtualCurrentSensor,
        attachVirtualForecastSensor: isAttachVirtualForecastSensor,
        attachIWSensor: isAttachIWSensor,
      });

      if (isPlotCreated) {
        resellersStore.addNewPlot(plotsStore.selectedRows[0]);
        setIsAttachVirtualCurrentSensor(true);
        setIsAttachVirtualForecastSensor(true);
        setIsAttachIWSensor(false);
        plotFormPropertiesRef.current?.resetPropertiesOnDuplicate();
      }

      setIsDuplicating(false);
    }, [
      plotsStore,
      resellersStore,
      isAttachVirtualCurrentSensor,
      isAttachVirtualForecastSensor,
      isAttachIWSensor,
    ]);

    const isSubmitActionDisabled = !isFormValid || isDuplicating || isSaving;

    return (
      <Dialog
        open={visible}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <StyledDialogContent>
          <PlotFormProperties
            ref={plotFormPropertiesRef}
            mode={mode}
            isAttachVirtualCurrentSensor={isAttachVirtualCurrentSensor}
            isAttachVirtualForecastSensor={isAttachVirtualForecastSensor}
            isAttachIWSensor={isAttachIWSensor}
            setIsAttachVirtualCurrentSensor={setIsAttachVirtualCurrentSensor}
            setIsAttachVirtualForecastSensor={setIsAttachVirtualForecastSensor}
            setIsAttachIWSensor={setIsAttachIWSensor}
            setLocation={setLocation}
          />
        </StyledDialogContent>
        <Divider />
        <DialogActions>
          {mode === ActionMode.CREATE ? (
            <>
              <Button
                onClick={handleSubmitClick}
                disabled={isSubmitActionDisabled}
                loading={isSaving}
              >
                <Text textcolor={theme.color.white} size="md">
                  {t('create')}
                </Text>
              </Button>
              <Button
                onClick={handleCreateAndDuplicateClick}
                disabled={isSubmitActionDisabled}
                loading={isDuplicating}
              >
                <Text textcolor={theme.color.white} size="md">
                  {t('create_and_duplicate')}
                </Text>
              </Button>
            </>
          ) : (
            <Button
              onClick={handleSubmitClick}
              disabled={!isDirty || isSubmitActionDisabled}
              loading={isSaving}
            >
              <Text textcolor={theme.color.white} size="sm">
                {t('edit')}
              </Text>
            </Button>
          )}
          <Button onClick={closeDialog}>
            <Text textcolor={theme.color.white} size="sm">
              {t('cancel')}
            </Text>
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default PlotManagementDialog;
