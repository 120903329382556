import React, { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AlertSeverity, AlertState } from 'models/alert';
import { GqlPlotAlertData } from 'gql/types/plots';
import { DEFAULT_DATE_FORMAT } from 'core/constants';
import { getAlertTypeTranslation } from 'utils/alertsUtils';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { theme } from 'components/UI';

const Row = styled(Grid)`
  width: 100%;
`;

const ListRow = styled(Row)`
  padding-top: 1rem;

  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${theme.color.border};
  }
`;

const StyledText = styled(Text)`
  color: ${theme.color.text};
`;

const severityColor: Record<AlertSeverity, string> = {
  [AlertSeverity.High]: theme.color.red,
  [AlertSeverity.Medium]: theme.color.amber,
  [AlertSeverity.Low]: theme.color.leafGreen,
};

interface AlertTooltipProps {
  plot: GqlPlotAlertData;
}

const AlertTooltip: FC<AlertTooltipProps> = ({ plot }) => {
  const { t } = useTranslation('grower');

  return (
    <Row direction="column" gap="0.5rem">
      <Row direction="row" gap="1rem">
        <StyledText>{t('plot_name')}:</StyledText>{' '}
        <StyledText weight="bold">{plot.name}</StyledText>
      </Row>
      <Row>
        {plot.alerts?.map((alert) => (
          <ListRow>
            <Row direction="row" justify="between" gap="1rem">
              <StyledText>{t('severity')}:</StyledText>{' '}
              <Text weight="bold" color={severityColor[alert.severity]}>
                {alert.severity}
              </Text>
            </Row>
            <Row direction="row" justify="between" gap="1rem">
              <StyledText>{t('status')}:</StyledText>{' '}
              <Text
                weight="bold"
                color={
                  alert.state === AlertState.Active
                    ? theme.color.red
                    : theme.color.leafGreen
                }
              >
                {alert.state}
              </Text>
            </Row>
            <Row direction="row" justify="between" gap="1rem">
              <StyledText>{t('type')}:</StyledText>{' '}
              <StyledText>
                {getAlertTypeTranslation(alert.type?.type)}
              </StyledText>
            </Row>
            <Row direction="row" justify="between" gap="1rem">
              <StyledText>{t('date')}:</StyledText>{' '}
              <StyledText>
                {format(parseISO(alert.timestamp), DEFAULT_DATE_FORMAT)}
              </StyledText>
            </Row>
          </ListRow>
        ))}
      </Row>
    </Row>
  );
};

export default AlertTooltip;
