import { observer } from 'mobx-react';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/shared/DataTable';
import { Column } from 'components/shared/DataTable/DataTable';
import { PhenStageColumn } from 'models/protocol';

const phenStageColumnStyle = {
  width: '45%',
};

const dateColumnStyle = {
  width: '30%',
};

const durationColumnStyle = {
  width: '20%',
};

const fDeltaColumnStyle = {
  width: '20%',
};

interface IPhenStageTable {
  ModelAgeRanges: PhenStageColumn[];
  selectedPhenStageRow: PhenStageColumn | null;
  setSelectedPhenStageRow: Dispatch<SetStateAction<PhenStageColumn | null>>;
}

const PhenStageTable: FC<IPhenStageTable> = (props) => {
  const { ModelAgeRanges, selectedPhenStageRow, setSelectedPhenStageRow } =
    props;

  const { t } = useTranslation('crop_models');
  const handleSelectionChange = useCallback(
    (e: DataTableSelectionChangeParams) => {
      setSelectedPhenStageRow(e.value);
    },
    [setSelectedPhenStageRow],
  );

  return (
    <DataTable
      resizableColumns
      showGridlines
      scrollable
      value={ModelAgeRanges}
      selectionMode="single"
      selection={selectedPhenStageRow}
      onSelectionChange={handleSelectionChange}
      dataKey="name"
      compareSelectionBy="equals"
    >
      <Column
        field="name"
        header={t('phen_stage')}
        style={phenStageColumnStyle}
      />
      <Column field="date" header={t('start_date')} style={dateColumnStyle} />
      <Column
        field="duration"
        header={t('duration')}
        style={durationColumnStyle}
      />
      <Column field="fdelta" header={t('fdelta')} style={fDeltaColumnStyle} />
    </DataTable>
  );
};

export default observer(PhenStageTable);
