import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import { useStores } from 'stores/hooks/hooks';
import Text from 'components/UI/Text';
import Space from 'components/UI/Space';
import { useTranslation } from 'react-i18next';
import { Divider, Paper } from '@mui/material';
import Grid from 'components/UI/Grid';
import { TabPanel, TabView } from 'components/UI/TabView';
import { AgeRange, GrowingType } from 'models/protocol';
import PhenStages from './PhenStages/PhenStages';
import Plots from './Plots/Plots';

const RowTitleContainer = styled(Grid)`
  margin: 0.5rem;
`;

const MultipleRowDetailsContainer = styled(RowTitleContainer)`
  max-height: calc(50% - 1rem);
`;

const ModelDetailsContainer = styled(Paper)<{ $allowScroll?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(p) => p.theme.palette.background.default};
  margin: 1rem;
  overflow: ${(p) => (p.$allowScroll ? 'auto' : 'hidden')};
  height: calc(100% - 2rem); /* 2rem to negate margin top and bottom */
  max-height: calc(100% - 2rem);
`;

const StyledDivider = styled(Divider)`
  height: '2px';
  background-color: ${(p) => p.theme.color.primeBorder};
`;

const ModelDetails: FC = () => {
  const { cropModelStore } = useStores();
  const { selectedRows } = cropModelStore;
  const [activeIndex, setActiveIndex] = useState(1);
  const [sharedDropDownAgeRange, setSharedDropDownAgeRange] =
    useState<AgeRange | null>(
      (selectedRows.length &&
        selectedRows[0].ageRange[selectedRows[0].ageRange.length - 1]) ||
        null,
    );

  const [sharedDropdownGrowingType, setSharedDropdownGrowingType] =
    useState<GrowingType | null>(
      sharedDropDownAgeRange?.growingType[0] || null,
    );

  const [sharedDate, setSharedDate] = useState<Date | null>(
    new Date(new Date(new Date().setMonth(0)).setDate(1)),
  );

  const tabPanelContentStyle = useCallback(
    (index: number) => ({
      display: index === activeIndex ? 'flex' : 'none',
      flex: 1,
    }),
    [activeIndex],
  );

  const firstRow = selectedRows?.[0];
  useEffect(() => {
    if (selectedRows.length) {
      const ageRange =
        selectedRows[0].ageRange[selectedRows[0].ageRange.length - 1];

      setSharedDropDownAgeRange(ageRange);

      setSharedDropdownGrowingType(ageRange.growingType[0]);
    }
  }, [selectedRows, firstRow]);

  const { t } = useTranslation('crop_models');
  if (!selectedRows.length) {
    return null;
  }

  if (selectedRows.length > 1) {
    return (
      <ModelDetailsContainer elevation={3} square $allowScroll>
        {selectedRows.map((el, i) => (
          <MultipleRowDetailsContainer
            key={el.protocolHeader.protocolId}
            align="stretch"
            flex={1}
          >
            <Grid align="start" direction="row" justify="start">
              <Text>{el.protocolHeader.description}</Text>
              <Space width={1} />
              <Text size="md">{el.protocolHeader.protocolId}</Text>
            </Grid>
            <StyledDivider />
            <Space height={1} />
            <PhenStages
              selectedRow={el}
              sharedDropDownAgeRange={sharedDropDownAgeRange}
              setSharedDropDownAgeRange={setSharedDropDownAgeRange}
              sharedDropdownGrowingType={sharedDropdownGrowingType}
              setSharedDropdownGrowingType={setSharedDropdownGrowingType}
              sharedDate={sharedDate}
              setSharedDate={setSharedDate}
              isSecond={i !== 0}
            />
          </MultipleRowDetailsContainer>
        ))}
      </ModelDetailsContainer>
    );
  }

  return (
    <ModelDetailsContainer elevation={3} square>
      <RowTitleContainer direction="row" justify="start" align="end">
        <Text>{selectedRows[0].protocolHeader.description}</Text>
        <Space width={1} />
        <Text size="md">{selectedRows[0].protocolHeader.protocolId}</Text>
      </RowTitleContainer>
      <TabView
        activeIndex={activeIndex}
        renderActiveOnly={false}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel
          contentStyle={tabPanelContentStyle(0)}
          header={t('parameters')}
        >
          {selectedRows.map((el) => (
            <div key={el.protocolHeader.protocolId}>
              <ReactJson src={el} />
            </div>
          ))}
        </TabPanel>
        <TabPanel
          contentStyle={tabPanelContentStyle(1)}
          header={t('phen_stages')}
        >
          <PhenStages selectedRow={selectedRows[0]} />
        </TabPanel>
        <TabPanel
          contentStyle={tabPanelContentStyle(2)}
          header={t('matched_plots')}
        >
          <Plots selectedRows={selectedRows} />
        </TabPanel>
        <TabPanel
          contentStyle={tabPanelContentStyle(3)}
          header={t('change_log')}
        >
          {t('change_log')}
        </TabPanel>
      </TabView>
    </ModelDetailsContainer>
  );
};

export default observer(ModelDetails);
