import { useCallback, useState } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import { AlertTableFilters } from '../AlertsTable';

interface UseAlertsTableFiltersValue {
  alertFilters: AlertTableFilters;
  debouncedFilters: AlertTableFilters;
  onAlertFilterChange(key: keyof AlertTableFilters, value: string): void;
}

export const useAlertsTableFilters = (): UseAlertsTableFiltersValue => {
  const [alertFilters, setAlertFilters] = useState<AlertTableFilters>({
    alertSubType: '',
    alertType: '',
    plotName: '',
  });

  const debouncedFilters = useDebounce(alertFilters);

  const onAlertFilterChange = useCallback(
    (key: keyof AlertTableFilters, value: string) => {
      setAlertFilters((prev) => ({ ...prev, [key]: value }));
    },
    [],
  );

  return { alertFilters, debouncedFilters, onAlertFilterChange };
};
