/* eslint-disable @typescript-eslint/no-explicit-any */
import { differenceInDays } from 'date-fns';
import { faCheck, faArchive, faFile } from '@fortawesome/free-solid-svg-icons';
import { PlotStatus } from 'models/plot';

/**
 * returns hex value for generated from the percent param
 * @param valNum percent int
 */
export const percentToHex = (valNum: number) => {
  const decimalValue = Math.round((valNum * 255) / 100);
  let hexValue;
  if (valNum < 7) {
    hexValue = `0${decimalValue.toString(16).toUpperCase()}`;
  } else {
    hexValue = decimalValue.toString(16).toUpperCase();
  }

  return hexValue;
};

export const promiseMaker = (): {
  promise: Promise<any>;
  res: any;
  rej: any;
} => {
  let res: any;
  let rej: any;
  const promise: Promise<any> = new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;
  });

  return {
    promise,
    res,
    rej,
  };
};

export const paramSerializer = (params: { [text: string]: any }) => {
  const parameters = new URLSearchParams();
  (Object.keys(params) as Array<string>).forEach((el) => {
    const value = params[el];
    if (value && value.toString) {
      parameters.append(el, value.toString());
    }
  });

  return parameters.toString();
};

/**
 *
 * @param field json field string, for example json {yellow:{test:'hello'}}, field string here is "yellow.test" to get 'hello'
 * @param data the json object
 */
export const resolveFieldData = (field: string | undefined, data: any) => {
  return field
    ?.split('.')
    .reduce((dataObject: any, remainingFieldString: string) => {
      return dataObject && dataObject[remainingFieldString];
    }, data);
};

export const calculateAgeByDate = (date: number) => {
  return `${Number(
    (differenceInDays(Date.now(), Number(date) || 0) / 365).toFixed(3),
  ).toFixed(3)} y`;
};

export const plotStatusIcon = (statusName: string) => {
  switch (statusName) {
    case PlotStatus.Active:
      return faCheck;
    case PlotStatus.Draft:
      return faFile;
    case PlotStatus.Archive:
      return faArchive;
    default:
      return null;
  }
};

export const checkIfValidLatitudeAndLongitude = (str: string) => {
  const regexExp = /^((-?|\+?)?\d+(\.\d+)?),\s*((-?|\+?)?\d+(\.\d+)?)$/gi;
  return regexExp.test(str);
};

export const getUnique = <T>(values: T[]): T[] => Array.from(new Set(values));
