import React from 'react';
import styled from 'styled-components';
import Text from 'components/UI/Text';
import { Tooltip } from '@mui/material';
import { ProtocolProgress } from 'models/protocol';

const StyledTextWarningText = styled(Text)`
  width: 100%;
  background-color: orange;
`;

const CropWarningBody = (props: ProtocolProgress) => {
  const { protocol } = props;
  const { importWarnings } = protocol;
  const fullText = importWarnings.toString().replaceAll(',', ', ');

  return (
    <Tooltip arrow title={fullText} placement="top">
      <StyledTextWarningText inline singleline size="md">
        {fullText}
      </StyledTextWarningText>
    </Tooltip>
  );
};

export default CropWarningBody;
