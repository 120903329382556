import React, { FC } from 'react';
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
} from 'react-hook-form';
import { observer } from 'mobx-react';
import { useStores } from 'stores/hooks/hooks';
import SystemsTypeCreateStep from './SystemsCreateSteps/SystemsTypeCreateStep';
import SystemsConfigurationCreateStep from './SystemsCreateSteps/SystemsConfigurationCreateStep';
import {
  SystemCreateStep,
  SystemCreateValidationSchema,
} from './validationSchemas/systemCreateValidationSchema';
import SystemsDiscoveryCreateStep from './SystemsCreateSteps/SystemsDiscoveryCreateStep';
import SystemsStatusCreateStep from './SystemsCreateSteps/SystemsStatusCreateStep';
import SystemsSummaryCreateStep from './SystemsCreateSteps/SystemsSummaryCreateStep';

interface SystemsCreateFormPropertiesProps {
  step: SystemCreateStep;
  control: Control<SystemCreateValidationSchema>;
  register: UseFormRegister<SystemCreateValidationSchema>;
  errors: FieldErrors<SystemCreateValidationSchema>;
  getValues: UseFormGetValues<SystemCreateValidationSchema>;
}

export type SystemsCreateFormStepProps = Pick<
  SystemsCreateFormPropertiesProps,
  'control' | 'register' | 'errors' | 'getValues'
>;

const SystemsCreateFormProperties: FC<SystemsCreateFormPropertiesProps> =
  observer(({ step, ...rest }) => {
    const { systemsStore } = useStores();
    const isSystemOnline =
      !systemsStore.createSystemForm.newSystemSensors.error;

    if (step === SystemCreateStep.TYPE) {
      return <SystemsTypeCreateStep {...rest} />;
    }

    if (step === SystemCreateStep.CONFIGURATION) {
      return <SystemsConfigurationCreateStep {...rest} />;
    }

    if (step === SystemCreateStep.DISCOVERY) {
      return isSystemOnline ? (
        <SystemsDiscoveryCreateStep {...rest} />
      ) : (
        <SystemsStatusCreateStep />
      );
    }

    if (step === SystemCreateStep.SUMMARY) {
      return <SystemsSummaryCreateStep {...rest} />;
    }

    return null;
  });

export default SystemsCreateFormProperties;
