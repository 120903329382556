import React, { FC } from 'react';
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
} from 'react-hook-form';
import { observer } from 'mobx-react';
import { CreateFeatureForm } from 'stores/featuresStore';
import FeatureTypeCreateStep from './FeatureCreateSteps/FeatureTypeCreateStep';
import FeatureConfigurationCreateStep from './FeatureCreateSteps/FeatureConfigurationCreateStep';
import FeaturesDiscoveryCreateStep from './FeatureCreateSteps/FeaturesDiscoveryCreateStep';
import FeaturesSummaryCreateStep from './FeatureCreateSteps/FeaturesSummaryCreateStep';

export enum FeatureCreateStep {
  TYPE = 0,
  CONFIGURATION = 1,
  DISCOVERY = 2,
  SUMMARY = 3,
}

interface FeatureCreateFormPropertiesProps {
  step: FeatureCreateStep;
  control: Control<any>;
  errors: FieldErrors;
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<CreateFeatureForm>;
}

export type FeatureCreateFormStepProps = Pick<
  FeatureCreateFormPropertiesProps,
  'control' | 'errors' | 'register' | 'getValues'
>;

const FeatureCreateFormProperties: FC<FeatureCreateFormPropertiesProps> =
  observer(({ step, ...rest }) => {
    if (step === FeatureCreateStep.TYPE) {
      return <FeatureTypeCreateStep {...rest} />;
    }

    if (step === FeatureCreateStep.CONFIGURATION) {
      return <FeatureConfigurationCreateStep {...rest} />;
    }

    if (step === FeatureCreateStep.DISCOVERY) {
      return <FeaturesDiscoveryCreateStep {...rest} />;
    }

    if (step === FeatureCreateStep.SUMMARY) {
      return <FeaturesSummaryCreateStep {...rest} />;
    }

    return null;
  });

export default FeatureCreateFormProperties;
