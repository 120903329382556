import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnSortParams } from 'primereact/column';
import Grid from 'components/UI/Grid';
import { User } from 'models/user';

const getLoginTimeDate = (date: number | null): number =>
  new Date(date ?? '')?.getTime();

const StatusBodyContainer: FC<{ data: User }> = observer(({ data }) => {
  return (
    <Grid>
      {data.active ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <FontAwesomeIcon icon={faTimes} />
      )}
    </Grid>
  );
});

export const StatusBody = (data: User) => {
  return <StatusBodyContainer data={data} />;
};

export const loginTimeSortFunction =
  (data: User[]) => (params: ColumnSortParams) => {
    const sortedData = data.slice();
    const { order } = params;
    if (order === 1 || order === -1) {
      return sortedData.sort(
        (a, b) =>
          (getLoginTimeDate(a.lastLoginTime) -
            getLoginTimeDate(b.lastLoginTime)) *
          order,
      );
    }

    return sortedData;
  };

const GoogleAnalyticsBodyContainer: FC<{ data: User }> = observer(
  ({ data }) => {
    const { gaRelevant } = data;
    return (
      <Grid>
        {gaRelevant ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        )}
      </Grid>
    );
  },
);

export const GoogleAnalyticsBody = (data: User) => {
  return <GoogleAnalyticsBodyContainer data={data} />;
};
