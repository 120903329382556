import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  faCheck,
  faTimes,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { Tooltip } from '@mui/material';
import { useStores } from 'stores/hooks/hooks';
import { Checkbox } from 'primereact/checkbox';
import { theme } from 'components/UI';
import styled from 'styled-components';
import { LANGUAGES } from 'core/constants';
import { UserExcel } from 'models/user';

const ErrorText = styled(Text)`
  color: red;
`;

const UserFirstNameBody = (data: UserExcel) => {
  return <UserFirstNameBodyContainer data={data} />;
};

const UserFirstNameBodyContainer: FC<{ data: UserExcel }> = observer(
  (props) => {
    const { data } = props;
    const firstName = data?.data?.firstName;
    const errorMessage = data.importErrors.find((error) =>
      error.fieldName.includes('FIRST_NAME'),
    )?.msg;

    return (
      <Tooltip arrow title={errorMessage || firstName} placement="top">
        {errorMessage ? (
          <ErrorText>{firstName || errorMessage}</ErrorText>
        ) : (
          <Text>{firstName}</Text>
        )}
      </Tooltip>
    );
  },
);

const UserLastNameBody = (data: UserExcel) => {
  return <UserLastNameBodyContainer data={data} />;
};

const UserLastNameBodyContainer: FC<{ data: UserExcel }> = observer((props) => {
  const { data } = props;
  const lastName = data?.data?.lastName;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('LAST_NAME'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || lastName} placement="top">
      {errorMessage ? (
        <ErrorText>{lastName || errorMessage}</ErrorText>
      ) : (
        <Text>{lastName}</Text>
      )}
    </Tooltip>
  );
});

const UserEmailBody = (data: UserExcel) => {
  return <UserEmailBodyContainer data={data} />;
};

const UserEmailBodyContainer: FC<{ data: UserExcel }> = observer((props) => {
  const { data } = props;
  const email = data?.data?.email;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('EMAIL'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || email} placement="top">
      {errorMessage ? (
        <ErrorText>{email || errorMessage}</ErrorText>
      ) : (
        <Text>{email}</Text>
      )}
    </Tooltip>
  );
});

const UserLocaleBody = (data: UserExcel) => {
  return <UserLocaleBodyContainer data={data} />;
};

const UserLocaleBodyContainer: FC<{ data: UserExcel }> = observer((props) => {
  const { data } = props;
  const { membersStore } = useStores();
  const [supportedLocales, setSupportedLocales] = useState();
  const getSupportedLocales = useCallback(async () => {
    const locales = await membersStore.getSupportedLocales();
    setSupportedLocales(locales);
  }, [membersStore]);

  useEffect(() => {
    getSupportedLocales();
  }, [getSupportedLocales]);

  const lang = LANGUAGES.find(
    (item) =>
      (supportedLocales &&
        supportedLocales[item.value] ===
          data?.data?.language?.toLocaleLowerCase()) ||
      item.id.toLocaleLowerCase() === data?.data?.language?.toLocaleLowerCase(),
  )?.lang;

  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('LANGUAGE'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || lang} placement="top">
      {errorMessage ? (
        <ErrorText>{errorMessage || lang}</ErrorText>
      ) : (
        <Text>{lang}</Text>
      )}
    </Tooltip>
  );
});

const UserPhoneBody = (data: UserExcel) => {
  return <UserPhoneBodyContainer data={data} />;
};

const UserPhoneBodyContainer: FC<{ data: UserExcel }> = observer((props) => {
  const { data } = props;
  const phone = data?.data?.phone;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('PHONE'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || phone} placement="top">
      {errorMessage ? (
        <ErrorText>{phone || errorMessage}</ErrorText>
      ) : (
        <Text>{phone}</Text>
      )}
    </Tooltip>
  );
});

const isSelectedBody = (data: UserExcel) => {
  return <IsSelectedContainer data={data} />;
};

const IsSelectedContainer: FC<{ data: UserExcel }> = observer((props) => {
  const { membersStore } = useStores();
  const { data } = props;

  return (
    <Grid
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Checkbox
        disabled={!data.isValid}
        checked={data.selected}
        onChange={(e: any) => {
          e.stopPropagation();
          membersStore.setExcelUserSelected(data.data.uid, e.checked);
        }}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      />
    </Grid>
  );
});

const StatusBody = (data: UserExcel) => {
  return <StatusBodyContainer data={data} />;
};

const StatusBodyContainer: FC<{ data: UserExcel }> = observer((props) => {
  const { data } = props;

  return (
    <Grid align="center" justify="center">
      {data.isValid ? (
        <FontAwesomeIcon icon={faCheck} color={theme.color.success} />
      ) : (
        <FontAwesomeIcon icon={faTimes} color={theme.color.error} />
      )}
    </Grid>
  );
});

const CheckSquareIcon = () => <CheckSquareIconContainer />;
const CheckSquareIconContainer = observer(() => {
  const { membersStore } = useStores();
  const validUsers = membersStore.userUploadExcel.filter((el) => el.isValid);
  return (
    <Checkbox
      checked={validUsers.every((el) => el.selected)}
      onChange={(e) => {
        validUsers.forEach((el) => {
          membersStore.setExcelUserSelected(el.data.uid, e.checked);
        });
      }}
    />
  );
});

const CheckRoundIcon = () => <CheckRoundIconContainer />;
const CheckRoundIconContainer = () => (
  <Grid align="center" justify="center">
    <FontAwesomeIcon icon={faCircleCheck} />
  </Grid>
);

const UserCompanyRoleBodyContainer: FC<{ data: UserExcel }> = observer(
  (props) => {
    const { data } = props;
    const companyRole = data?.data?.companyRole;
    const errorMessage = data.importErrors.find((error) =>
      error.fieldName.includes('COMPANY_ROLE'),
    )?.msg;

    return (
      <Tooltip arrow title={errorMessage || companyRole} placement="top">
        {errorMessage ? (
          <ErrorText>{companyRole || errorMessage}</ErrorText>
        ) : (
          <Text>{companyRole}</Text>
        )}
      </Tooltip>
    );
  },
);

const UserUnitsBodyContainer: FC<{ data: UserExcel }> = observer((props) => {
  const { data } = props;
  const units = data?.data?.units;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('UNITS'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || units} placement="top">
      {errorMessage ? (
        <ErrorText>{units || errorMessage}</ErrorText>
      ) : (
        <Text>{units}</Text>
      )}
    </Tooltip>
  );
});

const UserOriginContainer: FC<{ data: UserExcel }> = observer((props) => {
  const { data } = props;
  const userOrigin = data?.data?.userOrigin;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('USER_ORIGIN'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || userOrigin} placement="top">
      {errorMessage ? (
        <ErrorText>{userOrigin || errorMessage}</ErrorText>
      ) : (
        <Text>{userOrigin}</Text>
      )}
    </Tooltip>
  );
});

const UserStatusContainer: FC<{ data: UserExcel }> = observer((props) => {
  const { data } = props;
  const status = data?.data?.status;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('STATUS'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || status} placement="top">
      {errorMessage ? (
        <ErrorText>{status || errorMessage}</ErrorText>
      ) : (
        <Text>{status}</Text>
      )}
    </Tooltip>
  );
});

const UserGoogleAnalyticsContainer: FC<{ data: UserExcel }> = observer(
  (props) => {
    const { data } = props;
    const googleAnalytics = data?.data?.googleAnalytics;
    const errorMessage = data.importErrors.find((error) =>
      error.fieldName.includes('GOOGLE_ANALYTICS'),
    )?.msg;

    return (
      <Tooltip arrow title={errorMessage || googleAnalytics} placement="top">
        {errorMessage ? (
          <ErrorText>{googleAnalytics || errorMessage}</ErrorText>
        ) : (
          <Text>{googleAnalytics}</Text>
        )}
      </Tooltip>
    );
  },
);

const UserSendWelcomeEmailContainer: FC<{ data: UserExcel }> = observer(
  (props) => {
    const { data } = props;
    const sendWelcomeEmail = data?.data?.sendWelcomeEmail;
    const errorMessage = data.importErrors.find((error) =>
      error.fieldName.includes('SEND_WELCOME_EMAIL'),
    )?.msg;

    return (
      <Tooltip arrow title={errorMessage || sendWelcomeEmail} placement="top">
        {errorMessage ? (
          <ErrorText>{sendWelcomeEmail || errorMessage}</ErrorText>
        ) : (
          <Text>{sendWelcomeEmail}</Text>
        )}
      </Tooltip>
    );
  },
);

export {
  UserFirstNameBody,
  UserLastNameBody,
  UserEmailBody,
  isSelectedBody,
  StatusBody,
  CheckSquareIcon,
  CheckRoundIcon,
  UserLocaleBody,
  UserPhoneBody,
  UserFirstNameBodyContainer,
  UserLastNameBodyContainer,
  UserEmailBodyContainer,
  UserLocaleBodyContainer,
  UserPhoneBodyContainer,
  UserCompanyRoleBodyContainer,
  UserUnitsBodyContainer,
  UserOriginContainer,
  UserStatusContainer,
  UserGoogleAnalyticsContainer,
  UserSendWelcomeEmailContainer,
};
