import api from 'Api/ApiMethods';
import i18next from 'i18next';
import { makeAutoObservable } from 'mobx';
import {
  SystemConfigurationSchema,
  SystemDefaultSchema,
  SystemParamsType,
  SystemSchemaProperty,
  SystemSensorType,
  SystemType,
  SystemVariant,
  SystemVwsSchema,
  VmsPropertiesType,
} from 'models/systems';
import { getSafeArray, replaceByIndex } from 'utils/arrayUtils';
import { VWS_SYSTEMS_LIST, WEATHER_SYSTEMS_LIST } from 'core/constants';
import { EditableSensor, Sensor, SensorGroup } from 'models/sensor';
import {
  checkIsEditableSensorsValid,
  getDefaultEditableSensors,
  getSensorCreatePayload,
} from 'utils/plotSensorsUtils';
import { SensorCreatePayload } from 'models/api/sensor';
import { GrowerSystem } from 'models/grower';
import { getAxiosErrorMessage } from 'utils/httpErrorsHelpers';
import { RootStore } from './rootStore';
import { LoadableField } from './types/types';

export interface SystemSchemaEditedValues {
  values: SystemParamsType;
  isValid: boolean;
}

type SystemConfigSchema = LoadableField<SystemConfigurationSchema | null> &
  SystemSchemaEditedValues;

interface SensorPayloadRecordValue {
  payload: EditableSensor;
  createdSensor: Sensor | null;
}

type SensorPayloadRecord = Record<string, SensorPayloadRecordValue>;

interface SystemSchemaDetails {
  isWeather: boolean;
  isVws: boolean;
  configurationSchema: SystemConfigSchema;
}

interface CreateSystemForm extends SystemSchemaDetails {
  type: SystemVariant | null;
  newSystemData: LoadableField<GrowerSystem | null>;
  newSystemSensors: LoadableField<EditableSensor[] | null>;
  sensorPayloadRecord: SensorPayloadRecord;
  isSystemActivating: boolean;
  isSystemCreated: boolean;
  isSensorPayloadsValid: boolean;
}

interface EditSystemForm extends SystemSchemaDetails {
  selectedSystem: GrowerSystem | null;
  isUpdating: boolean;
}

const defaultSystemSchema: SystemConfigSchema = {
  data: null,
  loading: false,
  isValid: false,
  values: {},
};

const defaultCreateSystemValues: CreateSystemForm = {
  type: null,
  isWeather: false,
  isVws: false,
  isSystemActivating: false,
  newSystemData: {
    data: null,
    loading: false,
  },
  newSystemSensors: {
    data: null,
    loading: false,
  },
  configurationSchema: defaultSystemSchema,
  sensorPayloadRecord: {},
  isSystemCreated: false,
  isSensorPayloadsValid: true,
};

const defaultEditSystemValues: EditSystemForm = {
  isWeather: false,
  isVws: false,
  selectedSystem: null,
  isUpdating: false,
  configurationSchema: defaultSystemSchema,
};

export class SystemsStore {
  rootStore: RootStore = {} as RootStore;
  systemTypes: LoadableField<SystemType[]> = {
    data: [],
    loading: false,
  };

  featuresTypes: LoadableField<any[]> = {
    data: [],
    loading: false,
  };

  systemValves: LoadableField<string[]> = {
    data: [],
    loading: false,
  };

  sensorTypes: LoadableField<SystemSensorType[]> = {
    data: [],
    loading: false,
  };

  createSystemForm: CreateSystemForm = defaultCreateSystemValues;
  editSystemForm: EditSystemForm = defaultEditSystemValues;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get sensorTypeValues(): string[] {
    return this.sensorTypes.data.map(({ type }) => type);
  }

  get sensorGroupValues(): string[] {
    return this.sensorTypes.data.map(({ group }) => group);
  }

  get systemSelectedPayloadRecords(): SensorPayloadRecordValue[] {
    return Object.values(this.createSystemForm.sensorPayloadRecord).filter(
      ({ payload: { details } }) => details.selected,
    );
  }

  setSystemType(type: SystemVariant): void {
    this.createSystemForm = {
      ...defaultCreateSystemValues,
      type,
      isWeather: WEATHER_SYSTEMS_LIST.includes(type),
      isVws: VWS_SYSTEMS_LIST.includes(type),
    };

    this.getSystemConfigurationSchema(type);
  }

  async getSystemTypes(): Promise<void> {
    if (this.systemTypes.data.length) {
      return;
    }

    this.systemTypes.loading = true;
    try {
      const response = await api.getSystemTypes();
      this.systemTypes.data = Array.isArray(response)
        ? response.filter((type) => !type.SystemType?.isDeprecated)
        : [];
    } catch {
      this.errorToast(i18next.t('errors:something_went_wrong'));
    } finally {
      this.systemTypes.loading = false;
    }
  }

  async getSystemValves(systemId: number): Promise<void> {
    this.systemValves.loading = true;
    try {
      const response = await api.getSystemValves(systemId);
      this.systemValves.data = getSafeArray(response);
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    } finally {
      this.systemValves.loading = false;
    }
  }

  async getSensorTypes(): Promise<void> {
    if (this.sensorTypes.data.length) {
      return;
    }

    this.sensorTypes.loading = true;
    try {
      const response = await api.getSensorTypes();
      this.sensorTypes.data = Array.isArray(response) ? response : [];
    } catch {
      this.errorToast(i18next.t('errors:something_went_wrong'));
    } finally {
      this.sensorTypes.loading = false;
    }
  }

  async setSensorsDefaultGroup(sensors: Sensor[]): Promise<Sensor[]> {
    try {
      if (!this.sensorTypes.data.length) {
        await this.getSensorTypes();
      }

      return sensors.map((sensor) => {
        const { sensorType } = sensor;
        if (sensorType?.type && !sensorType?.group) {
          const sensorGroup =
            this.sensorTypes.data.find(({ type }) => type === sensorType.type)
              ?.group ?? '';

          return {
            ...sensor,
            sensorType: {
              ...sensorType,
              group: sensorGroup as SensorGroup,
            },
          };
        }

        return sensor;
      });
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    }
  }

  async getNewSystemSensors(growerId: number, systemId: number): Promise<void> {
    this.createSystemForm.newSystemSensors.loading = true;

    try {
      const response = await api.getSystemSensors(growerId, systemId);
      const sensors = Array.isArray(response)
        ? await this.setSensorsDefaultGroup(response)
        : [];

      const editableSensors = getDefaultEditableSensors(sensors);
      this.createSystemForm.newSystemSensors.data = editableSensors;
      this.createSystemForm.newSystemSensors.error = null;
      this.setEditedSensors(editableSensors);
    } catch (error) {
      this.createSystemForm.newSystemSensors.error = error;
      this.errorToast(i18next.t('errors:something_went_wrong'));
      throw error;
    } finally {
      this.createSystemForm.newSystemSensors.loading = false;
    }
  }

  setSelectedSystem(system: GrowerSystem): void {
    this.editSystemForm.selectedSystem = system;
    this.editSystemForm.isVws = VWS_SYSTEMS_LIST.includes(system.type);
    this.editSystemForm.isWeather = WEATHER_SYSTEMS_LIST.includes(system.type);
  }

  resetSystemForEdit(): void {
    this.editSystemForm = defaultEditSystemValues;
  }

  async loadSystemForEdit(): Promise<void> {
    const { selectedSystem } = this.editSystemForm;

    if (!selectedSystem) {
      return;
    }

    this.editSystemForm.configurationSchema.loading = true;
    try {
      const response = await api.getSystemConfigurationSchema(
        selectedSystem.type,
      );

      this.editSystemForm.configurationSchema.data = response;
      const properties = Object.keys(response?.schema?.properties ?? {});
      this.editSystemForm.configurationSchema.values = properties.reduce(
        (acc, key) => ({ ...acc, [key]: selectedSystem.params?.[key] }),
        {},
      );

      if (this.editSystemForm.isVws) {
        const vwsParams = selectedSystem.params?.vwsParams ?? {};
        this.editSystemForm.configurationSchema.values.vwsParams = vwsParams;
      }
    } catch {
      this.errorToast(i18next.t('errors:something_went_wrong'));
    } finally {
      this.editSystemForm.configurationSchema.loading = false;
    }
  }

  async updateSelectedSystem(updatedFields: Partial<GrowerSystem>) {
    this.editSystemForm.isUpdating = true;
    try {
      const systemParams = this.editSystemForm.selectedSystem?.params ?? {};
      const updatedSystem = {
        ...this.editSystemForm.selectedSystem,
        ...updatedFields,
        params: {
          ...systemParams,
          ...updatedFields.params,
        },
      } as GrowerSystem;

      await api.putUpdateSystem(updatedSystem);
      const growerSystemsList =
        this.rootStore.resellersStore.growerSystems.data ?? [];

      const updatedSystemIndex = growerSystemsList.findIndex(
        (item) => item.id === this.editSystemForm.selectedSystem?.id,
      );

      this.rootStore.resellersStore.growerSystems.data = replaceByIndex(
        growerSystemsList,
        updatedSystemIndex,
        updatedSystem,
      );

      this.successToast(i18next.t('system:system_updated_message'));
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    } finally {
      this.editSystemForm.isUpdating = false;
    }
  }

  async createNewSystem(
    growerId: number,
    displayName: string,
    serial: string,
  ): Promise<void> {
    this.createSystemForm.newSystemData.loading = true;
    try {
      const response = await api.postCreateSystem({
        id: null,
        active: false,
        type: this.createSystemForm.type as SystemVariant,
        growerId,
        displayName,
        serial,
        params: this.createSystemForm.configurationSchema.values,
      });

      this.createSystemForm.newSystemData.data = response;
      this.createSystemForm.isSystemCreated = true;
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));

      throw error;
    } finally {
      this.createSystemForm.newSystemData.loading = false;
    }
  }

  setEditedSensors(sensors: EditableSensor[]): void {
    this.createSystemForm.sensorPayloadRecord = sensors.reduce(
      (acc, sensor, index) => {
        const createdSensor =
          this.createSystemForm.sensorPayloadRecord[index]?.createdSensor ??
          null;

        return {
          ...acc,
          [index]: {
            payload: sensor,
            createdSensor,
          },
        };
      },
      {},
    );

    this.createSystemForm.isSensorPayloadsValid = checkIsEditableSensorsValid(
      this.systemSelectedPayloadRecords.map(({ payload }) => payload),
    );
  }

  private async updateNewSystemSensor(
    sensor: Sensor,
    index: number,
  ): Promise<void> {
    try {
      await api.putUpdateSensor({...this.generateSensors(sensor)});
      this.createSystemForm.sensorPayloadRecord[index] = {
        ...this.createSystemForm.sensorPayloadRecord[index],
        createdSensor: sensor,
      };
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    }
  }

  private async updateNewSystemSensors(): Promise<void> {
    await Promise.all(
      Object.values(this.createSystemForm.sensorPayloadRecord).map(
        ({ payload, createdSensor }, index) =>
          createdSensor && payload.details.selected
            ? this.updateNewSystemSensor(
                { ...payload.sensor, id: createdSensor.id },
                index,
              )
            : null,
      ),
    );
  }

  private async createNewSystemSensors(growerId: number): Promise<void> {
    const systemId = this.createSystemForm.newSystemData.data?.id as number;
    const createPayloadsDetails = Object.values(
      this.createSystemForm.sensorPayloadRecord,
    )
      .map(({ payload, createdSensor }, index) =>
        !createdSensor && payload.details.selected
          ? { serial: payload.sensor.serial, index, payload }
          : null,
      )
      .filter(Boolean);

    const payloadsToCreate: SensorCreatePayload[] = createPayloadsDetails.map(
      (record) =>
        getSensorCreatePayload(
          record?.payload.sensor as Sensor,
          this.sensorTypes.data,
        ),
    );

    if (!payloadsToCreate.length) {
      return;
    }

    try {
      const response = await api.postBatchCreateSensors(
        growerId,
        systemId,
        payloadsToCreate,
      );

      response.created?.forEach((sensor) => {
        const createdSensor = sensor.object;
        const payloadDetails = createPayloadsDetails.find(
          (record) => record?.serial === createdSensor.serial,
        );

        const payloadIndex = payloadDetails?.index as number;
        this.createSystemForm.sensorPayloadRecord[payloadIndex] = {
          ...this.createSystemForm.sensorPayloadRecord[payloadIndex],
          createdSensor,
        };
      });

      response.failed?.forEach((sensor) => {
        this.errorToast(
          sensor.attributes?.error_key ??
            i18next.t('system:sensor_create_error_message', {
              serial: sensor.object.serial,
            }),
        );
      });
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    }
  }

  private getPlotSensorsMatches(): Record<string, Sensor[]> {
    const result: Record<string, Sensor[]> = {};
    const sensorPayloadRecords = this.systemSelectedPayloadRecords;
    sensorPayloadRecords.forEach(({ payload, createdSensor }) => {
      if (createdSensor) {
        payload.details.plots?.forEach((plot) => {
          const plotSensors = result[plot.id];
          result[plot.id] = Array.isArray(plotSensors)
            ? [...plotSensors, createdSensor]
            : [createdSensor];
        });
      }
    });

    return result;
  }

  private async createOrUpdateNewSystemSensors(
    growerId: number,
  ): Promise<void> {
    try {
      await Promise.all([
        this.createNewSystemSensors(growerId),
        this.updateNewSystemSensors(),
      ]);

      const attachPlotsToSensorsPromises = Object.entries(
        this.getPlotSensorsMatches(),
      ).map(([plotId, plotSensors]) => {
          const modifiedSensors = plotSensors.map(sensor => ({...this.generateSensors(sensor)}));
          return api.putAttachMultiplePlotToSensor(Number(plotId), modifiedSensors);
        }
      );

      await Promise.all(attachPlotsToSensorsPromises);
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    }
  }

  async activateSystem(
    growerId: number,
    systemData: Pick<GrowerSystem, 'active' | 'displayName'>,
  ): Promise<void> {
    this.createSystemForm.isSystemActivating = true;

    try {
      const updatedSystem = {
        ...this.createSystemForm.newSystemData.data,
        displayName: systemData.displayName,
        active: systemData.active,
      } as GrowerSystem;

      await api.putUpdateSystem(updatedSystem);
      await this.createOrUpdateNewSystemSensors(growerId);
      this.rootStore.resellersStore.growerSystems.data = [
        ...(this.rootStore.resellersStore.growerSystems.data ?? []),
        updatedSystem,
      ];

      this.successToast(i18next.t('system:system_created_message'));
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    } finally {
      this.createSystemForm.isSystemActivating = false;
    }
  }

  private getSchemaPropertiesDefaultValues(
    properties: Record<string, SystemSchemaProperty> | VmsPropertiesType,
  ): Record<string, string> {
    const entries = Object.entries(properties ?? {}) as [
      string,
      SystemSchemaProperty,
    ][];

    return entries.reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value.default }),
      {},
    );
  }

  private setSchemaDefaultValues(
    system: SystemVariant,
    schema: SystemDefaultSchema | SystemVwsSchema,
  ): void {
    const isVws = VWS_SYSTEMS_LIST.includes(system);
    if (isVws) {
      const vwsSchema = schema as SystemVwsSchema;
      const baseValues = this.getSchemaPropertiesDefaultValues(
        vwsSchema?.properties,
      );

      const baseParamsValues = this.getSchemaPropertiesDefaultValues(
        vwsSchema?.properties?.vwsParams?.properties,
      );

      const paramsValues = this.getSchemaPropertiesDefaultValues(
        vwsSchema?.definitions?.['vws-schema']?.properties?.vwsParams
          ?.properties,
      );

      this.createSystemForm.configurationSchema.values = {
        ...baseValues,
        vwsParams: {
          ...baseParamsValues,
          ...paramsValues,
        },
      };
    } else {
      this.createSystemForm.configurationSchema.values =
        this.getSchemaPropertiesDefaultValues(schema?.properties);
    }
  }

  private async getSystemConfigurationSchema(
    system: SystemVariant,
  ): Promise<void> {
    this.createSystemForm.configurationSchema = {
      ...defaultCreateSystemValues.configurationSchema,
      loading: true,
    };

    try {
      const response = await api.getSystemConfigurationSchema(system);
      this.createSystemForm.configurationSchema.data = response;
      this.setSchemaDefaultValues(system, response.schema);
    } catch {
      this.errorToast(i18next.t('errors:something_went_wrong'));
    } finally {
      this.createSystemForm.configurationSchema.loading = false;
    }
  }

  resetStore(): void {
    this.systemTypes = { data: [], loading: false };
    this.sensorTypes = { data: [], loading: false };
    this.createSystemForm = defaultCreateSystemValues;
    this.editSystemForm = defaultEditSystemValues;
  }

  private successToast(message: string): void {
    this.rootStore?.snackBarStore.showToast({
      detail: message,
      severity: 'success',
      summary: 'Success',
    });
  }

  private errorToast(message: string): void {
    this.rootStore?.snackBarStore.showToast({
      detail: message,
    });
  }

  private getErrorMessage(error: unknown): string {
    return (
      getAxiosErrorMessage(error) ?? i18next.t('errors:something_went_wrong')
    );
  }

  private generateSensors = (sensor: Sensor): Sensor => {
    const modifiedSensor = {...sensor};
    if (modifiedSensor.params) {
      if (modifiedSensor.params?.alias) {
        modifiedSensor.alias = modifiedSensor.params.alias;
        delete modifiedSensor.params.alias;
      }
      
      if ('alias' in modifiedSensor.params) {
        delete modifiedSensor.params.alias;
      }
    }

    return modifiedSensor;
  }
}
