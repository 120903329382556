import i18next from 'i18next';
import { makeAutoObservable } from 'mobx';
import api from 'Api/ApiMethods';
import { USER_WELCOME_EMAIL } from 'core/constants';
import { getAxiosErrorMessage } from 'utils/httpErrorsHelpers';
import { getSafeArray } from 'utils/arrayUtils';
import { getDataTableMultiSelection } from 'utils/datatableUtils';
import {
  CompanyRole,
  User,
  UserExcel,
  UserGrower,
  UserGrowerRole,
  UserPreferences,
} from 'models/user';
import { UpdatedStatus } from 'models/shared';
import { RootStore } from './rootStore';
import { TableMultiSelection } from './types/types';

export class MembersStore {
  rootStore?: RootStore = undefined;
  members: User[] = [];
  memberGrowers: UserGrower[] = [];
  isMemberGrowersLoading = false;
  userTableMultiSelection: TableMultiSelection<User> = {
    activeRow: null,
    selection: [],
  };

  memberGrowerTableMultiSelection: TableMultiSelection<UserGrower> = {
    activeRow: null,
    selection: [],
  };

  selectedReseller: string[] = [];
  growersRoles: UserGrowerRole[] = [];
  memberGrowersAvailable: UserGrower[] = [];
  selectedRowsMembersGrowersAvailable: UserGrower[] = [];
  companyRoles: CompanyRole[] = [];
  activateUser: boolean | undefined;
  userPreferences: UserPreferences[] = [];
  userUploadExcel: UserExcel[] = [];
  invalidUsersFileBlob: Blob = new Blob();
  invalidUsersFileName = '';
  totalUserGrowersRecord = 0;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get editableUser(): User | null {
    return this.selectedRows[0] ?? null;
  }

  get selectedRows(): User[] {
    return getDataTableMultiSelection(this.userTableMultiSelection);
  }

  get selectedRowsMemberGrower(): UserGrower[] {
    return getDataTableMultiSelection(this.memberGrowerTableMultiSelection);
  }

  setMembers = (users: User[]) => {
    this.members = users;
  };

  setSelectedRows = async (selectedRows: TableMultiSelection<User>) => {
    this.userTableMultiSelection = selectedRows;
    this.activateUser = this.selectedRows[0]?.active ?? false;
    const activeRowId = this.userTableMultiSelection.activeRow?.id as number;
    if (!activeRowId) {
      this.memberGrowers = [];
      return;
    }

    this.getMemberGrowers(activeRowId);
  };

  resetSelectedRows = () => {
    this.setSelectedRows({
      selection: [],
      activeRow: null,
    });
  };

  getMemberGrowers = async (userId: number) => {
    try {
      this.isMemberGrowersLoading = true;
      const response = await api.getUserGrowers(userId);
      this.totalUserGrowersRecord = response.totalCount;
      this.memberGrowers = getSafeArray(response.paginatedCollection);
    } catch (error) {
      this.showError(getAxiosErrorMessage(error));
    } finally {
      this.isMemberGrowersLoading = false;
    }
  };

  getUserSelectedPreferences = (profileId: number): UserPreferences => {
    const selectedUserPreferences = this.userPreferences?.find(
      (item) => item.profileId === profileId,
    );

    return selectedUserPreferences ?? ({ profileId } as UserPreferences);
  };

  setSelectedRowsMemberGrower = (
    userGrower: TableMultiSelection<UserGrower>,
  ) => {
    this.memberGrowerTableMultiSelection = userGrower;
  };

  resetSelectedRowsMemberGrower = () => {
    this.setSelectedRowsMemberGrower({ selection: [], activeRow: null });
  };

  setSelectedReseller = (resellers: string[]) => {
    this.selectedReseller = resellers;
  };

  activateSelectedUsers = async () => {
    try {
      const nextStatus = !this.activateUser;
      const updateStatusPayload = this.selectedRows.map((row) => ({
        ...row,
        active: nextStatus,
      }));

      const response: UpdatedStatus[] = await api.putUsers(updateStatusPayload);
      const failedUsersIds: number[] = response
        ?.filter(({ updated }) => !updated)
        .map(({ id }) => id);

      if (response?.length) {
        const updatedUserIds = new Set(response.map((status) => status.id));
        this.members = this.members.map((user) =>
          updatedUserIds.has(user.id) ? { ...user, active: nextStatus } : user,
        );
      }

      if (failedUsersIds?.length) {
        this.rootStore?.snackBarStore.showToast({
          detail: i18next.t('users:user_activation_error_message', {
            ids: failedUsersIds,
          }),
        });
      }

      this.activateUser = this.selectedRows[0].active;
      this.resetSelectedRows();
    } catch (e) {
      this.selectedRows.forEach((el) => {
        el.active = !el.active;
      });

      this.activateUser = this.selectedRows[0].active;
      this.members
        .filter((el) => this.selectedRows.includes(el))
        .forEach((el) => {
          el.active = !el.active;
        });
    }
  };

  initData = async () => {
    try {
      const allUsers = await api.getAllUsers();
      this.companyRoles = await api.getAllCompanyRoles();
      allUsers.paginatedCollection.forEach((el: User) => {
        const companyRole = this.companyRoles.find(
          (role: CompanyRole) => role.uid === el.companyRoleId,
        )?.role;

        el.role = companyRole || i18next.t('users:not_available');
        el.units =
          el.preferredMeasurementSystem || i18next.t('users:not_available');
      });

      this.setMembers(allUsers.paginatedCollection);
      this.setSelectedRows({ selection: [], activeRow: this.members[0] });
    } catch (error) {
      if (error instanceof Error) {
        this.rootStore?.snackBarStore.showToast({
          detail: error.message,
        });
      }
    }
  };

  getGrowersRoles = async (userId: number) => {
    try {
      const roles = await api.getUserGrowersRoles(userId);
      this.growersRoles = roles.map((role: string, index: number) => {
        return {
          id: index,
          name: role,
        };
      });
    } catch {
      this.rootStore?.snackBarStore.showToast({
        detail: `Can't get SupPlant roles`,
      });
    }
  };

  editMemberGrowersRole = async (role: UserGrowerRole) => {
    const growersIds = this.selectedRowsMemberGrower.map((grower) => grower.id);

    try {
      await api.putUserGrowersRole(
        this.selectedRows[0].id,
        role.id,
        growersIds,
      );

      this.memberGrowers.forEach((grower) => {
        if (growersIds.includes(grower.id)) {
          grower.userGrowerRole = role.name;
        }
      });

      this.resetSelectedRowsMemberGrower();
    } catch {
      this.rootStore?.snackBarStore.showToast({
        detail: `Can't edit roles for growers ${growersIds.toString()}`,
      });
    }
  };

  setMembersGrowersAvailable = (memberGrowersAvailable: UserGrower[]) => {
    this.memberGrowersAvailable = memberGrowersAvailable;
  };

  setSelectedRowsMembersGrowersAvailable = (
    userGrowerAvailable: UserGrower[],
  ) => {
    this.selectedRowsMembersGrowersAvailable = userGrowerAvailable;
  };

  assignUserGrowers = async (roleName: string) => {
    const growersIds = this.selectedRowsMembersGrowersAvailable.map(
      (grower) => grower.id,
    );

    const roleId = this.growersRoles.find((el) => el.name === roleName)?.id;
    try {
      await api.putAssignUserGrowers(
        this.selectedRows[0].id,
        roleId as number,
        growersIds,
      );

      const userGrower = this.memberGrowersAvailable.filter(
        (grower) => !growersIds.includes(grower.id),
      );

      this.selectedRowsMembersGrowersAvailable.forEach(
        (userGrowerElement: UserGrower) => {
          userGrowerElement.userGrowerRole = roleName;
        },
      );

      this.memberGrowersAvailable = userGrower;
      this.selectedRowsMembersGrowersAvailable.forEach((member) => {
        const roles = this.growersRoles.find((role) => role.id === roleId);
        member.userGrowerRole = roles
          ? roles.name
          : i18next.t('users:not_available');
      });

      this.memberGrowers = [
        ...this.selectedRowsMembersGrowersAvailable,
        ...this.memberGrowers,
      ];

      this.selectedRowsMembersGrowersAvailable = [];
    } catch {
      this.rootStore?.snackBarStore.showToast({
        detail: `Can't assign user ${
          this.selectedRows[0].id
        } to growers ${growersIds.toString()}`,
      });
    }
  };

  unassignUserGrowers = async () => {
    const growersIds = this.selectedRowsMemberGrower.map((grower) => grower.id);

    try {
      await api.deleteUnassignUserGrowers(this.selectedRows[0].id, growersIds);
      const userGrower = this.memberGrowers.filter(
        (grower) => !growersIds.includes(grower.id),
      );

      this.memberGrowers = userGrower;
      this.memberGrowersAvailable = [
        ...this.selectedRowsMemberGrower,
        ...this.memberGrowersAvailable,
      ];

      this.resetSelectedRowsMemberGrower();
    } catch {
      this.rootStore?.snackBarStore.showToast({
        detail: `Can't unassign user ${
          this.selectedRows[0].id
        } to growers ${growersIds.toString()}`,
      });
    }
  };

  getMembersGrowersAssigned = async (offset: number, cnt: number) => {
    const userGrowersAssigned = await api.getUserGrowers(
      this.selectedRows[0].id,
      offset,
      cnt,
      true,
    );

    userGrowersAssigned?.paginatedCollection.forEach((grower: UserGrower) => {
      grower.name = grower.name
        ? grower.name
        : i18next.t('users:not_available');

      grower.resellerName = grower.resellerName
        ? grower.resellerName
        : i18next.t('users:not_available');
    });

    this.memberGrowers = userGrowersAssigned.paginatedCollection;
    return userGrowersAssigned.totalCount;
  };

  getMembersGrowersAvailable = async (offset: number, cnt: number) => {
    const userGrowersAvailable = await api.getUserGrowers(
      this.selectedRows[0].id,
      offset,
      cnt,
      false,
    );

    userGrowersAvailable?.paginatedCollection.forEach((grower: UserGrower) => {
      grower.name = grower.name
        ? grower.name
        : i18next.t('users:not_available');

      grower.resellerName = grower.resellerName
        ? grower.resellerName
        : i18next.t('users:not_available');
    });

    this.memberGrowersAvailable = userGrowersAvailable.paginatedCollection;
    return userGrowersAvailable.totalCount;
  };

  createUser = async (userData: User) => {
    try {
      const userPayload: User = {
        ...userData,
        linkTemplate: USER_WELCOME_EMAIL,
        userApi: null,
        userPreferences: this.getUserSelectedPreferences(
          userData.userPreferences.profileId,
        ),
      };

      const createdUser = await api.postUser(userPayload);
      if (createdUser) {
        this.setSelectedRows({ selection: [], activeRow: createdUser });
        this.setMembers([createdUser, ...this.members]);
        return this.selectedRows.length > 0;
      }

      this.rootStore?.snackBarStore.showToast({
        detail: `Not get user properties from the server`,
      });
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail:
          getAxiosErrorMessage(error) ??
          i18next.t<string>('users:create_user_error'),
      });

      throw error;
    }

    return false;
  };

  editUser = async (userEditedData: User) => {
    try {
      const userPayload: User = {
        ...this.editableUser,
        ...userEditedData,
        userApi: null,
      };

      const response = await api.putUsers([userPayload]);
      const editUsersStatus = response.filter(
        (status: UpdatedStatus) => status.updated === false,
      );

      this.members = this.members.map((user) => {
        if (user.id === userPayload.id) {
          this.setSelectedRows({ selection: [], activeRow: userPayload });
          return userPayload;
        }

        return user;
      });

      if (editUsersStatus.length) {
        this.rootStore?.snackBarStore.showToast({
          detail: `User doesn't updated: ${editUsersStatus.map(
            (status: UpdatedStatus) => status.id,
          )}`,
        });
      } else {
        this.rootStore?.snackBarStore.showToast({
          detail: `User updated successfully`,
          severity: 'success',
          summary: 'Success',
        });

        return true;
      }
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail:
          getAxiosErrorMessage(error) ??
          i18next.t<string>('errors:something_went_wrong'),
      });

      throw error;
    }

    return false;
  };

  deleteUsers = async () => {
    try {
      const usersToDelete = this.selectedRows.map((user) => user.id);
      const res = (await api.deleteUsers(usersToDelete)) || [];
      const members = this.members.filter(
        (user) => !usersToDelete.includes(user.id),
      );

      this.members = members;
      this.resetSelectedRows();
      const deleteUsersStatus = res.filter((el: UpdatedStatus) => !el.updated);

      if (deleteUsersStatus.length) {
        this.rootStore?.snackBarStore.showToast({
          detail: `User doesn't deleted: ${deleteUsersStatus.map(
            (el: UpdatedStatus) => el.id,
          )}`,
        });
      } else {
        this.rootStore?.snackBarStore.showToast({
          detail: `User delete successfully`,
          severity: 'success',
          summary: 'Success',
        });
      }

      this.resetSelectedRows();
    } catch (e) {
      this.selectedRows.forEach((el) => {
        el.active = !el.active;
      });

      this.members
        .filter((el) => this.selectedRows.includes(el))
        .forEach((el) => {
          el.active = !el.active;
        });
    }
  };

  getUserPreferences = async () => {
    try {
      const response = await api.getUserPreferences();
      this.userPreferences = Array.isArray(response) ? response : [];
    } catch {
      this.showError(i18next.t('errors:something_went_wrong'));
    }
  };

  uploadExcelUsers = async (file: File) => {
    try {
      const res = (await api.postUploadUsersExcel(file)) || [];
      this.userUploadExcel = res.map((user: UserExcel) => ({
        isValid: user.importErrors.length === 0,
        selected: user.importErrors.length === 0,
        data: user.data,
        importErrors: user.importErrors,
        importWarnings: user.importWarnings,
      }));
    } catch (error) {
      if (error instanceof Error) {
        this.showError(`Plots excel file is not valid - ${error.message}`);
      } else {
        this.showError(`Something get wrong - please try later`);
      }
    }

    return this.userUploadExcel;
  };

  createUsersBatch = async () => {
    try {
      const response = (await api.postCreateUsers(this.userUploadExcel)) || [];

      if (response) {
        this.invalidUsersFileBlob = new Blob([response], {
          type: 'application/octet-stream',
        });

        this.invalidUsersFileName = response.headers
          ? response.headers['Content-Disposition']?.split('filename=')[1]
          : 'invalid_users.xlsx';
      }

      return true;
    } catch (error) {
      this.showError(`Something get wrong, while creating plots batch`);
    }

    return false;
  };

  getSupportedLocales = async () => {
    const localesResponse = (await api.getSupportedLocales()) || {};
    if (localesResponse) {
      return localesResponse.data;
    }

    return null;
  };

  setExcelUserSelected = (userId: number, selected: boolean) => {
    const found = this.userUploadExcel.find((el) => el.data?.uid === userId);
    if (found) {
      found.selected = selected;
    }
  };

  showError = (message: string) => {
    this.rootStore?.snackBarStore.showToast({
      detail: message,
    });
  };
}
