import { alpha } from '@mui/material';
import { createGlobalStyle, css } from 'styled-components';

import theme from './theme';

const globalStyles = css`
  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  body {
    font-family: ${theme.fonts.metropolis};
    min-width: 100vw;
    min-height: 100vh;
    background: ${theme.color.white};
    p,
    text {
      font-family: ${theme.fonts.metropolis};
    }
  }

  body[dir='rtl'] {
    direction: rtl;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    outline: none;
  }

  .p-component {
    font-family: ${theme.fonts.metropolis}, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 1rem;
    font-weight: normal;
  }

  .p-inputtext {
    font-family: ${theme.fonts.metropolis}, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  .p-float-label.error label {
    color: ${(p) => p.theme.color.error};
  }

  .p-float-label label {
    transition-property: top, font-size, padding;
    left: 0.7rem;
  }

  .p-float-label .p-inputwrapper-filled ~ label {
    top: 0;
    font-size: 0.75rem;
    background: white;
    padding: 0 5px;
  }

  .p-float-label .p-inputwrapper-focus ~ label {
    top: 0;
    font-size: 0.75rem;
    background: white;
    padding: 0 5px;
    color: ${(p) => p.theme.palette.primary.main};
  }

  .p-float-label .p-inputwrapper.p-disabled ~ label,
  .p-float-label .p-inputwrapper-focus.p-disabled ~ label,
  .p-float-label .p-inputwrapper-filled.p-disabled ~ label {
    color: ${(p) => p.theme.color.disable};
  }

  .p-column-filter {
    height: 41px;
  }

  .p-multiselect-panel,
  .p-dropdown-panel {
    z-index: 2000 !important;
  }

  /* primereact checkbox style */
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: ${(p) => p.theme.palette.primary.main};
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 0.2rem ${(p) => alpha(p.theme.palette.primary.main, 0.5)};
    border-color: ${(p) => p.theme.palette.primary.main};
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: ${(p) => p.theme.palette.primary.main};
    background: ${(p) => p.theme.palette.primary.main};
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: ${(p) => p.theme.palette.primary.main};
    background: ${(p) => p.theme.palette.primary.main};
  }

  /* primereact inputtext style */
  .p-inputtext:enabled:focus {
    border-color: ${(p) => p.theme.palette.primary.main};
    box-shadow: 0 0 0 0.2rem ${(p) => alpha(p.theme.palette.primary.main, 0.5)};
  }
  .p-inputtext:enabled:hover {
    border-color: ${(p) => p.theme.palette.primary.main};
  }

  /* multiSelect style */
  .p-multiselect:not(.p-disabled).p-focus {
    border-color: ${(p) => p.theme.palette.primary.main};
    box-shadow: 0 0 0 0.2rem ${(p) => alpha(p.theme.palette.primary.main, 0.5)};
  }

  .p-multiselect:not(.p-disabled):hover {
    border-color: ${(p) => p.theme.palette.primary.main};
  }

  /* primereact tree */
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-tree-toggler:focus {
    box-shadow: 0 0 0 0.2rem ${(p) => alpha(p.theme.palette.primary.main, 0.5)};
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    box-shadow: 0 0 0 0.2rem ${(p) => alpha(p.theme.palette.primary.main, 0.5)};
  }

  .p-treenode-label {
    white-space: nowrap;
  }
`;

const GlobalStyles = createGlobalStyle`${globalStyles}`;

export default GlobalStyles;
