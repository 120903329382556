export const insertByIndex = <T>(data: T[], index: number, item: T): T[] => {
  const result = [...data];
  result.splice(index, 0, item);
  return result;
};

export const replaceByIndex = <T>(data: T[], index: number, item: T): T[] => {
  const result = [...data];
  result.splice(index, 1, item);
  return result;
};

export const getSafeArray = <T>(data: T[]): T[] => {
  return Array.isArray(data) ? data : [];
};
