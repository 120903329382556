import { RefObject, useCallback, useEffect } from 'react';
import { LatLngExpression, ZoomPanOptions } from 'leaflet';
import { MapRef } from 'components/shared/Map/Map.types';

const defaultZoom = 7;
const defaultZoomOptions: ZoomPanOptions = {
  animate: false,
};

interface UseMoveToPlotLocationProps {
  location: LatLngExpression;
  mapRef: RefObject<MapRef>;
  zoom?: number;
  zoomOptions?: ZoomPanOptions;
}

const useMapMoveToPlotLocation = ({
  location,
  mapRef,
  zoom = defaultZoom,
  zoomOptions = defaultZoomOptions,
}: UseMoveToPlotLocationProps): void => {
  const moveToPlotsLocation = useCallback(() => {
    let timerId = null;
    if (location) {
      if (timerId) {
        clearTimeout(timerId);
      }

      timerId = setTimeout(() => {
        mapRef.current?.map.flyTo(
          location as LatLngExpression,
          zoom,
          zoomOptions,
        );
      });
    }
  }, [location, mapRef, zoom, zoomOptions]);

  useEffect(() => {
    moveToPlotsLocation();
  }, [moveToPlotsLocation]);
};

export default useMapMoveToPlotLocation;
