import React from 'react';
import styled from 'styled-components';
import Text from 'components/UI/Text';
import { Tooltip } from '@mui/material';
import i18next from 'i18next';
import { ProtocolProgress } from 'models/protocol';

const StyledTextCategoryText = styled(Text)`
  width: 100%;
`;

const CropCategoryBody = (props: ProtocolProgress) => {
  const { protocol } = props;
  const { classification } = protocol;
  const fullText = classification.cropCategory
    ? classification.cropCategory.toString().replaceAll(',', ', ')
    : i18next.t('crop_models:not_available');

  return (
    <Tooltip arrow title={fullText} placement="top">
      <StyledTextCategoryText inline singleline size="md">
        {fullText}
      </StyledTextCategoryText>
    </Tooltip>
  );
};

export default CropCategoryBody;
