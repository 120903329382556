import React, { FC, useEffect, useRef } from 'react';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { useStores } from 'stores/hooks/hooks';
import { Toast as PrimeToast } from 'primereact/toast';
import styled from 'styled-components';

const Toast = styled(PrimeToast)`
  .p-toast-icon-close {
    flex-shrink: 0;
  }
  .p-toast-message-text {
    overflow: hidden;
  }
  .p-toast-message-content {
    overflow: hidden;
  }
  .p-toast-detail {
    overflow: auto;
  }
`;

/**
 * used by the snackbar store to show snackbars.
 */
const Notifier: FC<WithSnackbarProps> = (props) => {
  const { snackBarStore } = useStores();
  const { enqueueSnackbar, closeSnackbar } = props;
  const toast = useRef<PrimeToast | null>(null);

  useEffect(() => {
    autorun(() => {
      const { latestNotification } = snackBarStore;
      if (latestNotification) {
        enqueueSnackbar(latestNotification.message, latestNotification.options);
      }
    });

    autorun(() => {
      const { latestRemove } = snackBarStore;
      if (latestRemove) {
        closeSnackbar(latestRemove);
      }
    });

    autorun(() => {
      const { latestToast } = snackBarStore;
      if (latestToast) {
        toast.current?.show(latestToast);
      }
    });
  }, [closeSnackbar, enqueueSnackbar, snackBarStore]);

  return <Toast ref={toast} position="top-right" baseZIndex={1500} />;
};

const NotifierComponent = observer(Notifier);

export default withSnackbar(NotifierComponent);
