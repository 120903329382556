import { PlotModel } from 'models/plot';

export const validatePlotCreatePayload = (plot: PlotModel): boolean => {
  return (
    !!plot.name &&
    !!plot.serviceLevel &&
    !!plot.irrigationMethod &&
    !!plot.growingType &&
    !!plot.area?.unit &&
    !!plot.soil &&
    !!plot.crop &&
    (!!plot.variety || !!plot.category) &&
    !!plot.plantTime &&
    !!plot.growthMethod &&
    (!!plot.startSeason || !!plot.initialStage?.date)
  );
};

export const checkIsPlantDateDisabled = (
  plot: PlotModel,
  plotLocation: string,
): boolean => {
  const isBasicPlantFieldsEmpty =
    !plot.serviceLevel ||
    !plot.area ||
    !plot.crop ||
    !plot.irrigationMethod ||
    !plot.growingType ||
    !plot.soil ||
    !plot.name;

  const isCropDetailsEmpty =
    (!plot.variety && !plot.category) || plotLocation.length === 0;

  return isBasicPlantFieldsEmpty || isCropDetailsEmpty;
};

export const checkIsInitialStageDisabled = (plot: PlotModel): boolean => {
  return !plot.plantTime || !plot.growthMethod;
};
