import React, { FC, useCallback } from 'react';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import Dialog from 'components/UI/Dialog';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import { DialogCloseAction } from 'models/shared';

interface ConfirmDialogProps {
  onClose: (res: DialogCloseAction) => void;
  isOpen: boolean;
  title: string;
  message: string;
  loading?: boolean;
  disabled?: boolean;
  buttonText: string;
  backText: string;
  confirmColor?:
    | 'error'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
  declineColor?:
    | 'error'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  isOpen,
  onClose,
  title,
  buttonText,
  backText,
  message,
  loading,
  disabled,
  declineColor = 'error',
  confirmColor = 'primary',
}) => {
  const declineCallback = useCallback(() => {
    onClose(DialogCloseAction.Declined);
  }, [onClose]);

  const confirmCallback = useCallback(() => {
    onClose(DialogCloseAction.Confirmed);
  }, [onClose]);

  return (
    <Dialog
      onClose={declineCallback}
      open={isOpen}
      dialogTitle={title}
      titleWeight="bold"
    >
      <DialogContent>
        <Grid>
          <Text>{message}</Text>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color={declineColor} onClick={declineCallback}>
          {backText}
        </Button>
        <Button
          color={confirmColor}
          onClick={confirmCallback}
          loading={loading}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
