import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpStatus } from 'core/enums';
import { rootStore } from 'stores/contexts/storesContext';

const { userStore } = rootStore;

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL_WEBFRONT}/api/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = userStore.isAuthenticated
    ? userStore.accessTokenBlock?.access_token
    : null;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ operation }) => {
  const { response } = operation?.getContext() ?? {};
  return new Observable((observer) => {
    if (
      response?.status === HttpStatus.NotAuthorized &&
      !userStore.isRefreshingToken
    ) {
      userStore.refreshUserToken();
    } else {
      const subscriber = {
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      };

      response.subscribe(subscriber);
    }
  });
});

export const initGqlClient = (): ApolloClient<NormalizedCacheObject> => {
  const apolloCache = new InMemoryCache({});

  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: apolloCache,
  });
};
