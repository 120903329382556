import React, { FC } from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import { IProtocol } from 'models/protocol';

const CropWinterBodyContainer: FC<{ data: IProtocol }> = observer(
  ({ data }) => {
    const { midWinter } = data;
    return (
      <div>
        {midWinter ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        )}
      </div>
    );
  },
);

const CropWinterBody = (props: IProtocol) => {
  return <CropWinterBodyContainer data={props} />;
};

export default CropWinterBody;
