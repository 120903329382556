import React, { FC } from 'react';
import styled from 'styled-components';
import { TabViewMode } from 'core/enums';
import { AlertVariant } from 'models/alert';
import Grid from 'components/UI/Grid';
import { VisibilityControl } from 'components/shared/VisibilityControl';
import TechnicalAlertsTable from './TechnicalAlertsTable';
import AgronomicAlertsTable from './AgronomicAlertsTable';
import LiveMonitoringMap from '../LiveMonitoringMap';
import { MapVisibilityControl } from '../LiveMonitoring.styles';

const Container = styled(Grid)`
  height: 100%;
`;

const TableVisibilityControl = styled(VisibilityControl)`
  height: 100%;
`;

interface LiveMonitoringAlertsProps {
  viewMode: TabViewMode;
  variant: AlertVariant;
}

const LiveMonitoringAlerts: FC<LiveMonitoringAlertsProps> = ({
  viewMode,
  variant,
}) => (
  <Container>
    <TableVisibilityControl visible={viewMode === TabViewMode.Table}>
      {variant === AlertVariant.Technical ? (
        <TechnicalAlertsTable />
      ) : (
        <AgronomicAlertsTable />
      )}
    </TableVisibilityControl>
    <MapVisibilityControl visible={viewMode === TabViewMode.Map}>
      <LiveMonitoringMap />
    </MapVisibilityControl>
  </Container>
);

export default LiveMonitoringAlerts;
