import React, { FC } from 'react';
import { observer } from 'mobx-react';
import ReactJson from 'react-json-view';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';

const Container = styled(Grid)`
  .react-json-view {
    max-height: 35vh;
  }
`;

const PlotDetails: FC = observer(() => {
  const { resellersStore } = useStores();
  const selectedPlot = resellersStore.selectedRowsPlots[0];

  return (
    <Container direction="row">
      {!!selectedPlot && <ReactJson src={selectedPlot} />}
    </Container>
  );
});

export default PlotDetails;
