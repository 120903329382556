import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Text from 'components/UI/Text';
import Grid from 'components/UI/Grid';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { EditableSensor } from 'models/sensor';
import { GrowerSystem } from 'models/grower';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { SystemsCreateFormStepProps } from '../SystemsCreateFormProperties';
import {
  SensorsEditableRowOptions,
  SensorsEditableTable,
} from '../../SensorsEditableTable';
import { getSensorPlotOptions } from '../../Systems.utils';

const StyledSensorsEditableTable = styled(SensorsEditableTable)`
  margin-top: 0.5rem;
`;

const SystemsSummaryCreateStep: FC<SystemsCreateFormStepProps> = observer(
  ({ control, register, getValues }) => {
    const { t } = useTranslation('system');
    const { systemsStore, resellersStore } = useStores();
    const formValues = getValues();
    const {
      createSystemForm: { newSystemSensors, sensorPayloadRecord },
    } = systemsStore;

    const system = systemsStore.createSystemForm.newSystemData
      .data as GrowerSystem;

    const sensorOptions: SensorsEditableRowOptions = useMemo(
      () => ({
        plot: getSensorPlotOptions(resellersStore.resellerPlots),
      }),
      [resellersStore.resellerPlots],
    );

    const onEditSensorsChange = useCallback(
      (sensors: EditableSensor[]) => {
        systemsStore.setEditedSensors(sensors);
      },
      [systemsStore],
    );

    const tableSensors = useMemo(
      () =>
        Object.values(sensorPayloadRecord)
          .filter(({ payload }) => payload.details.selected)
          .map(({ payload }) => payload),
      [sensorPayloadRecord],
    );

    useEffect(() => {
      systemsStore.getSensorTypes();
    }, [systemsStore]);

    return (
      <Grid direction="column" align="stretch">
        <Grid direction="row" justify="between">
          <Text weight="bold">{t('system_summary')}:</Text>
          <Grid direction="row" gap="0.2rem">
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="active"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        {...register('active')}
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                }
                label={<Text weight="bold">{t('activate_system')}</Text>}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid direction="row" align="center" justify="between">
          <Grid direction="column" align="start" gap="0.5rem">
            <Grid direction="row" gap="0.2rem">
              <Text>{t('type')}:</Text>
              <Text>{formValues.type}</Text>
            </Grid>
            <Grid direction="row" gap="0.2rem">
              <Text>{t('name')}:</Text>
              <Text>{formValues.displayName}</Text>
            </Grid>
            <Grid direction="row" gap="0.2rem">
              <Text>{t('serial')}:</Text>
              <Text>{formValues.serial}</Text>
            </Grid>
            <Grid direction="row" gap="0.2rem">
              <Text>{t('added_sensors')}:</Text>
            </Grid>
          </Grid>
        </Grid>
        {!newSystemSensors.loading && (
          <StyledSensorsEditableTable
            system={system}
            sensors={tableSensors}
            sensorTypes={systemsStore.sensorTypes.data}
            options={sensorOptions}
            onChange={onEditSensorsChange}
            tableHeight="33.5vh"
            disabled
          />
        )}
      </Grid>
    );
  },
);

export default SystemsSummaryCreateStep;
