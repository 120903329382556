import { observer } from 'mobx-react';
import React, { FC } from 'react';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from 'stores/hooks/hooks';
import {
  DEFAULT_ALERT_FILTER_CATEGORY_LIST,
  DEFAULT_ALERT_FILTER_CATEGORY_TECHNICAL_LIST,
} from 'core/constants';
import {
  GET_PLOTS_AGRONOMIC_ALERTS,
  GET_PLOTS_TECHNICAL_ALERTS,
} from 'gql/queries/plots';
import { useQuery } from '@apollo/client';
import { AlertSeverity, AlertState, AlertVariant } from 'models/alert';
import { GqlPlotAlerts } from 'gql/types/plots';
import i18next from 'i18next';
import LiveMonitoringAlertsMap from './LiveMonitoringAlertsMap';

const LiveMonitoringMap: FC = observer(() => {
  const { resellersStore, snackBarStore } = useStores();
  const growerIds = resellersStore.selectedTreeNodeGrowerIds;

  const { data: agronomicAlerts, loading: isAgronomicAlertsLoading } =
    useQuery<GqlPlotAlerts>(GET_PLOTS_AGRONOMIC_ALERTS, {
      variables: {
        growerIds,
        alertState: [AlertState.Active],
        alertFilterCategory: DEFAULT_ALERT_FILTER_CATEGORY_LIST,
        severity: [AlertSeverity.High],
      },
      fetchPolicy: 'no-cache',
      skip:
        resellersStore.alertsMapVariant !== AlertVariant.Agronomic ||
        !growerIds.length,
      onError() {
        snackBarStore.showToast({
          detail: i18next.t('errors:something_went_wrong'),
        });
      },
    });

  const { data: technicalAlerts, loading: isTechnicalAlertsLoading } =
    useQuery<GqlPlotAlerts>(GET_PLOTS_TECHNICAL_ALERTS, {
      variables: {
        growerIds,
        alertState: [AlertState.Active],
        alertFilterCategory: DEFAULT_ALERT_FILTER_CATEGORY_TECHNICAL_LIST,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      skip:
        resellersStore.alertsMapVariant !== AlertVariant.Technical ||
        !growerIds.length,
      onError() {
        snackBarStore.showToast({
          detail: i18next.t('errors:something_went_wrong'),
        });
      },
    });

  if (isAgronomicAlertsLoading || isTechnicalAlertsLoading) {
    return <Loading />;
  }

  return (
    <LiveMonitoringAlertsMap
      alertType={resellersStore.alertsMapVariant}
      agronomicAlerts={agronomicAlerts as GqlPlotAlerts}
      technicalAlerts={technicalAlerts as GqlPlotAlerts}
    />
  );
});

export default LiveMonitoringMap;
