import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

const WrapGrid = styled(Grid)`
  flex-wrap: wrap;
`;

const CouponsDetails: FC = observer(() => {
  const { couponsStore } = useStores();
  const { t } = useTranslation('coupons');

  const UTCDates = useMemo(() => {
    if (
      couponsStore.selectedTreeNode?.data?.validFromUTC &&
      couponsStore.selectedTreeNode?.data?.validToUTC
    ) {
      const fromDate = new Date(
        couponsStore.selectedTreeNode?.data?.validFromUTC,
      ).toISOString();

      const toDate = new Date(
        couponsStore.selectedTreeNode?.data?.validToUTC,
      ).toISOString();

      const formattedFromDate = `${fromDate.substring(
        0,
        10,
      )} ${fromDate.substring(11, 19)}`;

      const formattedToDate = `${toDate.substring(0, 10)} ${toDate.substring(
        11,
        19,
      )}`;

      return { formattedFromDate, formattedToDate };
    }

    return null;
  }, [
    couponsStore.selectedTreeNode?.data?.validFromUTC,
    couponsStore.selectedTreeNode?.data?.validToUTC,
  ]);

  if (!couponsStore.selectedTreeNode?.data) {
    return <Text weight="bold">{t('no_selected_coupon')}</Text>;
  }

  return (
    <FullHeightGrid direction="row" align="start" justify="start" flex={3}>
      <WrapGrid
        align="start"
        gap="0.5rem"
        justify="start"
        display="grid"
        grid-template-columns="auto auto"
      >
        <Text>{t('code')}:</Text>
        <Text weight="bold">
          &nbsp;{couponsStore.selectedTreeNode?.data?.code}&nbsp;
        </Text>
        <Text>{t('grower_id')}:</Text>
        <Text weight="bold">
          &nbsp;{couponsStore.selectedTreeNode?.data?.growerId}&nbsp;
        </Text>
        <Text>{t('max_transactions')}:</Text>
        <Text weight="bold">
          &nbsp;{couponsStore.selectedTreeNode?.data?.maxTransactions}&nbsp;
        </Text>
        <Text>{t('name')}:</Text>
        <Text weight="bold">
          &nbsp;{couponsStore.selectedTreeNode?.data?.name}&nbsp;
        </Text>
        <Text>{t('eligible_countries')}:</Text>
        <Text weight="bold">
          &nbsp;
          {couponsStore.selectedTreeNode?.data?.params?.eligibleCountries?.join(
            ',',
          )}
          &nbsp;
        </Text>
        <Text>{t('valid_from_utc')}:</Text>
        <Text weight="bold">
          &nbsp;{UTCDates?.formattedFromDate}
          &nbsp;
        </Text>
        <Text>{t('valid_to_utc')}:</Text>
        <Text weight="bold">
          &nbsp;{UTCDates?.formattedToDate}
          &nbsp;
        </Text>
      </WrapGrid>
    </FullHeightGrid>
  );
});

export default CouponsDetails;
