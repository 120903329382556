import Page from 'components/shared/Page';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState, useCallback, MouseEvent } from 'react';
import Grid from 'components/UI/Grid';
import { ToggleBox, ToggleBoxProps } from 'components/shared/ToggleBox';
import { VisibilityControl } from 'components/shared/VisibilityControl';
import VerticalCollapse from 'components/shared/VerticalCollapse';
import styled from 'styled-components';
import { TabPanel, TabView, TabViewTabChangeParams } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import i18next from 'i18next';
import { TabViewMode } from 'core/enums';
import Loading from 'components/UI/Loading/Loading';
import CouponsTree from './CouponsTree/CouponsTree';
import MenuBar from './MenuBar';
import CouponsDetails from './CouponsDetails/CouponsDetails';

const StyledMarginGrid = styled(Grid)`
  margin: 1rem 1rem 0 1rem;
`;

const TabViewContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 1rem;
`;

const StyledToggleBox = styled(ToggleBox)`
  position: absolute;
  right: 32px;
  top: 8px;
  z-index: 1;
`;

export const TreeContainer = styled.div`
  height: calc(100vh - 106px);
  margin: 1rem;
  margin-right: 0;
  overflow: hidden;
`;

export enum ResellersGrowerTab {
  Details = 0,
  Plots = 1,
  Systems = 2,
  Features = 3,
  Growers = 4,
  Users = 5,
}

const plotsViewOptions: ToggleBoxProps['options'] = [
  { value: TabViewMode.Table, label: i18next.t('grower:table') },
  { value: TabViewMode.Map, label: i18next.t('grower:map') },
];

const Coupons: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { couponsStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState<ResellersGrowerTab>(
    ResellersGrowerTab.Details,
  );

  const [plotsViewMode, setPlotsViewMode] = useState<TabViewMode>(
    TabViewMode.Table,
  );

  const onTabChange = useCallback((e: TabViewTabChangeParams) => {
    setActiveIndex(e.index);
  }, []);

  const handlePlotsViewChange = useCallback(
    (_event: MouseEvent<HTMLElement>, value: TabViewMode) => {
      setPlotsViewMode((prev) => value ?? prev);
    },
    [],
  );

  useEffect(() => {
    couponsStore.getAllCoupons().then(() => {
      setIsLoading(false);
    });

    return () => {
      couponsStore.setCoupons([]);
      couponsStore.setSelectedTreeNode(null);
    };
  }, [couponsStore]);

  if (isLoading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <Page>
      <Grid direction="column" align="stretch" justify="start" grow>
        <StyledMarginGrid align="stretch">
          <MenuBar selectedTab={activeIndex} />
        </StyledMarginGrid>
        <VerticalCollapse
          leftSideWidth="400px"
          leftComponent={
            <TreeContainer>
              <CouponsTree />
            </TreeContainer>
          }
          rightComponent={
            <TabViewContainer>
              <TabView
                activeIndex={activeIndex}
                renderActiveOnly={false}
                onTabChange={onTabChange}
              >
                <TabPanel header={t('details')}>
                  <CouponsDetails />
                </TabPanel>
              </TabView>
            </TabViewContainer>
          }
        />
      </Grid>
    </Page>
  );
});

export default Coupons;
