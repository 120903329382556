export enum ActionMode {
  CREATE,
  EDIT,
}

export interface UpdatedStatus {
  id: number;
  updated: boolean;
}

export enum DialogOnCloseReason {
  BackDropClick = 'backdropClick',
  EscapeKeyDown = 'escapeKeyDown',
}

export enum DialogCloseAction {
  Confirmed = 'confirmed',
  Declined = 'declined',
}

export type ErrorResponse = {
  message: string;
};

export interface FilterObject {
  label: string;
  value: string;
}

export type DropDownOptions = {
  label: string | number;
  value: string | number;
  disabled?: boolean;
};

/**
 * used when you want to use the table global search to work for a complex item.
 * for example if server returns date like 123123123 and you want to display 11-Jan-13
 * its impossible to sort 11-jan-90 because you don't know if 90 means 2090 or 1990,
 * so you keep the {oldValue} and use it to sort, and let the table use {newValue}
 */
export interface GenericTableItem {
  oldValue: unknown;
  newValue: unknown;
}

export enum PHENOLOGICAL_STATUS {
  PHENOLOGICAL = 'normal',
  PHENOLOGICAL_PENDING = 'pending',
  PHENOLOGICAL_POSTPONED = 'postpone',
}

export type PhenologicalStages = {
  id?: string | null;
  stage?: string | null;
  status?: PHENOLOGICAL_STATUS | null;
};

export interface CropType {
  id: number;
  name: string;
}
