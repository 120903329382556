import React, { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores/hooks/hooks';
import { Paper } from '@mui/material';
import { TabViewTabChangeParams } from 'primereact/tabview';
import { TabPanel, TabView } from 'components/UI/TabView';
import Loading from 'components/UI/Loading/Loading';
import Details from './Details/Details';
import UserPermissions from './UserPermissions';

const UserDetailsContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(p) => p.theme.palette.background.default};
  margin: 1rem;
  height: calc(100% - 2rem); /* 2rem to negate margin top and bottom */
  max-height: calc(100% - 2rem);
`;

const StyledTabView = styled(TabView)`
  .p-tabview-nav {
    margin-left: 3px;
  }
`;

const StyledLoading = styled(Loading)`
  align-items: start;
`;

const UserDetails: FC = observer(() => {
  const { membersStore } = useStores();
  const [activeIndex, setActiveIndex] = useState(1);

  const onTabChange = useCallback((e: TabViewTabChangeParams) => {
    setActiveIndex(e.index);
  }, []);

  return (
    <UserDetailsContainer>
      <StyledTabView
        activeIndex={activeIndex}
        renderActiveOnly={false}
        onTabChange={onTabChange}
      >
        <TabPanel header="Details">
          <Details />
        </TabPanel>
        <TabPanel header="Permissions">
          {membersStore.isMemberGrowersLoading ? (
            <StyledLoading />
          ) : (
            <UserPermissions />
          )}
        </TabPanel>
      </StyledTabView>
    </UserDetailsContainer>
  );
});

export default UserDetails;
