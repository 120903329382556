import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import Loading from 'components/UI/Loading/Loading';
import { GrowerType } from 'models/grower';

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

const WrapGrid = styled(Grid)`
  flex-wrap: wrap;
`;

const Details: FC = observer(() => {
  const { resellersStore } = useStores();
  const { t } = useTranslation('grower');
  const isGrower = resellersStore.selectedGrowerType === GrowerType.Grower;

  if (!resellersStore.selectedTreeNode?.data) {
    return <Text weight="bold">{t('details_multi_user_error')}</Text>;
  }

  if (isGrower && resellersStore.resellerPlotsLoading) {
    return <Loading />;
  }

  return (
    <FullHeightGrid direction="row" align="start" justify="start" flex={3}>
      <WrapGrid
        align="start"
        gap="0.5rem"
        justify="start"
        display="grid"
        grid-template-columns="auto auto"
      >
        <Text>{t('growerID')}:</Text>
        <Text weight="bold">
          &nbsp;{resellersStore.selectedTreeNode?.data?.id}&nbsp;
        </Text>
        <Text>{t('grower')}:</Text>
        <Text weight="bold">
          &nbsp;{resellersStore.selectedTreeNode?.data?.name}&nbsp;
        </Text>
        <Text>{t('reseller')}:</Text>
        <Text weight="bold">
          &nbsp;{resellersStore.selectedTreeNode?.data?.resellerName}&nbsp;
        </Text>
        {isGrower && (
          <>
            <Text>{t('plots')}:</Text>
            <Text weight="bold">
              &nbsp;{resellersStore.selectedTreeNode?.data?.plotCount}&nbsp;
            </Text>
            <Text>{t('hectares')}:</Text>
            <Text weight="bold">
              &nbsp;{resellersStore.selectedTreeNode?.data?.hectares}&nbsp;
            </Text>
          </>
        )}
      </WrapGrid>
    </FullHeightGrid>
  );
});

export default Details;
