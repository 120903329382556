import { ActionMode } from 'models/shared';
import { observer } from 'mobx-react';
import { useStores } from 'stores/hooks/hooks';
import React, { FC } from 'react';
import FeatureCreateDialog from './FeatureDialog/FeatureCreateDialog';

export interface MLModelManagementDialogProps {
  onHide(): void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const FeatureManagementDialog: FC<MLModelManagementDialogProps> = observer(
  (props) => {
    const { visible, mode } = props;
    const isEdit = mode === ActionMode.EDIT;

    if (visible && !isEdit) {
      return <FeatureCreateDialog {...props} />;
    }

    return null;
  },
);

export default FeatureManagementDialog;
