import styled from 'styled-components';

interface Props {
  flex?: number; // flex number, default is 'initial'
  justify?: JustifyProp; // justify content prop, default is space-between
  align?: AlignProp; // align items prop, default is center
  grow?: boolean; // whether is should take all available space, default is false
  overflow?: Overflow; // which overflow to use, default is visible
  inline?: boolean; // whether to use flex or inline-flex, default is flex
}

type JustifyProp = 'start' | 'end' | 'stretch' | 'between' | 'center';
type AlignProp = 'start' | 'end' | 'center' | 'baseline' | 'stretch';
type Overflow = 'auto' | 'hidden' | 'scroll' | 'visible';
enum JustifyOptions {
  start = 'flex-start',
  end = 'flex-end',
  stretch = 'stretch',
  between = 'space-between',
  center = 'center',
}

enum AlignItems {
  start = 'flex-start',
  end = 'flex-end',
  stretch = 'stretch',
  baseline = 'baseline',
  center = 'center',
}

function justifyToCssValue(justify: JustifyProp) {
  return JustifyOptions[justify];
}

function alignToCssValue(align: AlignProp) {
  return AlignItems[align];
}

const StyledRow = styled.div<Props>`
  display: ${(p) => (p.inline ? 'inline-flex' : 'flex')};
  flex-direction: row;
  align-items: ${(p) => (p.align ? alignToCssValue(p.align) : 'center')};
  justify-content: ${(p) =>
    p.justify ? justifyToCssValue(p.justify) : 'space-between'};
  overflow: ${(p) => (p.overflow ? p.overflow : 'visible')};
  ${(p) => p.grow && 'width: 100%'};
  flex: ${(p) => p.flex || 'initial'};
`;

// const Row: FC<Props> = ({ flex, ...props }) => <StyledRow {...props} />
export default StyledRow;
