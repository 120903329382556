/* eslint-disable func-names */
import {
  observable,
  action,
  IObservableArray,
  runInAction,
  makeAutoObservable,
} from 'mobx';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { Close } from '@mui/icons-material';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { theme } from 'components/UI';
import React from 'react';
import { ToastMessage, ToastMessageType } from 'primereact/toast';

const StyledClose = styled(Close)`
  cursor: pointer;
  color: ${theme.color.white};
`;

type Notification = {
  message: SnackbarMessage;
  options: OptionsObject;
};

type SnackbarVariant =
  | 'error'
  | 'default'
  | 'success'
  | 'warning'
  | 'info'
  | undefined;

export class SnackBarStore {
  constructor() {
    makeAutoObservable(this, {
      notifications: observable,
      latestNotification: observable,
      latestToast: observable,
      latestRemove: observable,
      enqueueSnackbar: action,
      showError: action,
      showToast: action,
    });
  }

  notifications: IObservableArray<Notification> = observable([]);
  latestNotification: Notification | null = null;
  latestRemove: SnackbarKey | null = null;
  latestToast: ToastMessageType | null = null;
  enqueueSnackbar(message: string, options: OptionsObject) {
    const notification = {
      message,
      options: {
        key: new Date().getTime() + Math.random(),
        ...options,
      },
    };

    this.latestNotification = notification;
  }

  showError(message: string, variant: SnackbarVariant = 'error') {
    this.enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      action: (key) => (
        <IconButton
          size="small"
          onClick={() =>
            runInAction(() => {
              this.latestRemove = key;
            })
          }
        >
          <StyledClose />
        </IconButton>
      ),
    });

    return [];
  }

  showToast({
    severity = 'error', // decides error color, error=red, warn = yellow, info =blue ,success=green
    summary = 'Error:', // message title
    detail, // message body
    content, // message content, replaces summary and detail if exists.
    life = 5000, // toast duration in ms e.g 5000 = 5 seconds
    sticky,
    closable = true, // whether toast is closable
  }: ToastMessage) {
    const toast = {
      closable,
      severity,
      content,
      detail,
      life,
      sticky,
      summary,
    };

    this.latestToast = toast;
  }
}
