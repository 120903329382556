import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
} from 'react';
import { observer } from 'mobx-react';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import styled from 'styled-components';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import Space from 'components/UI/Space';
import { ActionMode, DialogOnCloseReason } from 'models/shared';

const NoWrappingText = styled(Text)`
  white-space: nowrap;
`;

interface GrowerManagementDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const GrowerManagementDialog: FC<GrowerManagementDialogProps> = observer(
  ({ visible = false, onHide, title, mode }) => {
    const { t } = useTranslation('grower');
    const { resellersStore } = useStores();
    const [growerNameEdit, setGrowerNameEdit] = useState(
      resellersStore.growerAction?.name || '',
    );

    const [initialActionString, setInitialActionString] = useState('');
    useEffect(() => {
      if (initialActionString || growerNameEdit === '') {
        return;
      }

      setInitialActionString(growerNameEdit);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resellersStore.growerAction]);

    const isDirty = initialActionString !== growerNameEdit;

    const closeDialog = useCallback(() => {
      setInitialActionString('');
      onHide();
    }, [onHide]);

    const handleCloseDialog = useCallback(
      (event: any, reason: DialogOnCloseReason) => {
        if (reason && reason === DialogOnCloseReason.BackDropClick) {
          return;
        }

        setGrowerNameEdit('');
        closeDialog();
      },
      [closeDialog],
    );

    const handleCloseButton = useCallback(() => {
      setGrowerNameEdit('');
      closeDialog();
    }, [closeDialog]);

    const handleChange = useCallback(
      (
        event: ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >,
      ) => {
        const { value } = event.target;
        setGrowerNameEdit(value);
      },
      [],
    );

    const handleSubmitClick = useCallback(async () => {
      resellersStore.growerAction.name = growerNameEdit.trim();
      if (mode === ActionMode.CREATE) {
        resellersStore.createNewReseller();
      } else {
        resellersStore.editReseller();
      }

      onHide();
    }, [onHide, resellersStore, mode, growerNameEdit]);

    return (
      <Dialog
        open={visible}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <DialogContent>
          <Space height={1} />
          <Grid direction="row" gap="2.3rem">
            <NoWrappingText>{t('name')}:</NoWrappingText>
            <Input
              required
              label={t('name')}
              id="name"
              value={growerNameEdit}
              variant="outlined"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Space height={1} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleSubmitClick}
            disabled={!isDirty || growerNameEdit.trim() === ''}
          >
            <Text textcolor={theme.color.white} size="sm">
              {t('ok')}
            </Text>
          </Button>
          <Button onClick={handleCloseButton}>
            <Text textcolor={theme.color.white} size="sm">
              {t('cancel')}
            </Text>
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default GrowerManagementDialog;
