import React, { Dispatch, FC, memo, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Text from 'components/UI/Text';
import logo from 'assets/images/icons/SupPlant_Icon.svg';
import Space from 'components/UI/Space';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import {
  iconStyle,
  MinimizeAble,
  StyledIconButton,
  StyledLogo,
  StyledRow,
} from './Styles';

const Head: FC<{
  isMinimized: boolean;
  hideText: boolean;
  setIsPinned: Dispatch<SetStateAction<boolean>>;
  isPinned: boolean;
}> = ({ isMinimized, hideText, setIsPinned, isPinned }) => {
  const { t } = useTranslation('drawer');
  return (
    <StyledRow className="drawer-header" justify="start">
      <StyledLogo src={logo} />
      <StyledRow align="center" justify="between" flex={1}>
        <MinimizeAble isMinimized={hideText}>
          <Space width={1} />
          <Text singleline weight="bold">
            {t('auth:title')}
          </Text>
        </MinimizeAble>
        <MinimizeAble isMinimized={isMinimized} withOpacity align="end">
          <StyledIconButton onClick={() => setIsPinned((prev) => !prev)}>
            {isPinned ? (
              <Lock style={iconStyle} />
            ) : (
              <LockOpen style={iconStyle} />
            )}
          </StyledIconButton>
        </MinimizeAble>
      </StyledRow>
    </StyledRow>
  );
};

export default memo(Head);
