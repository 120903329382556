import React, { useMemo } from 'react';
import { SystemSchemaEditedValues } from 'stores/systemsStore';
import { SystemDefaultSchema, SystemVwsSchema } from 'models/systems';
import { ActionMode } from 'models/shared';
import useDynamicFields from './useDynamicFields';
import {
  DynamicFieldsProps,
  DynamicFieldsValue,
} from './useDynamicFields.types';

interface UseVwsDynamicFieldsProps extends DynamicFieldsProps<SystemVwsSchema> {
  mode?: ActionMode;
}

const useVwsDynamicFields = ({
  mode,
  schema,
  defaultValues,
  showHidden,
  disabled,
}: UseVwsDynamicFieldsProps): DynamicFieldsValue => {
  const baseSchema = schema?.definitions['vws-schema'] as SystemDefaultSchema;
  const paramsSchema = schema?.definitions['vws-schema']?.properties
    ?.vwsParams as SystemDefaultSchema;

  const baseFields = useDynamicFields({
    schema: baseSchema,
    defaultValues,
    showHidden,
    disabled,
  });

  const paramsFields = useDynamicFields({
    schema: paramsSchema,
    defaultValues:
      defaultValues.vwsParams as SystemSchemaEditedValues['values'],
    showHidden: mode === ActionMode.EDIT,
    disabled,
  });

  const values = useMemo(
    () =>
      ({
        ...baseFields.values,
        vwsParams: {
          ...paramsFields.values,
        },
      } as SystemSchemaEditedValues['values']),
    [baseFields.values, paramsFields.values],
  );

  const errors = useMemo(
    () => ({ ...baseFields.errors, ...paramsFields.errors }),
    [baseFields.errors, paramsFields.errors],
  );

  return {
    values,
    errors,
    isValid: baseFields.isValid && paramsFields.isValid,
    isDirty: baseFields.isDirty || paramsFields.isDirty,
    fields: {
      baseFields: baseFields.fields.baseFields,
      paramsFields: paramsFields.fields.baseFields,
    },
  };
};

export default useVwsDynamicFields;
