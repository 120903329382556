import { CropModelStore } from './cropModelStore';
import { ResellersStore } from './resellersStore';
import { SnackBarStore } from './snackbarsStore';
import { UserStore } from './userStore';
import { MembersStore } from './membersStore';
import { PlotsStore } from './plotsStore';
import { WeatherStore } from './weatherStore';
import { SystemsStore } from './systemsStore';
import { FeaturesStore } from './featuresStore';
import { CouponsStore } from './couponsStore';

export class RootStore {
  userStore: UserStore = new UserStore(this);
  snackBarStore: SnackBarStore = new SnackBarStore();
  cropModelStore: CropModelStore = new CropModelStore(this);
  resellersStore: ResellersStore = new ResellersStore(this);
  systemsStore: SystemsStore = new SystemsStore(this);
  membersStore: MembersStore = new MembersStore(this);
  plotsStore: PlotsStore = new PlotsStore(this);
  weatherStore: WeatherStore = new WeatherStore(this);
  featuresStore: FeaturesStore = new FeaturesStore(this);
  couponsStore: CouponsStore = new CouponsStore(this);
}
