import React, { Component, ErrorInfo, FC, PropsWithChildren } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import Grid from '../Grid';
import Text from '../Text';

const StyledContainer = styled(Grid)`
  height: 100vh;
`;

const defaultErrorMessage = i18next.t('errors:something_went_wrong');

interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({
  message = defaultErrorMessage,
}) => (
  <StyledContainer justify="center" align="center">
    <Text fontSize={25}>{message}</Text>
  </StyledContainer>
);

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorMessage />;
    }

    return children;
  }
}

export default ErrorBoundary;
