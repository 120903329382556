import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores/hooks/hooks';
import Grid from 'components/UI/Grid';
import Loading from 'components/UI/Loading/Loading';
import { MassEditSettings } from 'models/plot';
import ResellerPlotMassEdit from './ResellerPlotMassEdit';

const Container = styled(Grid)`
  height: 100%;
`;

const ResellerMassEdit: FC = observer(() => {
  const { resellersStore, plotsStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();

    plotsStore
      .getGrowerMassPlotSettings(
        resellersStore.resellerPlots?.map((plot) => ({
          id: plot?.id,
          plotName: plot?.name || '',
        })),
        controller,
      )
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });

    return () => {
      plotsStore.setSelectedMassEditRows([]);
      plotsStore.plotMassSettings = {} as MassEditSettings;
      controller.abort();
    };
  }, [plotsStore, resellersStore.resellerPlots]);

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <ResellerPlotMassEdit />
    </Container>
  );
});

export default ResellerMassEdit;
