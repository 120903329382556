import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
  Controller,
} from 'react-hook-form';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Space from 'components/UI/Space';
import Input from 'components/UI/Input';
import Grid from 'components/UI/Grid';
import PhoneInput from 'components/UI/PhoneInput';
import Text from 'components/UI/Text';
import { useStores } from 'stores/hooks/hooks';
import {
  USER_STATUSES,
  LANGUAGES,
  UNITS,
  DEFAULT_USER_PROFILE_ID,
} from 'core/constants';
import { ActionMode } from 'models/shared';
import { UserPreferences } from 'models/user';
import { UserFormFieldsSchema } from './UserManagementFormSchemas';

interface UserFormProperties {
  mode: ActionMode;
  control: Control<UserFormFieldsSchema>;
  errors: FieldErrors<UserFormFieldsSchema>;
  register: UseFormRegister<UserFormFieldsSchema>;
  getValues: UseFormGetValues<UserFormFieldsSchema>;
}

const GridColumnWidth = styled(Grid)`
  width: 50%;
  padding: 0 1rem;
`;

const NoWrappingText = styled(Text)`
  white-space: nowrap;
`;

const PHONE_DEFAULT_COUNTRY = 'IL';

const UserFormProperties: FC<UserFormProperties> = observer(
  ({ mode, control, errors, register, getValues }) => {
    const { t } = useTranslation('users');
    const { membersStore } = useStores();
    const formValues = getValues();
    const [supportedLocales, setSupportedLocales] = useState();

    const getSupportedLocales = useCallback(async () => {
      const locales = await membersStore.getSupportedLocales();
      setSupportedLocales(locales);
    }, [membersStore]);

    useEffect(() => {
      getSupportedLocales();
    }, [getSupportedLocales]);

    return (
      <>
        <Space height={1} />
        <Grid direction="column" gap="1rem" align="stretch">
          <Grid direction="row" align="stretch">
            <GridColumnWidth direction="column" align="stretch">
              <Grid direction="row" gap="2.3rem">
                <NoWrappingText>{t('first_name')}:</NoWrappingText>
                <TextField
                  fullWidth
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  label={t('first_name')}
                  variant="outlined"
                  type="text"
                  {...register('firstName')}
                />
              </Grid>
            </GridColumnWidth>
            <GridColumnWidth direction="column" align="stretch">
              <Grid direction="row" gap="6rem">
                <NoWrappingText>{t('status')}:</NoWrappingText>
                <Controller
                  control={control}
                  name="active"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      select
                      fullWidth
                      value={value ? 0 : 1}
                      label={t('status')}
                      onChange={(event) => {
                        onChange(Number(event.target.value) === 0);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {USER_STATUSES.map((active) => (
                        <option key={active.id} value={active.id}>
                          {active.name}
                        </option>
                      ))}
                    </Input>
                  )}
                />
              </Grid>
            </GridColumnWidth>
          </Grid>
          <Grid direction="row" align="stretch">
            <GridColumnWidth direction="column" align="stretch">
              <Grid direction="row" gap="2.3rem">
                <NoWrappingText>{t('last_name')}:</NoWrappingText>
                <TextField
                  fullWidth
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  label={t('last_name')}
                  variant="outlined"
                  type="text"
                  {...register('lastName')}
                />
              </Grid>
            </GridColumnWidth>
            <GridColumnWidth direction="column" align="stretch">
              <Grid direction="row" gap="1rem">
                <NoWrappingText>{t('google_analytics')}:</NoWrappingText>
                <Controller
                  control={control}
                  name="gaRelevant"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      select
                      fullWidth
                      value={value ? 0 : 1}
                      label={t('google_analytics')}
                      onChange={(event) => {
                        onChange(Number(event.target.value) === 0);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {USER_STATUSES.map((active) => (
                        <option key={active.id} value={active.id}>
                          {active.name}
                        </option>
                      ))}
                    </Input>
                  )}
                />
              </Grid>
            </GridColumnWidth>
          </Grid>
          <Grid direction="row" align="stretch">
            <GridColumnWidth direction="column" align="stretch">
              <Grid direction="row" gap="4.5rem" grow>
                <NoWrappingText>{t('email')}:</NoWrappingText>
                <TextField
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  label={t('email')}
                  variant="outlined"
                  {...register('email')}
                />
              </Grid>
              <Space height={1} />
              <Grid direction="row" gap="0.4rem" grow>
                <NoWrappingText>{t('company_role')}:</NoWrappingText>
                <Controller
                  control={control}
                  name="companyRoleId"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      select
                      fullWidth
                      value={value}
                      label={t('company_role')}
                      onChange={(event) => {
                        onChange(Number(event.target.value));
                      }}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {!formValues?.companyRoleId && (
                        <option>{t('select_role')}</option>
                      )}
                      {membersStore.companyRoles.map((role) => (
                        <option key={role.uid} value={role.uid}>
                          {role.role}
                        </option>
                      ))}
                    </Input>
                  )}
                />
              </Grid>
              <Space height={1} />
              <Grid direction="row" gap="4.1rem" grow>
                <NoWrappingText>{t('phone')}:</NoWrappingText>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      fullWidth
                      value={value}
                      label={t('phone')}
                      defaultCountry={PHONE_DEFAULT_COUNTRY}
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                      onChange={(phoneValue) => {
                        onChange(phoneValue);
                      }}
                    />
                  )}
                />
              </Grid>
              <Space height={1} />
              <Grid direction="row" gap="4.7rem" grow>
                <NoWrappingText>{t('units')}:</NoWrappingText>
                <Controller
                  control={control}
                  name="preferredMeasurementSystem"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      select
                      fullWidth
                      value={value}
                      label={t('units')}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {UNITS.map((unit) => (
                        <option key={unit.id} value={unit.unit}>
                          {unit.unit}
                        </option>
                      ))}
                    </Input>
                  )}
                />
              </Grid>
              <Space height={1} />
              <Grid direction="row" gap="2.5rem" grow>
                <NoWrappingText>{t('language')}:</NoWrappingText>
                <Controller
                  control={control}
                  name="locale"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      select
                      fullWidth
                      value={value}
                      label={t('language')}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {LANGUAGES.map((language) => (
                        <option
                          key={
                            supportedLocales
                              ? supportedLocales[language.value]
                              : language.id
                          }
                          value={
                            supportedLocales
                              ? supportedLocales[language.value]
                              : language.id
                          }
                        >
                          {language.lang}
                        </option>
                      ))}
                    </Input>
                  )}
                />
              </Grid>
              {mode === ActionMode.CREATE && (
                <>
                  <Space height={1} />
                  <Grid direction="row" gap="2.1rem" grow>
                    <NoWrappingText>{t('user_origin')}:</NoWrappingText>
                    <Controller
                      control={control}
                      name="userPreferences.profileId"
                      render={({ field: { onChange, value } }) => (
                        <Input
                          select
                          fullWidth
                          value={value}
                          label={t('user_origin')}
                          defaultValue={DEFAULT_USER_PROFILE_ID}
                          onChange={(event) => {
                            onChange(Number(event.target.value));
                          }}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {JSON.parse(
                            JSON.stringify(membersStore.userPreferences),
                          ).map((option: UserPreferences) => (
                            <option
                              key={option.profileId}
                              value={option.profileId}
                            >
                              {t(option.profileDescription)}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </Grid>
                </>
              )}
            </GridColumnWidth>
          </Grid>
        </Grid>
        <Space height={1} />
      </>
    );
  },
);

export default UserFormProperties;
