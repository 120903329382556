import React from 'react';
import { LatLngExpression } from 'leaflet';
import {
  CustomMarkerProps,
  MapEntityProps,
  MarkerEntityProps,
  PolygonEntityProps,
} from 'components/shared/Map/MapEntity/MapEntity.types';
import markerIcon from 'assets/images/icons/default_marker.png';
import markerRedIcon from 'assets/images/icons/default_marker_red.png';
import { GqlPlotAlertData } from 'gql/types/plots';
import { DEFAULT_ALERT_FILTER_CATEGORY_LIST } from 'core/constants';
import { SensorGroup } from 'models/sensor';
import {
  errorHoverPathOptions,
  errorPathOptions,
  getAlertMarker,
  getPlotDefaultMarker,
  getPlotPolygonCoordinates,
  getPlotAlertsLocation,
  getSensorMarker,
  hoverPathOptions,
  pathOptions,
} from '../utils/alertsMapUtils';

interface MapDetails {
  plotsCount: number;
  alertsCount: number;
  plotsLocation: LatLngExpression | null;
}

export type FilterMapEntitiesType = Record<
  SensorGroup,
  {
    mapEntities: MapEntityProps[];
    mapDetails: MapDetails;
  }
>;

const checkPlotHasAlerts = (
  plot: GqlPlotAlertData,
  filterValue: SensorGroup,
): boolean => {
  return plot.alerts?.some(
    (alert) => alert.type.filterCategory === filterValue,
  );
};

export const getPlotMarker = (
  plot: GqlPlotAlertData,
  hasAlerts: boolean,
): MarkerEntityProps => {
  const iconUrl = hasAlerts ? markerRedIcon : markerIcon;
  return getPlotDefaultMarker(plot, iconUrl);
};

export const getSensorMarkers = (
  plot: GqlPlotAlertData,
  filterValue: SensorGroup,
): CustomMarkerProps[] => {
  const filterSensors = plot.sensors.filter(
    (sensor) =>
      sensor.sensorType?.group === filterValue &&
      !!(sensor.latitude && sensor.longitude),
  );

  return filterSensors.map((sensor) => getSensorMarker(sensor));
};

export const getPlotPolygon = (
  plot: GqlPlotAlertData,
  filterValue: SensorGroup,
  hasAlerts: boolean,
): PolygonEntityProps => {
  const coordinates = getPlotPolygonCoordinates(plot);
  const customMarkers = getSensorMarkers(plot, filterValue);

  return {
    positions: coordinates.map(([long, lat]) => [lat, long]) ?? [],
    tooltipContent: plot.name,
    pathOptions: hasAlerts ? errorPathOptions : pathOptions,
    hoverPathOptions: hasAlerts ? errorHoverPathOptions : hoverPathOptions,
    customMarkers: hasAlerts
      ? [...customMarkers, getAlertMarker(plot)]
      : customMarkers,
  };
};

export const getFilterMapDetails = (
  plots: GqlPlotAlertData[],
  filterValue: SensorGroup,
): MapDetails => {
  let plotsCount = 0;
  let alertsCount = 0;
  plots.forEach((plot) => {
    const hasAlert = checkPlotHasAlerts(plot, filterValue);
    const hasSensor = plot.sensors?.some(
      (sensor) => sensor.sensorType?.group === filterValue,
    );

    if (hasSensor) {
      plotsCount += 1;
    }

    if (hasAlert) {
      alertsCount += 1;
    }
  });

  return {
    plotsCount,
    alertsCount,
    plotsLocation: getPlotAlertsLocation(plots),
  };
};

export const getFilterMapEntities = (
  plots: GqlPlotAlertData[],
  onlyAlerts?: boolean,
): FilterMapEntitiesType => {
  const result: FilterMapEntitiesType = {} as FilterMapEntitiesType;
  DEFAULT_ALERT_FILTER_CATEGORY_LIST.forEach((filterValue) => {
    const mapEntities: MapEntityProps[] = [];
    plots.forEach((plot) => {
      const hasAlerts = checkPlotHasAlerts(plot, filterValue);
      if ((onlyAlerts && hasAlerts) || !onlyAlerts)
        mapEntities.push({
          entityKey: plot.id.toString(),
          polygon: getPlotPolygon(plot, filterValue, hasAlerts),
          marker: getPlotMarker(plot, hasAlerts),
        });
    });

    result[filterValue] = {
      mapEntities,
      mapDetails: getFilterMapDetails(plots, filterValue),
    };
  });

  return result;
};
