import { MapProps } from 'components/shared/Map/Map.types';
import { LatLng, LatLngExpression, latLng, latLngBounds } from 'leaflet';

const MAP_SOUTH_WEST_CORNER: LatLng = latLng(-90, -200);
const MAP_NORTH_EAST_CORNER: LatLng = latLng(90, 200);
const MAP_NEAR_EAST_CENTER: LatLngExpression = [32, 35];
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

type DefaultMapOptionsProps = Pick<
  MapProps,
  'mapContainerProps' | 'googleLayerProps' | 'mapConfig'
>;

export const defaultMapOptions: DefaultMapOptionsProps = {
  mapContainerProps: {
    center: MAP_NEAR_EAST_CENTER,
    zoom: 4,
    minZoom: 2,
    maxZoom: 21,
    maxBoundsViscosity: 1.0,
    maxBounds: latLngBounds(MAP_SOUTH_WEST_CORNER, MAP_NORTH_EAST_CORNER),
  },
  mapConfig: {
    moveToEntityAnimationDuration: 2.5,
    selectedPolygonZoomLevel: 17,
    showPolygonZoomLevel: 15,
  },
  googleLayerProps: {
    googleMapsLoaderConf: {
      apiKey: GOOGLE_MAPS_API_KEY,
    },
    type: 'hybrid',
    noWrap: true,
  },
};
