export interface SystemType {
  SystemType: {
    name: string;
    type: string;
    isActions: boolean;
    isAutoSerial: boolean;
    isConnectivity: boolean;
    isDeprecated: boolean;
    isForecast: boolean;
    isParams: boolean;
    isSensors: boolean;
    isSetSupported: boolean;
  };
}

export enum SystemPropertyType {
  String = 'string',
  Number = 'number',
  Object = 'object',
  Boolean = 'boolean',
}

export interface SystemSchemaProperty {
  $ref: string;
  title: string;
  description: string;
  type: SystemPropertyType;
  default: string;
  error: string;
  pattern: string;
  format: string;
  empty: boolean;
  maximum?: number;
  minimum?: number;
}

export interface SystemConfigurationProperties extends Record<string, unknown> {
  server?: string;
  accessTokenKey?: string;
  accessToken?: string;
  type?: string;
}

export interface SystemDefaultSchema {
  title: string;
  new_hidden: string[];
  required: string[];
  properties: Record<string, SystemSchemaProperty>;
  definitions: Record<string, { enum: string[] }>;
}

export type SystemParamPropertyType = string | boolean | number;

export type SystemParamsType = {
  [key: string]:
    | SystemParamPropertyType
    | { [key: string]: SystemParamPropertyType };
};

export type VmsPropertiesType = {
  vwsParams: SystemDefaultSchema;
} & Record<string, SystemSchemaProperty>;

export interface SystemVmsSchema extends SystemDefaultSchema {
  properties: VmsPropertiesType;
  type?: SystemPropertyType.Object;
}

export interface SystemVwsSchema {
  title: string;
  properties: VmsPropertiesType;
  allOf?: [{ $ref: string }];
  definitions: {
    'vws-schema': SystemVmsSchema;
  };
}

export interface SystemConfigurationSchema {
  schema: SystemDefaultSchema | SystemVwsSchema;
}

export interface SystemSensorType {
  id: number;
  type: string;
  family: string;
  group: string;
  name: string;
  identifier: string;
  physical: boolean;
  active: boolean;
}

export enum SystemVariant {
  DREAM = 'DREAM',
  DRY = 'DRY',
  VWS_ACCUWEATHER = 'VWS_ACCUWEATHER',
  VWS_CLIMACELL = 'VWS_CLIMACELL',
  GSI = 'GSI',
  PROGRES = 'PROGRES',
  ICCPRO = 'ICCPRO',
  DFM = 'DFM',
  IOP = 'IOP',
  IW = 'IW',
  DAVIS = 'DAVIS',
}
