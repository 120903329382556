import i18next from 'i18next';
import { z } from 'zod';
import { matchIsValidTel } from 'mui-tel-input';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_MEASUREMENT_SYSTEM,
  DEFAULT_USER_PROFILE_ID,
} from 'core/constants';
import {
  digitRegex,
  singleSpaceSeparatorRegex,
  specialCharacterRegex,
} from 'core/regexps';

const validateName = (value: string): boolean => {
  const isNumber = digitRegex.test(value);
  const isSpecialCharacter = specialCharacterRegex.test(value);
  const isValidSpaces = singleSpaceSeparatorRegex.test(value);
  return !!value?.length && !isNumber && !isSpecialCharacter && isValidSpaces;
};

const getEmptyError = (field: string): string => {
  return i18next.t('validation:empty_field_error', {
    field,
  });
};

const getInvalidFieldError = (field: string): string => {
  return i18next.t('validation:invalid_field_error', {
    field,
  });
};

const getSpecialCharsError = (field: string): string => {
  return i18next.t('validation:field_contains_special_characters_error', {
    field,
  });
};

const userPreferencesSchema = z.object({
  profileId: z.number().default(DEFAULT_USER_PROFILE_ID),
});

export const userFormFieldsSchema = z.object({
  firstName: z
    .string()
    .nonempty({
      message: getEmptyError('First name'),
    })
    .refine(validateName, {
      message: getSpecialCharsError('First name'),
    }),
  lastName: z
    .string()
    .nonempty({
      message: getEmptyError('Last name'),
    })
    .refine(validateName, {
      message: getSpecialCharsError('Last name'),
    }),
  email: z.string().email({
    message: getInvalidFieldError('Email'),
  }),
  active: z.boolean().default(false),
  locale: z.string().default(DEFAULT_LANGUAGE),
  gaRelevant: z.boolean().default(false),
  preferredMeasurementSystem: z.string().default(DEFAULT_MEASUREMENT_SYSTEM),
  companyRoleId: z.number(),
  phone: z.string().refine((value) => matchIsValidTel(value ?? ''), {
    message: getInvalidFieldError('Phone'),
  }),
  userPreferences: userPreferencesSchema,
});

export type UserFormFieldsSchema = z.infer<typeof userFormFieldsSchema>;
