/* eslint-disable react/destructuring-assignment */
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  MenuItem,
  TextField,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material';
import { format } from 'date-fns';
import { ServiceLevel, Plot, TimeZone, SoilType } from 'models/plot';

export const PlantingDateBody = (plot: Plot) => {
  const { plantedAt } = plot;
  const date = format(new Date(plantedAt), 'dd-MMM-yy');
  return <div>{date}</div>;
};

export const GetServiceLevelOptionLabel = (el: ServiceLevel) =>
  `${el.serviceLevel} (${el.count})`;

export const ServiceLevelRenderInput = (
  params: AutocompleteRenderInputParams,
) => {
  const { t } = useTranslation('crop_models');
  return <TextField {...params} label={t('service_level')} />;
};

export const ServiceLevelRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: ServiceLevel,
  state: AutocompleteRenderOptionState,
) => (
  <MenuItem {...props} key={option.serviceLevel} value={option.serviceLevel}>
    <Checkbox color="primary" checked={state.selected} />
    {option.serviceLevel} ({option.count})
  </MenuItem>
);

export const GetTimeZoneOptionLabel = (el: TimeZone) =>
  `${el.timezone} (${el.count})`;

export const TimeZoneRenderInput = (params: AutocompleteRenderInputParams) => {
  const { t } = useTranslation('crop_models');
  return <TextField {...params} label={t('time_zone')} />;
};

export const TimeZoneRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: TimeZone,
  state: AutocompleteRenderOptionState,
) => (
  <MenuItem {...props} key={option.timezone} value={option.timezone}>
    <Checkbox color="primary" checked={state.selected} />
    {option.timezone} ({option.count})
  </MenuItem>
);

export const SoilTypeOptionLabel = (el: SoilType) =>
  `${el.soilName} (${el.count})`;

export const SoilTypeRenderInput = (params: AutocompleteRenderInputParams) => {
  const { t } = useTranslation('crop_models');
  return <TextField {...params} label={t('soil_type')} />;
};

export const SoilTypeRenderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: SoilType,
  state: AutocompleteRenderOptionState,
) => (
  <MenuItem {...props} key={option.soilId} value={option.soilName}>
    <Checkbox color="primary" checked={state.selected} />
    {option.soilName} ({option.count})
  </MenuItem>
);
