import React, { FC, useCallback, useState } from 'react';
import { Marker, Tooltip, Polygon } from 'react-leaflet';
import {
  LeafletPolygonRefType,
  MapEntityComponentProps,
  MapEntityDisplayMode,
} from './MapEntity.types';
import useEventHandlers from './hooks/useEventHandlers';
import usePolygonCenter from './hooks/usePolygonCenter';
import { resizeIcon } from './MapEntity.utils';

const MapEntity: FC<MapEntityComponentProps> = (props) => {
  const [polygonRef, setPolygonRef] = useState<LeafletPolygonRefType>();
  const { entityKey, zoomLevel, polygon, marker, mapConfig } = props;
  const { polygonEventHandlers, markerEventHandlers, displayMode } =
    useEventHandlers(props);

  const handlePolygonRefChange = useCallback((ref: LeafletPolygonRefType) => {
    setPolygonRef(ref);
  }, []);

  const isZoomedOut = zoomLevel < mapConfig.showPolygonZoomLevel;
  const polygonCenter = usePolygonCenter({ polygonRef });

  return isZoomedOut || !polygon.positions?.length ? (
    <Marker
      {...marker}
      eventHandlers={markerEventHandlers}
      key={`marker-${entityKey}`}
    >
      <Tooltip>{marker.tooltipContent}</Tooltip>
    </Marker>
  ) : (
    <Polygon
      {...polygon}
      ref={handlePolygonRefChange as VoidFunction}
      key={`polygon-${entityKey}`}
      eventHandlers={polygonEventHandlers}
      pathOptions={
        displayMode === MapEntityDisplayMode.Hover
          ? polygon.hoverPathOptions
          : polygon.pathOptions
      }
    >
      <Tooltip>{polygon.tooltipContent}</Tooltip>
      {polygon.customMarkers?.map((customMarker) => (
        <Marker
          {...customMarker}
          position={
            customMarker.alignCenter && polygonCenter
              ? polygonCenter
              : customMarker.position
          }
          key={`custom-marker-${customMarker.key}`}
          icon={resizeIcon(customMarker.icon, customMarker.iconSize, zoomLevel)}
          title=""
        >
          <Tooltip>{customMarker.tooltipContent}</Tooltip>
        </Marker>
      ))}
    </Polygon>
  );
};

export default MapEntity;
