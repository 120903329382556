// Types that cannot be opened inside a chrome browser
const restrictedTypes = [
  'application/vnd.ms-powerpoint',
  'application/octet-stream',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const displayFileOrDownload = (blob: Blob, fileName: string): void => {
  const blobType = blob.type;
  const data = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  if (restrictedTypes.includes(blobType)) {
    link.click();
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  } else {
    window.open(data);
  }
};

export { displayFileOrDownload };
