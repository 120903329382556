import api from 'Api/ApiMethods';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { WeatherSystem } from 'models/models';
import { ErrorResponse } from 'models/shared';
import { RootStore } from './rootStore';

export class WeatherStore {
  rootStore?: RootStore = undefined;
  weatherSystems: WeatherSystem[] = [];
  selectedRows: WeatherSystem[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getWeatherSystems = async (plotCoordinates: string, radius: number) => {
    try {
      const coordinates = plotCoordinates.split(',');
      // this.weatherSystems = await api.getWeatherSystems(Number(coordinates[0]), Number(coordinates[1]), radius);
      return true;
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(error)
          ? (error.response?.data as ErrorResponse)?.message
          : `Cannot get weather systems`,
      });
    }

    return false;
  };

  setSelectedRows = (selectedRows: WeatherSystem[]) => {
    this.selectedRows = selectedRows;
  };

  setWeatherSystemSelected = (systemId: number, selected: boolean) => {
    const found = this.weatherSystems.find(
      (weather) => weather.id === systemId,
    );

    if (found) {
      found.isSelected = selected;
    }
  };

  getExcelFileForComparison = async (weatherProviders: string[]) => {
    try {
      const selectedSystems = this.weatherSystems.filter(
        (system) => system.isSelected,
      );

      const systemsIds = selectedSystems.map((system) => system.id);
      // const file = await api.getExcelFileForComparison(systemsIds, weatherProviders);
      // return file;
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail: axios.isAxiosError(error)
          ? (error.response?.data as ErrorResponse)?.message
          : `Cannot get weather systems`,
      });
    }
  };
}
