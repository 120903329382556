import { WebPlot } from './plot';

export enum SensorGroup {
  Plant = 'PLANT',
  Soil = 'SOIL',
  Weather = 'WEATHER',
  Imagery = 'IMAGERY',
  Irrigation = 'IRRIGATION',
  Technical = 'TECHNICAL',
}

interface SensorType {
  id: number;
  name: string;
  group: SensorGroup;
  type: string;
  active: boolean;
  family: string;
}

export interface Sensor {
  id: number;
  serial: string;
  type: string;
  displayName: string;
  systemId: number;
  systemSerial: string;
  isPublic: boolean;
  connectivity: number;
  location: string;
  sensorTypeId: string;
  sensorType: SensorType;
  latitude: number;
  longitude: number;
  depth?: number;
  alias?: string;
  params?: {
    companyUuid?: string;
    altitude?: number;
    depth?: number;
    valveId?: string;
    id?: string | number;
    alias?: string;
    uuid?: string;
  };
}

export interface ActiveSensor {
  sensor: Sensor;
  plots: WebPlot[];
}

export interface EditableSensor {
  sensor: Sensor;
  details: {
    plots: WebPlot[];
    selected?: boolean;
  };
}
