import React, { FC } from 'react';
import styled from 'styled-components';
import Button from 'components/UI/Button';
import Text from 'components/UI/Text';
import Grid from 'components/UI/Grid';

const StyledButton = styled(Button)`
  position: relative;
  width: 100%;
  padding-left: 3.5rem;
  border-radius: 0;
  justify-content: flex-start;
  border-left: ${({ theme }) => `2px solid ${theme.color.transparent}`};
  border-top: ${({ theme }) => `1px solid ${theme.color.border}`};

  &:last-child {
    border-bottom: ${({ theme }) => `1px solid ${theme.color.border}`};
  }

  &.active {
    border-left: ${({ theme }) => `2px solid ${theme.color.success}`};
    background-color: ${({ theme }) => theme.color.paleAqua};
  }
`;

const FiltersContainer = styled(Grid)`
  height: 100%;
  min-width: 240px;
`;

const GreenCircle = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ theme }): string => theme.color.lightGreen};
`;

const AlertCircle = styled(Grid)`
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${({ theme }): string => theme.color.brightRed};
`;

const StyledText = styled(Text)`
  color: ${({ theme }): string => theme.color.text};
`;

const AlertText = styled(StyledText)`
  color: ${({ theme }): string => theme.color.white};
  line-height: 20px;
`;

const FilterTitle = styled(StyledText)`
  padding-left: 1rem;
  margin-bottom: 1rem;
`;

const IconContainer = styled(Grid)`
  position: absolute;
  left: 0;
  min-width: 48px;
`;

export interface LiveMonitoringMapFilterOption {
  label: string;
  value: string;
  description: string;
  icon: string;
  errorsNumber?: number;
}

interface LiveMonitoringMapFilterProps {
  title: string;
  options: LiveMonitoringMapFilterOption[];
  activeFilter: string;
  onChange(value: string): void;
}

const LiveMonitoringMapFilter: FC<LiveMonitoringMapFilterProps> = ({
  title,
  options,
  activeFilter,
  onChange,
}) => (
  <FiltersContainer direction="column" justify="start" align="start">
    <FilterTitle weight="bold" size="md">
      {title}
    </FilterTitle>
    {options.map(({ value, label, icon, description, errorsNumber }) => (
      <StyledButton
        key={value}
        variant="text"
        onClick={() => onChange(value)}
        size="small"
        className={value === activeFilter ? 'active' : ''}
      >
        <Grid direction="column" align="start">
          <StyledText>{label}</StyledText>
          <Grid direction="row" gap="0.5rem">
            <GreenCircle />
            <StyledText size="sm">{description}</StyledText>
          </Grid>
        </Grid>
        <IconContainer direction="row" align="center" justify="center">
          <img src={icon} alt={label} />
        </IconContainer>
        {!!errorsNumber && (
          <AlertCircle content="center" align="center">
            <AlertText weight="bold">{errorsNumber}</AlertText>
          </AlertCircle>
        )}
      </StyledButton>
    ))}
  </FiltersContainer>
);

export default LiveMonitoringMapFilter;
