import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { BrowserRoutes } from 'core/enums';
import { Navigate, Outlet, Route, Routes as Switch } from 'react-router-dom';
import Login from 'components/pages/Login/Login';
import ForgotPassword from 'components/pages/ForgotPassword/ForgotPassword';
import PasswordReset from 'components/pages/PasswordReset/PasswordReset';
import { useStores } from 'stores/hooks/hooks';
import Drawer from 'components/shared/Drawer';
import Layout from './Layout';
import { Users } from './pages/Users';
import { Resellers } from './pages/Resellers';
import { CropModel } from './pages/CropModel';
import { LiveMonitoring } from './pages/LiveMonitoring';
import Coupons from './pages/Coupons/Coupons';

export const AuthedRoute: FC = observer(() => {
  const { userStore } = useStores();
  return userStore.isAuthenticated ? (
    <Navigate to={BrowserRoutes.HOME} replace />
  ) : (
    <Outlet />
  );
});

export const PrivateRoute: FC = observer(() => {
  const { userStore } = useStores();
  return userStore.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={BrowserRoutes.AUTH} replace />
  );
});

const Routes: FC = observer(() => {
  const { userStore } = useStores();
  return (
    <Layout>
      {userStore.isAuthenticated && <Drawer />}
      <Switch>
        <Route path="*" element={<Navigate to={BrowserRoutes.NOT_FOUND} />} />
        <Route
          path={BrowserRoutes.HOME}
          element={<Navigate to={BrowserRoutes.CROP_MODELS} />}
        />
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path={BrowserRoutes.GROWERS_SETTINGS}
            element={<Resellers />}
          />
          <Route path={BrowserRoutes.USERS_SETTINGS} element={<Users />} />
          <Route
            path={BrowserRoutes.LIVE_MONITORING}
            element={<LiveMonitoring />}
          />
          <Route path={BrowserRoutes.COUPONS} element={<Coupons />} />
          <Route path={BrowserRoutes.CROP_MODELS} element={<CropModel />} />
          <Route
            index
            element={<Navigate to={BrowserRoutes.CROP_MODELS} replace />}
          />
        </Route>
        <Route path={BrowserRoutes.AUTH} element={<AuthedRoute />}>
          <Route
            path={BrowserRoutes.RESET_PASSWORD}
            element={<PasswordReset />}
          />
          <Route
            path={BrowserRoutes.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route index element={<Login />} />
        </Route>
      </Switch>
    </Layout>
  );
});

export default Routes;
