export type Locale = 'iw_IL' | 'en_US' | 'es_ES' | 'zh_CN';

export interface AccessTokenBlock {
  email: string;
  firstName: string;
  id: string;
  lastLogin: number;
  lastName: string;
  locale: string;
  preferredMeasurementSystem: string;
}

export interface UserApiLimits {
  maxNumOfGrowersAllowed: number | null;
  maxNumOfPlotsAllowed: number | null;
  maxNumOfPlotPerGrowersAllowed: number | null;
}

export interface UserApi {
  uid: number;
  userId: number;
  apiKey: null | string;
  clientId: null | string;
  limits: null | UserApiLimits;
}

export interface UserPaging {
  paginatedCollection: User[];
  totalCount: number;
}

export interface UserManagementValidation {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  growersLimit: boolean;
  plotsLimit: boolean;
  plotsPerGrowersLimit: boolean;
  phone: boolean;
}

export interface UserPreferences {
  profileId: number;
  profileDescription: string;
}

export enum UserRole {
  User = 'USER',
  Admin = 'ADMIN',
  GbiAdmin = 'GBI_ADMIN',
}

export interface User {
  id: number;
  lastLoginTime: number | null;
  firstName: string;
  lastName: string;
  email: string;
  active: boolean;
  locale: Locale;
  phone?: string;
  gaRelevant: boolean;
  userApi: UserApi | null;
  role?: string;
  units?: string;
  companyRoleId: number;
  preferredMeasurementSystem: string;
  linkTemplate: string | undefined;
  userPreferences: UserPreferences;
}

export interface ActivateUser {
  id: number;
  active: boolean;
}

export interface CompanyRole {
  uid: number;
  role: string;
}

export interface UserGrower {
  id: number;
  name: string;
  resellerName: string;
  resellerId: number;
  userGrowerRole: string;
}

export interface UserGrowerRole {
  id: number;
  name: string;
}

export enum MeasurementSystem {
  Metric = 'METRIC',
  Imperial = 'IMPERIAL',
}

export interface UserExcelData {
  uid: number;
  resellerId: number;
  growerId: number;
  firstName: string;
  lastName: string;
  email: string;
  companyRole: string;
  phone: string;
  units: string;
  language: Locale;
  userOrigin: number;
  status: string;
  googleAnalytics: string;
  sendWelcomeEmail: boolean;
}

export type UserExcel = {
  isValid: boolean;
  data: UserExcelData;
  selected: boolean;
  importErrors: ParseExcelMessages[];
  importWarnings: ParseExcelMessages[];
};

export type ParseExcelMessages = {
  fieldName: string;
  msg: string;
};
