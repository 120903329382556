import { WebPlot } from 'models/plot';
import { EditableSensorPlotOption } from './SensorsEditableTable';

export const getSensorPlotOptions = (
  plots: WebPlot[],
): EditableSensorPlotOption[] => {
  return plots.map((plot) => ({
    label: plot.name,
    value: plot,
  }));
};
