import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import format from 'date-fns/format';
import { Paper } from '@mui/material';
import {
  Column,
  PrimeReactDataTable,
} from 'components/shared/DataTable/DataTable';
import {
  DEFAULT_DATE_FORMAT,
  HIGHLIGHTED_ROW_CLASS_NAME,
  SCROLL_HEIGHT_FULL_SCREEN,
} from 'core/constants';
import { User } from 'models/user';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import useInactiveRowClassName from 'hooks/datatable/useInactiveRowClassName';
import { useStores } from 'stores/hooks/hooks';
import Grid from 'components/UI/Grid';
import { getDataTableCheckboxFilterTemplate } from 'components/shared/DataTable/DataTableColumns';
import MultiselectDataTable, {
  MultiselectDataTableChangeEvent,
} from 'components/shared/MultiselectDataTable';
import { ContextMenu } from 'primereact/contextmenu';
import { FilterMatchMode } from 'primereact/api';
import MenuBar from './MenuBar';
import { GoogleAnalyticsBody, loginTimeSortFunction } from './Components';

const UsersTableContainer = styled(Grid)`
  height: 100%;
`;

const DataTableWrapper = styled(Paper)`
  margin: 1rem;
  overflow: hidden;
`;

export const UsersTable: FC = observer(() => {
  const { membersStore } = useStores();
  const { t } = useTranslation('users');
  const contextRef = useRef<ContextMenu | null>(null);
  const dataTable = useRef<PrimeReactDataTable | null>(null);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
    gaRelevant: { value: null, matchMode: FilterMatchMode.EQUALS },
    lastLoginTime: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });

  const checkActive = useCallback((user: User) => user.active, []);
  const { getRowClassName } = useInactiveRowClassName({
    checkActive,
    rowClassName: HIGHLIGHTED_ROW_CLASS_NAME,
  });

  const onGlobalFilterChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      filters.global.value = (event.target.value || '').trim();
      setFilters({ ...filters });
    },
    [filters],
  );

  const onContextMenuSelectionChange = useCallback(
    ({ value }: DataTableSelectionChangeParams) => {
      membersStore.setSelectedRows({ selection: [], activeRow: value });
    },
    [membersStore],
  );

  const onContextMenu = useCallback(
    (e: any) => contextRef.current?.show(e.originalEvent),
    [],
  );

  const onMultiselectChange = useCallback(
    ({ activeRow, selection }: MultiselectDataTableChangeEvent<User>) => {
      membersStore.setSelectedRows({
        activeRow,
        selection,
      });
    },
    [membersStore],
  );

  const getLastLoginColumnBody = useCallback(
    ({ lastLoginTime }: User) =>
      lastLoginTime
        ? format(
            new Date(lastLoginTime)?.getTime(),
            DEFAULT_DATE_FORMAT,
          )?.toString()
        : '',
    [],
  );

  return (
    <UsersTableContainer flex={1}>
      <DataTableWrapper elevation={3}>
        <MultiselectDataTable
          showGridlines
          scrollable
          resizableColumns
          stripedRows
          paginator
          id="UsersTable"
          dataKey="id"
          filterDisplay="row"
          ref={dataTable}
          onContextMenuSelectionChange={onContextMenuSelectionChange}
          onContextMenu={onContextMenu}
          rowClassName={getRowClassName}
          onMultiselectChange={onMultiselectChange}
          scrollHeight={SCROLL_HEIGHT_FULL_SCREEN}
          value={membersStore.members}
          selection={membersStore.userTableMultiSelection.selection}
          activeRow={membersStore.userTableMultiSelection.activeRow}
          emptyMessage={t('empty_message')}
          header={<MenuBar onGlobalFilterChange={onGlobalFilterChange} />}
          filters={filters}
          globalFilterFields={['firstName', 'lastName', 'email']}
          rows={15}
        >
          <Column field="firstName" header={t('first_name')} sortable filter />
          <Column field="lastName" header={t('last_name')} sortable filter />
          <Column field="email" header={t('email')} sortable filter />
          <Column field="phone" header={t('phone')} sortable filter />
          <Column
            filter
            sortable
            field="gaRelevant"
            header={t('google_analytics')}
            body={GoogleAnalyticsBody}
            filterElement={getDataTableCheckboxFilterTemplate}
            dataType="boolean"
          />
          <Column
            filter
            sortable
            field="lastLoginTime"
            header={t('last_connected')}
            sortFunction={loginTimeSortFunction(membersStore.members)}
            body={getLastLoginColumnBody}
            showFilterMenu={false}
          />
        </MultiselectDataTable>
      </DataTableWrapper>
    </UsersTableContainer>
  );
});
