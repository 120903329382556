import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'components/UI/PhoneInput';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading/Loading';
import Text from 'components/UI/Text';
import { theme } from 'components/UI';
import { useStores } from 'stores/hooks/hooks';
import { matchIsValidTel } from 'mui-tel-input';
import SmsCodeLogin from 'components/pages/Login/SmsCodeLogin';

const PHONE_DEFAULT_COUNTRY = 'IL';

const SmsInputContainer = styled.div``;

const StyledButton = styled(Button)`
  background-color: ${(p) => p.theme.color.leafGreen};

  &:hover {
    background-color: ${(p) => p.theme.color.leafGreen};
  }
`;

const SmsInput: FC = () => {
  const { t } = useTranslation('auth');
  const { userStore } = useStores();
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [number, setNumber] = useState('');
  const [smsSent, setSmsSent] = useState(false);
  const [requestId, setRequestId] = useState('');

  const phoneOnChange = useCallback((phoneValue: string) => {
    setValue(phoneValue);
  }, []);

  useEffect(() => {
    const ready = matchIsValidTel(value);
    if (ready) {
      setNumber(value);
    } else {
      setNumber('');
    }
  }, [value]);

  const handleClickSubmitPhone = useCallback(async () => {
    setIsLoading(true);
    const phoneSendResult = await userStore.getSmsPhoneCode(number);
    if (phoneSendResult) {
      setRequestId(phoneSendResult);
      setSmsSent(!!phoneSendResult);
    }

    setIsLoading(false);
  }, [number, userStore]);

  if (smsSent) {
    return <SmsCodeLogin phoneNumber={number} smsRequestId={requestId} />;
  }

  return (
    <SmsInputContainer>
      <PhoneInput
        fullWidth
        value={value}
        label={t('phone')}
        defaultCountry={PHONE_DEFAULT_COUNTRY}
        helperText={t('enter_your_phone')}
        onChange={(phoneValue) => {
          phoneOnChange(phoneValue);
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <StyledButton
          type="submit"
          onClick={handleClickSubmitPhone}
          fullWidth
          disabled={!number}
        >
          <Text textcolor={theme.color.white} size="sm" weight="bold">
            {t('send_code')}
          </Text>
        </StyledButton>
      )}
    </SmsInputContainer>
  );
};

export default SmsInput;
