import { List } from '@mui/material';
import { BrowserRoutes } from 'core/enums';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { drawerRoutes } from './DrawerRoutes';
import ExpandableRoute from './ExpandableRoute';
import { StyledListItem } from './Styles';

const DrawerList = ({ isMinimized }: { isMinimized: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pathName, setPathName] = useState(
    location.pathname.replace(/\/+$/g, ''),
  );

  const handleNavigationTabClick = useCallback(
    (route: BrowserRoutes) => {
      return () => navigate(route);
    },
    [navigate],
  );

  useEffect(() => {
    setPathName(location.pathname.replace(/\/+$/g, ''));
  }, [location, setPathName]);

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      <StyledListItem divider />
      {drawerRoutes.map((route) => (
        <ExpandableRoute
          key={route.title}
          title={route.title}
          icon={route.icon}
          childrenRoutes={route.childrenRoutes}
          isMinimized={isMinimized}
          pathName={pathName}
          onRouteClick={handleNavigationTabClick}
        />
      ))}
    </List>
  );
};

export default memo(DrawerList);
