import React, { useCallback } from 'react';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from 'components/UI/Grid';
import styled from 'styled-components';
import { Divider } from '@mui/material';
import Text, { TextWeight } from '../Text';

export interface DialogTitleProps {
  // eslint-disable-next-line react/no-unused-prop-types
  id: string;
  dialogTitle: string | React.ReactNode;
  onClose: () => void;
  showClose: boolean;
  titleWeight?: TextWeight;
}

const StyledDialogTitle = styled(Grid)`
  padding: 0.5rem 1rem;
`;

const DialogTitle: React.FC<DialogTitleProps> = (props: DialogTitleProps) => {
  const { dialogTitle, onClose, showClose, titleWeight } = props;

  return (
    <>
      <StyledDialogTitle direction="row">
        <Grid direction="row" flex={1}>
          {dialogTitle && (
            <Text size="lg" weight={titleWeight}>
              {dialogTitle}
            </Text>
          )}
        </Grid>
        {showClose && onClose ? (
          <IconButton aria-label="close" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </StyledDialogTitle>
      <Divider />
    </>
  );
};

interface IDialog extends DialogProps {
  open: boolean;
  showClose?: boolean;
  dialogTitle?: string;
  titleWeight?: TextWeight;
}

const Dialog: React.FC<IDialog> = (props) => {
  const {
    onClose,
    open,
    children,
    dialogTitle,
    showClose = true,
    titleWeight,
    ...rest
  } = props;

  const closeDialog = useCallback(() => {
    if (!onClose) {
      return;
    }

    onClose({}, 'escapeKeyDown');
  }, [onClose]);

  return (
    <MuiDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      {...rest}
    >
      {(dialogTitle || showClose) && (
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeDialog}
          dialogTitle={dialogTitle}
          showClose={showClose}
          titleWeight={titleWeight}
        />
      )}
      {children}
    </MuiDialog>
  );
};

export default Dialog;
