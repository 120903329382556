import { TableMultiSelection } from 'stores/types/types';

export const getDataTableMultiSelection = <T>({
  selection,
  activeRow,
}: TableMultiSelection<T>): T[] => {
  if (selection?.length) {
    return selection;
  }

  if (activeRow) {
    return [activeRow];
  }

  return [];
};
