import { Menubar as PrimeReactMenuBar } from 'primereact/menubar';
import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { GrowerType } from 'models/grower';

const MenuBar: FC = () => {
  const { t } = useTranslation('liveMonitoring');
  const { resellersStore } = useStores();

  const runAnomalyDetection = useCallback(() => {
    if (resellersStore.selectedGrowerType !== GrowerType.Grower) {
      return;
    }

    resellersStore.runAnomalyDetection(resellersStore.selectedGrowerId);
  }, [resellersStore]);

  const items = useMemo(
    () => [
      {
        label: t('run_anomaly_detection'),
        icon: 'pi pi-fw pi-file',
        disabled: false,
        command: runAnomalyDetection,
      },
    ],
    [t, runAnomalyDetection],
  );

  return (
    <div>
      <PrimeReactMenuBar model={items} />
    </div>
  );
};

export default observer(MenuBar);
