import Grid from 'components/UI/Grid';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import React, { FC, useEffect, useMemo } from 'react';
import { useStores } from 'stores/hooks/hooks';
import {
  HALF_SCREEN_TABLE_BOTTOM_HEIGHT,
  HALF_SCREEN_TABLE_TOP_HEIGHT,
} from 'core/constants';
import { Paper } from '@mui/material';
import { Column } from 'components/shared/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import Text from 'components/UI/Text';
import { FeatureCreateFormStepProps } from '../FeatureCreateFormProperties';

const DataTableContainer = styled(Paper)`
  margin: 1rem;
  overflow: hidden;
  max-height: ${HALF_SCREEN_TABLE_TOP_HEIGHT};
`;

const FeaturesSummaryCreateStep: FC<FeatureCreateFormStepProps> = observer(
  () => {
    const { t } = useTranslation('feature');
    const { featuresStore, resellersStore } = useStores();
    const { createFeatureForm } = featuresStore;
    const plotName = useMemo(() => {
      const plot = resellersStore.resellerPlots.find(
        (item) => item.id === createFeatureForm.plotId,
      );

      return plot?.name;
    }, [createFeatureForm.plotId, resellersStore.resellerPlots]);

    const tableFilters: DataTableFilterMeta = {
      'sensorType.name': {
        value: '',
        matchMode: FilterMatchMode.CONTAINS,
      },
      displayName: { value: '', matchMode: FilterMatchMode.CONTAINS },
      latitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
      longitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
      'sensorType.group': { value: '', matchMode: FilterMatchMode.CONTAINS },
      type: { value: '', matchMode: FilterMatchMode.CONTAINS },
    };

    useEffect(() => {
      featuresStore.getSensors();
    }, [featuresStore]);

    const tableRows = useMemo(
      () => featuresStore.selectedSensors,
      [featuresStore.selectedSensors],
    );

    return (
      <Grid flex={1}>
        <Grid direction="row" align="start" alignSelf="start" justify="between">
          <Grid direction="column" align="start" gap="0.5rem">
            <Grid direction="row" gap="0.2rem">
              <Text>{t('type')}:</Text>
              <Text>{createFeatureForm.mlModelId?.name}</Text>
            </Grid>
            <Grid direction="row" gap="0.2rem">
              <Text>{t('name')}:</Text>
              <Text>
                {createFeatureForm.name} {createFeatureForm.displayName}
              </Text>
            </Grid>
            <Grid direction="row" gap="0.2rem">
              <Text>{t('position')}:</Text>
              <Text>
                {createFeatureForm.latitude}, {createFeatureForm.longitude}
              </Text>
            </Grid>
            <Grid direction="row" gap="0.2rem">
              <Text>{t('plot')}:</Text>
              <Text>{plotName}</Text>
            </Grid>
          </Grid>
        </Grid>
        <DataTableContainer elevation={3}>
          <DataTable
            id="FeatureSensorsTableSummary"
            showGridlines
            resizableColumns
            value={tableRows}
            scrollable
            scrollHeight={HALF_SCREEN_TABLE_BOTTOM_HEIGHT}
            dataKey="id"
            emptyMessage={t('empty_message')}
            stripedRows
            filters={tableFilters}
            filterDisplay="row"
          >
            <Column
              field="sensorType.name"
              header={t('sensor')}
              sortable
              filter
            />
            <Column field="displayName" header={t('name')} sortable filter />
            <Column field="latitude" header={t('latitude')} sortable filter />
            <Column field="longitude" header={t('longitude')} sortable filter />
            <Column
              field="sensorType.group"
              header={t('group')}
              sortable
              filter
            />
            <Column field="type" header={t('type')} sortable filter />
          </DataTable>
        </DataTableContainer>
      </Grid>
    );
  },
);

export default FeaturesSummaryCreateStep;
