import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Split as ReactSplitter, SplitProps } from '@geoffcox/react-splitter';
import { theme } from 'components/UI';

const Circle = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${theme.color.defaultText};
  border-radius: 50%;
`;

const SplitterContainer = styled.div<{ horizontal: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.horizontal ? 'row' : 'column')};
  align-items: center;
  gap: 3px;
  height: 100%;

  &:active {
    div {
      background: ${(p) => p.theme.palette.primary.main};
    }
  }
`;

const Line = styled.div<{ horizontal: boolean }>`
  ${(p) => (p.horizontal ? `height: 2px` : `width: 2px`)};
  background-color: ${theme.color.defaultText};
  flex: 1;
`;

const CustomSplitter: FC<SplitProps> = (props) => {
  const { horizontal = true } = props;
  return (
    <SplitterContainer horizontal={horizontal}>
      <Line horizontal={horizontal} />
      <Circle />
      <Circle />
      <Circle />
      <Line horizontal={horizontal} />
    </SplitterContainer>
  );
};

export const Split: FC<PropsWithChildren<SplitProps>> = ({
  horizontal = true,
  minPrimarySize = '25%',
  minSecondarySize = '20%',
  splitterSize = '0.75rem',
  renderSplitter = CustomSplitter,
  ...props
}) => {
  return (
    <ReactSplitter
      horizontal={horizontal}
      minPrimarySize={minPrimarySize}
      minSecondarySize={minSecondarySize}
      renderSplitter={renderSplitter}
      splitterSize={splitterSize}
      {...props}
    >
      {props.children}
    </ReactSplitter>
  );
};
