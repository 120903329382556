import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { LatLngExpression } from 'leaflet';
import Map from 'components/shared/Map/Map';
import { defaultMapOptions } from 'core/configs/mapConfig';
import { useStores } from 'stores/hooks/hooks';
import Loading from 'components/UI/Loading/Loading';
import { GET_PLOTS_TECHNICAL_ALERTS } from 'gql/queries/plots';
import { GqlPlotAlerts } from 'gql/types/plots';
import { AlertState } from 'models/alert';
import { DEFAULT_ALERT_TYPE_LIST } from 'core/constants';
import useTechnicalAlertsMapEntities from 'hooks/map/useTechnicalAlertsMapEntities';
import useMapMoveToPlotLocation from 'hooks/map/useMapMoveToPlotLocation';
import { MapRef } from 'components/shared/Map/Map.types';
import useMapInvalidateSize from 'hooks/map/useMapInvalidateSize';

const MapContainer = styled.div`
  position: relative;
  height: 86vh;
  width: 100%;
  overflow: auto;
`;

const ResellersMap = observer(() => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);
  const { resellersStore } = useStores();
  const growerID = resellersStore.selectedGrowerId;

  const { data: plotsData, loading: isPlotsDataLoading } =
    useQuery<GqlPlotAlerts>(GET_PLOTS_TECHNICAL_ALERTS, {
      skip: !growerID,
      variables: {
        growerIds: [growerID],
        alertState: [AlertState.Active],
        alertTypeIds: DEFAULT_ALERT_TYPE_LIST,
      },
      fetchPolicy: 'no-cache',
    });

  const { mapEntities, mapDetails } = useTechnicalAlertsMapEntities({
    alertsData: plotsData as GqlPlotAlerts,
  });

  useMapInvalidateSize({ mapContainerRef, mapRef });
  useMapMoveToPlotLocation({
    mapRef,
    location: mapDetails.plotsLocation as LatLngExpression,
  });

  return (
    <MapContainer ref={mapContainerRef}>
      {isPlotsDataLoading ? (
        <Loading />
      ) : (
        <Map ref={mapRef} mapEntities={mapEntities} {...defaultMapOptions} />
      )}
    </MapContainer>
  );
});

export default ResellersMap;
