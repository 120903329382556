import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStores } from 'stores/hooks/hooks';
import {
  DataTableRowClickEventParams,
  DataTable as PrimeReactDataTable,
} from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Paper } from '@mui/material';
import DataTable from 'components/shared/DataTable';
import { Column } from 'components/shared/DataTable/DataTable';
import { getDataTableCheckboxFilterTemplate } from 'components/shared/DataTable/DataTableColumns';
import { FilterObject } from 'models/shared';
import MapDialog from './MapDialog';
import MenuBar from './MenuBar';
import CropSelectFilter from './CropOptionFilter';
import CropWinterBody from './CropWinterBody';
import CropVarietyBody from './CropVarietyBody';
import CropRegionBody from './CropRegionBody';
import CropCategoryBody from './CropCategoryBody';

const CropModelTableContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`;

const TableContainer = styled(Paper)`
  margin: 1rem;
  overflow: hidden;
  background-color: ${(p) => p.theme.color.defaultText};
`;

const dataKey = 'protocolHeader.protocolId'; // key used to index
const rowExpansionTemplate = () => null;

const CropModelTable: FC = observer(() => {
  const { cropModelStore } = useStores();
  const [mapDialogOpen, setMapDialogOpen] = useState<{
    isOpen: boolean;
    coordinates: { lat: number; lng: number }[];
    title: string;
  }>({ isOpen: false, coordinates: [], title: '' });

  const { t } = useTranslation('crop_models');
  const dataTable = useRef<PrimeReactDataTable | null>(null);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    'classification.cropType.name': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    'protocolHeader.growerName': {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    'protocolHeader.description': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    'classification.cropCategory': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    'classification.cropVariety': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    'classification.region': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    'protocolHeader.version': {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    midWinter: { value: null, matchMode: FilterMatchMode.EQUALS },
    'protocolHeader.protocolId': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });

  const onGlobalFilterChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      filters.global.value = (event.target.value || '').trim();
      setFilters({ ...filters });
    },
    [filters],
  );

  const onSelectionChange = useCallback(
    ({ data }: DataTableRowClickEventParams) => {
      const isSameCrop = cropModelStore.selectedRows[0] === data;
      cropModelStore.setSelectedRows(isSameCrop ? [] : [data]);
    },
    [cropModelStore],
  );

  const cropVarietyOptions = useMemo(
    () =>
      cropModelStore.cropVarieties.map((el) => ({
        label: el,
        value: el,
      })) as FilterObject[],
    [cropModelStore.cropVarieties],
  );

  const cropNameOptions = useMemo(
    () =>
      cropModelStore.cropNames.sort().map((el) => ({
        label: el,
        value: el,
      })) as FilterObject[],
    [cropModelStore.cropNames],
  );

  const growerOptions = useMemo(
    () =>
      cropModelStore.growerNames.sort().map((el) => ({
        label: el,
        value: el,
      })) as FilterObject[],
    [cropModelStore.growerNames],
  );

  const cropCategoryOptions = useMemo(
    () =>
      cropModelStore.cropCategories.map((el) => ({
        label: el,
        value: el === t('not_available') ? '' : el,
      })) as FilterObject[],
    [cropModelStore.cropCategories, t],
  );

  const cropVersionOptions = useMemo(
    () =>
      cropModelStore.protocolVersions.filter(Boolean).map((el) => ({
        label: Number(el).toString(),
        value: Number(el).toString(),
      })) as FilterObject[],
    [cropModelStore.protocolVersions],
  );

  const handleGoogleMapHide = useCallback(
    () => setMapDialogOpen({ isOpen: false, coordinates: [], title: '' }),
    [setMapDialogOpen],
  );

  const SelectFilterElement = useCallback(
    (options: any, data: FilterObject[], placeholder: string) => (
      <CropSelectFilter
        options={data}
        onOptionFilterChange={(e) => options.filterApplyCallback(e.value)}
        selectedOptions={options.value}
        placeholder={placeholder}
      />
    ),
    [],
  );

  return (
    <CropModelTableContainer>
      <TableContainer elevation={3} square>
        <MapDialog
          onHide={handleGoogleMapHide}
          visible={mapDialogOpen.isOpen}
          coordinates={mapDialogOpen.coordinates}
          dialogTitle={mapDialogOpen.title}
        />
        <DataTable
          ref={dataTable}
          selection={cropModelStore.selectedRows}
          selectionMode="single"
          dataKey={dataKey}
          value={cropModelStore.protocols}
          header={<MenuBar onGlobalFilterChange={onGlobalFilterChange} />}
          resizableColumns
          showGridlines
          emptyMessage={t('empty_message')}
          rowExpansionTemplate={rowExpansionTemplate}
          onRowClick={onSelectionChange}
          filters={filters}
          filterDisplay="row"
          globalFilterFields={[
            'classification.cropType.name',
            'protocolHeader.growerName',
            'protocolHeader.description',
            'classification.cropCategory',
            'classification.cropVariety',
          ]}
        >
          <Column
            field="classification.cropType.name"
            header={t('crop')}
            filter
            filterElement={(option) =>
              SelectFilterElement(option, cropNameOptions, t('filter_by_crop'))
            }
            showFilterMenu={false}
            sortable
          />
          <Column
            field="protocolHeader.growerName"
            header={t('grower')}
            filter
            filterElement={(option) =>
              SelectFilterElement(option, growerOptions, t('filter_by_grower'))
            }
            showFilterMenu={false}
            sortable
          />
          <Column
            field="protocolHeader.description"
            header={t('name')}
            filter
            filterPlaceholder={t('filter_by_name')}
            showFilterMenu={false}
            sortable
          />
          <Column
            field="classification.cropCategory"
            header={t('category')}
            body={CropCategoryBody}
            filter
            filterElement={(option) =>
              SelectFilterElement(
                option,
                cropCategoryOptions,
                t('filter_by_category'),
              )
            }
            showFilterMenu={false}
            sortable
          />
          <Column
            field="classification.cropVariety"
            header={t('variety')}
            body={CropVarietyBody}
            filter
            filterElement={(option) =>
              SelectFilterElement(
                option,
                cropVarietyOptions,
                t('filter_by_variety'),
              )
            }
            showFilterMenu={false}
            sortable
          />
          <Column
            field="classification.region"
            header={t('region')}
            body={(data) => (
              <CropRegionBody
                data={data}
                t={t}
                setMapDialogOpen={setMapDialogOpen}
              />
            )}
            filter
            filterPlaceholder={t('filter_by_region')}
            showFilterMenu={false}
            sortable
          />
          <Column
            field="protocolHeader.version"
            header={t('version')}
            filter
            filterElement={(option) =>
              SelectFilterElement(
                option,
                cropVersionOptions,
                t('filter_by_version'),
              )
            }
            showFilterMenu={false}
            sortable
          />
          <Column
            field="midWinter"
            header={t('mid_winter')}
            body={CropWinterBody}
            filter
            filterElement={getDataTableCheckboxFilterTemplate}
            dataType="boolean"
          />
          <Column
            field="protocolHeader.protocolId"
            header={t('id')}
            filter
            filterPlaceholder={t('filter_by_id')}
            sortable
          />
        </DataTable>
      </TableContainer>
    </CropModelTableContainer>
  );
});

export default CropModelTable;
