import React, { FC, useCallback, useMemo, useState } from 'react';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import Dialog from 'components/UI/Dialog';
import Select from 'components/UI/Select';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DropdownChangeParams } from 'primereact/dropdown';
import { useStores } from 'stores/hooks/hooks';
import { DialogCloseAction } from 'models/shared';
import { UserGrowerRole } from 'models/user';
import { USER_ROLES } from 'core/constants';

interface RoleDialogProps {
  onClose: (
    res: DialogCloseAction,
    selectedRole: UserGrowerRole | null,
  ) => void;
  definedRole: string;
  isOpen: boolean;
  title: string;
  message: string;
  buttonText: string;
  backText: string;
  confirmColor?:
    | 'error'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
  declineColor?:
    | 'error'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
}

const RoleDialog: FC<RoleDialogProps> = ({
  isOpen,
  onClose,
  title,
  buttonText,
  backText,
  message,
  declineColor = 'warning',
  confirmColor = 'primary',
  definedRole,
}) => {
  const { t } = useTranslation('users');
  const { membersStore } = useStores();
  const [selectedRole, setSelectedRole] = useState<UserGrowerRole>(
    {} as UserGrowerRole,
  );

  const rolesNames = useMemo(
    () =>
      membersStore.growersRoles.map((role: UserGrowerRole) => {
        const userRole = USER_ROLES.find(({ value }) => value === role.name);
        return {
          value: role.name,
          label: userRole?.label ?? role.name,
        };
      }),
    [membersStore.growersRoles],
  );

  const onDecline = useCallback(() => {
    onClose(DialogCloseAction.Declined, null);
  }, [onClose]);

  const onConfirm = useCallback(() => {
    onClose(DialogCloseAction.Confirmed, selectedRole);
  }, [onClose, selectedRole]);

  const handleChange = useCallback(
    (e: DropdownChangeParams) => {
      const roleId = membersStore.growersRoles.filter(
        (role: UserGrowerRole) => role.name === e.value,
      );

      setSelectedRole(roleId[0]);
    },
    [membersStore.growersRoles],
  );

  return (
    <Dialog
      onClose={onDecline}
      open={isOpen}
      dialogTitle={title}
      titleWeight="bold"
    >
      <DialogContent>
        <Grid
          display="flex"
          justify="center"
          direction="row"
          align="center"
          gap="1rem"
        >
          <Text>{message}</Text>
          <Select
            onChange={handleChange}
            options={rolesNames}
            value={selectedRole.name || definedRole}
            placeholder={t('select_role')}
          />
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color={declineColor} onClick={onDecline}>
          {backText}
        </Button>
        <Button color={confirmColor} onClick={onConfirm}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleDialog;
