import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { DialogActions, Divider } from '@mui/material';
import styled from 'styled-components';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { ResellerTreeMode } from 'models/grower';
import ResellersTree from '../../ResellersTree/ResellersTree';

const StyledDialog = styled(Dialog)`
  .Grid-fPHmoc {
    padding: 0 1rem;
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  padding-left: 35px;
`;

interface MoveDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
}

const MoveDialog: FC<MoveDialogProps> = observer(
  ({ visible = false, onHide, title }) => {
    const { t } = useTranslation('grower');
    const { resellersStore } = useStores();

    const moveToAnotherReseller = useCallback(() => {
      resellersStore.moveGrowerToReseller();
      onHide();
    }, [onHide, resellersStore]);

    return (
      <StyledDialog
        open={visible}
        onClose={onHide}
        fullWidth
        maxWidth="lg"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <Title>{t('choose_destination_distributor')}</Title>
        <ResellersTree mode={ResellerTreeMode.Reseller} />
        <Divider />
        <DialogActions>
          <Button
            onClick={moveToAnotherReseller}
            disabled={resellersStore.selectedTreeNode === null}
          >
            <Text textcolor={theme.color.white} size="sm">
              {t('ok')}
            </Text>
          </Button>
          <Button onClick={onHide}>
            <Text textcolor={theme.color.white} size="sm">
              {t('cancel')}
            </Text>
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  },
);

export default MoveDialog;
