import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import PrimeReactSelect from 'components/UI/Select';
import Text from 'components/UI/Text';
import {
  SystemDefaultSchema,
  SystemParamPropertyType,
  SystemPropertyType,
  SystemSchemaProperty,
} from 'models/systems';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import styled from 'styled-components';
import { SystemSchemaEditedValues } from 'stores/systemsStore';
import { SystemCreateValidationSchema } from '../../validationSchemas/systemCreateValidationSchema';
import {
  DynamicFieldsFieldError,
  DynamicFieldsProps,
  DynamicFieldsValue,
} from './useDynamicFields.types';

const StyledPrimeReactSelect = styled(PrimeReactSelect)`
  padding: 8px 0;
`;

const getDefinitionOptions = (
  schema: SystemDefaultSchema,
  path: string,
): string[] => {
  const pathKeys = path.replace('#', '').split('/').filter(Boolean);
  if (!pathKeys.length) return [];

  let cursor = schema as unknown as Record<string, unknown>;
  pathKeys.forEach((key) => {
    cursor = cursor[key] as Record<string, unknown>;
  });

  return (cursor?.enum ?? []) as string[];
};

const validateField = (
  values: SystemSchemaEditedValues['values'],
  field: string,
): boolean => {
  return (
    typeof values[field] === 'number' ||
    typeof values[field] === 'boolean' ||
    !!values[field]
  );
};

const schemaAccessTokenKeys = ['accessTokenKey', 'accessToken'];
const validateAccessTokenFields = (
  values: SystemSchemaEditedValues['values'],
): boolean => {
  return schemaAccessTokenKeys.some((key) => !!values[key]);
};

const useDynamicFields = ({
  schema,
  defaultValues,
  showHidden,
  disabled,
}: DynamicFieldsProps<SystemDefaultSchema>): DynamicFieldsValue => {
  const { t } = useTranslation('validation');
  const [values, setValues] =
    useState<SystemSchemaEditedValues['values']>(defaultValues);

  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>(
    {},
  );

  const onFieldChange = useCallback(
    (field: string, value: SystemParamPropertyType) => {
      setValues((prev) => ({ ...prev, [field]: value }));
    },
    [],
  );

  const setTouchedKey = useCallback((key: string, value: boolean) => {
    setTouchedFields((prev) => ({ ...prev, [key]: value }));
  }, []);

  const enabledProperties: Record<string, SystemSchemaProperty> = useMemo(
    () =>
      Object.entries(schema?.properties ?? {}).reduce((acc, [key, data]) => {
        const isHidden = !showHidden && schema?.new_hidden?.includes(key);
        const isSchema = data.type === SystemPropertyType.Object;
        return isHidden || isSchema ? acc : { ...acc, [key]: data };
      }, {}),
    [schema, showHidden],
  );

  const errors: Record<string, DynamicFieldsFieldError> = useMemo(() => {
    if (!schema) return {};

    return Object.keys(enabledProperties).reduce((acc, field) => {
      const { pattern } = enabledProperties[field];
      const value = values[field];
      const isTouched = touchedFields[field];
      const nextAcc: Record<string, DynamicFieldsFieldError> = {
        ...acc,
        [field]: { text: null, isTouched },
      };

      if (pattern && isTouched && !new RegExp(pattern).test(value as string)) {
        nextAcc[field].text = t('invalid_field_default_error');
      }

      return nextAcc;
    }, {});
  }, [values, touchedFields, schema, enabledProperties, t]);

  const getSelectField = useCallback(
    (
      data: SystemSchemaProperty,
      formKey: keyof SystemCreateValidationSchema,
    ) => {
      const options = getDefinitionOptions(
        schema as SystemDefaultSchema,
        data.$ref,
      ).map((option) => ({
        label: option,
        value: option,
      }));

      return (
        <StyledPrimeReactSelect
          key={formKey}
          value={values[formKey] || null}
          label={data.title}
          options={options}
          disabled={disabled}
          onChange={(event) => {
            setTouchedKey(formKey, true);
            onFieldChange(formKey, event.target.value);
          }}
        />
      );
    },
    [schema, values, disabled, onFieldChange, setTouchedKey],
  );

  const fields: ReactElement[] = useMemo(
    () =>
      Object.entries(enabledProperties).map(([key, data]) => {
        const formKey = key as keyof SystemCreateValidationSchema;

        if (data.$ref) {
          return getSelectField(data, formKey);
        }

        if (data.type === SystemPropertyType.Number) {
          return (
            <TextField
              type={data.type}
              key={formKey}
              value={values[formKey]}
              label={data.title}
              disabled={disabled}
              InputProps={{
                inputProps: { min: data.minimum, max: data.maximum },
              }}
              onChange={({ target: { value } }) => {
                setTouchedKey(formKey, true);
                onFieldChange(formKey, value ? Number(value) : '');
              }}
              error={!!errors[formKey]?.text}
              helperText={errors[formKey]?.text}
              fullWidth
            />
          );
        }

        if (data.type === SystemPropertyType.Boolean) {
          return (
            <FormGroup>
              <FormControlLabel
                label={<Text>{data.title}</Text>}
                control={
                  <Checkbox
                    color="primary"
                    checked={!!values[formKey]}
                    disabled={disabled}
                    onChange={(_event, value) => {
                      setTouchedKey(formKey, true);
                      onFieldChange(formKey, value);
                    }}
                  />
                }
              />
            </FormGroup>
          );
        }

        return (
          <TextField
            key={formKey}
            value={values[formKey]}
            label={data.title}
            disabled={disabled}
            onChange={(event) => {
              setTouchedKey(formKey, true);
              onFieldChange(formKey, event.target.value);
            }}
            error={!!errors[formKey].text}
            helperText={errors[formKey].text}
            fullWidth
          />
        );
      }),
    [
      enabledProperties,
      values,
      errors,
      disabled,
      onFieldChange,
      getSelectField,
      setTouchedKey,
    ],
  );

  const isValid = useMemo(() => {
    const requiredFields: string[] = schema?.required ?? [];
    return requiredFields.every((field) => {
      if (schemaAccessTokenKeys.includes(field)) {
        return validateAccessTokenFields(values);
      }

      const isFieldError = errors[field]?.text;
      const isFieldValid = validateField(values, field);
      return isFieldValid && !isFieldError;
    });
  }, [errors, values, schema]);

  const isDirty = useMemo(
    () =>
      Object.entries(values ?? {}).some(
        ([key, value]) =>
          (value || defaultValues[key]) && value !== defaultValues[key],
      ),
    [defaultValues, values],
  );

  return {
    values,
    errors,
    isValid,
    isDirty,
    fields: {
      baseFields: fields,
    },
  };
};

export default useDynamicFields;
