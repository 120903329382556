import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styled from 'styled-components';
import { Menubar as PrimeReactMenuBar } from 'primereact/menubar';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import Text from 'components/UI/Text';
import { useStores } from 'stores/hooks/hooks';
import Input from 'components/UI/Input';
import Grid from 'components/UI/Grid/Grid';
import { MenuItem } from 'primereact/menuitem';
import { AREA_UNITS } from 'core/constants';
import { ActionMode } from 'models/shared';
import CouponManagementDialog from './CouponManagementDialog/CouponManagementDialog';

const StyledInput = styled(Input)`
  background-color: ${(p) => p.theme.palette.background.default};
`;

const StyledPrimeReactMenuBar = styled(PrimeReactMenuBar)`
  .p-submenu-list {
    width: auto;
  }

  .p-menuitem-text {
    text-wrap: nowrap;
  }
`;

const SelectedNodeTitle = styled(Text)`
  color: ${({ theme }) => theme.color.text};
  font-weight: 600;
`;

const defaultUnitValue = AREA_UNITS[0].id;

interface MenuBarEndComponentsProps {
  handleSearchChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
}

const MenuBarEndComponents: FC<MenuBarEndComponentsProps> = ({
  handleSearchChange,
}) => {
  const { t } = useTranslation('users');

  return (
    <Grid gap="1rem" direction="row">
      {/* <StartSelect /> */}
      <StyledInput
        size="small"
        placeholder={t('search')}
        type="text"
        onChange={handleSearchChange}
      />
    </Grid>
  );
};

const Menubar: FC<{ selectedTab: number }> = ({ selectedTab }) => {
  const { t } = useTranslation('coupons');
  const { couponsStore } = useStores();

  const [menuModel, setMenuModel] = useState<MenuItem[]>([]);
  const [couponActionMode, setCouponActionMode] = useState<ActionMode>(
    ActionMode.CREATE,
  );

  const [couponManagementDialog, setCouponManagementDialog] = useState(false);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // resellersStore.setGlobalFilter((event.target.value || '').trim());
    },
    [],
  );

  const onCreate = useCallback(() => {
    setCouponActionMode(ActionMode.CREATE);
    setCouponManagementDialog(true);
  }, []);

  const handleCouponManagementDialogClose = useCallback(() => {
    setCouponManagementDialog(false);
  }, []);

  const couponItems: MenuItem[] = useMemo(
    () => [
      {
        label: t('create_coupon'),
        icon: 'pi pi-fw pi-plus',
        command: () => {
          onCreate();
        },
      },
    ],
    [onCreate, t],
  );

  useEffect(() => {
    setMenuModel(couponItems);
  }, [couponItems]);

  return (
    <div>
      <StyledPrimeReactMenuBar
        model={menuModel}
        end={<MenuBarEndComponents handleSearchChange={handleSearchChange} />}
      />
      {couponManagementDialog && (
        <CouponManagementDialog
          onHide={handleCouponManagementDialogClose}
          visible={couponManagementDialog}
          title={t('create_coupon')}
          mode={ActionMode.CREATE}
        />
      )}
    </div>
  );
};

export default observer(Menubar);
