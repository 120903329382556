import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import { DialogOnCloseReason } from 'models/shared';
import { useStores } from 'stores/hooks/hooks';
import SystemsCreateFormProperties from './SystemsCreateFormProperties';
import {
  SystemCreateStep,
  SystemCreateValidationSchema,
  systemCreateValidationSchema,
} from './validationSchemas/systemCreateValidationSchema';
import SystemsCreateDialogFooter from './SystemsCreateDialogFooter';
import { SystemsManagementDialogProps } from '../SystemsManagementDialog';

const StyledDialogContent = styled(DialogContent)`
  padding: 1rem;
  height: 40vh;
  overflow: auto;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 2rem 1rem 0.5rem;
`;

const steps: SystemCreateStep[] = [
  SystemCreateStep.TYPE,
  SystemCreateStep.CONFIGURATION,
  SystemCreateStep.DISCOVERY,
  SystemCreateStep.SUMMARY,
];

const SystemsCreateDialog: FC<SystemsManagementDialogProps> = observer(
  ({ visible = false, onHide, title }) => {
    const [step, setStep] = useState<SystemCreateStep>(SystemCreateStep.TYPE);
    const { t } = useTranslation('system');
    const { systemsStore, resellersStore } = useStores();
    const { createSystemForm } = systemsStore;
    const {
      newSystemData,
      newSystemSensors,
      isSystemActivating,
      isSensorPayloadsValid,
    } = createSystemForm;

    const growerId = resellersStore.selectedGrowerId;
    const isSystemOnline = !newSystemSensors.error;

    const {
      formState: { isValid, errors },
      control,
      register,
      setValue,
      reset,
      getValues,
    } = useForm<SystemCreateValidationSchema>({
      resolver: zodResolver(systemCreateValidationSchema),
      mode: 'onChange',
      defaultValues: { _step: SystemCreateStep.TYPE },
    });

    const handleDialogClose = useCallback(
      (_event: unknown, reason: DialogOnCloseReason) => {
        if (reason !== DialogOnCloseReason.BackDropClick) {
          onHide();
        }
      },
      [onHide],
    );

    const handleBackClick = useCallback(() => {
      const currentStepIndex = steps.findIndex((item) => item === step);
      const nextStep = steps[currentStepIndex - 1];
      const isSystemCreated = !!createSystemForm.newSystemData.data;
      if (nextStep === SystemCreateStep.TYPE && !isSystemCreated) {
        reset();
      }

      setStep(nextStep);
      setValue('_step', Number(nextStep));
    }, [step, setValue, reset, createSystemForm.newSystemData.data]);

    useEffect(() => {
      if (!newSystemData.data?.id) return;

      systemsStore
        .getNewSystemSensors(growerId, newSystemData.data.id)
        .then(() => {
          setStep(SystemCreateStep.DISCOVERY);
        })
        .catch(() => {
          setStep(SystemCreateStep.DISCOVERY);
        });
    }, [newSystemData.data?.id, growerId, systemsStore]);

    const handleDefaultNextClick = useCallback(() => {
      const currentStepIndex = steps.findIndex((item) => item === step);
      const nextStep = steps[currentStepIndex + 1];
      setStep(nextStep);
      setValue('_step', Number(nextStep));
    }, [step, setValue]);

    const handleConfigurationNextClick = useCallback(() => {
      const values = getValues();
      if (createSystemForm.isSystemCreated) {
        handleDefaultNextClick();
      } else {
        systemsStore
          .createNewSystem(growerId, values.displayName, values.serial)
          .then(handleDefaultNextClick)
          .catch(() => null);
      }
    }, [
      growerId,
      systemsStore,
      createSystemForm.isSystemCreated,
      getValues,
      handleDefaultNextClick,
    ]);

    const handleActivateSystem = useCallback(() => {
      systemsStore
        .activateSystem(growerId, getValues())
        .then(onHide)
        .catch(() => null);
    }, [systemsStore, growerId, getValues, onHide]);

    const handleNextClick = useCallback(() => {
      if (step === SystemCreateStep.CONFIGURATION) {
        handleConfigurationNextClick();
      } else if (step === SystemCreateStep.SUMMARY) {
        handleActivateSystem();
      } else {
        handleDefaultNextClick();
      }
    }, [
      step,
      handleDefaultNextClick,
      handleConfigurationNextClick,
      handleActivateSystem,
    ]);

    const dialogSubTitle = useMemo(() => {
      const values = getValues();
      if (step === SystemCreateStep.TYPE) {
        return t('system_type');
      }

      if (step === SystemCreateStep.CONFIGURATION) {
        return t('system_config_for', { type: values.type });
      }

      if (step === SystemCreateStep.DISCOVERY) {
        return t('system_serial_discovery', {
          system: values.displayName,
          serial: values.serial,
        });
      }

      return '';
    }, [t, step, getValues]);

    const isSystemSchemaValid =
      step !== SystemCreateStep.CONFIGURATION ||
      createSystemForm.configurationSchema.isValid;

    return (
      <Dialog
        open={visible}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="lg"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <StyledDialogContent>
          {dialogSubTitle && (
            <Text weight="bold" size="md" marginBottom="1rem">
              {dialogSubTitle}
            </Text>
          )}
          <SystemsCreateFormProperties
            step={step}
            control={control}
            register={register}
            errors={{ ...errors }}
            getValues={getValues}
          />
        </StyledDialogContent>
        <Divider />
        <StyledDialogActions>
          <SystemsCreateDialogFooter
            step={step}
            steps={steps}
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}
            isSubmitDisabled={
              newSystemData.loading ||
              isSystemActivating ||
              !isSensorPayloadsValid ||
              !isValid ||
              (!isSystemOnline && step === SystemCreateStep.DISCOVERY) ||
              !isSystemSchemaValid
            }
            isLoading={newSystemData.loading || isSystemActivating}
          />
        </StyledDialogActions>
      </Dialog>
    );
  },
);

export default SystemsCreateDialog;
