import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Grid from 'components/UI/Grid';
import Loading from 'components/UI/Loading/Loading';
import { Controller } from 'react-hook-form';
import { useStores } from 'stores/hooks/hooks';
import { FeatureCreateFormStepProps } from '../FeatureCreateFormProperties';

const COLUMNS_NUMBER = 4;

interface TypeColumn {
  key: string;
  options: ReactElement[];
}

const sliceIntoColumns = (
  data: ReactElement[],
  columns: number,
): TypeColumn[] => {
  const result: TypeColumn[] = [];
  const chunkSize = Math.ceil(data.length / columns);
  let startCopyIndex = 0;
  for (let index = 0; index < columns; index += 1) {
    const chunk = data.slice(startCopyIndex, startCopyIndex + chunkSize);
    result.push({ key: index.toString(), options: chunk });
    startCopyIndex += chunkSize;
  }

  return result;
};

const ColumnsContainer = styled(Grid)`
  height: 70%;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormGroup-root {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const FeatureTypeCreateStep: FC<FeatureCreateFormStepProps> = observer(
  ({ control }) => {
    const { featuresStore } = useStores();
    const { featuresTypes } = featuresStore;

    useEffect(() => {
      if (!featuresTypes.data.length) {
        featuresStore.getFeaturesSchema();
      }
    }, [featuresStore, featuresTypes.data.length]);

    const typeOptions: TypeColumn[] = useMemo(
      () =>
        sliceIntoColumns(
          featuresTypes.data.map(({ name, id }) => (
            <FormControlLabel
              key={id}
              value={id}
              label={name}
              control={<Radio />}
            />
          )),
          COLUMNS_NUMBER,
        ),
      [featuresTypes.data],
    );

    const handleTypeSelect = useCallback(
      (type: number | string) => {
        const modelType = featuresStore.featuresTypes.data.find(
          (feature) => feature.id === Number(type),
        );

        featuresStore.setFeatureType(modelType);
      },
      [featuresStore],
    );

    if (featuresTypes.loading) {
      return <Loading />;
    }

    return (
      <Controller
        control={control}
        name="type"
        defaultValue={featuresStore.createFeatureForm.mlModelId?.id}
        render={({ field: { onChange, value } }) => (
          <ColumnsContainer direction="row" justify="evenly">
            <FormControl>
              <RadioGroup
                value={value}
                onChange={(event) => {
                  onChange(event);
                  handleTypeSelect(event.target.value);
                }}
              >
                {typeOptions.map(({ options, key }) => (
                  <Grid key={key} align="start">
                    {options}
                  </Grid>
                ))}
              </RadioGroup>
            </FormControl>
          </ColumnsContainer>
        )}
      />
    );
  },
);

export default FeatureTypeCreateStep;
