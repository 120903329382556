import { useCallback } from 'react';
import { DataTableProps } from 'primereact/datatable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Row extends Record<string, any> {
  id: number | null;
}

interface UseInactiveRowClassNameProps {
  rowClassName: string;
  checkActive(data: Row): boolean;
}

interface UseInactiveRowClassNameValue {
  getRowClassName: DataTableProps['rowClassName'];
}

const useInactiveRowClassName = ({
  rowClassName,
  checkActive,
}: UseInactiveRowClassNameProps): UseInactiveRowClassNameValue => {
  const getRowClassName = useCallback(
    (row: Row) => (checkActive(row) ? '' : rowClassName),
    [rowClassName, checkActive],
  );

  return {
    getRowClassName,
  };
};

export default useInactiveRowClassName;
