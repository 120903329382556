import { RefObject, useEffect, useMemo, useState } from 'react';

export const useIsInViewport = (
  elementRef: RefObject<HTMLElement>,
): boolean => {
  const [isInViewport, setIsInViewport] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsInViewport(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [elementRef, observer]);

  return isInViewport;
};
