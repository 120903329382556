import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import Loading from 'components/UI/Loading/Loading';
import Text from 'components/UI/Text';
import Grid from 'components/UI/Grid';
import Button from 'components/UI/Button';
import { useStores } from 'stores/hooks/hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserGrowerRole } from 'models/user';
import { GrowerRole, ResellerUsers } from 'models/grower';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import AssignUsersTable from './AssignUsersTable';

const Container = styled(Grid)`
  height: 70vh;
`;

const ActionsContainer = styled(Grid)`
  position: relative;
`;

const StyledLoading = styled(Loading)`
  position: absolute;
  bottom: 0;
  transform: translateY(140%);
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-weight: bold;
`;

const AssignUsersContent: FC = observer(() => {
  const { t } = useTranslation('users');
  const { membersStore, resellersStore, snackBarStore } = useStores();
  const { showToast } = snackBarStore;
  const { resellerUsers, assignUsersToGrower, unassignUsersFromGrower } =
    resellersStore;

  const { members, growersRoles, initData: getAllUsers } = membersStore;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<ResellerUsers[]>([]);
  const [selectedAssignedUsers, setSelectedAssignedUsers] = useState<
    ResellerUsers[]
  >([]);

  const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
  const growerId = resellersStore.selectedGrowerId;

  const activeResellerUsers: ResellerUsers[] = useMemo(
    () => resellerUsers.filter((user) => user.active),
    [resellerUsers],
  );

  const assignedUsersIds = useMemo(
    () => new Set<number>(activeResellerUsers.map((user) => user.id)),
    [activeResellerUsers],
  );

  const unassignedTableUsers = useMemo(
    () => members.filter((user) => !assignedUsersIds.has(user.id)),
    [members, assignedUsersIds],
  );

  useEffect(() => {
    setIsMembersLoading(true);
    getAllUsers()
      .catch(() => {
        showToast({
          detail: i18next.t('errors:something_went_wrong'),
          summary: 'error',
          severity: 'error',
        });
      })
      .finally(() => setIsMembersLoading(false));
  }, [getAllUsers, showToast]);

  const handleAssignUsers = useCallback(
    async (roleName: GrowerRole) => {
      setIsLoading(true);
      const role = growersRoles.find(
        (growerRole) => growerRole.name === roleName,
      ) as UserGrowerRole;

      await assignUsersToGrower(growerId, role, selectedUsers);
      setSelectedUsers([]);
      setIsLoading(false);
    },
    [growerId, growersRoles, assignUsersToGrower, selectedUsers],
  );

  const handleUnassignUsers = useCallback(async () => {
    setIsLoading(true);
    await unassignUsersFromGrower(growerId, selectedAssignedUsers);

    setSelectedAssignedUsers([]);
    setIsLoading(false);
  }, [growerId, unassignUsersFromGrower, selectedAssignedUsers]);

  const handleUsersSelect = useCallback((data: ResellerUsers[]) => {
    setSelectedAssignedUsers([]);
    setSelectedUsers(data);
  }, []);

  const handleAssignedUsersSelect = useCallback((data: ResellerUsers[]) => {
    setSelectedUsers([]);
    setSelectedAssignedUsers(data);
  }, []);

  const isAssignButtonDisabled = !selectedUsers.length || isLoading;
  const isUnassignButtonDisabled = !selectedAssignedUsers.length || isLoading;

  return (
    <Container direction="row" align="center" gap="1rem">
      <AssignUsersTable
        value={unassignedTableUsers}
        loading={isMembersLoading}
        selection={selectedUsers}
        setSelection={handleUsersSelect}
      />
      <ActionsContainer gap="1rem" direction="column">
        <Text weight="bold">{t('assign_as')}</Text>
        <StyledButton
          disabled={isAssignButtonDisabled}
          color="primary"
          endIcon={<FontAwesomeIcon icon={faLongArrowAltRight} />}
          onClick={() => {
            handleAssignUsers(GrowerRole.User);
          }}
        >
          {t('user')}
        </StyledButton>
        <StyledButton
          disabled={isAssignButtonDisabled}
          color="primary"
          endIcon={<FontAwesomeIcon icon={faLongArrowAltRight} />}
          onClick={() => {
            handleAssignUsers(GrowerRole.Admin);
          }}
        >
          {t('admin')}
        </StyledButton>
        <StyledButton
          disabled={isAssignButtonDisabled}
          color="primary"
          endIcon={<FontAwesomeIcon icon={faLongArrowAltRight} />}
          onClick={() => {
            handleAssignUsers(GrowerRole.GbiAdmin);
          }}
        >
          {t('GBI_admin')}
        </StyledButton>
        <StyledButton
          disabled={isUnassignButtonDisabled}
          color="warning"
          startIcon={<FontAwesomeIcon icon={faLongArrowAltLeft} />}
          onClick={handleUnassignUsers}
        >
          {t('remove')}
        </StyledButton>
        {isLoading && <StyledLoading size="1.5rem" />}
      </ActionsContainer>
      <AssignUsersTable
        value={activeResellerUsers}
        selection={selectedAssignedUsers}
        setSelection={handleAssignedUsersSelect}
      />
    </Container>
  );
});

export default AssignUsersContent;
