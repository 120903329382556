import React, { FC, useCallback, useMemo } from 'react';
import Text from 'components/UI/Text';
import Grid from 'components/UI/Grid';
import Button from 'components/UI/Button';
import { useStores } from 'stores/hooks/hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import UserGrowersTable from './UserGrowersTable';

const Title = styled(Text)`
  font-weight: bold;
`;

const ButtonMaxWidth = styled(Button)`
  width: 100%;
  font-weight: bold;
`;

const Container = styled(Grid)`
  min-height: 60vh;
`;

const AssignUserGrowerContent: FC = observer(() => {
  const { t } = useTranslation('users');
  const { membersStore } = useStores();

  const handleAssignUserGrowers = useCallback(
    (roleName: string) => {
      const roleId = membersStore.growersRoles.find(
        (el) => el.name === roleName,
      );

      if (!roleId) {
        return;
      }

      membersStore.assignUserGrowers(`${roleName}`);
    },
    [membersStore],
  );

  const handleRemoveGrowers = useCallback(() => {
    membersStore.unassignUserGrowers();
  }, [membersStore]);

  const assignedUserGrowersTitle = useMemo(
    () =>
      `${t('grant')} ${membersStore.selectedRows[0].firstName} ${
        membersStore.selectedRows[0].lastName
      } ${t('permission_to')}`,
    [t, membersStore],
  );

  return (
    <Container gap="1rem" direction="row" flex={1}>
      <UserGrowersTable isAssigned={false} title={t('all_growers')} />
      <Grid gap="1rem" direction="column">
        <Title>{t('assign_as')}</Title>
        <ButtonMaxWidth
          disabled={!membersStore.selectedRowsMembersGrowersAvailable.length}
          color="primary"
          endIcon={<FontAwesomeIcon icon={faLongArrowAltRight} />}
          onClick={() => {
            handleAssignUserGrowers('USER');
          }}
        >
          {t('user')}
        </ButtonMaxWidth>
        <ButtonMaxWidth
          disabled={!membersStore.selectedRowsMembersGrowersAvailable.length}
          color="primary"
          endIcon={<FontAwesomeIcon icon={faLongArrowAltRight} />}
          onClick={() => {
            handleAssignUserGrowers('ADMIN');
          }}
        >
          {t('admin')}
        </ButtonMaxWidth>
        <ButtonMaxWidth
          disabled={!membersStore.selectedRowsMembersGrowersAvailable.length}
          color="primary"
          endIcon={<FontAwesomeIcon icon={faLongArrowAltRight} />}
          onClick={() => {
            handleAssignUserGrowers('GBI_ADMIN');
          }}
        >
          {t('GBI_admin')}
        </ButtonMaxWidth>
        <ButtonMaxWidth
          disabled={!membersStore.selectedRowsMemberGrower.length}
          color="warning"
          startIcon={<FontAwesomeIcon icon={faLongArrowAltLeft} />}
          onClick={handleRemoveGrowers}
        >
          {t('remove')}
        </ButtonMaxWidth>
      </Grid>
      <UserGrowersTable isAssigned title={assignedUserGrowersTitle} />
    </Container>
  );
});

export default AssignUserGrowerContent;
