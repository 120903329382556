import React, { FC, useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { ActionMode, DialogOnCloseReason } from 'models/shared';
import styled from 'styled-components';
import { getColumnTitle, MassEditPlotSettings } from './ResellerPlotMassEdit';
import {
  getDssOrSettingsValues,
  SettingType,
  tableKeys,
} from './ResellerMassEdit.utils';
import MassEditInput from './MassEditInput';

const failedColor = '#ff40401F'; // opacity 0.12
const successColor = '#42B77D1F'; // opacity 0.12

const DEFAULT_INPUT: { [key: string]: number | boolean | undefined } = {
  id: undefined,
  name: undefined,
  // DSS Settings
  soilMin: undefined,
  soilMax: undefined,
  irrigationMin: undefined,
  irrigationMax: undefined,
  frequency: undefined,
  disableF: undefined,
  correctionFactor: undefined,
  disableCF: undefined,
  planDuration: undefined,
  // Plot Settings
  DaytimeParMin: undefined,
  ATmax: undefined,
  VPDmax: undefined,
  VPDNIGHTmax: undefined,
  SDIrate: undefined,
  MDSrate: undefined,
  MdsMin: undefined,
  MdsRateChange: undefined,
  MdsMinET: undefined,
  PMBDelta: undefined,
  WaterNominalPressure: undefined,
};

const StyledHeaderText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DialogBody = styled.div`
  display: inline-flex;
  padding: 1rem 1rem;
  flex-direction: column;
`;

const TableFlexRow = styled.div<{ $successColor?: string }>`
  display: flex;
  flex-direction: row;
  background: ${(p) => (p.$successColor ? `${p.$successColor}` : 'white')};
`;

const TableItem = styled.div<{ $isHeader?: boolean }>`
  background: ${(p) => (p.$isHeader ? '#f8f9fa' : 'unset')};
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100px;
  max-width: 100px;
  border: 1px solid #e0e0e0;
  max-height: 50px;
  min-height: 50px;
  padding: 5px 10px;
  align-items: center;
`;

interface PlotSettingsManagementDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const StyledDialogContent = styled(DialogContent)`
  padding: 0 1rem;
`;

type SuccessMap = Map<string | number, { success: boolean; color: string }>;

const ResellerMassEditDialog: FC<PlotSettingsManagementDialogProps> = observer(
  ({ visible = false, onHide, title, mode }) => {
    const { t } = useTranslation('plot');
    const { plotsStore, userStore, resellersStore } = useStores();
    const settingsSchema =
      plotsStore?.plotMassSettings?.massSettings?.[0]?.settings?.plotSettings
        ?.schema;

    const dssSchema = plotsStore?.plotMassSettings?.dssValidation?.schema;

    const [apiSuccessMap, setApiSuccessMap] = useState<SuccessMap | undefined>(
      undefined,
    );

    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

    const hasError = useMemo(
      () => Object.values(errors).some((error) => error),
      [errors],
    );

    const [massValues, setMassValues] = useState(DEFAULT_INPUT);

    const [isSaving, setIsSaving] = useState(false);

    const onChangeInput = useCallback(
      (value: any, error: boolean, key: string) => {
        const keyInErrors = Object.keys(errors).includes(key);
        const shouldSetError =
          (error && !keyInErrors) || (!error && keyInErrors);

        if (shouldSetError) {
          setErrors((prev) => {
            if (keyInErrors && error === false) {
              const newErrors = { ...prev };
              delete newErrors[key];
              return newErrors;
            }

            return {
              ...prev,
              [key]: error,
            };
          });
        }

        setMassValues((prev) => {
          return {
            ...prev,
            [key]: value,
          };
        });
      },
      [errors],
    );

    const tableColumnTitles = useMemo(() => {
      const titlesObject: { [key: string]: string } = {};
      tableKeys.forEach((tableKey) => {
        titlesObject[tableKey.key] = getColumnTitle(
          plotsStore.plotMassSettings.dssValidation?.schema,
          plotsStore.plotMassSettings.massSettings?.[0]?.settings?.plotSettings
            ?.schema,
          tableKey.key,
          tableKey.type,
        );
      });

      return titlesObject;
    }, [
      plotsStore.plotMassSettings.dssValidation,
      plotsStore.plotMassSettings.massSettings,
    ]);

    const closeDialog = useCallback(() => {
      onHide();
    }, [onHide]);

    const handleCloseDialog = useCallback(
      (event: unknown, reason: DialogOnCloseReason) => {
        if (reason && reason === DialogOnCloseReason.BackDropClick) {
          return;
        }

        closeDialog();
      },
      [closeDialog],
    );

    const handleSubmitClick = useCallback(async () => {
      setIsSaving(true);
      const dssData = getDssOrSettingsValues(massValues, SettingType.dss);
      const settingsData = getDssOrSettingsValues(
        massValues,
        SettingType.settings,
      );

      const res = (await plotsStore.postPlotsMassEdit(
        dssData,
        settingsData,
        plotsStore?.selectedMassEditRows,
      )) as { plotId: number; dssSuccess: boolean; settingsSuccess: boolean }[];

      const successMap: SuccessMap = new Map();
      res.forEach((item) => {
        successMap.set(item?.plotId, {
          success: item?.dssSuccess && item?.settingsSuccess,
          color:
            item?.dssSuccess && item?.settingsSuccess
              ? successColor
              : failedColor,
        });
      });

      setApiSuccessMap(successMap);

      setIsSaving(false);
      // closeDialog();
    }, [massValues, plotsStore]);

    return (
      <Dialog
        open={visible}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <StyledDialogContent>
          <DialogBody>
            <TableFlexRow>
              {tableKeys.map((tableKey) => (
                <TableItem $isHeader>
                  <StyledHeaderText
                    key={tableKey.key}
                    title={tableColumnTitles[tableKey.key] || tableKey.key}
                  >
                    {tableColumnTitles[tableKey.key] || tableKey.key}
                  </StyledHeaderText>
                </TableItem>
              ))}
            </TableFlexRow>
            <TableFlexRow>
              {tableKeys.map((tableKey) => {
                if (tableKey.key === 'id' || tableKey.key === 'name') {
                  return <TableItem />;
                }

                return (
                  <TableItem>
                    <MassEditInput
                      formKey={tableKey.key}
                      dssSchema={dssSchema}
                      key={tableKey.key}
                      value={massValues?.[tableKey?.key]}
                      settingsSchema={settingsSchema}
                      handleChangeValue={onChangeInput}
                    />
                  </TableItem>
                );
              })}
            </TableFlexRow>
            {plotsStore?.selectedMassEditRows?.map((row) => {
              return (
                <TableFlexRow $successColor={apiSuccessMap?.get(row.id)?.color}>
                  {tableKeys.map((tableKey) => (
                    <TableItem>
                      <Text key={tableKey.key}>
                        {row?.[tableKey?.key as keyof MassEditPlotSettings]}
                      </Text>
                    </TableItem>
                  ))}
                </TableFlexRow>
              );
            })}
          </DialogBody>
        </StyledDialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleSubmitClick}
            disabled={hasError}
            loading={isSaving}
          >
            <Text textcolor={theme.color.white} size="sm">
              {t('edit')}
            </Text>
          </Button>
          <Button onClick={closeDialog}>
            <Text textcolor={theme.color.white} size="sm">
              {t('cancel')}
            </Text>
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default ResellerMassEditDialog;
