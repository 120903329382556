import React from 'react';
import styled from 'styled-components';
import {
  TabView as PrimeReactTabView,
  TabPanel as PrimeReactTabPanel,
} from 'primereact/tabview';
import { alpha } from '@mui/material/styles';

export const TabView = styled(PrimeReactTabView)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  .p-tabview-panels {
    display: flex;
    overflow: auto;
    flex: 1;
  }

  .p-tabview-panels > div:not(.p-hidden) {
    display: flex;
    overflow: auto;
    flex: 1;
  }

  .p-tabview-nav {
    margin-top: 0.2rem; /* compensate for 0.2rem box shadow
   */
  }

  &.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem ${(p) => alpha(p.theme.palette.primary.main, 0.5)};
  }

  &.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export const TabPanel = PrimeReactTabPanel;
