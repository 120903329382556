import { ActionMode } from 'models/shared';
import { observer } from 'mobx-react';
import { useStores } from 'stores/hooks/hooks';
import React, { FC, useEffect, useState } from 'react';
import SystemsCreateDialog from './SystemsCreateDialog/SystemsCreateDialog';
import SystemsEditDialog from './SystemsEditDialog';

export interface SystemsManagementDialogProps {
  onHide(): void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const SystemsManagementDialog: FC<SystemsManagementDialogProps> = observer(
  (props) => {
    const { visible, mode } = props;
    const { systemsStore } = useStores();
    const [isEditDataLoaded, setEditDataLoaded] = useState<boolean>(false);
    const isEdit = mode === ActionMode.EDIT;

    useEffect(() => {
      if (isEdit) {
        systemsStore.loadSystemForEdit().then(() => {
          setEditDataLoaded(true);
        });
      }
    }, [systemsStore, isEdit]);

    if (visible && !isEdit) {
      return <SystemsCreateDialog {...props} />;
    }

    if (visible && isEdit && isEditDataLoaded) {
      return <SystemsEditDialog {...props} />;
    }

    return null;
  },
);

export default SystemsManagementDialog;
