import React, { FC, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Menubar, MenubarProps } from 'primereact/menubar';
import { FilterMatchMode } from 'primereact/api';
import { DataTableFilterMeta } from 'primereact/datatable';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Paper } from '@mui/material';
import {
  IS_APP_SYSTEMS_DISABLED,
  HALF_SCREEN_TABLE_BOTTOM_HEIGHT,
} from 'core/constants';
import { useStores } from 'stores/hooks/hooks';
import { TableMultiSelection } from 'stores/types/types';
import { Sensor } from 'models/sensor';
import { DialogCloseAction } from 'models/shared';
import { getDataTableMultiSelection } from 'utils/datatableUtils';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import { Column } from 'components/shared/DataTable/DataTable';
import Grid from 'components/UI/Grid';
import MultiselectDataTable, {
  MultiselectDataTableChangeEvent,
} from 'components/shared/MultiselectDataTable';
import { GrowerSystem } from 'models/grower';

const Container = styled(Paper)`
  margin: 1rem;
  margin-top: 0.5rem;
`;

const DataTableContainer = styled(Grid)`
  max-height: ${HALF_SCREEN_TABLE_BOTTOM_HEIGHT};
`;

const tableFilters: DataTableFilterMeta = {
  'sensorType.name': { value: '', matchMode: FilterMatchMode.CONTAINS },
  displayName: { value: '', matchMode: FilterMatchMode.CONTAINS },
  serial: { value: '', matchMode: FilterMatchMode.CONTAINS },
  type: { value: '', matchMode: FilterMatchMode.CONTAINS },
  latitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
  longitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
  systemSerial: { value: '', matchMode: FilterMatchMode.CONTAINS },
  systemDisplayName: { value: '', matchMode: FilterMatchMode.CONTAINS },
};

const getSortedSensorRows = (
  sensors: Sensor[],
  plotSensorsIds: Set<number>,
): Sensor[] => {
  return [...sensors]
    .sort((sensorA, sensorB) => (sensorA.serial < sensorB.serial ? -1 : 1))
    .sort((sensor) => (plotSensorsIds.has(Number(sensor.id)) ? -1 : 1));
};

const addSystemDisplayNameToSensors = (
  sensors: Sensor[],
  plotSystems: GrowerSystem[],
): (Sensor & { systemDisplayName: string | undefined })[] => {
  const uniqueSensors = sensors.filter((sensorItem, index, self) => index === self.findIndex((t) => t.id === sensorItem.id));
  const sensorArrayWithSystemName = uniqueSensors.map((sensor) => {
    const system = plotSystems?.find(
      (plotSystem) => plotSystem?.serial === sensor?.systemSerial,
    );

    return {
      ...sensor,
      systemDisplayName: system?.displayName,
    };
  });

  return sensorArrayWithSystemName;
};

type ActionType = 'Add' | 'Remove';

const PlotsSensorsTable: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { resellersStore, snackBarStore } = useStores();
  const { growerSensors, growerSystems, plotSensors, plotSystems } = resellersStore;
  const [selectedRows, setSelectedRows] = useState<TableMultiSelection<Sensor>>(
    { selection: [], activeRow: null },
  );

  console.log('11', JSON.parse(JSON.stringify(growerSensors)))
  console.log('22', JSON.parse(JSON.stringify(plotSensors)))

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ActionType>();
  const selectedPlotId = resellersStore.selectedRowsPlots[0]?.id;
  const isRemoveActionType = actionType === 'Remove';

  const modifiedPlotSensors = useMemo(() => {
    return addSystemDisplayNameToSensors([...growerSensors.data, ...plotSensors.data], [...growerSystems.data, ...plotSystems.data]);
  }, [growerSensors.data, growerSystems.data, plotSensors.data, plotSystems.data]);

  const plotSensorsIds = useMemo(
    () => new Set(plotSensors.data.map((sensor) => Number(sensor.id))),
    [modifiedPlotSensors],
  );

  const tableSelectedRows: Sensor[] = useMemo(
    () => getDataTableMultiSelection(selectedRows),
    [selectedRows],
  );

  const selectedSensorId = tableSelectedRows[0]?.id;

  const showConfirmDialog = useCallback((action: ActionType) => {
    setActionType(action);
    setConfirmVisible(true);
  }, []);

  const resetTable = useCallback(() => {
    setSelectedRows({ selection: [], activeRow: null });
    setLoading(false);
    setConfirmVisible(false);
  }, []);

  const removeSelectedSensors = useCallback(() => {
    setLoading(true);
    resellersStore
      .detachSensorsFromPlot(selectedPlotId, tableSelectedRows)
      .then(resetTable)
      .catch(() => {
        setLoading(false);
      });
  }, [resellersStore, selectedPlotId, tableSelectedRows, resetTable]);

  const addSelectedSensors = useCallback(() => {
    setLoading(true);
    const addedNewSensors = tableSelectedRows.filter(
      (row) => !plotSensorsIds.has(row.id),
    );

    resellersStore
      .attachSensorsToPlot(selectedPlotId, addedNewSensors)
      .then(resetTable)
      .catch(() => {
        setLoading(false);
      });
  }, [
    resellersStore,
    selectedPlotId,
    tableSelectedRows,
    plotSensorsIds,
    resetTable,
  ]);

  const handleConfirmSubmit = useCallback(
    (action: DialogCloseAction) => {
      if (action !== DialogCloseAction.Confirmed) {
        setConfirmVisible(false);
        return;
      }

      if (isRemoveActionType) {
        removeSelectedSensors();
      } else {
        addSelectedSensors();
      }
    },
    [isRemoveActionType, addSelectedSensors, removeSelectedSensors],
  );

  const getAttachedColumnBody = useCallback(
    (sensor: Sensor) =>
      plotSensorsIds.has(Number(sensor.id)) ? <CheckIcon /> : <CloseIcon />,
    [plotSensorsIds],
  );

  const tableRows = useMemo(
    () => getSortedSensorRows(modifiedPlotSensors, plotSensorsIds),
    [modifiedPlotSensors, plotSensorsIds],
  );

  const onMultiselectChange = useCallback(
    ({ selection, activeRow }: MultiselectDataTableChangeEvent<Sensor>) => {
      const attachmentValues = selection.map((row) =>
        plotSensorsIds.has(row.id),
      );

      const isValidSelection = attachmentValues.every(
        (value) => value === attachmentValues[0],
      );

      if (isValidSelection) {
        setSelectedRows({ activeRow, selection });
      } else {
        snackBarStore.showToast({
          detail: t('invalid_plot_sensor_selection_error'),
        });
      }
    },
    [snackBarStore, plotSensorsIds, t],
  );

  const menuBarActions: MenubarProps['model'] = useMemo(
    () => [
      {
        label: i18next.t('general:add'),
        icon: 'pi pi-fw pi-plus',
        disabled:
          IS_APP_SYSTEMS_DISABLED ||
          !tableSelectedRows.length ||
          plotSensorsIds.has(Number(selectedSensorId)),
        command: () => showConfirmDialog('Add'),
      },
      {
        label: i18next.t('general:remove'),
        icon: 'pi pi-fw pi-trash',
        disabled:
          IS_APP_SYSTEMS_DISABLED ||
          !tableSelectedRows.length ||
          !plotSensorsIds.has(Number(selectedSensorId)),
        command: () => showConfirmDialog('Remove'),
      },
    ],
    [
      showConfirmDialog,
      plotSensorsIds,
      selectedSensorId,
      tableSelectedRows.length,
    ],
  );

  return (
    <Container>
      <Menubar model={menuBarActions} />
      <DataTableContainer>
        <MultiselectDataTable
          id="PlotsSensorsTable"
          variant="strict"
          dataKey="id"
          showGridlines
          resizableColumns
          value={tableRows}
          scrollable
          scrollHeight={HALF_SCREEN_TABLE_BOTTOM_HEIGHT}
          emptyMessage={t('empty_message')}
          stripedRows
          filters={tableFilters}
          filterDisplay="row"
          activeRow={selectedRows.activeRow}
          selection={selectedRows.selection}
          onMultiselectChange={onMultiselectChange}
        >
          <Column header={t('attached')} body={getAttachedColumnBody} />
          <Column
            field="sensorType.name"
            header={t('sensor')}
            sortable
            filter
          />
          <Column field="displayName" header={t('name')} sortable filter />
          <Column field="serial" header={t('sensor_serial')} sortable filter />
          <Column
            field="systemSerial"
            header={t('system_serial')}
            sortable
            filter
          />
          <Column
            field="systemDisplayName"
            header={t('system_name')}
            sortable
            filter
          />
          <Column field="type" header={t('type')} sortable filter />
          <Column field="latitude" header={t('latitude')} sortable filter />
          <Column field="longitude" header={t('longitude')} sortable filter />
        </MultiselectDataTable>
      </DataTableContainer>
      <ConfirmDialog
        backText={i18next.t('general:cancel')}
        buttonText={
          isRemoveActionType
            ? i18next.t('general:remove')
            : i18next.t('general:add')
        }
        isOpen={isConfirmVisible}
        title={i18next.t('dialog:confirm_dialog_title', {
          action: isRemoveActionType ? 'Remove' : 'Add',
        })}
        message={i18next.t('dialog:confirm_dialog_action_message', {
          action: isRemoveActionType ? 'remove' : 'add',
          entity: 'sensors',
        })}
        onClose={handleConfirmSubmit}
        loading={isLoading}
        disabled={isLoading}
      />
    </Container>
  );
});

export default PlotsSensorsTable;
