import React, { FC, useCallback, useState } from 'react';
import Dialog from 'components/UI/Dialog';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import { observer } from 'mobx-react';
import Button from 'components/UI/Button';
import { useStores } from 'stores/hooks/hooks';
import { computed } from 'mobx';
import { useTranslation } from 'react-i18next';
import UserUploadComplete from './UserUploadComplete';
import UserExcelTable from './UserExcelTable';

enum Stage {
  TableReview, // table review
  UploadComplete, // complete and close modalF
}

interface UserUploadDialogProps {
  fileName: string;
  onClose: () => void;
}

const UserUploadDialog: FC<UserUploadDialogProps> = ({ fileName, onClose }) => {
  const { membersStore } = useStores();
  const { t } = useTranslation('users');
  const isOpen = Boolean(fileName);
  const [stage, setStage] = useState<Stage>(Stage.TableReview);
  const handleClose = useCallback(() => {
    onClose();
    // so that we wont see this component go back to tableReview state we settimeout
    setTimeout(() => {
      setStage(Stage.TableReview);
    }, 0);
  }, [onClose]);

  const isUploadEnabled = computed(() =>
    membersStore.userUploadExcel.some((el) => el.selected),
  ).get();

  const handleFileCreate = useCallback(async () => {
    const isCreated = await membersStore.createUsersBatch();
    if (isCreated) {
      setStage(Stage.UploadComplete);
    }
  }, [membersStore]);

  return (
    <Dialog
      open={isOpen}
      dialogTitle={stage === Stage.TableReview ? fileName : 'Done'}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
    >
      <DialogContent>
        {stage === Stage.TableReview && <UserExcelTable />}
        {stage === Stage.UploadComplete && <UserUploadComplete />}
      </DialogContent>
      <Divider />
      <DialogActions>
        {stage === Stage.TableReview && (
          <>
            <Button variant="outlined" onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Button disabled={!isUploadEnabled} onClick={handleFileCreate}>
              {t('create')}
            </Button>
          </>
        )}
        {stage === Stage.UploadComplete && (
          <Button variant="outlined" onClick={handleClose}>
            {t('close')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default observer(UserUploadDialog);
