import React, { ReactElement } from 'react';
import { format, parseISO } from 'date-fns';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { GqlPlotAlertsPaginatedContent } from 'gql/types/plots';
import { DEFAULT_DATE_FORMAT } from 'core/constants';
import { getAlertTypeTranslation } from 'utils/alertsUtils';

const EMPTY_VALUE = 'N/A';
export const TABLE_ALERTS_MAX_ROWS_NUMBER = 50;

export const getAlertsDateColumnBody = ({
  alertSetTime,
}: GqlPlotAlertsPaginatedContent): string => {
  const formattedDate = alertSetTime
    ? format(parseISO(alertSetTime), DEFAULT_DATE_FORMAT)
    : '';

  return formattedDate;
};

export const getIgnoreColumnBody = ({
  alertIgnore,
}: GqlPlotAlertsPaginatedContent): ReactElement => {
  return alertIgnore ? <CheckIcon /> : <CloseIcon />;
};

export const getAlertTypeColumnBody = ({
  alertType,
}: GqlPlotAlertsPaginatedContent): string => {
  return getAlertTypeTranslation(alertType);
};

export const getSensorNameColumnBody = ({
  sensorName,
}: GqlPlotAlertsPaginatedContent): string => sensorName || EMPTY_VALUE;

export const getSensorSerialColumnBody = ({
  sensorSerial,
}: GqlPlotAlertsPaginatedContent): string => sensorSerial || EMPTY_VALUE;

export const getSensorTypeColumnBody = ({
  sensorType,
}: GqlPlotAlertsPaginatedContent): string => sensorType || EMPTY_VALUE;

export const getSystemSerialColumnBody = ({
  systemSerial,
}: GqlPlotAlertsPaginatedContent): string => systemSerial || EMPTY_VALUE;
