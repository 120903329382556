import React, { FC } from 'react';
import styled from 'styled-components';
import { Backdrop, BackdropProps } from '@mui/material';
import Loading, { LoadingProps } from 'components/UI/Loading/Loading';

const LoadingBackdrop = styled(Backdrop)`
  z-index: 9999;
`;

interface BackdropLoadingProps extends LoadingProps, BackdropProps {}

const BackdropLoading: FC<BackdropLoadingProps> = (props) => {
  const { size, color } = props;
  return (
    <LoadingBackdrop {...props}>
      <Loading size={size} color={color} />
    </LoadingBackdrop>
  );
};

export default BackdropLoading;
