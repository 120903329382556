import { MassEditSettings } from 'models/plot';
import { JSONSchema } from 'stores/types/types';

export enum SettingType {
  dss = 'dss',
  settings = 'settings',
}

export interface MassEditPlotData {
  // dss
  soilMin: number | undefined;
  soilMax: number | undefined;
  irrigationMin: number | undefined;
  irrigationMax: number | undefined;
  frequency: number | undefined;
  disableF: boolean | undefined;
  correctionFactor: number | undefined;
  disableCF: boolean | undefined;
  planDuration: number | undefined;
  // settings
  DaytimeParMin: number | undefined;
  ATmax: number | undefined;
  VPDmax: number | undefined;
  VPDNIGHTmax: number | undefined;
  SDIrate: number | undefined;
  MDSrate: number | undefined;
  MdsMin: number | undefined;
  MdsRateChange: number | undefined;
  MdsMinET: number | undefined;
  PMBDelta: number | undefined;
  WaterNominalPressure: number | undefined;
}

export const tableKeys: { key: string; type: SettingType | undefined }[] = [
  { key: 'id', type: undefined },
  { key: 'name', type: undefined },
  { key: 'soilMin', type: SettingType.dss },
  { key: 'soilMax', type: SettingType.dss },
  { key: 'irrigationMin', type: SettingType.dss },
  { key: 'irrigationMax', type: SettingType.dss },
  { key: 'frequency', type: SettingType.dss },
  { key: 'disableF', type: SettingType.dss },
  { key: 'correctionFactor', type: SettingType.dss },
  { key: 'disableCF', type: SettingType.dss },
  { key: 'planDuration', type: SettingType.dss },
  { key: 'DaytimeParMin', type: SettingType.settings },
  { key: 'ATmax', type: SettingType.settings },
  { key: 'VPDmax', type: SettingType.settings },
  { key: 'VPDNIGHTmax', type: SettingType.settings },
  { key: 'SDIrate', type: SettingType.settings },
  { key: 'MDSrate', type: SettingType.settings },
  { key: 'MdsMin', type: SettingType.settings },
  { key: 'MdsRateChange', type: SettingType.settings },
  { key: 'MdsMinET', type: SettingType.settings },
  { key: 'PMBDelta', type: SettingType.settings },
  { key: 'WaterNominalPressure', type: SettingType.settings },
];

export const getDssOrSettingsValues = (
  data: {
    [key: string]: number | boolean | string | undefined;
  },
  setting: SettingType,
) => {
  const paramValues: { [key: string]: number | boolean | string | undefined } =
    {};

  tableKeys
    .filter((tableKey) => tableKey.type === setting)
    .forEach((tableKey) => {
      if (data[tableKey.key] !== undefined) {
        paramValues[tableKey.key] = data[tableKey.key];
      }
    });

  return paramValues as { [key: string]: number | boolean | string };
};

// Code to generate default values for missing PARAMS in form, in case we will need it later
export const generateDefaults = (
  properties: JSONSchema,
  parameters: { [key: string]: any } = {},
) => {
  Object.entries(properties).forEach(([key, property]) => {
    if (property.type === 'boolean') {
      parameters[key] = parameters[key] ?? false;
    } else if (property.type === 'object') {
      const newParams: { [key: string]: any } = parameters[key] || {};
      parameters[key] = newParams;
      generateDefaults(property.properties, newParams);
    } else {
      parameters[key] = parameters[key] ?? property.default;
    }
  });

  return parameters;
};

export const getMassEditTableRows = (
  massSettings: MassEditSettings['massSettings'] | undefined,
) => {
  return (
    massSettings?.map((plotSettings) => ({
      id: plotSettings?.plotId,
      name: plotSettings?.plotName,
      // DSS Settings
      soilMin: plotSettings?.settings?.dssSettings?.params?.soilMin,
      soilMax: plotSettings?.settings?.dssSettings?.params?.soilMax,
      irrigationMin: plotSettings?.settings?.dssSettings?.params?.irrigationMin,
      irrigationMax: plotSettings?.settings?.dssSettings?.params?.irrigationMax,
      frequency: plotSettings?.settings?.dssSettings?.params?.frequency,
      disableF: plotSettings?.settings?.dssSettings?.params?.disableF,
      correctionFactor:
        plotSettings?.settings?.dssSettings?.params?.correctionFactor,
      disableCF: plotSettings?.settings?.dssSettings?.params?.disableCF,
      planDuration: plotSettings?.settings?.dssSettings?.params?.planDuration,
      // Plot Settings
      DaytimeParMin:
        plotSettings?.settings?.plotSettings?.params?.DaytimeParMin,
      ATmax: plotSettings?.settings?.plotSettings?.params?.ATmax,
      VPDmax: plotSettings?.settings?.plotSettings?.params?.VPDmax,
      VPDNIGHTmax: plotSettings?.settings?.plotSettings?.params?.VPDNIGHTmax,
      SDIrate: plotSettings?.settings?.plotSettings?.params?.SDIrate,
      MDSrate: plotSettings?.settings?.plotSettings?.params?.MDSrate,
      MdsMin: plotSettings?.settings?.plotSettings?.params?.MdsMin,
      MdsRateChange:
        plotSettings?.settings?.plotSettings?.params?.MdsRateChange,
      MdsMinET: plotSettings?.settings?.plotSettings?.params?.MdsMinET,
      PMBDelta: plotSettings?.settings?.plotSettings?.params?.PMBDelta,
      WaterNominalPressure:
        plotSettings?.settings?.plotSettings?.params?.WaterNominalPressure,
    })) || []
  );
};
