import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Menubar } from 'primereact/menubar';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { observer } from 'mobx-react';
import BackdropLoading from 'components/UI/BackdropLoading';
import Input from 'components/UI/Input';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import axios from 'axios';
import { ExtendedIProtocol } from 'models/protocol';
import { DialogCloseAction, ErrorResponse } from 'models/shared';
import { UploadDialog } from './UploadDialog';

const DISABLED_UPLOAD_MODEL =
  process.env.REACT_APP_UPLOAD_MODEL_DISABLED === 'true';

const excelFileType =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const MenubarContainer = styled.div`
  .p-menubar {
    border-radius: unset;
  }
`;

const StyledInput = styled(Input)`
  background-color: ${(p) => p.theme.palette.background.default};
`;

interface HeaderProps {
  onGlobalFilterChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
}

const MenuBar: FC<HeaderProps> = ({ onGlobalFilterChange }) => {
  const { t } = useTranslation('crop_models');
  const { cropModelStore } = useStores();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [protocols, setProtocols] = useState<ExtendedIProtocol[] | null>(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadFilename, setUploadFilename] = useState('');
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [removeModelDialogOpen, setRemoveModelDialogOpen] = useState(false);
  const [cropName, setCropName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedCropVarieties = useMemo(() => {
    if (
      cropModelStore.selectedRows[0]?.classification.cropVariety.length > 15
    ) {
      return `${cropModelStore.selectedRows[0].classification.cropVariety
        .slice(0, 15)
        .toString()}, ...`;
    }

    return cropModelStore.selectedRows[0]?.classification.cropVariety.toString();
  }, [cropModelStore.selectedRows]);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setUploadLoading(true);
    if (e.target?.files?.length) {
      try {
        const res: ExtendedIProtocol[] =
          await cropModelStore.uploadExcelProtocols(e.target.files[0]);

        if (res && res.length) {
          setProtocols(res);
          setUploadDialogOpen(true);
        }

        setUploadLoading(false);
      } catch (error) {
        if (error instanceof Error || axios.isAxiosError(error)) {
          if (axios.isAxiosError(error)) {
            const errorMessage =
              (error.response?.data as ErrorResponse)?.message || error.message;

            if (errorMessage) {
              setUploadErrors([errorMessage]);
              setProtocols([]);
              setUploadDialogOpen(true);
            }
          }
        }

        setUploadLoading(false);
      }

      setUploadFilename(e.target.files[0].name);
    }

    e.target.value = '';
  };

  const handleDialogClose = () => {
    setUploadErrors([]);
    setUploadDialogOpen(false);
  };

  const onCloseRemoveModelDialog = useCallback(
    (response: DialogCloseAction) => {
      if (response === DialogCloseAction.Confirmed) {
        cropModelStore.removeModel(
          cropModelStore.selectedRows[0].protocolHeader.growerId,
          cropModelStore.selectedRows[0].protocolHeader.protocolId,
        );
      }

      setRemoveModelDialogOpen(false);
    },
    [cropModelStore],
  );

  const items = useMemo(
    () => [
      {
        label: t('upload_model'),
        disabled: DISABLED_UPLOAD_MODEL,
        icon: 'pi pi-fw pi-upload',
        command: () => {
          inputRef?.current?.click();
        },
      },
      {
        label: t('duplicate'),
        disabled: true,
        icon: 'pi pi-fw pi-copy',
        // command: function here,
      },
      {
        label: t('edit'),
        icon: 'pi pi-fw pi-pencil',
        disabled: true,
        // command: function here,
      },
      {
        label: t('remove'),
        disabled: !(cropModelStore.selectedRows.length === 1),
        icon: 'pi pi-fw pi-trash',
        command: () => setRemoveModelDialogOpen(true),
      },
    ],
    [t, cropModelStore.selectedRows.length],
  );

  // clear protocols once dialog closes (bug only happens when using mocks)
  useEffect(() => {
    if (!uploadDialogOpen) {
      setProtocols([]);
    }
  }, [uploadDialogOpen]);

  useEffect(() => {
    if (removeModelDialogOpen) {
      const crop = cropModelStore.protocols.find(
        (cropElement) =>
          cropElement.classification.cropId ===
          cropModelStore.selectedRows[0].classification.cropId,
      );

      setCropName(crop?.classification.cropType?.name || '');
    }
  }, [removeModelDialogOpen, cropModelStore]);

  return (
    <>
      {removeModelDialogOpen && (
        <ConfirmDialog
          backText={t('cancel')}
          buttonText={t('remove')}
          isOpen={removeModelDialogOpen}
          title={t('title_remove_model_confirmation')}
          message={t('remove_model_dialog', {
            crop: cropName,
            description:
              cropModelStore.selectedRows[0].protocolHeader.description,
            category:
              cropModelStore.selectedRows[0].classification.cropCategory ||
              'N/A',
            variety: selectedCropVarieties || 'N/A',
          })}
          onClose={onCloseRemoveModelDialog}
        />
      )}
      <BackdropLoading open={uploadLoading} />
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        accept={excelFileType}
      />
      <UploadDialog
        startingErrors={uploadErrors}
        protocols={protocols}
        onHide={handleDialogClose}
        visible={uploadDialogOpen}
        uploadFilename={uploadFilename}
      />
      <MenubarContainer>
        <Menubar
          model={items}
          end={
            <StyledInput
              size="small"
              placeholder={t('search')}
              type="text"
              onChange={onGlobalFilterChange}
            />
          }
        />
      </MenubarContainer>
    </>
  );
};

export default observer(MenuBar);
