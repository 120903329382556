import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Menubar as PrimeReactMenuBar } from 'primereact/menubar';
import Input from 'components/UI/Input';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import { useStores } from 'stores/hooks/hooks';
import { ActionMode, DialogCloseAction } from 'models/shared';
import { UserExcel } from 'models/user';
import api from 'Api/ApiMethods';
import saveAs from 'file-saver';
import UserManagementDialog from './UserManagementDialog/UserManagementDialog';
import UserUploadDialog from './UserUploadDialog/UserUploadDialog';

const excelFileType =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const StyledInput = styled(Input)`
  background-color: ${(p) => p.theme.palette.background.default};
`;

const StyledPrimeReactMenuBar = styled(PrimeReactMenuBar)`
  .p-submenu-list {
    width: auto;
    z-index: 9999;
  }

  .p-menuitem-text {
    text-wrap: nowrap;
  }
`;

interface HeaderProps {
  onGlobalFilterChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
}

const MenuBar: FC<HeaderProps> = ({ onGlobalFilterChange }) => {
  const { t } = useTranslation('users');
  const { membersStore } = useStores();
  const [userManagementDialog, setUserManagementDialog] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [userFileName, setUserFileName] = useState<string>('');
  const [userActionMode, setUserActionMode] = useState<ActionMode>(
    ActionMode.CREATE,
  );

  const [removeMembersDialogOpen, setRemoveMembersDialogOpen] = useState(false);
  const [disableMembersDialogOpen, setDisableMembersDialogOpen] =
    useState(false);

  const onCloseRemoveDialog = useCallback(
    (response: DialogCloseAction) => {
      if (response === DialogCloseAction.Confirmed) {
        membersStore.deleteUsers();
      }

      setRemoveMembersDialogOpen(false);
    },
    [membersStore],
  );

  const onCloseDisableDialog = useCallback(
    (response: DialogCloseAction) => {
      if (response === DialogCloseAction.Confirmed) {
        membersStore.activateSelectedUsers();
      }

      setDisableMembersDialogOpen(false);
    },
    [membersStore],
  );

  const userCreateModal = useCallback(() => {
    setUserActionMode(ActionMode.CREATE);
    membersStore.setSelectedRows({ selection: [], activeRow: null });
    setUserManagementDialog(true);
    setCreateUser(true);
  }, [membersStore]);

  const userEditModal = useCallback(() => {
    setUserActionMode(ActionMode.EDIT);
    setUserManagementDialog(true);
    setCreateUser(false);
  }, []);

  const handleUserManagementDialogClose = useCallback(() => {
    setUserManagementDialog(false);
  }, []);

  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target?.files?.[0]) {
        const fileName = e.target?.files?.[0]?.name;
        const res: UserExcel[] = await membersStore.uploadExcelUsers(
          e.target.files[0],
        );

        if (res && fileName) {
          setUserFileName(fileName);
        }
      }

      e.target.value = '';
    },
    [membersStore],
  );

  const handleUserDialogClose = useCallback(() => {
    setUserFileName('');
  }, []);

  const downloadUsersTemplate = useCallback(async () => {
    const file = await api.getUsersExcelTemplate();

    saveAs(
      new Blob([file], { type: 'application/octet-stream' }),
      'users_template.xlsx',
    );
  }, []);

  const items = useMemo(
    () => [
      {
        label: t('users'),
        icon: 'pi pi-fw pi-file',
        items: [
          {
            label: t('new_user'),
            icon: 'pi pi-fw pi-plus',
            command: userCreateModal,
          },
          {
            label: t('import_users'),
            icon: 'pi pi-fw pi-upload',
            command: () => {
              inputRef?.current?.click();
            },
          },
          {
            label: t('download_template'),
            icon: 'pi pi-fw pi-download',
            command: downloadUsersTemplate,
          },
        ],
      },
      {
        label: t('edit'),
        disabled: !membersStore.userTableMultiSelection.activeRow,
        icon: 'pi pi-fw pi-pencil',
        command: userEditModal,
      },
      {
        label: membersStore.activateUser ? t('inactivate') : t('active'),
        disabled: !membersStore.selectedRows.length,
        command: () => setDisableMembersDialogOpen(true),
        icon: 'fas fa-hand-paper',
      },
      {
        label: t('remove'),
        disabled: !membersStore.selectedRows.length,
        command: () => setRemoveMembersDialogOpen(true),
        icon: 'fas fa-times',
      },
      {
        label: t('reset_password'),
        disabled: true,
      },
    ],
    [
      t,
      userCreateModal,
      downloadUsersTemplate,
      membersStore.userTableMultiSelection.activeRow,
      membersStore.activateUser,
      membersStore.selectedRows.length,
      userEditModal,
    ],
  );

  return (
    <div>
      {removeMembersDialogOpen && (
        <ConfirmDialog
          backText={t('cancel')}
          buttonText={t('remove')}
          isOpen={removeMembersDialogOpen}
          title={t('confirm_dialog_user_delete_title')}
          message={t('confirm_dialog_user_delete_message')}
          onClose={onCloseRemoveDialog}
        />
      )}
      {disableMembersDialogOpen && (
        <ConfirmDialog
          backText={t('cancel')}
          buttonText={
            membersStore.selectedRows[0]?.active ? t('inactive') : t('active')
          }
          isOpen={disableMembersDialogOpen}
          title={t('confirm_dialog_user_disable_title')}
          message={t('confirm_dialog_user_disable_message', {
            activate: membersStore.selectedRows[0]?.active
              ? 'inactive'
              : 'active',
          })}
          onClose={onCloseDisableDialog}
        />
      )}
      {userManagementDialog && (
        <UserManagementDialog
          onHide={handleUserManagementDialogClose}
          visible={userManagementDialog}
          title={t('user_management_title', {
            action: createUser ? 'Create' : 'Edit',
          })}
          mode={userActionMode}
        />
      )}
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        accept={excelFileType}
      />
      <UserUploadDialog
        fileName={userFileName}
        onClose={handleUserDialogClose}
      />
      <StyledPrimeReactMenuBar
        model={items}
        end={
          <StyledInput
            size="small"
            placeholder={t('search')}
            type="text"
            onChange={onGlobalFilterChange}
          />
        }
      />
    </div>
  );
};

export default observer(MenuBar);
