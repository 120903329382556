import React, { FC, useCallback } from 'react';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { DialogOnCloseReason } from 'models/shared';
import AssignUserGrowerContent from './AssignUserGrowerContent';

interface AssignUserGrowerDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
}

const AssignUserGrowerDialog: FC<AssignUserGrowerDialogProps> = ({
  visible = false,
  onHide,
  title,
}) => {
  const { t } = useTranslation('users');
  const closeDialog = useCallback(
    (event: any, reason: DialogOnCloseReason) => {
      if (reason && reason === DialogOnCloseReason.BackDropClick) {
        return;
      }

      onHide();
    },
    [onHide],
  );

  return (
    <Dialog
      open={visible}
      onClose={closeDialog}
      fullWidth
      maxWidth="lg"
      dialogTitle={title}
      titleWeight="bold"
      disableEscapeKeyDown
    >
      <DialogContent>
        <AssignUserGrowerContent />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onHide}>
          <Text textcolor={theme.color.white} size="sm">
            {t('close')}
          </Text>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignUserGrowerDialog;
