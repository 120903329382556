import styled from 'styled-components';

interface Props {
  direction?: DirectionProp;
  flex?: number | string; // flex number, default is 'initial'
  gap?: number | string; // gap between elements
  justify?: JustifyProp; // justify content prop, default is space-between
  align?: AlignProp; // align items prop, default is center
  alignSelf?: AlignSelfProp; // align items prop, default is center
  grow?: boolean; // whether is should take all available space, default is false
  overflow?: Overflow; // which overflow to use, default is visible
  inline?: boolean; // whether to use flex or inline-flex, default is flex
  display?: DisplayProp;
  'grid-template-columns'?: string;
}

type DisplayProp = 'flex' | 'grid';
type DirectionProp = 'column' | 'row';
type JustifyProp =
  | 'start'
  | 'end'
  | 'stretch'
  | 'between'
  | 'center'
  | 'evenly';
type AlignProp = 'start' | 'end' | 'center' | 'baseline' | 'stretch';
type AlignSelfProp =
  | 'auto'
  | 'stretch'
  | 'center'
  | 'start'
  | 'end'
  | 'baseline'
  | 'initial'
  | 'inherit';

type Overflow = 'auto' | 'hidden' | 'scroll' | 'visible';

enum JustifyOptions {
  start = 'flex-start',
  end = 'flex-end',
  stretch = 'stretch',
  between = 'space-between',
  center = 'center',
  evenly = 'space-evenly',
}

enum AlignItems {
  start = 'flex-start',
  end = 'flex-end',
  stretch = 'stretch',
  baseline = 'baseline',
  center = 'center',
}

enum AlignSelfItems {
  auto = 'auto',
  stretch = 'stretch',
  center = 'center',
  start = 'flex-start',
  end = 'flex-end',
  baseline = 'baseline',
  initial = 'initial',
  inherit = 'inherit',
}

function justifyToCssValue(justify: JustifyProp) {
  return JustifyOptions[justify];
}

function alignToCssValue(align: AlignProp) {
  return AlignItems[align];
}

function alignSelfToCssValue(align: AlignSelfProp) {
  return AlignSelfItems[align];
}

const Grid = styled.div<Props>`
  display: ${(p) => p.display};
  flex-direction: ${(p) => p.direction};
  align-items: ${(p) => (p.align ? alignToCssValue(p.align) : 'center')};
  align-self: ${(p) =>
    p.alignSelf ? alignSelfToCssValue(p.alignSelf) : 'auto'};
  justify-content: ${(p) =>
    p.justify ? justifyToCssValue(p.justify) : 'space-between'};
  overflow: ${(p) => p.overflow};
  ${(p) => p.grow && 'width: 100%;'}
  ${(p) => p.gap && `gap: ${p.gap};`}
  flex: ${(p) => p.flex};
  ${(p) =>
    p['grid-template-columns'] &&
    `grid-template-columns: ${p['grid-template-columns']};`}
`;

Grid.defaultProps = {
  'grid-template-columns': 'none',
  align: 'center',
  alignSelf: 'auto',
  direction: 'column',
  display: 'flex',
  flex: 'initial',
  gap: 'normal normal',
  grow: false,
  inline: false,
  justify: 'between',
  overflow: 'visible',
} as Props;

// const Row: FC<Props> = ({ flex, ...props }) => <StyledRow {...props} />
export default Grid;
