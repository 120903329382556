import React, { FC } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIconSelectedStyle, faIconStyle, OverflowText } from './Styles';
import { ChildRouteProps, ExpandableRouteProps } from './ExpandableRoute';

interface RouteContentProps
  extends Pick<ExpandableRouteProps, 'icon' | 'isMinimized'>,
    Pick<ChildRouteProps, 'label'> {
  isSelected: boolean;
}

export const RouteContent: FC<RouteContentProps> = ({
  label,
  icon,
  isSelected,
  isMinimized,
}) =>
  isMinimized ? (
    <ListItemIcon>
      <FontAwesomeIcon
        icon={icon}
        style={isSelected ? faIconSelectedStyle : faIconStyle}
      />
    </ListItemIcon>
  ) : (
    <OverflowText $hideText={false}>{label}</OverflowText>
  );
