import React from 'react';
import { RootStore } from '../rootStore';

// TODO: add more stores in RootStore class, then add to context below
export const rootStore = new RootStore();

export const storesContext = React.createContext({
  rootStore,
  userStore: rootStore.userStore,
  snackBarStore: rootStore.snackBarStore,
  cropModelStore: rootStore.cropModelStore,
  resellersStore: rootStore.resellersStore,
  systemsStore: rootStore.systemsStore,
  membersStore: rootStore.membersStore,
  plotsStore: rootStore.plotsStore,
  weatherStore: rootStore.weatherStore,
  featuresStore: rootStore.featuresStore,
  couponsStore: rootStore.couponsStore,
});
