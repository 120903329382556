import api from 'Api/ApiMethods';
import i18next from 'i18next';
import { makeAutoObservable } from 'mobx';
import { getAxiosErrorMessage } from 'utils/httpErrorsHelpers';
import {
  ConnectedSensors,
  MandatorySensors,
  ModelType,
  NestedArray,
} from 'models/features';
import { Sensor } from 'models/sensor';
import { WebPlot } from 'models/plot';
import { RootStore } from './rootStore';
import { LoadableField } from './types/types';

export interface CreateFeatureForm {
  mlModelId: ModelType | null;
  latitude: number | null;
  longitude: number | null;
  name: string | null;
  displayName: string | null;
  plotId: number | null;
  connectedSensors: ConnectedSensors[] | null;
  id: number | null;
  plotName: string | null;
}

export interface SensorsPrerequisites {
  mandatorySensors: MandatorySensors[];
  oneOfList: NestedArray<string>;
  optionalSensors: string[];
  displayName: string;
}

const defaultCreateFeatureValues: CreateFeatureForm = {
  mlModelId: null,
  latitude: null,
  longitude: null,
  name: null,
  displayName: null,
  plotId: null,
  connectedSensors: null,
  id: null,
  plotName: null,
};

export class FeaturesStore {
  rootStore: RootStore = {} as RootStore;

  featuresTypes: LoadableField<any[]> = {
    data: [],
    loading: false,
  };

  createFeatureForm: CreateFeatureForm = defaultCreateFeatureValues;
  sensorsPrerequisites: SensorsPrerequisites[] = [];
  sensors: Sensor[] = [];
  selectedSensors: Sensor[] = [];
  features: CreateFeatureForm[] = [];
  selectedFeatures: CreateFeatureForm[] = [];
  selectedFeatureSensors: Sensor[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getAllFeatures = async (growerId: number, plots: WebPlot[]) => {
    this.features = [];
    this.selectedFeatures = [];
    const response = await api.getFeaturesByGrower(growerId);
    response.forEach((feature: CreateFeatureForm) => {
      feature.plotName =
        plots.find((plot) => plot.id === feature.plotId)?.name || '';
    });

    this.features.push(...response);
  };

  resetFeatures = () => {
    this.features = [];
    this.selectedFeatures = [];
  };

  setSelectedFeatures = async (newSelectedFeatures: CreateFeatureForm) => {
    this.selectedFeatures = [];
    this.selectedFeatures.push(newSelectedFeatures);
  };

  setSelectedFeatureSensor = async (newSelectedFeatureSensor: Sensor) => {
    this.selectedFeatureSensors.push(newSelectedFeatureSensor);
  };

  deactivateFeature = async () => {
    this.selectedFeatures.forEach(async (feature) => {
      try {
        await api.deleteFeature(feature.plotId, feature.id);
        this.features = this.features.filter((item) => item !== feature);
        const indexToRemove = this.features.findIndex(
          (item) => item.id === feature.id,
        );

        if (indexToRemove !== -1) {
          this.features.splice(indexToRemove, 1);
          this.selectedFeatures = [];
        }
      } catch (error) {
        this.errorToast(i18next.t('errors:something_went_wrong'));
      }
    });
  };

  getFeaturesSchema = async () => {
    if (this.featuresTypes.data.length) {
      return;
    }

    this.featuresTypes.loading = true;
    try {
      const response = await api.getFeaturesSchema();
      this.featuresTypes.data = Array.isArray(response)
        ? response.map((type) => type.modelTypeId)
        : [];

      this.sensorsPrerequisites = Array.isArray(response)
        ? response.map((item) => {
            return {
              ...item.sensorsPrerequisites,
              displayName: item.displayName,
            };
          })
        : [];
    } catch {
      this.errorToast(i18next.t('errors:something_went_wrong'));
    } finally {
      this.featuresTypes.loading = false;
    }
  };

  setFeatureType = (mlModelId: ModelType) => {
    this.createFeatureForm = {
      ...defaultCreateFeatureValues,
      mlModelId,
    };
  };

  setFeatureConfiguration = (values: any) => {
    this.createFeatureForm = {
      ...this.createFeatureForm,
      displayName: values.displayName,
      name: values.name,
    };
  };

  setFeatureConfigurationLocation = (latitude: number, longitude: number) => {
    this.createFeatureForm = {
      ...this.createFeatureForm,
      latitude,
      longitude,
    };
  };

  setFeaturePlot(plotId: number): void {
    this.createFeatureForm = {
      ...this.createFeatureForm,
      plotId,
    };
  }

  getSensors = async () => {
    try {
      if (this.createFeatureForm.plotId) {
        const response = await api.getPlotFeatureSensors(
          this.createFeatureForm.plotId,
        );

        this.sensors = response.sensors;
        if (this.sensors) {
          const sensorSoilLocation = this.sensors.find(
            (sensor) => sensor.sensorType.family === 'SoilVWC',
          );

          if (sensorSoilLocation) {
            this.setFeatureConfigurationLocation(
              sensorSoilLocation.latitude,
              sensorSoilLocation.longitude,
            );
          }
        }

        return response;
      }
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    }

    return null;
  };

  setSensors = async (newSensors: Sensor[]) => {
    this.sensors = [...newSensors];
  };

  setSelectedSensors = async (newSelectedSensors: Sensor[]) => {
    this.selectedSensors = [...newSelectedSensors];
  };

  setConnectedSensors = async () => {
    if (!this.selectedSensorsValidation()) {
      this.errorToast(i18next.t('feature:select_sensors_validation'));
      return false;
    }

    this.selectedSensors.forEach((item) => {
      if (!this.createFeatureForm.connectedSensors) {
        this.createFeatureForm.connectedSensors = [];
      }

      this.createFeatureForm.connectedSensors?.push({
        id: item.id,
      });
    });

    return true;
  };

  resetConnectedSensors = async () => {
    this.createFeatureForm.connectedSensors = [];
  };

  createFeature = async (plots: WebPlot[]) => {
    try {
      const result = await api.postFeature(this.createFeatureForm);
      const plotName =
        plots.find((plot) => plot.id === this.createFeatureForm.plotId)?.name ||
        '';

      this.features.push({
        ...result,
        plotId: this.createFeatureForm.plotId,
        plotName,
      });

      return result;
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      return false;
    }
  };

  private successToast(message: string): void {
    this.rootStore?.snackBarStore.showToast({
      detail: message,
      severity: 'success',
      summary: 'Success',
    });
  }

  errorToast = (message: string) => {
    this.rootStore?.snackBarStore.showToast({
      detail: message,
    });
  };

  private getErrorMessage(error: unknown): string {
    return (
      getAxiosErrorMessage(error) ?? i18next.t('errors:something_went_wrong')
    );
  }

  private selectedSensorsValidation = () => {
    const sensorsSchema = this.sensorsPrerequisites.find(
      (item) =>
        item.displayName === this.createFeatureForm.mlModelId?.name || '',
    );

    if (!sensorsSchema) {
      return false;
    }

    const uniqueSelectedSensors = this.selectedSensors.reduce(
      (acc: Sensor[], current: Sensor) => {
        const existingItem = acc.find((item) => item.id === current.id);
        if (!existingItem) {
          acc.push(current);
        }

        return acc;
      },
      [],
    );

    const sensorsFamilies = uniqueSelectedSensors.map(
      (sensor) => sensor.sensorType.family,
    );

    for (let i = 0; i < sensorsSchema.mandatorySensors.length; i += 1) {
      const mandatorySensors = sensorsFamilies.filter((family) =>
        sensorsSchema.mandatorySensors[i].sensorFamilies.includes(family),
      );

      if (
        mandatorySensors.length !==
        sensorsSchema.mandatorySensors[i].numberAppearanceAllowed
      ) {
        return false;
      }
    }

    for (let i = 0; i < sensorsSchema.oneOfList.length; i += 1) {
      for (let j = 0; j < sensorsSchema.oneOfList[i].length; j += 1) {
        const oneOfSensors = sensorsFamilies.filter((family) =>
          sensorsSchema.oneOfList[i][j].includes(family),
        );

        if (
          oneOfSensors.length > 0 &&
          oneOfSensors.length !== sensorsSchema.oneOfList[i][j].length
        ) {
          return false;
        }
      }
    }

    return true;
  };
}
