import { Paper } from '@mui/material';
import {
  Column,
  DataTable,
  PrimeReactDataTable,
} from 'components/shared/DataTable/DataTable';
import { observer } from 'mobx-react';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { UserExcel } from 'models/user';
import {
  CheckRoundIcon,
  CheckSquareIcon,
  isSelectedBody,
  StatusBody,
  UserCompanyRoleBodyContainer,
  UserEmailBody,
  UserEmailBodyContainer,
  UserFirstNameBody,
  UserFirstNameBodyContainer,
  UserLastNameBody,
  UserLastNameBodyContainer,
  UserLocaleBody,
  UserLocaleBodyContainer,
  UserPhoneBody,
  UserPhoneBodyContainer,
  UserUnitsBodyContainer,
  UserOriginContainer,
  UserStatusContainer,
  UserGoogleAnalyticsContainer,
  UserSendWelcomeEmailContainer,
} from './Components';

const PlotsTableContainer = styled(Grid)``;

const StyledSelectedData = styled(Grid)`
  column-gap: 1rem;
  border: 1px solid ${(p) => p.theme.color.border};
  padding: 1rem;
  border-radius: 4px;
  margin-block: 1rem;
`;

const DataTableWrapper = styled(Paper)`
  margin: 1rem;
  overflow: hidden;
`;

const GeneralParseExcelError = styled(Text)`
  color: red;
  font-weight: bold;
`;

const isSelectedStyle = { width: 'calc(21px + 1rem)' };
const isValidStyle = { width: 'calc(16px + 1rem)' };

const UserExcelTable: FC = () => {
  const { membersStore } = useStores();
  const userExcelResponse = membersStore.userUploadExcel;
  const { t } = useTranslation('users');
  const dataTable = useRef<PrimeReactDataTable | null>(null);
  const [selectedRow, setSelectedRow] = useState<UserExcel | null>(null);
  const [growerIdError, setGrowerIdError] = useState<string | null>(null);

  const onRowClick = useCallback(
    (e: DataTableRowClickEventParams) => {
      setSelectedRow(e.data);
      setGrowerIdError(null);
      const isGrowerIdError = e.data?.importWarnings.find((error: any) =>
        error.fieldName.includes('GROWER_ID'),
      );

      if (isGrowerIdError) {
        setGrowerIdError(`${t('grower:growerID')} ${isGrowerIdError.msg}`);
      }
    },
    [t],
  );

  return (
    <Grid flex={1} direction="row" align="start">
      <PlotsTableContainer flex={1}>
        <DataTableWrapper elevation={3}>
          <DataTable
            size="small"
            ref={dataTable}
            id="ExcelUsersTable"
            showGridlines
            resizableColumns
            value={userExcelResponse || []}
            selection={selectedRow}
            selectionMode="single"
            dataKey="data.uid"
            emptyMessage={t('empty_message')}
            stripedRows
            onRowClick={onRowClick}
            paginator
            rows={10}
          >
            <Column
              field="selected"
              header={CheckSquareIcon}
              style={isSelectedStyle}
              body={isSelectedBody}
            />
            <Column
              field="isValid"
              header={CheckRoundIcon}
              body={StatusBody}
              style={isValidStyle}
            />
            <Column
              field="data.firstName"
              header={t('first_name')}
              body={UserFirstNameBody}
            />
            <Column
              field="data.lastName"
              header={t('last_name')}
              body={UserLastNameBody}
            />
            <Column
              field="data.phone"
              header={t('phone')}
              body={UserPhoneBody}
            />
            <Column
              field="data.email"
              header={t('email')}
              body={UserEmailBody}
            />
            <Column
              field="data.language"
              header={t('language')}
              body={UserLocaleBody}
            />
          </DataTable>
        </DataTableWrapper>
      </PlotsTableContainer>
      {selectedRow && (
        <StyledSelectedData display="grid" grid-template-columns="auto auto">
          <Text>{t('first_name')}</Text>
          <UserFirstNameBodyContainer data={selectedRow} />
          <Text>{t('last_name')}</Text>
          <UserLastNameBodyContainer data={selectedRow} />
          <Text>{t('phone')}</Text>
          <UserPhoneBodyContainer data={selectedRow} />
          <Text>{t('email')}</Text>
          <UserEmailBodyContainer data={selectedRow} />
          <Text>{t('language')}</Text>
          <UserLocaleBodyContainer data={selectedRow} />
          <Text>{t('company_role')}</Text>
          <UserCompanyRoleBodyContainer data={selectedRow} />
          <Text>{t('units')}</Text>
          <UserUnitsBodyContainer data={selectedRow} />
          <Text>{t('user_origin')}</Text>
          <UserOriginContainer data={selectedRow} />
          <Text>{t('status')}</Text>
          <UserStatusContainer data={selectedRow} />
          <Text>{t('google_analytics')}</Text>
          <UserGoogleAnalyticsContainer data={selectedRow} />
          <Text>{t('send_welcome_email')}</Text>
          <UserSendWelcomeEmailContainer data={selectedRow} />
          {growerIdError && (
            <GeneralParseExcelError>{growerIdError}</GeneralParseExcelError>
          )}
        </StyledSelectedData>
      )}
    </Grid>
  );
};

export default observer(UserExcelTable);
