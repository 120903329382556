import React, { FC } from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { differenceInDays } from 'date-fns';
import { Tooltip } from '@mui/material';
import { User } from 'models/user';
import { WebPlot } from 'models/plot';

const StatusBodyContainer: FC<{ data: User }> = observer(({ data }) => {
  return (
    <Grid>
      {data.active ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <FontAwesomeIcon icon={faTimes} />
      )}
    </Grid>
  );
});

const StatusBody = (data: User) => {
  return <StatusBodyContainer data={data} />;
};

const AgeBodyContainer: FC<{ data: WebPlot }> = observer((props) => {
  const { data } = props;
  const age = `${Number(
    (differenceInDays(Date.now(), Number(data.plantTime) || 0) / 365).toFixed(
      3,
    ),
  ).toFixed(3)} y`;

  return (
    <Tooltip arrow title={age} placement="top">
      <Text>{age}</Text>
    </Tooltip>
  );
});

const AgeBody = (data: WebPlot) => {
  return <AgeBodyContainer data={data} />;
};

export { StatusBodyContainer, AgeBody };
