/* eslint-disable no-restricted-syntax */
import JSZip from 'jszip';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const toGeoJSON = require('@mapbox/togeojson');

export interface PolygonItem {
  name: string;
  value: GeoJSON.Feature;
}

const generatePolygonsList = (geoJson: any): PolygonItem[] => {
  if (!geoJson) {
    return [];
  }

  const { type, features } = geoJson;
  if (type !== 'FeatureCollection' || !features || !features.length) {
    return [];
  }

  const polygonsList = [];
  let polygonIndex = 1;
  for (const feature of features) {
    if (feature.geometry.type === 'Polygon') {
      const polygonItem = {
        name: feature.properties.name
          ? `#${polygonIndex} ${feature.properties.name}`
          : `#${polygonIndex} Polygon`,
        value: feature as GeoJSON.Feature,
      };

      polygonsList.push(polygonItem);
      polygonIndex += 1;
    }
  }

  return polygonsList;
};

const kmzFileToGeoJson = (file: File): Promise<any> =>
  new Promise((res) => {
    JSZip.loadAsync(file).then((zip: any) => {
      const kmlFiles = zip.file(/\.kml$/);
      for (const kmlFile of kmlFiles) {
        kmlFile.async('string').then((str: string) => {
          res(toGeoJSON.kml(new DOMParser().parseFromString(str, 'text/xml')));
        });
      }
    });
  });

const kmlFileToGeoJson = (file: File): Promise<any> =>
  new Promise((res) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      res(
        toGeoJSON.kml(
          new DOMParser().parseFromString(reader.result as string, 'text/xml'),
        ),
      );
    };

    reader.readAsText(file);
  });

const geoJsonFileToGeoJson = (file: File): Promise<any> =>
  new Promise((res) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      res(JSON.parse(reader.result as string));
    };

    reader.readAsText(file);
  });

const convertFileToGeoJson = async (file: File): Promise<any> => {
  const ext = file.name?.split('.')[1] || '';
  if (ext === 'geojson') {
    const geojson = await geoJsonFileToGeoJson(file);
    return geojson;
  }

  if (ext === 'kml') {
    const kml = await kmlFileToGeoJson(file);
    return kml;
  }

  if (ext === 'kmz') {
    const kmz = await kmzFileToGeoJson(file);
    return kmz;
  }

  throw new Error('File extension not supported');
};

export const extractPolygonsFileToList = async (
  file: File | null,
): Promise<PolygonItem[]> => {
  if (!file) {
    return [];
  }

  try {
    const polygonsGeoJson = await convertFileToGeoJson(file);
    const polygonList = generatePolygonsList(polygonsGeoJson);
    return polygonList;
  } catch {
    return [];
  }
};

export const generatePolygonGeoJsonFile = (
  polygonFeature: GeoJSON.Feature,
): File => {
  const geoJson = {
    type: 'FeatureCollection',
    features: [polygonFeature],
  };

  const blob = new Blob([JSON.stringify(geoJson)], {
    type: 'application/json',
  });

  return new File([blob], 'polygon.geojson');
};
