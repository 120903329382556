import { Paper } from '@mui/material';
import {
  Column,
  DataTable,
  PrimeReactDataTable,
} from 'components/shared/DataTable/DataTable';
import { observer } from 'mobx-react';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { PlotExcel } from 'models/plot';
import {
  AreaBody,
  AreaBodyContainer,
  CheckRoundIcon,
  CheckSquareIcon,
  CropTypeBody,
  CropTypeBodyContainer,
  GrowingEnvironmentBodyContainer,
  InitialStgeDateBodyContainer,
  IrrigationMethodBodyContainer,
  isSelectedBody,
  LocationBody,
  LocationBodyContainer,
  PlantDateBodyContainer,
  PlotNameBody,
  PlotNameBodyContainer,
  ServiceLevelBody,
  ServiceLevelBodyContainer,
  SoilTypeBodyContainer,
  StartTimeBody,
  StartTimeBodyContainer,
  StatusBody,
} from './Components';

const PlotsTableContainer = styled(Grid)``;

const StyledSelectedData = styled(Grid)`
  column-gap: 1rem;
  border: 1px solid ${(p) => p.theme.color.border};
  padding: 1rem;
  border-radius: 4px;
  margin-block: 1rem;
`;

const DataTableWrapper = styled(Paper)`
  margin: 1rem;
  overflow: hidden;
`;

const GeneralParseExcelError = styled(Text)`
  color: red;
  font-weight: bold;
`;

const isSelectedStyle = { width: 'calc(21px + 1rem)' };
const isValidStyle = { width: 'calc(16px + 1rem)' };

const PlotExcelTable: FC = () => {
  const { resellersStore } = useStores();
  const plotExcelResponse = resellersStore.plotUploadExcel;
  const { t } = useTranslation('grower');
  const dataTable = useRef<PrimeReactDataTable | null>(null);
  const [selectedRow, setSelectedRow] = useState<PlotExcel | null>(null);
  const [matchedProtocol, setMatchedProtocol] = useState<string | null>(null);
  const [growerIdError, setGrowerIdError] = useState<string | null>(null);

  const onRowClick = useCallback((e: DataTableRowClickEventParams) => {
    setSelectedRow(e.data);
    setMatchedProtocol(null);
    const isMatchedProtocolError = e.data?.importErrors.find((error: any) =>
      error.fieldName.includes('MatchedProtocol'),
    );

    if (isMatchedProtocolError) {
      setMatchedProtocol(isMatchedProtocolError.msg);
    }

    const isGrowerIdError = e.data?.importErrors.find((error: any) =>
      error.fieldName.includes('GrowerId'),
    );

    if (isGrowerIdError) {
      setGrowerIdError(isGrowerIdError.msg);
    }
  }, []);

  return (
    <Grid flex={1} direction="row" align="start">
      <PlotsTableContainer flex={1}>
        <DataTableWrapper elevation={3}>
          <DataTable
            size="small"
            ref={dataTable}
            id="ExcelPlotsTable"
            showGridlines
            resizableColumns
            value={plotExcelResponse || []}
            selection={selectedRow}
            selectionMode="single"
            dataKey="plot.id"
            emptyMessage={t('empty_message')}
            stripedRows
            onRowClick={onRowClick}
            paginator
            rows={10}
          >
            <Column
              field="selected"
              header={CheckSquareIcon}
              style={isSelectedStyle}
              body={isSelectedBody}
            />
            <Column
              field="isValid"
              header={CheckRoundIcon}
              body={StatusBody}
              style={isValidStyle}
            />
            <Column
              field="plot.name"
              header={t('plot_name')}
              body={PlotNameBody}
            />
            <Column
              field="plot.plantTime"
              header={t('start_date')}
              body={StartTimeBody}
            />
            <Column
              field="plot.serviceLevel"
              header={t('service_level')}
              body={ServiceLevelBody}
            />
            <Column
              field="plot.location"
              header={t('location')}
              body={LocationBody}
            />
            <Column field="plot.area" header={t('area')} body={AreaBody} />
            <Column
              field="plot.cropName"
              header={t('crop')}
              body={CropTypeBody}
            />
          </DataTable>
        </DataTableWrapper>
      </PlotsTableContainer>
      {selectedRow && (
        <StyledSelectedData display="grid" grid-template-columns="auto auto">
          <Text>{t('plot_name')}</Text>
          <PlotNameBodyContainer data={selectedRow} />
          <Text>{t('description')}</Text>
          <Text>{String(selectedRow.plot.description)}</Text>
          <Text>{t('service_level')}</Text>
          <ServiceLevelBodyContainer data={selectedRow} />
          <Text>{t('growing_environment')}</Text>
          <GrowingEnvironmentBodyContainer data={selectedRow} />
          <Text>{t('location')}</Text>
          <LocationBodyContainer data={selectedRow} />
          <Text>{t('polygon')}</Text>
          <Text>polygon</Text>
          <Text>{t('area')}</Text>
          <AreaBodyContainer data={selectedRow} />
          <Text>{t('soil')}</Text>
          <SoilTypeBodyContainer data={selectedRow} />
          <Text>{t('crop')}</Text>
          <CropTypeBodyContainer data={selectedRow} />
          <Text>{t('category')}</Text>
          <Text>{String(selectedRow.plot?.category)}</Text>
          <Text>{t('variety')}</Text>
          <Text>{String(selectedRow.plot?.variety)}</Text>
          <Text>{t('plant_date')}</Text>
          <StartTimeBodyContainer data={selectedRow} />
          <Text>{t('age')}</Text>
          <PlantDateBodyContainer data={selectedRow} />
          <Text>{t('growing_method')}</Text>
          <Text>{String(selectedRow.plot.growthMethod)}</Text>
          <Text>{t('initial_stage')}</Text>
          <Text>
            {String(
              t(`phenStageIds:${selectedRow.plot?.initialStage?.stageId}`),
            )}
          </Text>
          <Text>{t('initial_stage_date')}</Text>
          <InitialStgeDateBodyContainer data={selectedRow} />
          <Text>{t('irrigation_method')}</Text>
          <IrrigationMethodBodyContainer data={selectedRow} />
          {matchedProtocol && (
            <GeneralParseExcelError>{matchedProtocol}</GeneralParseExcelError>
          )}
          {growerIdError && (
            <GeneralParseExcelError>{growerIdError}</GeneralParseExcelError>
          )}
        </StyledSelectedData>
      )}
    </Grid>
  );
};

export default observer(PlotExcelTable);
