import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IProps {
  flex?: number;
}

const StyledGrow = styled.div<IProps>`
  display: flex;
  flex: ${(p) => p.flex};
`;

const Grow: FunctionComponent<IProps> = ({ flex = 1 }) => (
  <StyledGrow flex={flex} />
);

export default Grow;
