import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHandPaper } from '@fortawesome/free-solid-svg-icons';
import { ResellerTreeNode, TreeGrower } from 'models/grower';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import Tooltip from 'components/UI/Tooltip';

const NameBodyContainer: FC<{ data: ResellerTreeNode | TreeGrower }> = observer(
  (props) => {
    const { data } = props;
    return (
      <Grid direction="row" gap="1rem" justify="start">
        <Tooltip arrow title={data.name} placement="top">
          <Text singleline>{data.name}</Text>
        </Tooltip>
      </Grid>
    );
  },
);

export const NameBody = (data: ResellerTreeNode | TreeGrower) => {
  return <NameBodyContainer data={data} />;
};

const HectaresBodyContainer: FC<{ data: ResellerTreeNode | TreeGrower }> =
  observer((props) => {
    const { data } = props;
    if (data.growerType === 'RESELLER') {
      return null;
    }

    return (
      <Tooltip arrow title={data.hectares} placement="top">
        <Text>{data.hectares}</Text>
      </Tooltip>
    );
  });

export const HectaresBody = (data: ResellerTreeNode | TreeGrower) => {
  return <HectaresBodyContainer data={data} />;
};

const PlotsBodyContainer: FC<{ data: ResellerTreeNode | TreeGrower }> =
  observer((props) => {
    const { data } = props;
    if (data.growerType === 'RESELLER') {
      return null;
    }

    return (
      <Tooltip arrow title={data.plotCount} placement="top">
        <Text>{data.plotCount}</Text>
      </Tooltip>
    );
  });

export const PlotsBody = (data: ResellerTreeNode | TreeGrower) => {
  return <PlotsBodyContainer data={data} />;
};

const GrowerNumBodyContainer: FC<{ data: ResellerTreeNode | TreeGrower }> =
  observer((props) => {
    const { data } = props;

    return (
      <Tooltip arrow title={data.numOfGrowers || ''} placement="top">
        <Text>{data.numOfGrowers || ''}</Text>
      </Tooltip>
    );
  });

export const GrowerNumBody = (data: ResellerTreeNode | TreeGrower) => {
  return <GrowerNumBodyContainer data={data} />;
};

const StatusBodyContainer: FC<{ data: ResellerTreeNode | TreeGrower }> =
  observer(({ data }) => {
    return (
      <Grid>
        {data.isActive ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faHandPaper} />
        )}
      </Grid>
    );
  });

export const StatusBody = (data: ResellerTreeNode | TreeGrower) => {
  return <StatusBodyContainer data={data} />;
};
