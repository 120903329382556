import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
} from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Space from 'components/UI/Space';
import Grid from 'components/UI/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Text from 'components/UI/Text';
import { theme } from 'components/UI';
import { percentToHex } from 'utils/functions';
import {
  DataTable,
  DataTableSelectionChangeParams,
} from 'primereact/datatable';
import { Column } from 'components/shared/DataTable/DataTable';
import { ProtocolProgress } from 'models/protocol';
import CropVarietyBody from './UploadCropVarietyBody';
import CropWarningBody from './UploadCropWarningBody';
import CropCategoryBody from './UploadCropCategoryBody';

const StepperContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.color.white};
  margin: 1rem;
  padding: 1rem;
  overflow: hidden;
  align-self: stretch;
  overflow: auto;
  flex: 1;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
  gap: 8px;
  padding: 0;
  margin: 0;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
`;

const StyledErrorAccordionSummary = styled(StyledAccordionSummary)`
  border-bottom: 1px solid ${theme.color.error};
  margin-bottom: -1px;
  background-color: ${`${theme.color.error}${percentToHex(8)}`};
`;

const StyledWarningAccordion = styled(Accordion)`
  border: 1px solid ${theme.color.warning};
  flex: 1;
  color: ${theme.color.warning};
  background-color: ${`${theme.color.warning}${percentToHex(8)}`};
`;

const StyledWarningAccordionSummary = styled(StyledAccordionSummary)`
  border-bottom: 1px solid ${theme.color.warning};
  margin-bottom: -1px;
  background-color: ${`${theme.color.warning}${percentToHex(8)}`};
`;

const StyledErrorAccordion = styled(Accordion)`
  border: 1px solid ${theme.color.error};
  flex: 1;
  color: ${theme.color.error};
  background-color: ${`${theme.color.error}${percentToHex(8)}`};
`;

interface IStepper {
  errors: string[];
  warnings: string[];
  protocolProgress: ProtocolProgress[];
  selectedRow: ProtocolProgress | null;
  handleSelectionChange: (e: DataTableSelectionChangeParams) => void;
}

const ValidationPage: FC<IStepper> = ({
  errors,
  warnings,
  protocolProgress,
  handleSelectionChange,
  selectedRow,
}) => {
  const { t } = useTranslation('crop_models');

  return (
    <StepperContainer elevation={0}>
      {Boolean(errors.length) && (
        <Grid align="start" direction="row">
          <StyledErrorAccordion defaultExpanded square elevation={0}>
            <StyledErrorAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Text weight="bold" color="error">
                {t('errors')}
              </Text>
            </StyledErrorAccordionSummary>
            <StyledAccordionDetails>
              <ul>
                {errors.map((el, i) => (
                  <li key={el + i.toString()}>
                    <Text weight="bold" color="error">
                      {el}
                    </Text>
                  </li>
                ))}
              </ul>
            </StyledAccordionDetails>
          </StyledErrorAccordion>
        </Grid>
      )}
      {Boolean(warnings.length) && Boolean(errors.length) && (
        <Space height={1} />
      )}
      {Boolean(warnings.length) && (
        <Grid align="start" direction="row">
          <StyledWarningAccordion defaultExpanded square elevation={0}>
            <StyledWarningAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Text weight="bold" textcolor={theme.color.warning}>
                {t('warnings')}
              </Text>
            </StyledWarningAccordionSummary>
            <StyledAccordionDetails>
              <ul>
                {warnings.map((el, i) => (
                  <li key={el + i.toString()}>
                    <Text
                      key={el}
                      weight="bold"
                      textcolor={theme.color.warning}
                    >
                      {el}
                    </Text>
                  </li>
                ))}
              </ul>
            </StyledAccordionDetails>
          </StyledWarningAccordion>
        </Grid>
      )}
      {Boolean(protocolProgress.length) && (
        <>
          <Space height={1} />
          <Text weight="bold">{t('models')}:</Text>
          <div style={{ flex: 1 }}>
            <DataTable
              selectionMode="single"
              onSelectionChange={handleSelectionChange}
              value={protocolProgress}
              showGridlines
              scrollable
              resizableColumns
              selection={selectedRow}
              scrollHeight="100%"
            >
              <Column field="protocol.altId" header={t('id')} />
              <Column
                field="protocol.protocolHeader.description"
                header={t('name')}
              />
              <Column
                field="protocol.classification.cropType.name"
                header={t('crop')}
              />
              <Column
                field="protocol.classification.cropCategory"
                body={CropCategoryBody}
                header={t('category')}
              />
              <Column
                field="protocol.classification.cropVariety"
                header={t('variety')}
                body={CropVarietyBody}
              />
              <Column field="status" header={t('reviewStatus')} />
              <Column
                field="protocol.importWarnings"
                header={t('warning')}
                body={CropWarningBody}
              />
            </DataTable>
          </div>
        </>
      )}
    </StepperContainer>
  );
};

export default ValidationPage;
