import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, DataTable } from 'components/shared/DataTable/DataTable';
import { DataTable as PrimeReactDataTable } from 'primereact/datatable';
import { CoefficientColumn } from 'models/protocol';

interface ICoefficientTable {
  modelGrowingTypes: CoefficientColumn[];
  selectedGrowingTypes: CoefficientColumn[];
}

const dataTableStyle = {
  flex: 2,
};

const indexColumnStyle = {
  width: '8%',
};

const dateColumnStyle = {
  width: '21%',
};

const durationColumnStyle = {
  width: '15%',
};

const minAndMaxColumnStyle = {
  width: '20%',
};

const phenStageColumnStyle = {
  width: '25%',
};

const CoefficentTable: FC<ICoefficientTable> = (props) => {
  const { modelGrowingTypes, selectedGrowingTypes } = props;
  const { t } = useTranslation('crop_models');
  const dataTable = useRef<PrimeReactDataTable | null>(null);

  useEffect(() => {
    if (dataTable.current) {
      document
        .getElementById('CoefficentTable')
        ?.getElementsByClassName('p-highlight')?.[0]
        ?.scrollIntoView(false);
    }
  }, [selectedGrowingTypes]);

  return (
    <DataTable
      ref={dataTable}
      id="CoefficentTable"
      showGridlines
      resizableColumns
      value={modelGrowingTypes}
      scrollable
      selection={selectedGrowingTypes}
      selectionMode="multiple"
      dataKey="index"
      style={dataTableStyle}
    >
      <Column field="index" header="#" style={indexColumnStyle} />
      <Column field="date" header={t('start_date')} style={dateColumnStyle} />
      <Column
        field="duration"
        header={t('duration')}
        style={durationColumnStyle}
      />
      <Column field="min" header={t('min')} style={minAndMaxColumnStyle} />
      <Column field="max" header={t('max')} style={minAndMaxColumnStyle} />
      <Column
        field="phenStage"
        header={t('phen_stage_sync')}
        style={phenStageColumnStyle}
      />
    </DataTable>
  );
};

export default observer(CoefficentTable);
