import React from 'react';
import styled from 'styled-components';
import Text from 'components/UI/Text';
import { Tooltip } from '@mui/material';
import i18next from 'i18next';
import { ProtocolProgress } from 'models/protocol';

const StyledTextVarietyText = styled(Text)`
  width: 100%;
`;

const CropVarietyBody = (props: ProtocolProgress) => {
  const { protocol } = props;
  const { classification } = protocol;
  const fullText =
    classification.cropVariety && classification.cropVariety.length > 0
      ? classification.cropVariety.toString().replaceAll(',', ', ')
      : i18next.t('crop_models:not_available');

  return (
    <Tooltip arrow title={fullText} placement="top">
      <StyledTextVarietyText inline singleline size="md">
        {fullText}
      </StyledTextVarietyText>
    </Tooltip>
  );
};

export default CropVarietyBody;
