import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { DialogOnCloseReason } from 'models/shared';
import AssignUsersContent from './AssignUsersContent';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 80%;
  }
`;

interface AssignUsersDialogProps {
  onHide(): void;
  visible: boolean;
  title: string;
}

const AssignUsersDialog: FC<AssignUsersDialogProps> = ({
  visible,
  onHide,
  title,
}) => {
  const { t } = useTranslation('users');
  const closeDialog = useCallback(
    (_event: unknown, reason: DialogOnCloseReason) => {
      if (reason !== DialogOnCloseReason.BackDropClick) {
        onHide();
      }

      onHide();
    },
    [onHide],
  );

  return (
    <StyledDialog
      open={visible}
      onClose={closeDialog}
      fullWidth
      maxWidth="lg"
      dialogTitle={title}
      titleWeight="bold"
      disableEscapeKeyDown
    >
      <DialogContent>
        <AssignUsersContent />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onHide}>
          <Text textcolor={theme.color.white} size="sm">
            {t('close')}
          </Text>
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default AssignUsersDialog;
