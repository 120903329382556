import { Plot } from './plot';

export enum AlertState {
  Cleared = 'CLEARED',
  Active = 'ACTIVE',
}

export enum AlertSeverity {
  Medium = 'MEDIUM',
  High = 'HIGH',
  Low = 'LOW',
}

export enum AlertTypeValue {
  MaxZeroValue = 1,
  MaxEmptyValue = 2,
  MaxConstValue = 3,
  MaxRangeValue = 46,
  MinBatteryValue = 47,
  OfflineSystem = 52,
  UnstableSystem = 53,
  WPSAnomaly = 54,
}

export type Alert = {
  __typename?: 'Alert';
  id: string;
  severity: AlertSeverity;
  state: AlertState;
  ignore: boolean;
  type: AlertType;
  subtype: string;
  timestamp: string;
  timestampUtc?: string;
  clearedAt?: string;
  plot?: Plot | null;
  params?: AlertParam[];
};

export type AlertType = {
  __typename?: 'AlertType';
  id: string;
  type: string;
  description: string;
  probableCause: string;
  causeAlt: string;
  expirationInterval: string;
  iconCode: number;
  filterCategory: string;
};

export interface AlertParam {
  label: string;
  type: string;
  value: string;
}

export enum AlertVariant {
  Technical = 'Technical',
  Agronomic = 'Agronomic',
}
