import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import i18next from 'i18next';
import Map from 'components/shared/Map/Map';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LatLngExpression } from 'leaflet';
import { defaultMapOptions } from 'core/configs/mapConfig';
import useAgronomicAlertsMapEntities from 'hooks/map/useAgronomicAlertsMapEntities';
import useTechnicalAlertsMapEntities from 'hooks/map/useTechnicalAlertsMapEntities';
import useMapMoveToPlotLocation from 'hooks/map/useMapMoveToPlotLocation';
import { GqlPlotAlerts } from 'gql/types/plots';
import Grid from 'components/UI/Grid';
import { MapRef } from 'components/shared/Map/Map.types';
import useMapInvalidateSize from 'hooks/map/useMapInvalidateSize';
import { SensorGroup } from 'models/sensor';
import { AlertVariant } from 'models/alert';
import WeatherIcon from 'assets/images/icons/weather.svg';
import AlertIcon from 'assets/images/icons/technical_alert.svg';
import SoilIcon from 'assets/images/icons/soil.svg';
import PlantIcon from 'assets/images/icons/plant.svg';
import IrrigationIcon from 'assets/images/icons/irrigation.svg';
import LiveMonitoringMapFilter, {
  LiveMonitoringMapFilterOption,
} from './LiveMonitoringMapFilter';

const MapContainer = styled(Grid)`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

enum FilterValue {
  Plant = SensorGroup.Plant,
  Soil = SensorGroup.Soil,
  Irrigation = SensorGroup.Irrigation,
  Weather = SensorGroup.Weather,
  Technical = 'Technical',
}

interface LiveMonitoringAlertsMapProps {
  alertType: AlertVariant;
  agronomicAlerts: GqlPlotAlerts;
  technicalAlerts: GqlPlotAlerts;
}

type FilterOptionType = Omit<
  LiveMonitoringMapFilterOption,
  'description' | 'errorsNumber'
>;

const technicalFilterOptions: FilterOptionType[] = [
  {
    label: i18next.t('liveMonitoring:technical'),
    value: FilterValue.Technical,
    icon: AlertIcon,
  },
];

const agronomicFilterOptions: FilterOptionType[] = [
  {
    label: i18next.t('liveMonitoring:plant'),
    value: FilterValue.Plant,
    icon: PlantIcon,
  },
  {
    label: i18next.t('liveMonitoring:soil'),
    value: FilterValue.Soil,
    icon: SoilIcon,
  },
  {
    label: i18next.t('liveMonitoring:irrigation'),
    value: FilterValue.Irrigation,
    icon: IrrigationIcon,
  },
  {
    label: i18next.t('liveMonitoring:weather'),
    value: FilterValue.Weather,
    icon: WeatherIcon,
  },
];

const LiveMonitoringAlertsMap: FC<LiveMonitoringAlertsMapProps> = ({
  alertType,
  agronomicAlerts,
  technicalAlerts,
}) => {
  const { t } = useTranslation('liveMonitoring');
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);
  const [activeFilter, setActiveFilter] = useState<FilterValue>(
    FilterValue.Plant,
  );

  const { filterMapEntities } = useAgronomicAlertsMapEntities({
    alertsData: agronomicAlerts,
    showOnlyAlerts: true,
  });

  const technicalAlertsEntities = useTechnicalAlertsMapEntities({
    alertsData: technicalAlerts,
    showOnlyAlerts: true,
  });

  const onFilterChange = useCallback((value: string) => {
    setActiveFilter(value as FilterValue);
  }, []);

  const activeMapEntities = useMemo(
    () =>
      activeFilter === FilterValue.Technical
        ? technicalAlertsEntities
        : filterMapEntities[activeFilter as unknown as SensorGroup],
    [activeFilter, filterMapEntities, technicalAlertsEntities],
  );

  useMapInvalidateSize({ mapContainerRef, mapRef });
  useMapMoveToPlotLocation({
    mapRef,
    location: activeMapEntities.mapDetails.plotsLocation as LatLngExpression,
  });

  const filterOptions: LiveMonitoringMapFilterOption[] = useMemo(() => {
    const alertsFilterOptions =
      alertType === AlertVariant.Agronomic
        ? agronomicFilterOptions
        : technicalFilterOptions;

    return alertsFilterOptions.map((option) => {
      const details =
        option.value === FilterValue.Technical
          ? technicalAlertsEntities.mapDetails
          : filterMapEntities[option.value as SensorGroup].mapDetails;

      return {
        ...option,
        description: t('number_plots_ok', {
          number: details.plotsCount - details.alertsCount,
        }),
        errorsNumber: details.alertsCount,
      };
    });
  }, [filterMapEntities, technicalAlertsEntities.mapDetails, alertType, t]);

  const setFilterWithAlerts = useCallback(() => {
    const agronomicFilterWithAlerts = Object.entries(filterMapEntities).find(
      ([, { mapDetails }]) => mapDetails.alertsCount > 0,
    );

    if (agronomicFilterWithAlerts) {
      const [filterName] = agronomicFilterWithAlerts;
      setActiveFilter(filterName as FilterValue);
    } else if (technicalAlertsEntities.mapDetails.alertsCount > 0) {
      setActiveFilter(FilterValue.Technical);
    } else {
      setActiveFilter(
        alertType === AlertVariant.Agronomic
          ? FilterValue.Plant
          : FilterValue.Technical,
      );
    }
  }, [
    alertType,
    filterMapEntities,
    technicalAlertsEntities.mapDetails.alertsCount,
  ]);

  useEffect(() => {
    setFilterWithAlerts();
  }, [setFilterWithAlerts]);

  return (
    <MapContainer direction="row" ref={mapContainerRef}>
      <LiveMonitoringMapFilter
        title={
          alertType === AlertVariant.Agronomic ? t('filter_by_category') : ''
        }
        options={filterOptions}
        activeFilter={activeFilter}
        onChange={onFilterChange}
      />
      <Map
        ref={mapRef}
        mapEntities={activeMapEntities.mapEntities}
        {...defaultMapOptions}
      />
    </MapContainer>
  );
};

export default LiveMonitoringAlertsMap;
