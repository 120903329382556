import React from 'react';
import L, { LatLngExpression, PathOptions, PointExpression } from 'leaflet';
import { theme } from 'components/UI';
import {
  CustomMarkerProps,
  MarkerEntityProps,
} from 'components/shared/Map/MapEntity/MapEntity.types';
import alertIcon from 'assets/images/icons/alert_red_filled.svg';
import sensorIcon from 'assets/images/icons/sensor_circle.svg';
import { Sensor } from 'models/sensor';
import { GqlPlotAlertData } from 'gql/types/plots';
import AlertTooltip from './AlertTooltip';

const markerAnchor: PointExpression = [12, 41];

export const pathOptions: PathOptions = {
  color: theme.color.white,
  fillColor: theme.color.success,
  weight: 2,
  opacity: 1,
  fillOpacity: 0.5,
};

export const errorPathOptions: PathOptions = {
  ...pathOptions,
  fillColor: theme.color.red,
};

const defaultHoverPathOptions: PathOptions = { weight: 3 };
export const hoverPathOptions: PathOptions = {
  ...defaultHoverPathOptions,
  ...pathOptions,
};

export const errorHoverPathOptions: PathOptions = {
  ...defaultHoverPathOptions,
  ...errorPathOptions,
};

export const getPlotDefaultMarker = (
  plot: GqlPlotAlertData,
  iconUrl: string,
): MarkerEntityProps => {
  const position = (
    plot.location ? [plot.location.latitude, plot.location.longitude] : null
  ) as LatLngExpression;

  return {
    icon: new L.Icon({
      iconUrl,
      iconAnchor: markerAnchor,
    }),
    position,
    tooltipContent: plot.name,
  };
};

export const getAlertMarker = (plot: GqlPlotAlertData): CustomMarkerProps => {
  const { latitude, longitude } = plot.location;
  return {
    key: plot.id.toString(),
    icon: alertIcon,
    tooltipContent: <AlertTooltip plot={plot} />,
    position: [latitude, longitude],
    alignCenter: true,
    iconSize: 24,
  };
};

export const getSensorMarker = (sensor: Sensor): CustomMarkerProps => ({
  key: sensor.id.toString(),
  icon: sensorIcon,
  tooltipContent: sensor.displayName,
  position: [sensor.latitude, sensor.longitude],
});

export const getPlotPolygonCoordinates = (
  plot: GqlPlotAlertData,
): [number, number, number][] => {
  return plot?.polygon?.coordinates?.[0] ?? [];
};

export const getPlotAlertsLocation = (
  plots: GqlPlotAlertData[],
): LatLngExpression | null => {
  const alertsLocation = plots.find(
    (plot) => !!plot.alerts?.length && !!plot.location,
  )?.location;

  if (alertsLocation) {
    return [alertsLocation.latitude, alertsLocation.longitude];
  }

  const plotsLocation = plots.find((plot) => !!plot.location)?.location;
  return plotsLocation
    ? [plotsLocation.latitude, plotsLocation.longitude]
    : null;
};
