import { observer } from 'mobx-react';
import React, { FC } from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartsToolTip,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts';
import { theme } from 'components/UI';
import { format } from 'date-fns';
import { PhenStageColumn } from 'models/protocol';

interface ICoefficientGraph {
  graphData: {
    value: number;
    name: number;
  }[];
  selectedPhenStageRow: PhenStageColumn;
}

const dotValidations = {
  stroke: '',
  strokeWidth: 1,
  r: 3,
  strokeDasharray: '',
};

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, number>) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Value : ${payload?.[0].value}`}</p>
        <p className="desc">{`Date : ${format(label as Date, 'dd-MMM-yy')}`}</p>
      </div>
    );
  }

  return null;
};

const CoefficientGraph: FC<ICoefficientGraph> = (props) => {
  const { graphData, selectedPhenStageRow } = props;

  return (
    <ResponsiveContainer height="100%" width="100%">
      <LineChart
        data={graphData}
        margin={{
          top: 10,
          right: 50,
          bottom: 55,
        }}
      >
        <Line
          type="linear"
          dataKey="value"
          stroke={theme.palette.primary.main}
          strokeWidth="3px"
          dot={dotValidations}
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
        <XAxis
          interval={0}
          angle={90}
          dataKey="name"
          textAnchor="start"
          domain={['auto', 'auto']}
          tickFormatter={(unixTime) => format(unixTime as Date, 'dd-MMM-yy')}
          type="number"
          allowDuplicatedCategory={false}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <ReferenceArea
          fill="var(--green-100)"
          x1={new Date(selectedPhenStageRow.date).getTime()}
          x2={new Date(selectedPhenStageRow.dateEnd).getTime()}
          alwaysShow
        />
        <RechartsToolTip />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default observer(CoefficientGraph);
