import React, { FC, useCallback, useState } from 'react';
import {
  MuiTelInput,
  MuiTelInputProps,
  MuiTelInputInfo,
  matchIsValidTel,
} from 'mui-tel-input';
import styled from 'styled-components';

const defaultPreferredCountries: MuiTelInputProps['preferredCountries'] = [
  'IL',
  'MX',
  'AU',
  'MA',
  'PE',
  'NZ',
  'US',
  'AR',
  'CA',
  'FR',
  'ZA',
  'IN',
  'BR',
  'UY',
  'PY',
  'AE',
];

const defaultMenuProps: MuiTelInputProps['MenuProps'] = {
  MenuListProps: { style: { height: '300px', width: '415px' } },
};

const StyledMuiTelInput = styled(MuiTelInput)`
  .MuiOutlinedInput-root {
    padding-left: 8px;
  }
`;

export const PhoneInput: FC<MuiTelInputProps> = ({
  preferredCountries,
  onChange,
  MenuProps,
  error,
  ...rest
}) => {
  const [isInputValid, setIsInputValid] = useState(true);

  const handleChange = useCallback(
    (value: string, info: MuiTelInputInfo) => {
      onChange?.(value.replaceAll(' ', ''), info);
      setIsInputValid(matchIsValidTel(value));
    },
    [onChange],
  );

  return (
    <StyledMuiTelInput
      {...rest}
      error={!isInputValid || error}
      onChange={handleChange}
      preferredCountries={preferredCountries ?? defaultPreferredCountries}
      MenuProps={MenuProps ?? defaultMenuProps}
    />
  );
};
