import Grid from 'components/UI/Grid';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Sensor } from 'models/sensor';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useStores } from 'stores/hooks/hooks';
import {
  HALF_SCREEN_TABLE_BOTTOM_HEIGHT,
  HALF_SCREEN_TABLE_TOP_HEIGHT,
} from 'core/constants';
import { Paper } from '@mui/material';
import { Column } from 'components/shared/DataTable/DataTable';
import MultiselectDataTable, {
  MultiselectDataTableChangeEvent,
} from 'components/shared/MultiselectDataTable';
import { useTranslation } from 'react-i18next';
import { TableMultiSelection } from 'stores/types/types';
import { DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { FeatureCreateFormStepProps } from '../FeatureCreateFormProperties';

const DataTableContainer = styled(Paper)`
  margin: 1rem;
  overflow: hidden;
  max-height: ${HALF_SCREEN_TABLE_TOP_HEIGHT};
`;

const FeaturesDiscoveryCreateStep: FC<FeatureCreateFormStepProps> = observer(
  () => {
    const { t } = useTranslation('feature');
    const { featuresStore } = useStores();
    const [selectedRows, setSelectedRows] = useState<
      TableMultiSelection<Sensor>
    >({ selection: featuresStore.selectedSensors || [], activeRow: null });

    const tableFilters: DataTableFilterMeta = {
      'sensorType.name': {
        value: '',
        matchMode: FilterMatchMode.CONTAINS,
      },
      displayName: { value: '', matchMode: FilterMatchMode.CONTAINS },
      serial: { value: '', matchMode: FilterMatchMode.CONTAINS },
      latitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
      longitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
      'sensorType.group': { value: '', matchMode: FilterMatchMode.CONTAINS },
      type: { value: '', matchMode: FilterMatchMode.CONTAINS },
    };

    useEffect(() => {
      featuresStore.getSensors();
    }, [featuresStore]);

    const tableRows = useMemo(
      () => featuresStore.sensors,
      [featuresStore.sensors],
    );

    const onMultiselectChange = useCallback(
      ({ activeRow, selection }: MultiselectDataTableChangeEvent<Sensor>) => {
        setSelectedRows({ activeRow, selection });
        featuresStore.setSelectedSensors(selection);
      },
      [featuresStore],
    );

    return (
      <Grid flex={1}>
        <DataTableContainer elevation={3}>
          <MultiselectDataTable
            id="FeatureSensorsTable"
            showGridlines
            resizableColumns
            value={tableRows}
            scrollable
            scrollHeight={HALF_SCREEN_TABLE_BOTTOM_HEIGHT}
            dataKey="id"
            emptyMessage={t('empty_message')}
            stripedRows
            filters={tableFilters}
            filterDisplay="row"
            selection={selectedRows.selection}
            activeRow={selectedRows.activeRow}
            onMultiselectChange={onMultiselectChange}
          >
            <Column
              field="sensorType.name"
              header={t('sensor')}
              sortable
              filter
            />
            <Column field="displayName" header={t('name')} sortable filter />
            <Column field="serial" header={t('serial')} sortable filter />
            <Column field="latitude" header={t('latitude')} sortable filter />
            <Column field="longitude" header={t('longitude')} sortable filter />
            <Column
              field="sensorType.group"
              header={t('group')}
              sortable
              filter
            />
            <Column field="type" header={t('type')} sortable filter />
          </MultiselectDataTable>
        </DataTableContainer>
      </Grid>
    );
  },
);

export default FeaturesDiscoveryCreateStep;
