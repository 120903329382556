import React, { FC, SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { observer } from 'mobx-react';
import { PlotStatus } from 'models/plot';

const PlotStatusToggle: FC = observer(() => {
  const { t } = useTranslation('plot');
  const { resellersStore } = useStores();
  const { updatePlotStatus, selectedRowsPlots } = resellersStore;
  const selectedPlot = selectedRowsPlots[0];

  const handleStatusChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
      updatePlotStatus(checked ? PlotStatus.Active : PlotStatus.Draft);
    },
    [updatePlotStatus],
  );

  return (
    <FormControlLabel
      control={<Switch />}
      label={t('activate_plot')}
      labelPlacement="start"
      checked={selectedPlot.status === PlotStatus.Active}
      onChange={handleStatusChange}
    />
  );
});

export default PlotStatusToggle;
