import React, { FC, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from 'components/UI/Grid';
import Loading from 'components/UI/Loading/Loading';
import { VisibilityControl } from 'components/shared/VisibilityControl';
import { observer } from 'mobx-react';
import { SystemDefaultSchema, SystemVwsSchema } from 'models/systems';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import styled from 'styled-components';
import { SystemsCreateFormStepProps } from '../SystemsCreateFormProperties';
import useDynamicFields from './hooks/useDynamicFields';
import useVwsDynamicFields from './hooks/useVwsDynamicFields';

const Container = styled(Grid)`
  padding-top: 1rem;
`;

const Column = styled(Grid)`
  min-width: 220px;
  padding: 1rem 0;
`;

const ScrollableColumn = styled(Grid)`
  min-width: 220px;
  max-height: 33.5vh;
  overflow-y: auto;
  padding: 1rem;
`;

const SystemsConfigurationCreateStep: FC<SystemsCreateFormStepProps> = observer(
  ({ register, errors }) => {
    const { t } = useTranslation('system');
    const {
      systemsStore: { createSystemForm },
    } = useStores();

    const { configurationSchema } = createSystemForm;
    const dynamicFields = createSystemForm.isVws
      ? useVwsDynamicFields({
          schema: configurationSchema.data?.schema as SystemVwsSchema,
          defaultValues: configurationSchema.values,
          disabled: createSystemForm.isSystemCreated,
          showHidden: true,
        })
      : useDynamicFields({
          schema: configurationSchema.data?.schema as SystemDefaultSchema,
          defaultValues: configurationSchema.values,
          disabled: createSystemForm.isSystemCreated,
          showHidden: true,
        });

    useEffect(() => {
      configurationSchema.isValid = dynamicFields.isValid;
      configurationSchema.values = dynamicFields.values;
    }, [dynamicFields.isValid, dynamicFields.values, configurationSchema]);

    const { baseFields, paramsFields } = dynamicFields.fields;

    if (configurationSchema.loading) {
      return <Loading />;
    }

    return (
      <Container direction="row" justify="center" align="start" gap="2rem">
        <Column direction="column" justify="start" gap="1rem">
          <TextField
            {...register('displayName')}
            label={t('name')}
            error={!!errors.displayName}
            helperText={errors.displayName?.message}
          />
          <VisibilityControl visible={!createSystemForm.isWeather}>
            <TextField
              {...register('serial')}
              disabled={createSystemForm.isSystemCreated}
              label={t('serial')}
              error={!!errors.serial}
              helperText={errors.serial?.message}
            />
          </VisibilityControl>
        </Column>
        {!!baseFields.length && (
          <ScrollableColumn direction="column" justify="start" gap="1rem">
            {baseFields}
          </ScrollableColumn>
        )}
        {!!paramsFields?.length && (
          <ScrollableColumn direction="column" justify="start" gap="1rem">
            {paramsFields}
          </ScrollableColumn>
        )}
      </Container>
    );
  },
);

export default SystemsConfigurationCreateStep;
