import React, { FC } from 'react';
import styled from 'styled-components';
import Grow from 'components/UI/Grow';
import ForgotPasswordInput from 'components/pages/ForgotPassword/ForgotPasswordInput';
import AuthHeader from 'components/pages/Login/AuthHeader';

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

const ForgotPassword: FC = () => {
  return (
    <Page>
      <ContentContainer>
        <Grow />
        <ForgotPasswordContainer>
          <AuthHeader />
          <ForgotPasswordInput />
        </ForgotPasswordContainer>
      </ContentContainer>
    </Page>
  );
};

export default ForgotPassword;
