import Grid from 'components/UI/Grid';
import Loading from 'components/UI/Loading/Loading';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { EditableSensor } from 'models/sensor';
import { GrowerSystem } from 'models/grower';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useStores } from 'stores/hooks/hooks';
import { SystemsCreateFormStepProps } from '../SystemsCreateFormProperties';
import {
  SensorsEditableRowOptions,
  SensorsEditableTable,
} from '../../SensorsEditableTable';
import { getSensorPlotOptions } from '../../Systems.utils';

const StyledSensorsEditableTable = styled(SensorsEditableTable)`
  margin-top: 0.5rem;
`;

const SystemsDiscoveryCreateStep: FC<SystemsCreateFormStepProps> = observer(
  () => {
    const { systemsStore, resellersStore } = useStores();
    const {
      createSystemForm: { newSystemSensors },
    } = systemsStore;

    const system = systemsStore.createSystemForm.newSystemData
      .data as GrowerSystem;

    const editSensorOptions: SensorsEditableRowOptions = useMemo(
      () => ({
        plot: getSensorPlotOptions(resellersStore.resellerPlots),
      }),
      [resellersStore.resellerPlots],
    );

    const onEditSensorsChange = useCallback(
      (sensors: EditableSensor[]) => {
        systemsStore.setEditedSensors(sensors);
      },
      [systemsStore],
    );

    useEffect(() => {
      systemsStore.getSensorTypes();
    }, [systemsStore]);

    const tableSensors = useMemo(
      () =>
        Object.values(
          systemsStore.createSystemForm.sensorPayloadRecord ?? {},
        ).map(({ payload }) => payload),
      [systemsStore.createSystemForm.sensorPayloadRecord],
    );

    return (
      <Grid direction="column" align="stretch">
        {newSystemSensors.loading ? (
          <Grid direction="row" justify="center">
            <Loading />
          </Grid>
        ) : (
          <StyledSensorsEditableTable
            system={system}
            sensors={tableSensors}
            sensorTypes={systemsStore.sensorTypes.data}
            options={editSensorOptions}
            onChange={onEditSensorsChange}
            tableHeight="33.5vh"
            showActiveColumn
          />
        )}
      </Grid>
    );
  },
);

export default SystemsDiscoveryCreateStep;
