import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  FormLabel,
} from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Space from 'components/UI/Space';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { theme } from 'components/UI';
import Input from 'components/UI/Input';
import Grow from 'components/UI/Grow';
import Button from 'components/UI/Button';
import BoxContainer from 'components/UI/BoxContainer';
import { ProtocolProgress } from 'models/protocol';
import MapDialog from '../MapDialog';

const StepperContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.color.white};
  margin: 1rem;
  padding: 1rem;
  overflow: hidden;
  align-self: stretch;
  overflow: auto;
  flex: 1;
`;

const VarietyInputStyle = styled(Input)`
  &.VarietyInputStyle-dMsAUV {
    height: 100%;

    div {
      height: 100%;
    }
  }
`;

const GrowerIDInputStyle = styled(Input)`
  width: 98%;
`;

const VersionDraft = styled(FormLabel)`
  top: 25%;
`;

interface IStepper {
  selectedRow: ProtocolProgress;
}

const ParametersPage: FC<IStepper> = ({ selectedRow }) => {
  const { t } = useTranslation('crop_models');
  const coordinates =
    selectedRow.protocol.classification.region.coordinates?.map((el) => ({
      lng: el.y,
      lat: el.x,
    })) || [];

  const [mapDialogOpen, setMapDialogOpen] = useState(false);

  const [regionRadioSelection, setRegionRadioSelection] = useState<string>(
    selectedRow.protocol.classification.region.coordinates
      ? t('polygon')
      : t('stripe'),
  );

  const handleRegionRadioChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRegionRadioSelection(event.target.value);
    },
    [setRegionRadioSelection],
  );

  const cropVariety = useMemo(
    () =>
      selectedRow.protocol.classification.cropVariety &&
      selectedRow.protocol.classification.cropVariety.length > 0
        ? selectedRow.protocol.classification.cropVariety
            .toString()
            .replaceAll(',', ', ')
        : t('not_available'),
    [selectedRow, t],
  );

  const cropCategory = useMemo(
    () =>
      selectedRow.protocol.classification.cropCategory
        ? selectedRow.protocol.classification.cropCategory
            .toString()
            .replaceAll(',', ', ')
        : t('not_available'),
    [selectedRow, t],
  );

  const description = useMemo(
    () => selectedRow.protocol.protocolHeader.description.toString(),
    [selectedRow],
  );

  const title = useMemo(
    () =>
      `${t('model')}:${selectedRow.protocol.altId} | ${
        selectedRow.protocol.classification.cropType?.name ||
        selectedRow.protocol.classification.cropId
      } | ${description}`,
    [t, selectedRow, description],
  );

  const showPolygonButton = !(
    selectedRow.protocol.classification.region.minLatitude &&
    selectedRow.protocol.classification.region.maxLatitude
  );

  const handleMapDialogClose = useCallback(() => {
    setMapDialogOpen(false);
  }, [setMapDialogOpen]);

  const handleMapDialogOpen = useCallback(() => {
    setMapDialogOpen(true);
  }, [setMapDialogOpen]);

  return (
    <>
      <MapDialog
        coordinates={coordinates}
        visible={mapDialogOpen}
        onHide={handleMapDialogClose}
        showClose={false}
        small
      />
      <StepperContainer elevation={0}>
        <Grid direction="row" justify="between" gap="1rem" align="stretch">
          <Text weight="bold" color="primary" align="center">
            {title}
          </Text>
          <Grid direction="row" gap="1rem" align="stretch" justify="between">
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  checked
                  color="primary"
                  name={t('ready_for_matching')}
                />
              }
              label={t('ready_for_matching')}
            />
            <VersionDraft color="primary">
              {t('version_draft', { version: 1.23 })}
            </VersionDraft>
          </Grid>
        </Grid>
        <Space height={1} />
        <Grid direction="column" gap="1rem" align="stretch">
          <Grid direction="row" align="stretch">
            <Grid direction="column" align="stretch">
              <GrowerIDInputStyle
                label={`${t('grower')} ${t('id')}`}
                value={selectedRow.protocol.protocolHeader.growerId}
                variant="outlined"
                disabled
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid direction="row" align="stretch" gap="1rem">
            <Grid direction="column" align="stretch" gap="1rem" flex={1}>
              <Input
                label={t('crop')}
                value={
                  selectedRow.protocol.classification.cropType?.name ||
                  selectedRow.protocol.classification.cropId
                }
                variant="outlined"
                disabled
                fullWidth
              />
              <Input
                label={t('category')}
                value={cropCategory}
                variant="outlined"
                disabled
                multiline
                fullWidth
              />
            </Grid>
            <Grid direction="column" align="stretch" gap="1rem" flex={2}>
              <VarietyInputStyle
                label={t('variety')}
                value={cropVariety}
                variant="outlined"
                disabled
                multiline
                fullWidth
              />
            </Grid>
            <Grid direction="column" align="stretch" gap="1rem" flex={1}>
              <BoxContainer title={t('location')}>
                <Grid align="start">
                  <RadioGroup
                    row
                    aria-label="region"
                    name="region radio"
                    value={regionRadioSelection}
                    onChange={handleRegionRadioChange}
                  >
                    <FormControlLabel
                      value={t('stripe')}
                      disabled
                      control={<Radio color="primary" />}
                      label={t('stripe')}
                    />
                    <FormControlLabel
                      value={t('polygon')}
                      disabled
                      control={<Radio color="primary" />}
                      label={t('polygon')}
                    />
                  </RadioGroup>
                  <Grid gap="1rem" direction="row">
                    {showPolygonButton ? (
                      <Grid>
                        <Button
                          type="button"
                          variant="text"
                          onClick={handleMapDialogOpen}
                        >
                          <Text textcolor={theme.color.link} size="md">
                            {t('polygon')}
                          </Text>
                        </Button>
                      </Grid>
                    ) : (
                      <>
                        <Input
                          label="Lat Min"
                          value={
                            selectedRow.protocol.classification.region
                              .minLatitude
                          }
                          variant="outlined"
                          disabled
                        />
                        <Input
                          label="Lat Max"
                          value={
                            selectedRow.protocol.classification.region
                              .maxLatitude
                          }
                          variant="outlined"
                          disabled
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </BoxContainer>
            </Grid>
            <Grid direction="column" gap="1rem" flex={1}>
              <BoxContainer title={t('elevation')}>
                <Space height={1} />
                <Grid direction="row" gap="1rem">
                  <Input
                    label="Min"
                    value={
                      selectedRow.protocol.classification.region.elevation
                        .startingPoint
                    }
                    variant="outlined"
                    disabled
                  />
                  <Input
                    label="Max"
                    value={
                      selectedRow.protocol.classification.region.elevation
                        .endPoint
                    }
                    variant="outlined"
                    disabled
                  />
                </Grid>
              </BoxContainer>
            </Grid>
          </Grid>
          <Grid direction="row" justify="end" gap="1rem">
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  checked={selectedRow.protocol.midWinter}
                  color="primary"
                  name={t('mid_winter')}
                />
              }
              label={t('mid_winter')}
            />
          </Grid>
          <Input label={t('model_comments')} multiline disabled />
          <Input
            label={t('notes_for_version', { version: 1.23 })}
            multiline
            disabled
          />
          <Grow />
        </Grid>
      </StepperContainer>
    </>
  );
};

export default ParametersPage;
