import i18next from 'i18next';
import { DropdownProps } from 'primereact/dropdown';
import { AlertState, AlertTypeValue } from 'models/alert';
import { SensorGroup } from 'models/sensor';
import { SystemVariant } from 'models/systems';
import { GrowerType } from 'models/grower';
import { MeasurementSystem, UserRole } from 'models/user';
import { Locales } from './enums';

export const HIGHLIGHTED_ROW_CLASS_NAME = 'data-table-row-highlighted';
export const TREE_NODE_INACTIVE_CLASS_NAME = 'tree-node-inactive';
export const TREE_NODE_HIDDEN_CLASS_NAME = 'tree-node-hidden';

export const USER_STORAGE_KEY = '@USER_STORAGE_KEY';
export const USER_WELCOME_EMAIL = process.env.REACT_APP_WELCOME_EMAIL;

export const LANGUAGES = [
  { id: Locales.English, lang: 'English', value: 'ENGLISH' },
  { id: Locales.Hebrew, lang: 'Hebrew', value: 'HEBREW' },
  { id: Locales.Spanish, lang: 'Spanish', value: 'SPANISH' },
  { id: Locales.French, lang: 'Français', value: 'FRENCH' },
  { id: Locales.Arabic, lang: 'العربية', value: 'ARABIC' },
];

export const UNITS = [
  { id: 0, unit: 'METRIC' },
  { id: 1, unit: 'IMPERIAL' },
];

export const USER_STATUSES = [
  { id: 0, name: 'Active' },
  { id: 1, name: 'Inactive' },
];

export const AREA_UNITS = [
  { id: 'HECTARE', name: 'Hectare', shortName: 'ha' },
  { id: 'DUNAM', name: 'Dunam', shortName: 'du' },
  { id: 'ACRE', name: 'Acre', shortName: 'ac' },
  { id: 'MU', name: 'MU', shortName: 'mu' },
];

export const IRRIGATION_METHODS = [
  { value: 'FLOOD', label: 'Flood' },
  { value: 'FURROW', label: 'Furrow' },
  { value: 'SPRINKLER', label: 'Sprinkler' },
  { value: 'PIVOT', label: 'Pivot' },
  { value: 'MICRO_SPRINKLER', label: 'Micro sprinkler' },
  { value: 'DRIP', label: 'Drip' },
];

export const USER_ROLES = [
  { value: UserRole.User, label: 'User' },
  { value: UserRole.Admin, label: 'Admin' },
  { value: UserRole.GbiAdmin, label: 'GBI Admin' },
];

export const GROWER_TYPES = [
  { value: GrowerType.Grower, label: 'Grower' },
  { value: GrowerType.Reseller, label: 'Reseller' },
];

export const WEATHER_SYSTEMS_LIST = [
  SystemVariant.IW,
  SystemVariant.VWS_ACCUWEATHER,
  SystemVariant.VWS_CLIMACELL,
  SystemVariant.ICCPRO,
];

export const VWS_SYSTEMS_LIST = [
  SystemVariant.VWS_ACCUWEATHER,
  SystemVariant.VWS_CLIMACELL,
];

export const DEFAULT_ALERT_FILTER_CATEGORY_LIST = [
  SensorGroup.Irrigation,
  SensorGroup.Plant,
  SensorGroup.Soil,
  SensorGroup.Weather,
];

export const DEFAULT_ALERT_FILTER_CATEGORY_TECHNICAL_LIST = [
  SensorGroup.Technical,
];

export const DEFAULT_ALERT_TYPE_LIST = [
  AlertTypeValue.MaxZeroValue,
  AlertTypeValue.MaxEmptyValue,
  AlertTypeValue.MaxConstValue,
  AlertTypeValue.MaxRangeValue,
  AlertTypeValue.MinBatteryValue,
  AlertTypeValue.OfflineSystem,
  AlertTypeValue.UnstableSystem,
  AlertTypeValue.WPSAnomaly,
];

export const DEFAULT_ALERT_STATE_LIST = [AlertState.Active, AlertState.Cleared];
export const DEFAULT_USER_PROFILE_ID = 4; // SNS Rest of The  World (RoW) user profile ID
export const DEFAULT_MEASUREMENT_SYSTEM = MeasurementSystem.Metric;
export const DEFAULT_LANGUAGE = Locales.English;

// TODO: Remove hardcoded company uuids when it's possible
export const DEFAULT_COMPANY_OPTIONS: DropdownProps['options'] = [
  {
    label: i18next.t('system:sns'),
    value: process.env.REACT_APP_SNS_COMPANY_UUID,
  },
  {
    label: i18next.t('system:plant'),
    value: process.env.REACT_APP_PLANT_COMPANY_UUID,
  },
];

export const SCROLL_HEIGHT = '645px';
export const SCROLL_HEIGHT_FULL_SCREEN = '100vh';
export const HALF_SCREEN_TABLE_TOP_HEIGHT = '37vh';
export const HALF_SCREEN_TABLE_BOTTOM_HEIGHT = '33vh';

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

export const SENSOR_VALVE_TYPE_PREFIX = 'WPS_';

export const IS_APP_SYSTEMS_DISABLED =
  process.env.REACT_APP_SYSTEMS_DISABLED === 'true';
