import React, { FC, useCallback, useMemo, useState } from 'react';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import { useQuery } from '@apollo/client';
import { DEFAULT_ALERT_FILTER_CATEGORY_TECHNICAL_LIST } from 'core/constants';
import { DataTablePageParams } from 'primereact/datatable';
import { GqlPlotAlertsPaginated, GqlPlotSensorsSystems } from 'gql/types/plots';
import {
  GET_PLOTS_ALERTS_PAGINATED,
  GET_PLOTS_SENSORS_SYSTEMS,
} from 'gql/queries/plots';
import { AlertState, AlertVariant } from 'models/alert';
import { useStores } from 'stores/hooks/hooks';
import { TABLE_ALERTS_MAX_ROWS_NUMBER } from '../AlertsTable.utils';
import AlertsTable from '../AlertsTable';
import { useAlertsTableFilters } from '../hooks/useAlertsTableFilters';

const defaultLazyParams: DataTablePageParams = {
  first: 0,
  page: 0,
  rows: TABLE_ALERTS_MAX_ROWS_NUMBER,
};

const TechnicalAlertsTable: FC = observer(() => {
  const { resellersStore, snackBarStore } = useStores();
  const [lazyParams, setLazyParams] =
    useState<DataTablePageParams>(defaultLazyParams);

  const { alertFilters, debouncedFilters, onAlertFilterChange } =
    useAlertsTableFilters();

  const growerIds = resellersStore.selectedTreeNodeGrowerIds;
  const { data: plotAlerts, loading: isPlotAlertsLoading } =
    useQuery<GqlPlotAlertsPaginated>(GET_PLOTS_ALERTS_PAGINATED, {
      variables: {
        growerIds,
        alertState: [AlertState.Active],
        alertFilterCategory: DEFAULT_ALERT_FILTER_CATEGORY_TECHNICAL_LIST,
        alertPage: lazyParams.page,
        alertSize: lazyParams.rows,
        ...debouncedFilters,
      },
      fetchPolicy: 'no-cache',
      skip: !growerIds.length,
      onError() {
        snackBarStore.showToast({
          detail: i18next.t('errors:something_went_wrong'),
        });
      },
    });

  const { data: plotsSensorsSystems, loading: isPlotSensorsSystemsLoading } =
    useQuery<GqlPlotSensorsSystems>(GET_PLOTS_SENSORS_SYSTEMS, {
      variables: {
        growerIds,
      },
      fetchPolicy: 'no-cache',
      skip: !growerIds.length,
      onError() {
        snackBarStore.showToast({
          detail: i18next.t('errors:something_went_wrong'),
        });
      },
    });

  const onPageChange = useCallback(
    (params: DataTablePageParams) => {
      if (params.page !== lazyParams.page) {
        setLazyParams(params);
      }
    },
    [lazyParams],
  );

  const plotAlertsData = useMemo(() => {
    if (!plotAlerts || !plotsSensorsSystems) {
      return plotAlerts;
    }

    plotAlerts.pageAlerts.content.forEach((alert) => {
      plotsSensorsSystems?.plots.forEach((plot) => {
        const sensor = plot.sensors.find(
          (sensorItem) => Number(sensorItem.id) === Number(alert.sensorId),
        );

        if (sensor) {
          alert.sensorName = sensor.displayName;
          alert.sensorSerial = sensor.serial;
          alert.sensorType = sensor.sensorType.type;
          alert.systemSerial = sensor.systemSerial;
        }
      });
    });

    return plotAlerts;
  }, [plotAlerts, plotsSensorsSystems]);

  return (
    <AlertsTable
      alerts={plotAlertsData as GqlPlotAlertsPaginated}
      tableParams={lazyParams}
      loading={isPlotAlertsLoading || isPlotSensorsSystemsLoading}
      onPageChange={onPageChange}
      alertFilters={alertFilters}
      onAlertFilterChange={onAlertFilterChange}
      variant={AlertVariant.Technical}
    />
  );
});

export default TechnicalAlertsTable;
