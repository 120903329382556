import { Map } from 'leaflet';
import { ReactElement } from 'react';
import { MarkerProps, PolygonProps } from 'react-leaflet';
import { MapProps } from '../Map.types';

export enum MapEntityDisplayMode {
  Default = 'Default',
  Hover = 'Hover',
}

export interface MarkerEntityProps extends Omit<MarkerProps, 'title'> {
  tooltipContent: string | ReactElement;
}

export interface CustomMarkerProps extends Omit<MarkerEntityProps, 'icon'> {
  key: string;
  icon: string;
  iconSize?: number;
  alignCenter?: boolean;
}

export interface PolygonEntityProps extends PolygonProps {
  tooltipContent: string | ReactElement;
  hoverPathOptions: PolygonProps['pathOptions'];
  customMarkers?: CustomMarkerProps[];
}

export interface MapEntityProps {
  entityKey: string;
  polygon: PolygonEntityProps;
  marker: MarkerEntityProps;
}

export interface MapEntityComponentProps
  extends MapEntityProps,
    Required<Pick<MapProps, 'mapConfig'>> {
  map: Map;
  zoomLevel: number;
}

export interface LeafletPolygonType {
  getCenter(): { lat: number; lng: number } | null;
}

export type LeafletPolygonRefType = LeafletPolygonType | undefined;
