import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import Input, { Validation } from 'components/UI/Input/Input';
import { useTranslation } from 'react-i18next';
import { JSONSchema } from 'stores/types/types';
import {
  checkError,
  SettingsFormInput,
} from './PlotSettingsManagementDialog.utils';

const PlotSettingsFormInput: FC<SettingsFormInput> = (props) => {
  const { formProperty, parentKeys, value, handleChangeValue, formKey } = props;
  const { t } = useTranslation('settings_form');

  const [currentValue, setCurrentValue] = useState(
    value != null ? value : undefined,
  );

  const isError = checkError(
    currentValue as string | undefined,
    formProperty,
    t,
  );

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const checkIfError = checkError(event.target.value, formProperty, t);
    if (formProperty?.type === 'number') {
      setCurrentValue(event.target.value);
      handleChangeValue(
        [...(parentKeys || []), formKey],
        Number(event.target.value),
        checkIfError.isError,
      );
    }

    if (formProperty?.type === 'string') {
      setCurrentValue(event.target.value);
      handleChangeValue(
        [...(parentKeys || []), formKey],
        event.target.value,
        checkIfError.isError,
      );
    }
  };

  const inputValidation: Validation = useCallback(
    (text = '') => {
      const isValid = !isError.isError;
      const errorMessage = `${isError.message}\n${formProperty?.error || ''}`;

      return {
        isValid,
        message: isValid ? '' : errorMessage,
      };
    },
    [formProperty?.error, isError],
  );

  const handleSetDefault = useCallback(
    (property: JSONSchema) => {
      if (property?.default) {
        setCurrentValue(property.default);
        handleChangeValue(
          [...(parentKeys || []), formKey],
          property.default,
          false,
        );
      } else {
        setCurrentValue(false);
        // If there is no default value, we cant know what value to set for input (unlike checkbox , its false)
        handleChangeValue([...(parentKeys || []), formKey], '', false);
      }
    },
    [formKey, handleChangeValue, parentKeys],
  );

  useEffect(() => {
    if (value == null && formProperty && currentValue == null) {
      handleSetDefault(formProperty);
    }
  }, [currentValue, formProperty, handleSetDefault, value]);

  return (
    <Input
      validation={inputValidation}
      label={formProperty?.description}
      id="description"
      disabled={!formProperty?.display}
      value={currentValue}
      // defaultValue={value || formProperty?.default}
      variant="outlined"
      type="text"
      fullWidth
      onChange={handleChange}
    />
  );
};

export default PlotSettingsFormInput;
