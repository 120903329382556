import { useMemo } from 'react';
import { GqlPlotAlerts } from 'gql/types/plots';
import {
  FilterMapEntitiesType,
  getFilterMapEntities,
} from './useAgronomicAlertsMapEntities.utils';

interface UseAgronomicAlertsMapEntitiesProps {
  alertsData: GqlPlotAlerts;
  showOnlyAlerts?: boolean;
}

interface UseAgronomicAlertsMapEntitiesValue {
  filterMapEntities: FilterMapEntitiesType;
}

const useAgronomicAlertsMapEntities = ({
  alertsData,
  showOnlyAlerts,
}: UseAgronomicAlertsMapEntitiesProps): UseAgronomicAlertsMapEntitiesValue => {
  const filterMapEntities: FilterMapEntitiesType = useMemo(
    () => getFilterMapEntities(alertsData?.plots ?? [], showOnlyAlerts),
    [alertsData, showOnlyAlerts],
  );

  return {
    filterMapEntities,
  };
};

export default useAgronomicAlertsMapEntities;
