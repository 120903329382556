import React, { FC } from 'react';
import styled from 'styled-components';
import { Button as MuiButton, ButtonProps } from '@mui/material';
import Loading from 'components/UI/Loading/Loading';

const StyledButton = styled(MuiButton)<ButtonProps>`
  padding-top: 7px;
  padding-bottom: 7px;
  text-transform: none;

  .MuiButton-startIcon {
    body[dir='rtl'] & {
      margin-left: 8px;
      margin-right: -4px;
    }
  }
`;

interface LoadingButtonProps extends ButtonProps {
  loadingSize?: string;
  loading?: boolean;
}

const StyledLoading = styled(Loading)`
  position: absolute;
  visibility: visible;
  display: flex;
  left: 50%;
  transform: translate(-50%);
`;

const Button: FC<LoadingButtonProps> = ({
  variant = 'contained',
  loadingSize = '1rem',
  loading = false,
  children,
  ...rest
}) => (
  <StyledButton variant={variant} {...rest}>
    {children}
    {loading && <StyledLoading size={loadingSize} />}
  </StyledButton>
);

export default Button;
