import React, { FC } from 'react';
import styled from 'styled-components';
import ToggleButton from '@mui/lab/ToggleButton';
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/lab/ToggleButtonGroup';
import Text from 'components/UI/Text';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 34px;

  .Mui-selected,
  .Mui-selected:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.success};
  }
`;

const Label = styled(Text)`
  text-transform: none;
`;

interface Option {
  value: string | number;
  label: string;
  disabled?: boolean;
}

export interface ToggleBoxProps extends ToggleButtonGroupProps {
  options: Option[];
}

export const ToggleBox: FC<ToggleBoxProps> = ({
  value,
  options,
  onChange,
  ...rest
}) => (
  <StyledToggleButtonGroup
    color="primary"
    value={value}
    exclusive
    onChange={onChange}
    {...rest}
  >
    {options.map((option) => (
      <ToggleButton
        key={option.value}
        value={option.value}
        disabled={option.disabled}
      >
        <Label weight="bold">{option.label}</Label>
      </ToggleButton>
    ))}
  </StyledToggleButtonGroup>
);
