import TreeNode from 'primereact/treenode';
import { SystemParamsType, SystemVariant } from './systems';

export enum GrowerType {
  Reseller = 'RESELLER',
  Grower = 'GROWER',
}

export enum GrowerRole {
  User = 'USER',
  Admin = 'ADMIN',
  GbiAdmin = 'GBI_ADMIN',
}

export interface Grower {
  id: number;
  resellerId: number;
  name: string;
  plotCount: number;
  userGrowerRole: GrowerRole;
  criticalAlerts: number;
  otherAlerts: number;
  growerType: string;
  isActive: boolean;
  resellerName: string;
  hectares: number;
}

export interface PlotGrower {
  growerId: number;
  count: number;
}

export interface ResellerUsersPaginated {
  totalCount: string;
  paginatedCollection: ResellerUsers[];
}

export interface ResellerUsers {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  active: boolean;
  userRole: string;
  companyRoleId: number;
}

export interface GrowerSystem {
  id: number | null;
  growerId: number;
  active: boolean;
  displayName: string;
  serial: string;
  type: SystemVariant;
  params?: SystemParamsType;
}

export interface TreeGrower {
  id: number;
  resellerId: number;
  resellerName: string;
  name: string;
  plotCount: number;
  hectares: number;
  userGrowerRole: string;
  numOfGrowers: number;
  growers: null;
  growerType: GrowerType;
  isActive: boolean;
}

export interface ResellerTreeNode {
  name: string;
  id: number;
  resellerId: number;
  resellerName: string | null;
  plotCount: number;
  hectares: number;
  userGrowerRole: string;
  numOfGrowers: number;
  growers: TreeGrower[] | null;
  growerType: GrowerType;
  isActive: boolean;
}

export interface CustomTreeNode<T> extends TreeNode {
  data?: T;
  children: CustomTreeNode<T>[];
  key: string;
}

export enum ResellerTreeMode {
  Reseller,
  All,
  Grower,
}

export interface ResellerPlot {
  id: number;
  growerId: number;
  name: string;
  age: number;
  active: boolean;
  category: string;
  phenStage: string;
  alert: string;
  variety: string;
  cropName: string;
  irrigationMethod: string;
  growingType: string;
}
