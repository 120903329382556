import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores/hooks/hooks';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import GrowerSystems from './GrowerSystems';
import SystemSensors from './SystemSensors';

const Container = styled(Grid)`
  height: 100%;
`;

const Systems: FC = observer(() => {
  const {
    resellersStore: { selectedRowsSystems },
  } = useStores();

  return (
    <Container>
      <GrowerSystems />
      {!!selectedRowsSystems?.length && <SystemSensors />}
    </Container>
  );
});

export default Systems;
