import React, { memo } from 'react';
import styled from 'styled-components';
import logo from 'assets/images/icons/SupPlant_Logo.svg';

const HeaderContainer = styled.div`
  background: ${(p) => p.theme.color.loginBgGray};
  width: 340px;
  height: 110px;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;

  body[dir='rtl'] & {
    direction: ltr;
  }
`;

const HeaderLogo = styled.img`
  /* height: 60px;
  width: 60px; */
`;

const AuthHeader = () => {
  return (
    <HeaderContainer>
      <HeaderLogo src={logo} />
      {/* <Space width={1} /> */}
      {/* <HeaderText>{t`title`}</HeaderText> */}
    </HeaderContainer>
  );
};

export default memo(AuthHeader);
