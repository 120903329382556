import { observer } from 'mobx-react';
import React, {
  FC,
  useEffect,
  useState,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
  MouseEvent,
} from 'react';
import styled from 'styled-components';
import Row from 'components/UI/Row';
import Space from 'components/UI/Space';
import Text from 'components/UI/Text';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useStores } from 'stores/hooks/hooks';
import TextField from '@mui/material/TextField';
import Select from 'components/UI/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import useSelectedGrowingTypes from 'hooks/useSelectedGrowingTypes';
import PhenStageTable from 'components/pages/CropModel/ModelDetails/PhenStages/PhenStageTable';
import useModelAgeRanges from 'hooks/useModelAgeRanges';
import useModelGrowingTypes from 'hooks/useModelGrowingTypes';
import {
  AgeRange,
  GrowingType,
  IProtocol,
  CoefficientColumn,
  PhenStageColumn,
} from 'models/protocol';
import CoefficentTable from './CoefficentTable';
import CoefficientGraph from './CoefficentGraph';

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const StyledDatePicker = styled(DatePicker)`
  input {
    box-sizing: unset;
  }
`;

const ModelDetailsContainer = styled(Row)`
  display: flex;
  flex: 1;
  background-color: ${(p) => p.theme.color.white};
  grid-gap: 1rem;
  /* padding: 1rem; */
  padding: 0.5rem 0.2rem 0.2rem 0.2rem; /* compensate for 0.2rem box shadow */
  overflow: auto;
`;

const StyledFlex = styled.div<{ flex?: number }>`
  flex: ${(p) => p.flex || 1};
  height: 100%;
`;

const CalendarContainer = styled(StyledFlex)`
  display: flex;
  flex-direction: column;
`;

const StyledColumn = styled.div<{ hideOverflow?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: ${(p) => (p.hideOverflow ? 'hidden' : 'visible')};
`;

const SwitchAndTableContainer = styled(Row)`
  width: 100%;
  height: 100%;
`;

const CoefficentTableContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  flex: 1;
`;

interface IPhenStages {
  selectedRow: IProtocol;
  sharedDropDownAgeRange?: AgeRange | null;
  setSharedDropDownAgeRange?: Dispatch<SetStateAction<AgeRange | null>>;
  sharedDropdownGrowingType?: GrowingType | null;
  setSharedDropdownGrowingType?: Dispatch<SetStateAction<GrowingType | null>>;
  sharedDate?: Date | null;
  setSharedDate?: Dispatch<SetStateAction<Date | null>>; // used when user selects multiple items in the datatable
  isSecond?: boolean; // if this particular phenStage isnt the first, therefore must contain 'same as above' checkbox.
}

const PhenStages: FC<IPhenStages> = ({
  selectedRow,
  sharedDropDownAgeRange,
  setSharedDropDownAgeRange,
  sharedDropdownGrowingType,
  setSharedDropdownGrowingType,
  sharedDate,
  setSharedDate,
  isSecond = false,
}) => {
  const { t } = useTranslation('crop_models');
  const { cropModelStore } = useStores();

  const [alignment, setAlignment] = useState<'Graph' | 'Table'>('Table');
  const [sameAsAbove, setSameAsAbove] = useState(false);
  const [selectedPhenStageRow, setSelectedPhenStageRow] =
    useState<PhenStageColumn | null>(null);

  const [selectedDropDownAgeRange, setSelectedDropDownAgeRange] =
    useState<AgeRange | null>(
      selectedRow?.ageRange[(selectedRow?.ageRange.length || 0) - 1] || null,
    );

  const [selectedDropdownGrowingType, setSelectedDropdownGrowingType] =
    useState<GrowingType | undefined>(
      selectedDropDownAgeRange?.growingType[0] || undefined,
    );

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    new Date(new Date(new Date().setMonth(0)).setDate(1)),
  );

  // last working date is so that if we change selected date to a date that is invalid, the tables wont change.
  const [lastWorkingDate, setLastWorkingDate] = useState<Date>(
    new Date(new Date(new Date().setMonth(0)).setDate(1)),
  );

  const handleAgeRangeChange = useCallback(
    (e: any) => {
      const foundAgeRange = selectedRow?.ageRange.find(
        (el) => el === e.target.value,
      );

      if (foundAgeRange) {
        setSelectedDropDownAgeRange(foundAgeRange);
        setSelectedDropdownGrowingType(foundAgeRange.growingType[0]);
        if (
          !isSecond &&
          setSharedDropDownAgeRange &&
          setSharedDropdownGrowingType
        ) {
          setSharedDropDownAgeRange(foundAgeRange);
          setSharedDropdownGrowingType(foundAgeRange.growingType[0]);
        }
      }
    },
    [
      setSelectedDropDownAgeRange,
      selectedRow,
      isSecond,
      setSharedDropDownAgeRange,
      setSharedDropdownGrowingType,
    ],
  );

  const handleSameAsAboveChange = useCallback(() => {
    setSameAsAbove((p) => !p);
  }, [setSameAsAbove]);

  const handleGrowingTypeChange = useCallback(
    (e: any) => {
      const foundGrowingType = selectedDropDownAgeRange?.growingType.find(
        (el) => el === e.target.value,
      );

      if (foundGrowingType) {
        setSelectedDropdownGrowingType(
          selectedDropDownAgeRange?.growingType.find(
            (el) => el === e.target.value,
          ) || undefined,
        );

        if (!isSecond && setSharedDropdownGrowingType) {
          setSharedDropdownGrowingType(foundGrowingType);
        }
      }
    },
    [
      setSelectedDropdownGrowingType,
      selectedDropDownAgeRange,
      isSecond,
      setSharedDropdownGrowingType,
    ],
  );

  const handleDateChange = useCallback(
    (e: unknown) => {
      setSelectedDate(e as Date);
      if (e instanceof Date && !Number.isNaN(e.getDate())) {
        setLastWorkingDate(e as Date);
        if (!isSecond && setSharedDate) {
          setSharedDate(e as Date);
        }
      }
    },
    [setSelectedDate, isSecond, setSharedDate],
  );

  const handleButtonGroupChange = useCallback(
    (_e: MouseEvent<HTMLElement>, n: 'Graph' | 'Table' | null) => {
      if (n) {
        setAlignment(n);
      }
    },
    [],
  );

  const modelAgeRanges: PhenStageColumn[] = useModelAgeRanges(
    lastWorkingDate,
    selectedDropDownAgeRange,
  );

  const modelGrowingTypes: CoefficientColumn[] = useModelGrowingTypes(
    lastWorkingDate,
    selectedDropdownGrowingType,
  );

  const graphData = useMemo(() => {
    const growingTypesData = modelGrowingTypes.map((el) => ({
      value: el.min,
      name: new Date(el.date).getTime(),
    }));

    growingTypesData.push(
      ...modelGrowingTypes.map((el) => ({
        value: el.max,
        name: new Date(
          new Date(el.date).setDate(
            new Date(el.date).getDate() + el.duration - 1,
          ),
        ).getTime(),
      })),
    );

    growingTypesData.sort((a, b) => b.name - a.name);
    return growingTypesData;
  }, [modelGrowingTypes]);

  useEffect(() => {
    // update selected ageRange and Growing type when selected row changes.
    const tempSelectedAgeRange =
      selectedRow?.ageRange[(selectedRow?.ageRange.length || 0) - 1] || null;

    setSelectedDropDownAgeRange(tempSelectedAgeRange);
    setSelectedDropdownGrowingType(
      tempSelectedAgeRange?.growingType[0] || null,
    );
  }, [
    selectedRow,
    setSelectedDropDownAgeRange,
    setSelectedDropdownGrowingType,
  ]);

  useEffect(() => {
    // unselect phen stage when selected row changes
    setSelectedPhenStageRow(null);
  }, [selectedRow, setSelectedPhenStageRow]);

  useEffect(() => {
    // use the sharedAgeRange if you have 'sameAsAbove' checked.
    if (sharedDropDownAgeRange && sameAsAbove) {
      const found = selectedRow.ageRange.find(
        (el) => el.name === sharedDropDownAgeRange.name,
      );

      if (found) {
        setSelectedDropDownAgeRange(found);
      }
    }
  }, [
    sharedDropDownAgeRange,
    sameAsAbove,
    selectedRow.ageRange,
    setSelectedDropDownAgeRange,
  ]);

  useEffect(() => {
    // use the sharedGrowingType if you have 'sameAsAbove' checked.
    if (sharedDropdownGrowingType && sameAsAbove) {
      const found = selectedDropDownAgeRange?.growingType.find(
        (el) => el.type === sharedDropdownGrowingType.type,
      );

      if (found) {
        setSelectedDropdownGrowingType(found);
      }
    }
  }, [
    sharedDropdownGrowingType,
    sameAsAbove,
    selectedDropDownAgeRange,
    setSelectedDropdownGrowingType,
  ]);

  useEffect(() => {
    // use the sharedDate if you have 'sameAsAbove' checked.
    if (sharedDate && sameAsAbove) {
      setSelectedDate(sharedDate);
      setLastWorkingDate(sharedDate);
    }
  }, [sharedDate, sameAsAbove, setSelectedDate]);

  // useEffect(() => {
  //   // make sure to update sharedDate to use the above one's date.
  //   if (!isSecond && setSharedDate) {
  //     setSharedDate(selectedDate);
  //   }
  // }, [isSecond, setSharedDate, selectedDate]);

  useEffect(() => {
    // use the sharedDate if you have 'sameAsAbove' checked.
    if (!cropModelStore.areBothSelectionsSimilar) {
      setSameAsAbove(false);
    }
  }, [cropModelStore.areBothSelectionsSimilar, setSameAsAbove]);

  const selectedGrowingTypes = useSelectedGrowingTypes(
    modelGrowingTypes,
    selectedPhenStageRow,
  );

  if (!selectedRow) {
    return null;
  }

  return (
    <ModelDetailsContainer align="start">
      <CalendarContainer flex={1}>
        {isSecond && cropModelStore.areBothSelectionsSimilar && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={sameAsAbove}
                  onChange={handleSameAsAboveChange}
                  name="checkedA"
                />
              }
              label={t('same_as_above')}
            />
            <Space height={0.5} />
          </>
        )}
        <Select
          inputId="ageRangeSelect"
          value={selectedDropDownAgeRange}
          options={selectedRow.ageRange}
          optionLabel="name"
          onChange={handleAgeRangeChange}
          disabled={sameAsAbove}
          label={t('age')}
        />
        {selectedDropDownAgeRange && (
          <>
            <Space height={1} />
            <Select
              inputId="growingTypeSelect"
              value={selectedDropdownGrowingType}
              onChange={handleGrowingTypeChange}
              optionLabel="type"
              disabled={sameAsAbove}
              options={selectedDropDownAgeRange?.growingType || []}
              label={t('growing_method')}
            />

            <Space height={1} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledDatePicker
                renderInput={(params) => <TextField {...params} />}
                disabled={sameAsAbove}
                value={selectedDate}
                label={t('initial_stage_date')}
                inputFormat="dd-MMM-yy"
                mask="__-___-__"
                // will show console warning because mui thinks MMM can be more than 3 characters...
                onChange={handleDateChange}
                disableMaskedInput
                PopperProps={{ placement: 'top-start' }}
              />
            </LocalizationProvider>
          </>
        )}
      </CalendarContainer>
      <StyledFlex flex={2}>
        {selectedDropDownAgeRange && selectedDate && (
          <PhenStageTable
            ModelAgeRanges={modelAgeRanges}
            selectedPhenStageRow={selectedPhenStageRow}
            setSelectedPhenStageRow={setSelectedPhenStageRow}
          />
        )}
      </StyledFlex>
      <StyledFlex flex={3}>
        {selectedDropDownAgeRange && selectedDropdownGrowingType && (
          <SwitchAndTableContainer align="start" justify="start">
            <CoefficentTableContainer>
              <StyledColumn hideOverflow>
                {alignment === 'Graph' ? (
                  <CoefficientGraph
                    graphData={graphData}
                    selectedPhenStageRow={
                      selectedPhenStageRow || ({} as PhenStageColumn)
                    }
                  />
                ) : (
                  <CoefficentTable
                    modelGrowingTypes={modelGrowingTypes}
                    selectedGrowingTypes={selectedGrowingTypes}
                  />
                )}
              </StyledColumn>
              <SwitchContainer>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleButtonGroupChange}
                >
                  <ToggleButton value="Table">
                    <Text>{t('table')}</Text>
                  </ToggleButton>
                  <ToggleButton value="Graph">
                    <Text>{t('graph')}</Text>
                  </ToggleButton>
                </ToggleButtonGroup>
              </SwitchContainer>
            </CoefficentTableContainer>
          </SwitchAndTableContainer>
        )}
      </StyledFlex>
    </ModelDetailsContainer>
  );
};

export default observer(PhenStages);
