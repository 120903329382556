import i18next from 'i18next';
import { z } from 'zod';
import { SystemVariant } from 'models/systems';
import { WEATHER_SYSTEMS_LIST } from 'core/constants';

export enum SystemCreateStep {
  TYPE = 0,
  CONFIGURATION = 1,
  DISCOVERY = 2,
  SUMMARY = 3,
}

const getFieldError = (field: string) =>
  i18next.t<string>('validation:field_required', {
    field,
  });

const defaultSchema = z.object({
  _step: z.nativeEnum(SystemCreateStep),
});

const typeStepSchema = z.object({
  _step: z.literal(SystemCreateStep.TYPE),
  type: z.string().nonempty({ message: getFieldError('Type') }),
});

const configurationStepSchema = z.object({
  _step: z.literal(SystemCreateStep.CONFIGURATION),
  type: z.string().nonempty({ message: getFieldError('Type') }),
  serial: z.string().default(''),
  displayName: z.string().nonempty({ message: getFieldError('Name') }),
});

const summaryStepSchema = z.object({
  _step: z.literal(SystemCreateStep.SUMMARY),
  active: z.boolean().default(false),
});

const schemaConditions = z.discriminatedUnion('_step', [
  typeStepSchema,
  configurationStepSchema,
  summaryStepSchema,
]);

type ConfigurationStepType = z.infer<typeof configurationStepSchema>;
export const systemCreateValidationSchema = z
  .intersection(schemaConditions, defaultSchema)
  .refine((input) => {
    const { _step, type, serial } = input as unknown as ConfigurationStepType;
    const isSerialValid =
      !!serial || WEATHER_SYSTEMS_LIST.includes(type as SystemVariant);

    if (_step === SystemCreateStep.CONFIGURATION && !isSerialValid) {
      return false;
    }

    return true;
  });

type SystemCreateValidationFields = Omit<
  z.infer<typeof typeStepSchema>,
  '_step'
> &
  Omit<z.infer<typeof configurationStepSchema>, '_step'> &
  Omit<z.infer<typeof summaryStepSchema>, '_step'>;

export type SystemCreateValidationSchema = z.infer<
  typeof systemCreateValidationSchema
> &
  SystemCreateValidationFields;
