import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { useStores } from 'stores/hooks/hooks';
import { User } from 'models/user';
import { ActionMode, DialogOnCloseReason } from 'models/shared';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_MEASUREMENT_SYSTEM,
  DEFAULT_USER_PROFILE_ID,
} from 'core/constants';
import { TabViewTabChangeParams } from 'primereact/tabview';
import { TabPanel, TabView } from 'components/UI/TabView';
import UserFormProperties from './UserFormProperties';
import AssignUserGrowerContent from '../../UserPermissions/AssignUserGrowerDialog/AssignUserGrowerContent';
import {
  UserFormFieldsSchema,
  userFormFieldsSchema,
} from './UserManagementFormSchemas';
import UserPreferencesEdit from './UserPreferencesEdit';

const Title = styled(Text)`
  font-weight: bold;
`;

const StyledTabView = styled(TabView)`
  .p-tabview-nav {
    margin-left: 3px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 1rem;
  height: 70vh;
  overflow: auto;
`;

interface UserManagementDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const getEditUserFormValues = (user: User): Partial<UserFormFieldsSchema> => ({
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  companyRoleId: user.companyRoleId,
  preferredMeasurementSystem: user.preferredMeasurementSystem,
  locale: user.locale,
  phone: user.phone,
  active: user.active,
  gaRelevant: user.gaRelevant,
  userPreferences: {
    ...user.userPreferences,
    profileId: user.userPreferences?.profileId ?? DEFAULT_USER_PROFILE_ID,
  },
});

const defaultCreateUserFormValues: Partial<UserFormFieldsSchema> = {
  firstName: '',
  lastName: '',
  email: '',
  active: true,
  locale: DEFAULT_LANGUAGE,
  gaRelevant: true,
  preferredMeasurementSystem: DEFAULT_MEASUREMENT_SYSTEM,
  companyRoleId: undefined,
  phone: '',
  userPreferences: { profileId: DEFAULT_USER_PROFILE_ID },
};

const UserManagementDialog: FC<UserManagementDialogProps> = observer(
  ({ visible = false, onHide, title, mode }) => {
    const { t } = useTranslation('users');
    const { membersStore } = useStores();
    const [assignToGrowers, setAssignToGrowers] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [wizardTitle, setWizardTitle] = useState<string>(
      mode === ActionMode.CREATE
        ? `${t('step_1')} ${t('create')}`
        : `${t('edit')} ${t('user')}`,
    );

    const defaultValues = useMemo(() => {
      const selectedUser = membersStore.selectedRows[0];
      return mode === ActionMode.CREATE
        ? defaultCreateUserFormValues
        : getEditUserFormValues(selectedUser);
    }, [mode, membersStore.selectedRows]);

    const {
      formState: { isValid, isDirty, errors },
      control,
      register,
      getValues,
    } = useForm<UserFormFieldsSchema>({
      resolver: zodResolver(userFormFieldsSchema),
      mode: 'onChange',
      defaultValues,
    });

    const closeDialog = useCallback(() => {
      setAssignToGrowers(false);
      onHide();
    }, [onHide]);

    const handleCloseDialog = useCallback(
      (event: unknown, reason: DialogOnCloseReason) => {
        if (reason && reason === DialogOnCloseReason.BackDropClick) {
          return;
        }

        closeDialog();
      },
      [closeDialog],
    );

    const handleCreateUser = useCallback(
      (user: User) => {
        setIsLoading(true);
        membersStore
          .createUser(user)
          .then(() => {
            if (membersStore.selectedRows.length) {
              setWizardTitle(`${t('step_2')} ${t('assign_to_growers')}`);
              setAssignToGrowers(true);
              setIsLoading(false);
            }
          })
          .catch(() => {
            setIsLoading(false);
          });
      },
      [membersStore, t],
    );

    const handleEditUser = useCallback(
      (user: User) => {
        setIsLoading(true);
        membersStore
          .editUser(user)
          .then(() => {
            onHide();
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      },
      [membersStore, onHide],
    );

    const handleSubmitClick = useCallback(() => {
      const formValues = getValues() as User;
      switch(mode) {
        case ActionMode.CREATE:
          handleCreateUser(formValues);
          break;
        case ActionMode.EDIT: {
          if (activeIndex === 0) {
            handleEditUser(formValues);
          } else {
            console.log('Edit user origin here');
          }

          break;
        }
        
        default:
          break;
      }
    }, [mode, getValues, handleEditUser, handleCreateUser, activeIndex]);

    const onTabChange = useCallback((e: TabViewTabChangeParams) => {
      setActiveIndex(e.index);
    }, []);

    return (
      <Dialog
        open={visible}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <StyledTabView
          activeIndex={activeIndex}
          renderActiveOnly={false}
          onTabChange={onTabChange}
        >
          <TabPanel header={wizardTitle}>
            <StyledDialogContent>
              <Title>{wizardTitle}</Title>
              {!assignToGrowers ? (
                <UserFormProperties
                  mode={mode}
                  control={control}
                  register={register}
                  getValues={getValues}
                  errors={{ ...errors }}
                />
              ) : (
                <AssignUserGrowerContent />
              )}
            </StyledDialogContent>
          </TabPanel>
          <TabPanel header="User Preferences">
            <StyledDialogContent>
              <UserPreferencesEdit control={control} />
            </StyledDialogContent>
          </TabPanel>
        </StyledTabView>
        <Divider />
        <DialogActions>
          {!assignToGrowers ? (
            <>
              {mode === ActionMode.CREATE ? (
                <Button
                  endIcon={<FontAwesomeIcon icon={faLongArrowAltRight} />}
                  onClick={handleSubmitClick}
                  disabled={isLoading || !isValid}
                  loading={isLoading}
                >
                  <Text textcolor={theme.color.white} size="md">
                    {t('continue_to_step_2')} {t('assign_to_growers')}
                  </Text>
                </Button>
              ) : (
                <Button
                  onClick={handleSubmitClick}
                  disabled={!isDirty || isLoading || !isValid}
                  loading={isLoading}
                >
                  <Text textcolor={theme.color.white} size="sm">
                    {t('ok')}
                  </Text>
                </Button>
              )}
              <Button onClick={closeDialog}>
                <Text textcolor={theme.color.white} size="sm">
                  {t('cancel')}
                </Text>
              </Button>
            </>
          ) : (
            <Button onClick={closeDialog}>
              <Text textcolor={theme.color.white} size="sm">
                {t('done')}
              </Text>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  },
);

export default UserManagementDialog;
