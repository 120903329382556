import React, { FC } from 'react';
import styled from 'styled-components';
import { DataTable as PrimeReactDataTable } from 'primereact/datatable';
import {
  ColumnBodyType,
  ColumnProps,
  Column as PrimeReactColumn,
} from 'primereact/column';
import Text from 'components/UI/Text';
import Tooltip from 'components/UI/Tooltip';
import { resolveFieldData } from 'utils/functions';
import { observer } from 'mobx-react';
import { HIGHLIGHTED_ROW_CLASS_NAME } from 'core/constants';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-ts-comment */

export const DataTable = styled(PrimeReactDataTable)`
  &&& {
    th,
    td {
      padding: 1rem 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .p-datatable-scrollable-header {
      flex-shrink: 0;
    }

    .p-datatable-scrollable-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: calc(100% - 72px);
    }

    .p-datatable-scrollable-view {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
    }

    .p-datatable-scrollable-body {
      overflow-y: scroll;
    }
  }

  .p-datatable-table {
    width: 100%;
  }

  th {
    font-size: 1rem;
    height: inherit;
  }

  .p-filter-column > input {
    max-width: 100%;
  }

  .p-datatable-tbody .p-rowgroup-header td {
    padding: 0.25rem;
  }

  .p-rowgroup-header-name {
    vertical-align: middle;
  }

  .p-row-toggler {
    vertical-align: middle;
  }

  .p-datatable-header {
    border: none;
    padding: unset;
  }

  .p-filter-column > input {
    font-size: initial;
  }

  .${HIGHLIGHTED_ROW_CLASS_NAME} {
    td {
      background-color: ${({ theme }) => theme.color.palePink};
    }
  }
`;

export const GenericBody: FC<ColumnBodyType> = (
  data: any,
  props: CustomColumnProps,
  ..._parameters: any
) => {
  const { field, altField } = props;
  return <GenericBodyContainer data={data} field={field} altField={altField} />;
};

export const GenericBodyContainer: FC<{
  data: any;
  field: string | undefined;
  altField: string | undefined;
}> = observer(({ data, field, altField }) => {
  const text: string =
    resolveFieldData(field, data) || resolveFieldData(altField, data) || '';

  return (
    <Tooltip arrow title={text} placement="top">
      <Text size="md" singleline>
        {text}
      </Text>
    </Tooltip>
  );
});

export interface CustomColumnProps extends ColumnProps {
  altField?: string;
  bodyProps?: any;
}

export const Column: FC<CustomColumnProps> = () => {
  return null;
};

/* this exist to add a default 'body' prop to the DataTable Columns
  primereact ignores the Columns altogether and looks directly into their props, <Column/> never gets rendered.
*/

Column.defaultProps = {
  // @ts-ignore
  ...PrimeReactColumn.defaultProps,
  footer: null,
  body: GenericBody,
};

export { PrimeReactDataTable };
