import React, { FC } from 'react';
import { MultiSelectChangeParams } from 'primereact/multiselect';
import styled from 'styled-components';
import MultiSelect from 'components/UI/MultiSelect';

type CropSelectOption = {
  label: string;
  value: string;
};

interface ICropSelectFilter {
  selectedOptions: string[];
  options: CropSelectOption[];
  onOptionFilterChange: (e: MultiSelectChangeParams) => void;
  innerPlaceholder?: string;
  placeholder?: string;
}

const MultiSelectContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column; /* makes filter full width in table */

  .p-multiselect {
    flex: 1;
    max-width: 100%;
    font-size: initial;
  }
`;

const CropSelectFilter: FC<ICropSelectFilter> = ({
  selectedOptions,
  options,
  onOptionFilterChange,
  innerPlaceholder,
  placeholder,
}) => {
  return (
    <MultiSelectContainer>
      <MultiSelect
        value={selectedOptions}
        options={options}
        onChange={onOptionFilterChange}
        placeholder={placeholder}
        filter
        showClear
        filterPlaceholder={innerPlaceholder}
      />
    </MultiSelectContainer>
  );
};

export default CropSelectFilter;
