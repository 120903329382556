import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import Text from 'components/UI/Text';

const NoWrappingText = styled(Text)`
  white-space: nowrap;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  border: 0.6px solid rgba(0, 0, 0, 0.38);
  position: relative;
  border-radius: 5px;
  align-items: center;
`;

const StyledLegend = styled.legend`
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.38);
`;

const DivChildrenStyle = styled.div`
  overflow-x: hidden;
`;

interface BoxContainerProps {
  title?: string;
}

const BoxContainer: FC<PropsWithChildren<BoxContainerProps>> = ({
  title,
  children,
  ...rest
}) => {
  return (
    <Container {...rest}>
      {title && (
        <StyledLegend>
          <NoWrappingText>{title}</NoWrappingText>
        </StyledLegend>
      )}
      <DivChildrenStyle>{children}</DivChildrenStyle>
    </Container>
  );
};

export default BoxContainer;
