import { Menubar as PrimeReactMenuBar } from 'primereact/menubar';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Input from 'components/UI/Input';
import styled from 'styled-components';
import { DropdownChangeParams } from 'primereact/dropdown';
import MultiSelect from 'components/UI/MultiSelect';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import { DialogCloseAction } from 'models/shared';
import { UserGrowerRole } from 'models/user';
import RoleDialog from '../../../shared/RoleDialog';
import AssignUserGrowerDialog from './AssignUserGrowerDialog/AssignUserGrowerDialog';

const StyledInput = styled(Input)`
  background-color: ${(p) => p.theme.palette.background.default};
`;

const StyledSelect = styled(MultiSelect)`
  min-width: 12rem;
`;

const StartSelect = observer(() => {
  const { t } = useTranslation('users');
  const { membersStore } = useStores();
  const growers = useMemo(() => {
    let resellers = Array.from(
      new Set(membersStore.memberGrowers.map((el) => el.resellerName)),
    );

    resellers = resellers.filter((reseller) => reseller !== null);
    return resellers;
  }, [membersStore.memberGrowers]);

  const [selectedGrower, setSelectedGrower] = useState(null);
  const handleChange = useCallback(
    (e: DropdownChangeParams) => {
      membersStore.setSelectedReseller(e.value);
      setSelectedGrower(e.value);
    },
    [membersStore],
  );

  return (
    <StyledSelect
      onChange={handleChange}
      options={growers}
      value={selectedGrower}
      placeholder={t('select_a_reseller')}
    />
  );
});

interface MenuBarEndComponentsProps {
  handleSearchChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
}

const MenuBarEndComponents: FC<MenuBarEndComponentsProps> = ({
  handleSearchChange,
}) => {
  const { t } = useTranslation('users');

  return (
    <Grid gap="1rem" direction="row">
      <StartSelect />
      <StyledInput
        size="small"
        placeholder={t('search')}
        type="text"
        onChange={handleSearchChange}
      />
    </Grid>
  );
};

interface HeaderProps {
  onGlobalFilterChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
}

const MenuBar: FC<HeaderProps> = ({ onGlobalFilterChange }) => {
  const { t } = useTranslation('users');
  const { membersStore } = useStores();
  const [assignUserGrowerDialog, setAssignUserGrowerDialog] = useState(false);
  const [editRoleDialog, setEditRoleDialog] = useState(false);
  const [unassignUserGrowerDialogOpen, setUnassignUserGrowerDialogOpen] =
    useState(false);

  const [definedRole, setDefinedRole] = useState<string>('');

  useEffect(() => {
    membersStore.getGrowersRoles(membersStore.selectedRows[0]?.id);
  }, [membersStore]);

  const onCloseEditRoleDialog = useCallback(
    (response: DialogCloseAction, selectedRole: UserGrowerRole | null) => {
      if (response === DialogCloseAction.Confirmed && selectedRole) {
        membersStore.editMemberGrowersRole(selectedRole);
      }

      setEditRoleDialog(false);
    },
    [membersStore],
  );

  const onCloseUnassignDialog = useCallback(
    (response: DialogCloseAction) => {
      if (response === DialogCloseAction.Confirmed) {
        membersStore.unassignUserGrowers();
      }

      setUnassignUserGrowerDialogOpen(false);
    },
    [membersStore],
  );

  const handleAssignDialogClose = () => {
    setAssignUserGrowerDialog(false);
  };

  const assignToGrowersModal = useCallback(() => {
    setAssignUserGrowerDialog(true);
    membersStore.setSelectedRowsMemberGrower({
      selection: [],
      activeRow: null,
    });
  }, [membersStore]);

  const dialogEditRole = useCallback(() => {
    const name =
      membersStore.selectedRowsMemberGrower.length === 1
        ? membersStore.selectedRowsMemberGrower[0]?.userGrowerRole
        : '';

    setDefinedRole(name || '');
    setEditRoleDialog(true);
  }, [membersStore]);

  const items = useMemo(
    () => [
      {
        label: t('assign_to_growers'),
        icon: 'pi pi-fw pi-file',
        disabled: !membersStore.userTableMultiSelection.activeRow,
        command: assignToGrowersModal,
      },
      {
        label: t('edit_role'),
        icon: 'pi pi-fw pi-file',
        disabled: !membersStore.selectedRowsMemberGrower.length,
        command: dialogEditRole,
      },
      {
        label: t('unassign'),
        icon: 'pi pi-fw pi-file',
        disabled: !membersStore.selectedRowsMemberGrower.length,
        command: () => {
          setUnassignUserGrowerDialogOpen(true);
        },
      },
    ],
    [
      t,
      assignToGrowersModal,
      dialogEditRole,
      membersStore.selectedRowsMemberGrower.length,
      membersStore.userTableMultiSelection.activeRow,
    ],
  );

  return (
    <div>
      <RoleDialog
        backText={t(i18next.t('general:cancel'))}
        buttonText={t(i18next.t('general:edit'))}
        isOpen={editRoleDialog}
        title={t('edit_role')}
        message={t('supplant_role')}
        onClose={onCloseEditRoleDialog}
        definedRole={definedRole}
      />
      <AssignUserGrowerDialog
        onHide={handleAssignDialogClose}
        visible={assignUserGrowerDialog}
        title={t('assign_to_growers')}
      />
      <ConfirmDialog
        backText={t(i18next.t('general:cancel'))}
        buttonText={t(i18next.t('general:submit'))}
        isOpen={unassignUserGrowerDialogOpen}
        title={t('confirm_dialog_user_unassign_title')}
        message={t('confirm_dialog_user_unassign_message')}
        onClose={onCloseUnassignDialog}
      />
      <PrimeReactMenuBar
        model={items}
        end={<MenuBarEndComponents handleSearchChange={onGlobalFilterChange} />}
      />
    </div>
  );
};

export default observer(MenuBar);
