import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo,
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  CSSProperties,
} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import Space from 'components/UI/Space';
import Input from 'components/UI/Input';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { useStores } from 'stores/hooks/hooks';
import { AREA_UNITS } from 'core/constants';
import { Checkbox, Divider, Switch } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Area, InitialStageModel, PlotStatus } from 'models/plot';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckCircle';
import {
  calculateAgeByDate,
  checkIfValidLatitudeAndLongitude,
} from 'utils/functions';
import {
  extractPolygonsFileToList,
  PolygonItem,
} from 'utils/uploadPolygonsHelpers';
import { ActionMode } from 'models/shared';
import {
  checkIsInitialStageDisabled,
  checkIsPlantDateDisabled,
} from './PlotManagementDialog.utils';

export type PlotFormPropertiesRef = {
  resetPropertiesOnDuplicate(): void;
};

interface PlotFormPropertiesProps {
  mode: ActionMode;
  isAttachVirtualCurrentSensor: boolean;
  isAttachVirtualForecastSensor: boolean;
  isAttachIWSensor: boolean;
  setIsAttachVirtualCurrentSensor: Dispatch<SetStateAction<boolean>>;
  setIsAttachVirtualForecastSensor: Dispatch<SetStateAction<boolean>>;
  setIsAttachIWSensor: Dispatch<SetStateAction<boolean>>;
  setLocation: Dispatch<SetStateAction<string>>;
}

interface PolygonOption {
  label: string;
  id: GeoJSON.Feature;
}

interface CropTypeOption {
  label: string;
  id: string | number;
}

enum CropTypeField {
  Crop = 'crop',
  Category = 'category',
  Variety = 'variety',
}

const GridColumnWidth = styled(Grid)`
  width: 50%;
  padding: 0 1rem;

  p {
    min-width: 40%;
  }
`;

const GridFullWidth = styled(Grid)`
  width: 100%;
`;

const AutocompleteInput = styled(TextField)`
  input:not(.Mui-disabled)::placeholder {
    opacity: 1;
  }
`;

const NoWrappingText = styled(Text)`
  white-space: nowrap;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  input {
    box-sizing: unset;
  }
`;

const SensorOptionSwitch = styled(Switch)`
  .Mui-checked {
    .MuiSwitch-thumb {
      color: ${({ theme: { color } }): string => color.popupHoverButtonGreen};
    }
  }
`;

const PolygonInput = styled.input`
  display: none;
`;

const StatusCheckbox = styled(Checkbox)`
  position: relative;
  left: 9px;
`;

const autocompleteStyles: CSSProperties = { width: '60%' };

const getCropTypeOption = (options: string[]): CropTypeOption[] => {
  return options.map((option) => ({
    id: option,
    label: option,
  }));
};

const getCropTypeValueOption = (value: string): CropTypeOption | null => {
  return value ? { label: value, id: value } : null;
};

const PlotFormProperties = observer(
  forwardRef<PlotFormPropertiesRef, PropsWithChildren<PlotFormPropertiesProps>>(
    (
      {
        mode,
        isAttachVirtualCurrentSensor,
        isAttachVirtualForecastSensor,
        isAttachIWSensor,
        setIsAttachVirtualCurrentSensor,
        setIsAttachVirtualForecastSensor,
        setIsAttachIWSensor,
        setLocation,
      },
      ref,
    ) => {
      const { t } = useTranslation('plot');
      const { plotsStore, snackBarStore } = useStores();
      const { getprotocolId, plotAction } = plotsStore;
      const [plotLocation, setPlotLocation] = useState<string>('');
      const [polygonInputValue, setPolygonInputValue] = useState<string>('');
      const [selectedPlantDate, setSelectedPlantDate] = useState<Date | null>(
        plotsStore.plotAction.plantTime
          ? new Date(plotsStore.plotAction.plantTime)
          : null,
      );

      const [selectedPhenStageDate, setSelectedPhenStageDate] =
        useState<Date | null>(
          plotsStore.plotAction.initialStage?.date
            ? new Date(plotsStore.plotAction.initialStage?.date)
            : null,
        );

      const [plotAge, setPlotAge] = useState<string>('');
      const [polygons, setPolygons] = useState<PolygonItem[]>([]);
      const isCreateMode = mode === ActionMode.CREATE;
      const isEditMode = mode === ActionMode.EDIT;
      const isDraft = plotsStore.plotAction.status === PlotStatus.Draft;
      const isPlantDateFieldDisabled =
        isEditMode ||
        checkIsPlantDateDisabled(plotsStore.plotAction, plotLocation);

      const isInitialStageFieldDisabled =
        isPlantDateFieldDisabled ||
        checkIsInitialStageDisabled(plotsStore.plotAction);

      const polygonOptions: PolygonOption[] = useMemo(
        () =>
          polygons.map(({ name, value }) => ({
            label: name,
            id: value,
          })),
        [polygons],
      );

      const resetPropertiesOnDuplicate = useCallback(() => {
        plotsStore.selectedPolygon = undefined;
        plotsStore.plotAction.name = `${plotsStore.plotAction.name} copy`;
        plotsStore.plotAction.geojson = null;
        (plotsStore.plotAction.area as Area).val = 0;
        plotsStore.plotAction.initialStage = {} as InitialStageModel;
        plotsStore.plotAction.startSeason = undefined;
        plotsStore.plotManagementValidation.location = true;

        setPolygonInputValue('');
        setPlotLocation('');
        setLocation('');
        setPolygons([]);
        setSelectedPhenStageDate(null);
      }, [plotsStore, setLocation]);

      useImperativeHandle(ref, () => ({ resetPropertiesOnDuplicate }));

      useEffect(() => {
        if (
          isEditMode &&
          plotsStore.plotAction.latitude !== null &&
          plotsStore.plotAction.longitude !== null
        ) {
          setPlotLocation(
            `${plotsStore.plotAction.latitude},${plotsStore.plotAction.longitude}`,
          );
        }

        if (isEditMode && plotsStore.plotAction.plantTime) {
          setPlotAge(
            `Age: ${calculateAgeByDate(plotsStore.plotAction.plantTime)}`,
          );
        }
      }, [
        plotsStore.plotAction.latitude,
        plotsStore.plotAction.longitude,
        plotsStore.plotAction.plantTime,
        isEditMode,
      ]);

      useEffect(() => {
        let getprotocolTimeoutId: NodeJS.Timeout;
        if (
          isCreateMode &&
          plotAction.growerId &&
          plotAction.crop &&
          plotAction.latitude &&
          plotAction.longitude &&
          (plotAction.variety || plotAction.category)
        ) {
          getprotocolTimeoutId = setTimeout(getprotocolId, 300);
        }

        return () => clearTimeout(getprotocolTimeoutId);
      }, [
        getprotocolId,
        plotAction.growerId,
        plotAction.crop,
        plotAction.latitude,
        plotAction.longitude,
        plotAction.variety,
        plotAction.category,
        isCreateMode,
      ]);

      const handleChange = useCallback(
        (
          event: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
          >,
        ) => {
          const { value } = event.target;
          switch (event.target.id) {
            case 'plotName':
              plotsStore.plotAction.name = value;
              break;
            case 'description':
              plotsStore.plotAction.description = value;
              break;

            case 'areaNumber':
              if (plotsStore.plotAction.area) {
                plotsStore.plotAction.area.val = Number(value);
              }

              break;
            case 'location': {
              setPlotLocation(value);
              setLocation(value);
              plotsStore.plotManagementValidation.location = false;
              if (checkIfValidLatitudeAndLongitude(value)) {
                if (value.includes(',')) {
                  setPlotLocation(value);
                  const location = value.split(',');
                  plotsStore.plotAction.latitude = Number(location[0]);
                  plotsStore.plotAction.longitude = Number(location[1]);
                }
              } else {
                setLocation('');
              }

              break;
            }

            default:
              break;
          }
        },
        [
          plotsStore.plotAction,
          plotsStore.plotManagementValidation,
          setLocation,
        ],
      );

      const handleCropTypeChange = useCallback(
        (value: string | number, field: CropTypeField) => {
          const { growerId, latitude, longitude } = plotsStore.plotAction;
          if (field === CropTypeField.Crop) {
            const cropId = Number(value);
            plotsStore.plotAction.crop = cropId;
            plotsStore.resetCropDetails();
            plotsStore.getCategories(growerId, cropId);
            plotsStore.getVarieties(growerId, cropId);
          } else if (field === CropTypeField.Variety) {
            plotsStore.plotAction.variety = value as string;
            if (!latitude || !longitude) {
              snackBarStore.showError(t('location_error'));
            }
          } else if (field === CropTypeField.Category) {
            plotsStore.plotAction.category = value as string;
          }
        },
        [plotsStore, snackBarStore, t],
      );

      const handleSelectChange = useCallback(
        (event: SelectChangeEvent<HTMLSelectElement>) => {
          const { value } = event.target;
          switch (event.target.name) {
            case 'serviceLevel':
              plotsStore.plotAction.serviceLevel = value as string;
              if (
                (value as string) === 'GBI' ||
                (value as string) === 'GBI_MASTER' ||
                (value as string) === 'GBI_SLAVE'
              ) {
                plotsStore.getCropTypesGBI();
                break;
              }

              plotsStore.getCropTypes(plotsStore.plotAction.growerId);
              break;
            case 'irrigationMethod':
              plotsStore.plotAction.irrigationMethod = value as string;
              break;
            case 'growingEnvironment':
              plotsStore.plotAction.growingType = value as string;
              break;
            case 'areaType':
              if (plotsStore.plotAction.area) {
                plotsStore.plotAction.area.unit = value as string;
              }

              break;
            case 'soil':
              plotsStore.plotAction.soil = value as string;
              break;
            case 'growingMethod':
              plotsStore.plotAction.growthMethod = value as string;
              break;
            default:
              break;
          }
        },
        [plotsStore],
      );

      const handleDateChange = useCallback(
        (event: unknown, id: string) => {
          switch (id) {
            case 'plantDate': {
              plotsStore.plotAction.plantTime = (event as Date).getTime();
              setSelectedPlantDate(event as Date);
              setPlotAge(
                `Age: ${calculateAgeByDate(plotsStore.plotAction.plantTime)}`,
              );

              plotsStore.getPenologicalStages();
              plotsStore.getGrowingMethod();
              break;
            }

            case 'initialStage':
              plotsStore.plotAction.startSeason = (event as Date).getTime();
              setSelectedPhenStageDate(event as Date);
              break;
            default:
              break;
          }
        },
        [plotsStore],
      );

      const handleStatusChange = useCallback(
        (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
          plotsStore.plotAction.status = checked
            ? PlotStatus.Draft
            : PlotStatus.Active;
        },
        [plotsStore],
      );

      const handlePolygonsFileChange = useCallback(
        async (file: File | null) => {
          const uploadedPolygons = await extractPolygonsFileToList(file);
          setPolygons(uploadedPolygons);

          const polygon = uploadedPolygons[0];
          if (polygon?.value) {
            const { properties, ...validPolygonValueFields } =
              polygon.value ?? {};

            polygon.value = validPolygonValueFields as GeoJSON.Feature;
          }

          plotsStore.setSelectedPolygon(polygon);
        },
        [plotsStore],
      );

      const handlePolygonChange = useCallback(
        (_event: React.SyntheticEvent, value: PolygonOption | null): void => {
          const currentPolygon = polygons.find(
            ({ name }) => name === value?.label,
          );

          if (!currentPolygon) {
            return;
          }

          plotsStore.setSelectedPolygon(currentPolygon);
        },
        [polygons, plotsStore],
      );

      const onFileChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
          if (target?.files?.[0]) {
            setPolygonInputValue(target?.value);
            handlePolygonsFileChange(target.files[0]);
          }
        },
        [handlePolygonsFileChange],
      );

      const downloadPolygon = useCallback((): void => {
        saveAs(
          new Blob([JSON.stringify(plotsStore.plotAction?.geojson)], {
            type: 'application/json',
          }),
          'polygon.geojson',
        );
      }, [plotsStore.plotAction?.geojson]);

      const selectedCropValue: CropTypeOption | null = useMemo(() => {
        const selectedCrop = plotsStore.cropTypes?.find(
          (crop) => crop.id === plotAction?.crop,
        );

        return selectedCrop
          ? { label: selectedCrop.name, id: selectedCrop.id }
          : null;
      }, [plotsStore.cropTypes, plotAction?.crop]);

      const cropOptions: CropTypeOption[] = useMemo(
        () =>
          plotsStore.cropTypes.map((cropType) => ({
            id: cropType.id,
            label: cropType.name,
          })),
        [plotsStore.cropTypes],
      );

      const categoryOptions: CropTypeOption[] = useMemo(
        () => getCropTypeOption(plotsStore.categories),
        [plotsStore.categories],
      );

      const varietyOptions: CropTypeOption[] = useMemo(
        () => getCropTypeOption(plotsStore.varieties),
        [plotsStore.varieties],
      );

      return (
        <>
          <Space height={1} />
          <Grid direction="column" gap="1rem" align="stretch">
            <Grid direction="row" align="stretch">
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('plot_name')}:</NoWrappingText>
                  <Input
                    required
                    label={t('plot_name')}
                    id="plotName"
                    value={plotsStore.plotAction?.name}
                    variant="outlined"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
              </GridColumnWidth>
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('service_level')}:</NoWrappingText>
                  <Select
                    variant="outlined"
                    disabled={plotsStore.serviceLevel.length === 0}
                    fullWidth
                    value={
                      (plotsStore.plotAction
                        ?.serviceLevel as unknown as HTMLSelectElement) ||
                      ('defaultText' as unknown as HTMLSelectElement)
                    }
                    name="serviceLevel"
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="defaultText" disabled>
                      {t('default_select')}
                    </MenuItem>
                    {plotsStore.serviceLevel.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {t(service.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </GridColumnWidth>
            </Grid>
            <Grid direction="row" align="stretch">
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('description')}:</NoWrappingText>
                  <Input
                    label={t('description')}
                    id="description"
                    value={plotsStore.plotAction?.description}
                    variant="outlined"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
              </GridColumnWidth>
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('irrigation_method')}:</NoWrappingText>
                  <Select
                    fullWidth
                    disabled={plotsStore.irrigationMethods.length === 0}
                    value={
                      (plotsStore.plotAction
                        ?.irrigationMethod as unknown as HTMLSelectElement) ||
                      ('defaultText' as unknown as HTMLSelectElement)
                    }
                    name="irrigationMethod"
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="defaultText" disabled>
                      {t('default_select')}
                    </MenuItem>
                    {plotsStore.irrigationMethods.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </GridColumnWidth>
            </Grid>
            <Divider />
            <Grid direction="row" align="stretch">
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('growing_environment')}:</NoWrappingText>
                  <Select
                    fullWidth
                    disabled={plotsStore.growingType.length === 0}
                    value={
                      (plotsStore.plotAction
                        ?.growingType as unknown as HTMLSelectElement) ||
                      ('defaultText' as unknown as HTMLSelectElement)
                    }
                    name="growingEnvironment"
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="defaultText" disabled>
                      {t('default_select')}
                    </MenuItem>
                    {plotsStore.growingType.map((grow) => (
                      <MenuItem key={grow.id} value={grow.id}>
                        {t(grow.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </GridColumnWidth>
              <GridColumnWidth direction="row">
                <GridFullWidth direction="row">
                  <NoWrappingText>{t('area')}:</NoWrappingText>
                  <GridFullWidth direction="row" gap="1rem">
                    <Select
                      fullWidth
                      value={
                        (plotsStore.plotAction?.area
                          ?.unit as unknown as HTMLSelectElement) ||
                        ('defaultText' as unknown as HTMLSelectElement)
                      }
                      name="areaType"
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="defaultText" disabled>
                        {t('default_select')}
                      </MenuItem>
                      {AREA_UNITS.map((unit) => (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Input
                      required
                      label={t('enter_area')}
                      id="areaNumber"
                      value={plotsStore.plotAction?.area?.val}
                      variant="outlined"
                      type="number"
                      fullWidth
                      onChange={handleChange}
                    />
                  </GridFullWidth>
                </GridFullWidth>
              </GridColumnWidth>
            </Grid>
            <Grid direction="row" align="stretch">
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('location')}:</NoWrappingText>
                  <Input
                    required
                    error={plotsStore.plotManagementValidation.location}
                    label={t('location_example')}
                    id="location"
                    value={plotLocation}
                    disabled={isEditMode}
                    variant="outlined"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
              </GridColumnWidth>
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('soil')}:</NoWrappingText>
                  <Select
                    fullWidth
                    disabled={plotsStore.soilType.length === 0}
                    value={
                      (plotsStore.plotAction
                        ?.soil as unknown as HTMLSelectElement) ||
                      ('defaultText' as unknown as HTMLSelectElement)
                    }
                    name="soil"
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="defaultText" disabled>
                      {t('default_select')}
                    </MenuItem>
                    {plotsStore.soilType.map((soil) => (
                      <MenuItem key={soil.id} value={soil.id}>
                        {t(soil.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </GridColumnWidth>
            </Grid>
            <Grid direction="row" align="stretch">
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('polygon')}:</NoWrappingText>
                  <Autocomplete
                    id="polygon"
                    style={autocompleteStyles}
                    disabled={polygons.length === 0}
                    value={
                      plotsStore.selectedPolygon?.name
                        ? {
                          label: plotsStore.selectedPolygon.name,
                          id: plotsStore.selectedPolygon.value,
                        }
                        : null
                    }
                    options={polygonOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('default_select')}
                      />
                    )}
                    onChange={handlePolygonChange}
                    clearIcon={null}
                  />
                </Grid>
              </GridColumnWidth>
              <GridColumnWidth direction="row" justify="start">
                {isEditMode && !!plotsStore.plotAction?.geojson && (
                  <CheckBoxRoundedIcon
                    color="primary"
                    style={{ marginRight: 8 }}
                  />
                )}
                {isEditMode && (
                  <IconButton
                    disabled={!plotsStore.plotAction?.geojson}
                    aria-label="download"
                    component="label"
                    onClick={downloadPolygon}
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
                <IconButton aria-label="upload" component="label">
                  <PolygonInput
                    hidden
                    onChange={onFileChange}
                    accept=".geojson,.kml,.kmz"
                    type="file"
                    value={polygonInputValue}
                  />
                  <UploadIcon />
                </IconButton>
              </GridColumnWidth>
            </Grid>
            <Divider />
            <Grid direction="row" align="stretch">
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('crop')}:</NoWrappingText>
                  <Autocomplete
                    style={autocompleteStyles}
                    disabled={isEditMode || !plotsStore.cropTypes?.length}
                    value={selectedCropValue}
                    options={cropOptions}
                    renderInput={(params) => (
                      <AutocompleteInput
                        {...params}
                        placeholder={t('default_select')}
                        error={
                          !!(
                            plotsStore.plotAction?.crop &&
                            !plotsStore.varieties.length &&
                            !plotsStore.categories.length
                          )
                        }
                      />
                    )}
                    onChange={(_, value) =>
                      handleCropTypeChange(
                        value?.id as number,
                        CropTypeField.Crop,
                      )
                    }
                    clearIcon={null}
                  />
                </Grid>
              </GridColumnWidth>
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('plant_date')}:</NoWrappingText>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StyledDatePicker
                      disabled={isPlantDateFieldDisabled}
                      renderInput={(params: TextFieldProps) => (
                        <TextField {...params} helperText={plotAge} />
                      )}
                      value={selectedPlantDate}
                      label={t('plant_date')}
                      inputFormat="dd-MMM-yy"
                      mask="__-___-__"
                      onChange={(event: unknown) =>
                        handleDateChange(event, 'plantDate')
                      }
                      maxDate={new Date()}
                      disableMaskedInput
                      PopperProps={{ placement: 'top-start' }}
                    />
                  </LocalizationProvider>
                </Grid>
              </GridColumnWidth>
            </Grid>
            <Grid direction="row" align="stretch">
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('category')}:</NoWrappingText>
                  <Autocomplete
                    style={autocompleteStyles}
                    disabled={isEditMode || !plotsStore.categories?.length}
                    value={getCropTypeValueOption(
                      plotsStore.plotAction?.category,
                    )}
                    options={categoryOptions}
                    renderInput={(params) => (
                      <AutocompleteInput
                        {...params}
                        placeholder={t('default_select')}
                      />
                    )}
                    onChange={(_, value) =>
                      handleCropTypeChange(
                        value?.id as string,
                        CropTypeField.Category,
                      )
                    }
                    clearIcon={null}
                  />
                </Grid>
              </GridColumnWidth>
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('growing_method')}:</NoWrappingText>
                  <Select
                    fullWidth
                    disabled={isEditMode || !plotsStore.growingMethods?.length}
                    value={
                      (plotsStore.plotAction
                        ?.growthMethod as unknown as HTMLSelectElement) ||
                      ('defaultText' as unknown as HTMLSelectElement)
                    }
                    name="growingMethod"
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="defaultText" disabled>
                      {t('default_select')}
                    </MenuItem>
                    {plotsStore.growingMethods.map((growth) => (
                      <MenuItem key={growth} value={growth}>
                        {t(growth)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </GridColumnWidth>
            </Grid>
            <Grid direction="row" align="stretch">
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('variety')}:</NoWrappingText>
                  <Autocomplete
                    style={autocompleteStyles}
                    disabled={isEditMode || !plotsStore.varieties?.length}
                    value={getCropTypeValueOption(
                      plotsStore.plotAction?.variety,
                    )}
                    options={varietyOptions}
                    renderInput={(params) => (
                      <AutocompleteInput
                        {...params}
                        placeholder={t('default_select')}
                      />
                    )}
                    onChange={(_, value) =>
                      handleCropTypeChange(
                        value?.id as string,
                        CropTypeField.Variety,
                      )
                    }
                    clearIcon={null}
                  />
                </Grid>
              </GridColumnWidth>
              <GridColumnWidth direction="column" align="stretch">
                <Grid direction="row">
                  <NoWrappingText>{t('initial_stage')}:</NoWrappingText>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StyledDatePicker
                      disabled={isInitialStageFieldDisabled}
                      renderInput={(params: TextFieldProps) => (
                        <TextField
                          {...params}
                          helperText={t(
                            `phenStageIds:${isCreateMode
                              ? plotsStore.phenologicalStages[0]?.id || ''
                              : plotsStore.plotAction.initialStage?.stageId ||
                              ''
                            }`,
                          )}
                        />
                      )}
                      value={selectedPhenStageDate}
                      label={t('initial_stage')}
                      inputFormat="dd-MMM-yy"
                      mask="__-___-__"
                      onChange={(event: unknown) =>
                        handleDateChange(event, 'initialStage')
                      }
                      disableMaskedInput
                      PopperProps={{ placement: 'top-start' }}
                    />
                  </LocalizationProvider>
                </Grid>
              </GridColumnWidth>
            </Grid>
            {isCreateMode && (
              <>
                <Grid direction="row" align="stretch">
                  <GridColumnWidth direction="column" align="stretch">
                    <Grid direction="row">
                      <NoWrappingText>{t('draft')}:</NoWrappingText>
                      <StatusCheckbox
                        checked={isDraft}
                        onChange={handleStatusChange}
                      />
                    </Grid>
                  </GridColumnWidth>
                </Grid>
                <Divider />
                <Grid direction="row" align="stretch">
                  <GridColumnWidth direction="column" align="stretch">
                    <Grid direction="row">
                      <NoWrappingText>
                        {t('attach_virtual_current_sensor')}:
                      </NoWrappingText>
                      <SensorOptionSwitch
                        color="primary"
                        checked={isAttachVirtualCurrentSensor}
                        onChange={(): void => {
                          setIsAttachVirtualCurrentSensor(
                            !isAttachVirtualCurrentSensor,
                          );
                        }}
                      />
                    </Grid>
                  </GridColumnWidth>
                  <GridColumnWidth direction="column" align="stretch">
                    <Grid direction="row">
                      <NoWrappingText>
                        {t('attach_virtual_forecast_sensor')}:
                      </NoWrappingText>
                      <SensorOptionSwitch
                        color="primary"
                        checked={isAttachVirtualForecastSensor}
                        onChange={(): void => {
                          setIsAttachVirtualForecastSensor(
                            !isAttachVirtualForecastSensor,
                          );
                        }}
                      />
                    </Grid>
                  </GridColumnWidth>
                </Grid>
                <Grid direction="row" align="stretch">
                  <GridColumnWidth direction="column" align="stretch">
                    <Grid direction="row">
                      <NoWrappingText>{t('attach_iw_sensor')}:</NoWrappingText>
                      <SensorOptionSwitch
                        disabled={!plotsStore.selectedPolygon}
                        color="primary"
                        checked={isAttachIWSensor}
                        onChange={() => {
                          setIsAttachIWSensor(!isAttachIWSensor);
                        }}
                      />
                    </Grid>
                  </GridColumnWidth>
                </Grid>
              </>
            )}
          </Grid>
          <Space height={1} />
        </>
      );
    },
  ),
);

export default PlotFormProperties;
