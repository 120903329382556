import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { TabViewTabChangeParams } from 'primereact/tabview';
import { useStores } from 'stores/hooks/hooks';
import Loading from 'components/UI/Loading/Loading';
import { TabPanel, TabView } from 'components/UI/TabView';
import PlotsSensorsTable from './PlotsSensorsTable';
import PlotDetails from './PlotDetails';

const Container = styled.div`
  width: 100%;

  .p-tabview-panels {
    height: 100%;
  }

  .p-tabview-nav {
    margin: 0.5rem 1rem;
  }
`;

enum TabKey {
  Details = 0,
  Sensors = 1,
}

const PlotSensors: FC = observer(() => {
  const { t } = useTranslation('grower');
  const [tabIndex, setTabIndex] = useState(TabKey.Sensors);
  const { resellersStore } = useStores();
  const isLoading = resellersStore.growerSensors.loading;

  const onTabChange = useCallback(({ index }: TabViewTabChangeParams) => {
    setTabIndex(index);
  }, []);

  return (
    <Container>
      <TabView
        activeIndex={tabIndex}
        renderActiveOnly
        onTabChange={onTabChange}
      >
        <TabPanel header={t('details')}>
          <PlotDetails />
        </TabPanel>
        <TabPanel header={t('sensors')}>
          {isLoading ? <Loading /> : <PlotsSensorsTable />}
        </TabPanel>
      </TabView>
    </Container>
  );
});

export default PlotSensors;
