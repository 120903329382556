import { useMemo } from 'react';
import { add as addDays, format } from 'date-fns';
import { AgeRange, PhenStageColumn } from 'models/protocol';

export default function useModelAgeRanges(
  phenStageWorkingDate: Date,
  ageRange: AgeRange | null,
): PhenStageColumn[] {
  return useMemo(() => {
    let temp: PhenStageColumn[] = [];
    if (phenStageWorkingDate) {
      ageRange?.phenologicalStage.reduce(
        (prev: PhenStageColumn | null, curr, index) => {
          const date = prev
            ? addDays(prev.date as Date, {
                days: curr.stageTransition.calendarDays,
              })
            : phenStageWorkingDate;

          let duration: number | null = null;
          if (ageRange.phenologicalStage.length > index + 1) {
            duration =
              ageRange.phenologicalStage[index + 1]?.stageTransition
                .calendarDays || null;
          }

          let fdelta = 0;
          fdelta = ageRange.phenologicalStage[index]?.fdelta;

          const ageRangeItem: PhenStageColumn = {
            name: curr.name,
            duration,
            date,
            dateEnd: addDays(date, {
              days: duration || 0,
            }),
            fdelta: fdelta === 0 ? '0' : fdelta,
          };

          temp.push(ageRangeItem);
          return ageRangeItem;
        },
        null,
      );

      temp = temp.map((el) => ({
        ...el,
        date: format(el.date as Date, 'dd-MMM-yy'),
        dateEnd: format(el.dateEnd as Date, 'dd-MMM-yy'),
      }));
    }

    return temp;
  }, [ageRange, phenStageWorkingDate]);
}
