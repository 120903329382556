import { Typography, TypographyProps } from '@mui/material';
import React, { FC, forwardRef, memo } from 'react';
import styled from 'styled-components';
import { theme } from 'components/UI';

export type TextSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type TextWeight =
  | 'thin'
  | 'light'
  | 'normal'
  | 'medium'
  | 'bold'
  | 'heavy';
// thin = 100 | light = 300 | normal = 300 | medium = 500 | bold = 700 | heavy = 900

interface StyledTextProps {
  $size?: TextSize;
  $weight?: TextWeight;
  $textcolor?: string;
  $opacity?: number;
  $textAlign?: string;
  $singleline?: boolean;
  $inline?: boolean;
}

interface TextProps {
  size?: TextSize;
  weight?: TextWeight;
  textcolor?: string;
  opacity?: number;
  textAlign?: string;
  singleline?: boolean;
  inline?: boolean;
}

const weights: { [key: string]: number } = {
  thin: 100,
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
  heavy: 900,
};

const sizes: { [key: string]: string } = {
  xxs: theme.fontSize.xxs,
  xs: theme.fontSize.xs,
  sm: theme.fontSize.sm,
  md: theme.fontSize.md,
  lg: theme.fontSize.lg,
  xl: theme.fontSize.xl,
  xxl: theme.fontSize.xxl,
};

const BaseText = styled(Typography)<StyledTextProps & TypographyProps>`
  font-weight: ${(p) => weights[p.$weight || weights.normal]};
  font-size: ${(p) => sizes[p.$size || sizes.md]};
  ${(p) => (p.$textcolor ? `color: ${p.$textcolor};` : '')};
  word-spacing: normal;
  letter-spacing: normal;
  opacity: ${(p) => p.$opacity || null};
  text-align: ${(p) => p.$textAlign || null};
  display: ${(p) => (p.$inline ? 'inline-block' : 'block')};
  ${(p) =>
    p.$singleline &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
`;

const Text: FC<TextProps & TypographyProps> = forwardRef((props, ref) => {
  const {
    size,
    weight,
    textcolor,
    opacity,
    textAlign,
    singleline,
    inline,
    children,
    ...rest
  } = props;

  return (
    <BaseText
      ref={ref}
      $size={size}
      $weight={weight}
      $textcolor={textcolor}
      $opacity={opacity}
      $textAlign={textAlign}
      $singleline={singleline}
      $inline={inline}
      {...rest}
    >
      {children}
    </BaseText>
  );
});

export default memo(Text);
