import React, { FC, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Grid from 'components/UI/Grid';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import styled from 'styled-components';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FeatureCreateFormStepProps } from '../FeatureCreateFormProperties';

const Container = styled(Grid)`
  padding-top: 1rem;
`;

const Column = styled(Grid)`
  min-width: 220px;
  padding: 1rem 0;
`;

const FeatureConfigurationCreateStep: FC<FeatureCreateFormStepProps> = observer(
  ({ register, errors }) => {
    const { t } = useTranslation('feature');
    const { resellersStore, featuresStore } = useStores();

    const handlePlotChange = useCallback(
      (event: SelectChangeEvent<number>) => {
        featuresStore.setFeaturePlot(Number(event.target.value));
      },
      [featuresStore],
    );

    return (
      <Container direction="row" justify="center" align="start" gap="2rem">
        <Column direction="column" justify="start" gap="1rem">
          <TextField
            {...register('name')}
            defaultValue={featuresStore.createFeatureForm?.name || ''}
            label={t('name')}
            error={!!errors.name}
          />
        </Column>
        <Column direction="column" justify="start" gap="1rem">
          <TextField
            {...register('displayName')}
            defaultValue={featuresStore.createFeatureForm?.displayName || ''}
            label={t('displayName')}
            error={!!errors.displayName}
          />
        </Column>
        <Column direction="column" justify="start" gap="1rem">
          <Select
            {...register('plot')}
            fullWidth
            name="plot"
            onChange={handlePlotChange}
            value={featuresStore.createFeatureForm?.plotId || -1}
          >
            <MenuItem value={-1} disabled>
              {t('select_plot')}
            </MenuItem>
            {resellersStore.resellerPlots.map((plot) => (
              <MenuItem key={plot.id} value={plot.id}>
                {plot.name}
              </MenuItem>
            ))}
          </Select>
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="active"
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      {...register('active')}
                      checked={value}
                      onChange={onChange}
                    />
                  )}
                />
              }
              label={<Text weight="bold">{t('active')}</Text>}
            />
          </FormGroup> */}
        </Column>
      </Container>
    );
  },
);

export default FeatureConfigurationCreateStep;
