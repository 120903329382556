import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';
import Space from 'components/UI/Space';
import Input from 'components/UI/Input';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { useStores } from 'stores/hooks/hooks';
import { DEFAULT_USER_PROFILE_ID } from 'core/constants';
import { UserPreferences } from 'models/user';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { UserFormFieldsSchema } from './UserManagementFormSchemas';

interface UserPreferencesEdit {
  control: Control<UserFormFieldsSchema>;
}

const NoWrappingText = styled(Text)`
  white-space: nowrap;
`;

const UserPreferencesEdit: FC<UserPreferencesEdit> = observer(({ control }) => {
  const { t } = useTranslation('users');
  const { membersStore } = useStores();

  const handleResetPreferencesClick = useCallback(() => {
    console.log('reset preferences');
  }, []);

  return (
    <>
      <Space height={1} />
      <Grid direction="column" gap="1rem" align="stretch">
        <Grid direction="row" gap="2.1rem" grow>
          <NoWrappingText>{t('user_origin')}:</NoWrappingText>
          <Controller
            control={control}
            name="userPreferences.profileId"
            render={({ field: { onChange, value } }) => (
              <Input
                select
                fullWidth
                value={value}
                label={t('user_origin')}
                defaultValue={DEFAULT_USER_PROFILE_ID}
                onChange={(event) => {
                  onChange(Number(event.target.value));
                }}
                SelectProps={{
                  native: true,
                }}
              >
                {JSON.parse(JSON.stringify(membersStore.userPreferences)).map(
                  (option: UserPreferences) => (
                    <option key={option.profileId} value={option.profileId}>
                      {t(option.profileDescription)}
                    </option>
                  ),
                )}
              </Input>
            )}
          />
        </Grid>
        <Space height={3} />
        <Grid direction="row" align="stretch">
          <Button onClick={handleResetPreferencesClick}>
            <Text textcolor={theme.color.white} size="sm">
              {t('reset_unique_preferences')}
            </Text>
          </Button>
        </Grid>
      </Grid>
      <Space height={1} />
    </>
  );
});

export default UserPreferencesEdit;
