import { observer } from 'mobx-react';
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useState,
} from 'react';
import { BrowserRoutes } from 'core/enums';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Text from 'components/UI/Text';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from 'components/UI';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from '../../../stores/hooks/hooks';
import OutlinedInput from '../../shared/OutlinedInput';

const LoginContainer = styled.div``;

const LoginForm = styled.form`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.color.cascadingWhite};
  padding: 0 2rem 1rem 2rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-block: 0.25rem;
  padding-bottom: 0.5rem;
`;

const FormHeaderContainer = styled.div`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
`;

const StyledButton = styled(Button)`
  background-color: ${(p) => p.theme.color.leafGreen};

  &:hover {
    background-color: ${(p) => p.theme.color.leafGreen};
  }
`;

export type PasswordResetParams = {
  mail: string;
  token: string;
  locale: string;
};

const PasswordResetInput: FC = observer(() => {
  const { userStore, snackBarStore } = useStores();
  const { mail, token, locale } = useParams<PasswordResetParams>();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('auth');

  const handleNewPasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setNewPassword(e.target.value);
    },
    [],
  );

  const handleClick = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      userStore
        .resetPassword(newPassword, mail || '', token || '')
        .then(() => {
          navigate(BrowserRoutes.AUTH);
        })
        .catch((err) => {
          setIsLoading(false);
          snackBarStore.showToast({ detail: err.message });
        });
    },
    [snackBarStore, newPassword, mail, token, userStore, navigate],
  );

  const handleRepeatPasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setRepeatPassword(e.target.value);
    },
    [setRepeatPassword],
  );

  // if (!state.email && !state.passwordToken) {
  //   <Redirect to={BrowserRoutes.AUTH} />;
  // }

  const isUpdateDisabled =
    !newPassword || !repeatPassword || newPassword !== repeatPassword;

  return (
    <LoginContainer>
      <LoginForm>
        <FormHeaderContainer>
          <Text weight="bold" size="sm">
            {t('reset_password')}
          </Text>
        </FormHeaderContainer>
        <InputContainer>
          <OutlinedInput
            placeholder={t('new_password')}
            id="new_password"
            type="password"
            autoComplete="new-password"
            onChange={handleNewPasswordChange}
          />
        </InputContainer>
        <InputContainer>
          <OutlinedInput
            placeholder={t('repeat_password')}
            id="repeat_password"
            type="password"
            autoComplete="new-password"
            onChange={handleRepeatPasswordChange}
          />
        </InputContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <StyledButton
            type="submit"
            onClick={handleClick}
            disabled={isUpdateDisabled}
          >
            <Text textcolor={theme.color.white} size="sm" weight="bold">
              {t('update')}
            </Text>
          </StyledButton>
        )}
      </LoginForm>
    </LoginContainer>
  );
});

export default PasswordResetInput;
