import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import MultiSelect from 'components/UI/MultiSelect';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';
import PrimeReactSelect from 'components/UI/Select';
import Button from 'components/UI/Button';
import { getPlotsMergedNames } from 'utils/plotSensorsUtils';
import { ActionMode } from 'models/shared';
import {
  ColumnBodyOptions,
  ColumnBodyType,
  ColumnEditorOptions,
  ColumnEditorType,
  ColumnEventParams,
} from 'primereact/column';
import {
  SensorsEditableRow,
  SensorsSelectablePlotValue,
  SensorsSelectableValue,
} from './SensorsEditableTable';
import { EditableRowParamsValues } from './EditTableRowDialog';

const StyledSelect = styled(PrimeReactSelect)`
  border: none;
`;

const StyledMultiSelect = styled(MultiSelect)`
  border: none;
`;

const StyledTextField = styled(TextField)`
  input {
    padding: 0.5rem;
  }
`;

const ColumnButton = styled(Button)`
  color: ${({ theme }) => theme.color.text};
`;

interface EditableRowParamsData
  extends Pick<SensorsEditableRow, 'name' | 'originalSensor'> {
  params: EditableRowParamsValues;
}

interface useSensorsTableRowsActionsValue {
  onEditRowDialogClose(): void;
  onEditRowDialogSubmit(data: EditableRowParamsValues): void;
  getCheckboxBody: ColumnBodyType;
  getButtonBody: ColumnBodyType;
  getSelectBody: ColumnBodyType;
  getMultiSelectBody: ColumnBodyType;
  getCellEditor: ColumnEditorType;
  onCellEditComplete(event: ColumnEventParams): void;
  isMoreDialogVisible: boolean;
  editableRowValues: EditableRowParamsData;
}

interface useSensorsTableRowsActionsProps {
  mode: ActionMode;
  rows: SensorsEditableRow[];
  setRows(data: SensorsEditableRow[]): void;
  disabled?: boolean;
}

const useSensorsTableRowsActions = ({
  mode,
  rows,
  setRows,
  disabled,
}: useSensorsTableRowsActionsProps): useSensorsTableRowsActionsValue => {
  const [isMoreDialogVisible, setIsMoreDialogVisible] =
    useState<boolean>(false);

  const [editableRowId, setEditableRowId] = useState<number>(0);

  const updateRow = useCallback(
    (rowId: number, data: Partial<SensorsEditableRow>) => {
      const updatedRows = [...rows];
      const updatedRowindex = updatedRows.findIndex((row) => row.id === rowId);

      updatedRows[updatedRowindex] = {
        ...updatedRows[updatedRowindex],
        ...data,
      };

      setRows(updatedRows);
    },
    [rows, setRows],
  );

  const onCellEditComplete = useCallback(
    ({ field, newValue, rowData }: ColumnEventParams) => {
      const isValueChanged = newValue !== rowData[field];
      if (isValueChanged) {
        updateRow(rowData.id, {
          [field]: newValue,
        });
      }
    },
    [updateRow],
  );

  const getCellEditor = useCallback(
    (options: ColumnEditorOptions) =>
      disabled ? (
        options.value
      ) : (
        <StyledTextField
          type={options.column.props.dataType}
          value={options.value}
          onChange={(event) => {
            options.editorCallback?.(event?.target?.value);
          }}
        />
      ),
    [disabled],
  );

  const getSelectBody = useCallback(
    (data: SensorsEditableRow, { field }: ColumnBodyOptions) => {
      const fieldValues = data[
        field as keyof SensorsEditableRow
      ] as SensorsSelectableValue;

      if (disabled) {
        const selectedOption = fieldValues.options?.find(
          (option) => option.value === fieldValues.value,
        );

        return selectedOption?.label ?? '';
      }

      return (
        <StyledSelect
          value={fieldValues.value}
          options={fieldValues.options}
          onChange={(event) => {
            updateRow(data.id, {
              ...data,
              [field]: { ...fieldValues, value: event.value },
            });
          }}
        />
      );
    },
    [updateRow, disabled],
  );

  const getMultiSelectBody = useCallback(
    (data: SensorsEditableRow, { field }: ColumnBodyOptions) => {
      const fieldValues = data[
        field as keyof SensorsEditableRow
      ] as SensorsSelectablePlotValue;

      if (disabled || mode === ActionMode.EDIT) {
        const message = getPlotsMergedNames(fieldValues.value);
        return (
          <Tooltip arrow title={message} placement="top">
            <span>{message}</span>
          </Tooltip>
        );
      }

      return (
        <StyledMultiSelect
          filter
          value={fieldValues.value}
          options={fieldValues.options}
          onChange={(event) => {
            updateRow(data.id, {
              ...data,
              [field]: { ...fieldValues, value: event.value },
            });
          }}
        />
      );
    },
    [updateRow, disabled, mode],
  );

  const getButtonBody = useCallback(
    ({ id }: SensorsEditableRow) => (
      <ColumnButton
        variant="text"
        onClick={() => {
          setIsMoreDialogVisible(true);
          setEditableRowId(id);
        }}
      >
        ...
      </ColumnButton>
    ),
    [],
  );

  const getCheckboxBody = useCallback(
    (data: SensorsEditableRow, { field }: ColumnBodyOptions) => (
      <Checkbox
        checked={data.selected}
        onChange={(_event, value) => {
          updateRow(data.id, { [field]: value });
        }}
      />
    ),
    [updateRow],
  );

  const onEditRowDialogSubmit = useCallback(
    (params: Partial<EditableRowParamsValues>) => {
      const rowData = rows.find((row) => row.id === editableRowId);
      updateRow(editableRowId as number, { ...rowData, params });
      setIsMoreDialogVisible(false);
    },
    [updateRow, rows, editableRowId],
  );

  const onEditRowDialogClose = useCallback(() => {
    setIsMoreDialogVisible(false);
  }, []);

  const editableRowValues = useMemo(() => {
    const editableRow = rows.find((row) => row.id === editableRowId);
    const editableRowParams = editableRow?.params;
    return {
      name: editableRow?.name,
      originalSensor: editableRow?.originalSensor,
      params: {
        altitude: editableRowParams?.altitude,
        depth: editableRowParams?.depth,
        companyUuid: editableRowParams?.companyUuid,
        valveId: editableRowParams?.valveId,
        alias: editableRowParams?.alias,
      },
    } as EditableRowParamsData;
  }, [rows, editableRowId]);

  return {
    onEditRowDialogClose,
    onEditRowDialogSubmit,
    getCheckboxBody,
    getButtonBody,
    getSelectBody,
    getMultiSelectBody,
    getCellEditor,
    onCellEditComplete,
    isMoreDialogVisible,
    editableRowValues,
  };
};

export default useSensorsTableRowsActions;
