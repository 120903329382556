import React, { ChangeEvent, FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Tree, TreeEventNodeParams, TreeExpandedParams } from 'primereact/tree';
import TreeNode from 'primereact/treenode';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import Tooltip from 'components/UI/Tooltip';
import { useStores } from 'stores/hooks/hooks';
import {
  ResellerTreeNode,
  CustomTreeNode,
  ResellerTreeMode,
} from 'models/grower';
import {
  TREE_NODE_HIDDEN_CLASS_NAME,
  TREE_NODE_INACTIVE_CLASS_NAME,
} from 'core/constants';

interface ResellerTreeProps {
  mode: ResellerTreeMode;
}

interface TemplateProps {
  node: TreeNode;
}

const StyledTree = styled(Tree)`
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow: auto;
  border: none;
  padding: 0;

  .p-tree-container {
    display: flex;
    flex-direction: column;
    overflow: visible;
    height: 100%;
    width: 100%;
  }

  .p-treenode {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .p-highlight {
    z-index: 1;
  }

  .p-treenode-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-treenode-children {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .p-treenode-content {
    width: 100%;
  }

  .pi-fw {
    text-align: start;
  }

  .${TREE_NODE_INACTIVE_CLASS_NAME} {
    .p-treenode-content {
      border-radius: 5px;
      background-color: ${({ theme }) => theme.color.palePink};
    }
  }

  .${TREE_NODE_HIDDEN_CLASS_NAME} {
    display: none;
  }
`;

const ResellersTreeContainer = styled(Grid)`
  height: 100%;
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.white};
  border: ${({ theme }) => `1px solid ${theme.color.border}`};
`;

const Template: FC<TemplateProps> = ({ node }) => {
  const { label = '' } = node;
  return (
    <Tooltip arrow title={label} placement="top">
      <Text singleline>{label}</Text>
    </Tooltip>
  );
};

const ResellersTree: FC<ResellerTreeProps> = observer(({ mode, ...rest }) => {
  const { resellersStore } = useStores();
  const { t } = useTranslation('grower');

  if (!resellersStore.resellerTree) {
    return null;
  }

  const onSelect = useCallback(
    ({ node }: TreeEventNodeParams) => {
      const isSameNodeSelected =
        node.data.id === resellersStore.selectedGrowerId;

      if (isSameNodeSelected) {
        return;
      }

      resellersStore.setSelectedTreeNode(
        node as CustomTreeNode<ResellerTreeNode>,
        mode,
      );

      if (node.children?.length) {
        resellersStore.addExpandedTreeNodes(node.key as string, mode);
      }
    },
    [mode, resellersStore],
  );

  const onToggle = useCallback(
    (e: TreeExpandedParams) => {
      resellersStore.setExpandedTreeNodes(e.value, mode);
    },
    [mode, resellersStore],
  );

  const onActiveCheckboxChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, value: boolean) => {
      resellersStore.setTreeDisplayInactiveValue(value);
    },
    [resellersStore],
  );

  return (
    <ResellersTreeContainer align="stretch" overflow="auto" {...rest}>
      <StyledTree
        value={
          mode === ResellerTreeMode.All
            ? resellersStore.dataTree
            : resellersStore.dataResellerTree
        }
        filter
        selectionMode="single"
        nodeTemplate={(node) => <Template node={node} />}
        selectionKeys={
          mode === ResellerTreeMode.All
            ? resellersStore.selectedTreeNodeKey
            : resellersStore.selectedResellerTreeNodeKey
        }
        onSelect={onSelect}
        onToggle={onToggle}
        expandedKeys={
          mode === ResellerTreeMode.All
            ? resellersStore.expandedTreeNodes
            : resellersStore.expandedResellerTreeNodes
        }
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={resellersStore.isInactiveNodesVisible}
              onChange={onActiveCheckboxChange}
            />
          }
          label={<Text>{t('add_inactive')}</Text>}
        />
      </FormGroup>
    </ResellersTreeContainer>
  );
});

export default ResellersTree;
