import { observer } from 'mobx-react';
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useState,
} from 'react';
import styled from 'styled-components';
import { BrowserRoutes } from 'core/enums';
import { useTranslation } from 'react-i18next';
import Text from 'components/UI/Text';
import { Link, useNavigate } from 'react-router-dom';
import { theme } from 'components/UI';
import Button from 'components/UI/Button';
import validator from 'validator';
import { Validation } from 'components/UI/Input/Input';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from '../../../stores/hooks/hooks';
import OutlinedInput from '../../shared/OutlinedInput';

const LoginContainer = styled.div``;

const ForgotPasswordForm = styled.form`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.color.cascadingWhite};
  padding: 0 2rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-block: 0.25rem;
  padding-bottom: 0.5rem;
`;

const FormHeaderContainer = styled.div`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
`;

const StyledButton = styled(Button)`
  background-color: ${(p) => p.theme.color.leafGreen};

  &:hover {
    background-color: ${(p) => p.theme.color.leafGreen};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:visited {
    color: inherit;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ForgotPasswordContainer = styled.div`
  padding: 0.5rem 0;
`;

const ForgetPasswordInput: FC = () => {
  const { userStore, snackBarStore } = useStores();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('auth');
  const onChangeConfirmEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setEmail(e.target.value);
    },
    [],
  );

  const emailValidation: Validation = useCallback((text: string) => {
    const isValid = validator.isEmail(text);
    return {
      isValid,
      message: isValid ? '' : '',
    };
  }, []);

  const handleClick = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (!emailValidation(email).isValid) {
        snackBarStore.showToast({ detail: t('validation:email') });
      } else {
        setIsLoading(true);
        userStore
          .forgotPassword(email)
          .then((success: boolean) => {
            if (success) {
              navigate(BrowserRoutes.AUTH);
            } else {
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            snackBarStore.showToast({ detail: err.message });
          });
      }
    },
    [snackBarStore, email, emailValidation, userStore, navigate, t],
  );

  return (
    <LoginContainer>
      <ForgotPasswordForm>
        <FormHeaderContainer>
          <Text weight="bold" size="sm">
            {t('forgot_password')}
          </Text>
        </FormHeaderContainer>
        <InputContainer>
          <OutlinedInput
            validation={emailValidation}
            placeholder={t('email')}
            id="email"
            type="email"
            autoComplete="username"
            onChange={onChangeConfirmEmail}
          />
        </InputContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <StyledButton type="submit" onClick={handleClick}>
            <Text textcolor={theme.color.white} size="sm" weight="bold">
              {t('submit')}
            </Text>
          </StyledButton>
        )}
        <ForgotPasswordContainer>
          <Text size="sm" textcolor={theme.color.antiqueTin} align="right">
            <StyledLink to={`${BrowserRoutes.AUTH}`}>{t('login')}</StyledLink>
          </Text>
        </ForgotPasswordContainer>
      </ForgotPasswordForm>
    </LoginContainer>
  );
};

export default observer(ForgetPasswordInput);
