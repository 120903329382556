import { useMemo } from 'react';
import { LatLngExpression } from 'leaflet';
import { MapProps } from 'components/shared/Map/Map.types';
import { MapEntityProps } from 'components/shared/Map/MapEntity/MapEntity.types';
import { GqlPlotAlerts } from 'gql/types/plots';
import {
  getTechnicalAlertsMapDetails,
  getTechnicalAlertsMapEntities,
} from './useTechnicalAlertsMapEntities.utils';

interface UseTechnicalAlertsMapEntitiesProps {
  alertsData: GqlPlotAlerts;
  showOnlyAlerts?: boolean;
}

export interface TechnicalAlertsMapDetails {
  plotsCount: number;
  alertsCount: number;
  plotsLocation: LatLngExpression | null;
}

interface UseTechnicalAlertsMapEntitiesValue {
  mapEntities: MapProps['mapEntities'];
  mapDetails: TechnicalAlertsMapDetails;
}

const useTechnicalAlertsMapEntities = ({
  alertsData,
  showOnlyAlerts,
}: UseTechnicalAlertsMapEntitiesProps): UseTechnicalAlertsMapEntitiesValue => {
  const mapEntities: MapEntityProps[] = useMemo(
    () =>
      getTechnicalAlertsMapEntities(alertsData?.plots ?? [], showOnlyAlerts),
    [alertsData?.plots, showOnlyAlerts],
  );

  const mapDetails: TechnicalAlertsMapDetails = useMemo(
    () => getTechnicalAlertsMapDetails(alertsData?.plots ?? []),
    [alertsData?.plots],
  );

  return {
    mapEntities,
    mapDetails,
  };
};

export default useTechnicalAlertsMapEntities;
