import React, { FC } from 'react';
import styled from 'styled-components';
import Grow from 'components/UI/Grow';
import PasswordResetInput from 'components/pages/PasswordReset/PasswordResetInput';
import AuthHeader from 'components/pages/Login/AuthHeader';

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const PasswordResetContainer = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

const PasswordReset: FC = () => {
  return (
    <Page>
      <ContentContainer>
        <Grow />
        <PasswordResetContainer>
          <AuthHeader />
          <PasswordResetInput />
        </PasswordResetContainer>
      </ContentContainer>
    </Page>
  );
};

export default PasswordReset;
