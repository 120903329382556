import { SENSOR_VALVE_TYPE_PREFIX } from 'core/constants';
import { SensorCreatePayload } from 'models/api/sensor';
import { WebPlot } from 'models/plot';
import { EditableSensor, Sensor, SensorGroup } from 'models/sensor';
import { SystemSensorType } from 'models/systems';
import veryLocalStorage from './vls';

export const getAttachedSensorsRecord = (
  sensors: EditableSensor[],
): Record<string, Sensor[]> => {
  const result: Record<string, Sensor[]> = {};
  const attachedSensors: EditableSensor[] = sensors.filter(
    ({ details }) => !!details.plots?.length,
  );

  attachedSensors.forEach(({ sensor, details }) => {
    details.plots?.forEach((plot) => {
      const plotSensors = result[plot.id];
      result[plot.id] = Array.isArray(plotSensors)
        ? [...plotSensors, sensor]
        : [sensor];
    });
  });

  return result;
};

export const getSensorCreatePayload = (
  sensor: Sensor,
  sensorTypes: SystemSensorType[],
): SensorCreatePayload => {
  const sensorTypeId = sensorTypes.find(
    ({ type }) => type === sensor.sensorType?.type,
  )?.id;

  return {
    ...sensor,
    isPublic: sensor.isPublic ? 1 : 0,
    sensorType: sensor.sensorType?.type,
    typeId: sensorTypeId ?? null,
    params: JSON.stringify(sensor.params ?? {}),
  };
};

export const buildSensorQuery = (sensor: Sensor, systemId: number): string => {
  const defaultKeys: Array<keyof Sensor> = [
    'displayName',
    'serial',
    'systemSerial',
    'latitude',
    'longitude',
    'connectivity',
  ];

  const isPublicValue = sensor.isPublic ? 1 : 0;
  const paramsValue = encodeURIComponent(JSON.stringify(sensor.params ?? {}));
  const locationValue = `${sensor.latitude},${sensor.longitude}`;
  const defaultQuery = defaultKeys
    .map((key) => {
      const value = (sensor[key as keyof Sensor] as string) ?? '';
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return `?${defaultQuery}&systemId=${systemId}&sensorType=${sensor.sensorType?.type}&isPublic=${isPublicValue}&location=${locationValue}&params=${paramsValue}`;
};

export const getDefaultEditableSensors = (
  sensors: Sensor[],
  selected?: boolean,
): EditableSensor[] => {
  return sensors.map((sensor) => ({
    sensor,
    details: { selected: !!selected, plots: [] },
  }));
};

export const getPlotsMergedNames = (plots: WebPlot[]): string => {
  return plots?.length ? plots.map(({ name }) => name).join(', ') : '';
};

export const checkIsSensorValveType = (sensor: Sensor): boolean => {
  return (
    sensor?.sensorType?.group?.toLowerCase() ===
      SensorGroup.Irrigation.toLowerCase() &&
    sensor?.sensorType?.type?.startsWith(SENSOR_VALVE_TYPE_PREFIX)
  );
};

export const checkIsEditableSensorsValid = (
  sensors: EditableSensor[],
): boolean => {
  return sensors?.every(({ sensor }) => !!sensor.displayName);
};

export const generateCreateSensorBatch = (sensors: SensorCreatePayload[]): SensorCreatePayload[] => {
  const modifiedSensors = [...sensors];
  modifiedSensors.forEach(sensor => {
    if(sensor.params) {
      const params = JSON.parse(sensor.params);
      if (params?.alias) {
        veryLocalStorage.set('alias', params.alias);
        sensor.alias = params.alias;
        delete params.alias;
        sensor.params = JSON.stringify(params);
      }

      if ('alias' in params) {
        delete params.alias;
        sensor.params = Object.keys(params).length === 0 ? '' : JSON.stringify(params);
      }
    }
  });

  return modifiedSensors;
}

export const generateAttachMultiplePlotToSensor = (sensors: Sensor[]): Sensor[] => {
  const modifiedSensors = [...sensors];
  modifiedSensors.forEach(sensor => {
    if (sensor.params?.alias) {
      sensor.alias = sensor.params?.alias || '';
      delete sensor.params?.alias;
    } else {
      sensor.alias = veryLocalStorage.get('alias') || '';
      veryLocalStorage.remove('alias');
    }

    if (sensor.params && 'alias' in sensor.params) {
      delete sensor.params.alias;
    }
  });

  return modifiedSensors;
}
