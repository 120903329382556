import React, {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import {
  DataTableProps,
  DataTableRowClickEventParams,
} from 'primereact/datatable';
import {
  DataTable,
  Column,
  PrimeReactDataTable,
} from 'components/shared/DataTable/DataTable';

const checkboxColumnClassName = 'data-table-column-checkbox';

const StyledDataTable = styled(DataTable)`
  .${checkboxColumnClassName} {
    flex: 0 0 auto !important;
    width: 4rem;
    padding: 0 !important;
    justify-content: center;

    .MuiCheckbox-root {
      width: 100%;
      height: 100%;
    }
  }

  .p-datatable-thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RowType = Record<string, any> | null;

export interface MultiselectDataTableRowClickEventParams<T>
  extends DataTableRowClickEventParams {
  selection: T[];
}

export interface MultiselectDataTableChangeEvent<T> {
  activeRow: T;
  selection: T[];
  params: DataTableRowClickEventParams;
}

interface MultiselectDataTableProps<T>
  extends Omit<DataTableProps, 'selectionMode' | 'multiple'> {
  variant?: 'default' | 'strict';
  activeRow: T;
  selection: T[];
  onMultiselectChange(event: MultiselectDataTableChangeEvent<T>): void;
}

export const MultiselectDataTable = forwardRef<
  PrimeReactDataTable,
  PropsWithChildren<MultiselectDataTableProps<RowType>>
>(
  (
    {
      onRowClick,
      onMultiselectChange,
      selection,
      activeRow,
      children,
      dataKey,
      variant = 'default',
      ...tableProps
    },
    ref,
  ) => {
    const rowKey = dataKey as string;
    const selectedRowKeys: Set<number> = useMemo(
      () => new Set(selection.map((row) => row?.[rowKey])),
      [selection, rowKey],
    );

    const getMultiSelection = useCallback(
      (params: DataTableRowClickEventParams): RowType[] => {
        const { data } = params;
        if (selectedRowKeys.has(data[rowKey])) {
          return selection.filter((row) => row?.[rowKey] !== data[rowKey]);
        }

        return [...selection, data];
      },
      [rowKey, selection, selectedRowKeys],
    );

    const handleCheckboxClick = useCallback(
      (params: DataTableRowClickEventParams) => {
        onMultiselectChange({
          params,
          activeRow: null,
          selection: getMultiSelection(params),
        });
      },
      [getMultiSelection, onMultiselectChange],
    );

    const handleRowClick = useCallback(
      (params: DataTableRowClickEventParams) => {
        const isSame = activeRow?.[rowKey] === params.data?.[rowKey];
        if (variant === 'default') {
          onMultiselectChange({
            params,
            activeRow: isSame ? null : params.data,
            selection: [],
          });
        } else {
          onMultiselectChange({
            params,
            activeRow: null,
            selection: getMultiSelection(params),
          });
        }

        onRowClick?.(params);
      },
      [
        variant,
        activeRow,
        rowKey,
        onRowClick,
        getMultiSelection,
        onMultiselectChange,
      ],
    );

    const getCheckboxBody = useCallback(
      (data: RowType) => (
        <StyledCheckbox
          checked={selectedRowKeys.has(data?.[rowKey])}
          disabled={!data?.[rowKey]}
          onChange={() =>
            handleCheckboxClick({ data } as DataTableRowClickEventParams)
          }
        />
      ),
      [handleCheckboxClick, selectedRowKeys, rowKey],
    );

    const getActiveRowSelection = useCallback(
      () => (activeRow ? [activeRow] : []),
      [activeRow],
    );

    const selectedRows = useMemo(
      () => (variant === 'default' ? getActiveRowSelection() : selection),
      [variant, selection, getActiveRowSelection],
    );

    return (
      <StyledDataTable
        {...tableProps}
        multiple
        selectionMode="multiple"
        ref={ref}
        dataKey={dataKey}
        selection={selectedRows}
        onRowClick={handleRowClick}
      >
        <Column
          header=""
          body={getCheckboxBody}
          className={checkboxColumnClassName}
        />
        {children}
      </StyledDataTable>
    );
  },
);

export default MultiselectDataTable;
