import {
  MapEntityProps,
  MarkerEntityProps,
  PolygonEntityProps,
} from 'components/shared/Map/MapEntity/MapEntity.types';
import defaultMarkerIcon from 'assets/images/icons/default_marker.png';
import defaultMarkerRedIcon from 'assets/images/icons/default_marker_red.png';
import { GqlPlotAlertData } from 'gql/types/plots';
import {
  errorHoverPathOptions,
  errorPathOptions,
  getAlertMarker,
  getPlotDefaultMarker,
  getPlotPolygonCoordinates,
  getPlotAlertsLocation,
  hoverPathOptions,
  pathOptions,
} from '../utils/alertsMapUtils';
import { TechnicalAlertsMapDetails } from './useTechnicalAlertsMapEntities';

export const getPlotMarker = (
  plot: GqlPlotAlertData,
  hasAlerts: boolean,
): MarkerEntityProps => {
  const iconUrl = hasAlerts ? defaultMarkerRedIcon : defaultMarkerIcon;
  return getPlotDefaultMarker(plot, iconUrl);
};

export const getPlotPolygon = (
  plot: GqlPlotAlertData,
  hasAlerts: boolean,
): PolygonEntityProps => {
  const coordinates = getPlotPolygonCoordinates(plot);
  return {
    positions: coordinates.map(([long, lat]) => [lat, long]) ?? [],
    tooltipContent: plot.name,
    pathOptions: hasAlerts ? errorPathOptions : pathOptions,
    hoverPathOptions: hasAlerts ? errorHoverPathOptions : hoverPathOptions,
    customMarkers: hasAlerts ? [getAlertMarker(plot)] : [],
  };
};

export const getTechnicalAlertsMapEntities = (
  plots: GqlPlotAlertData[],
  onlyAlerts?: boolean,
): MapEntityProps[] => {
  const mapEntities: MapEntityProps[] = [];
  plots.forEach((plot) => {
    const hasAlerts = !!plot.alerts?.length;
    if ((onlyAlerts && hasAlerts) || !onlyAlerts) {
      mapEntities.push({
        entityKey: plot.id.toString(),
        polygon: getPlotPolygon(plot, hasAlerts),
        marker: getPlotMarker(plot, hasAlerts),
      });
    }
  });

  return mapEntities;
};

export const getTechnicalAlertsMapDetails = (
  plots: GqlPlotAlertData[],
): TechnicalAlertsMapDetails => {
  return {
    plotsCount: plots.length,
    alertsCount: plots.filter((plot) => !!plot.alerts?.length).length,
    plotsLocation: getPlotAlertsLocation(plots),
  };
};
