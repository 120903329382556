import React, { FC } from 'react';
import {
  faCheck,
  faTimes,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { format, differenceInDays } from 'date-fns';
import { Tooltip } from '@mui/material';
import { useStores } from 'stores/hooks/hooks';
import { Checkbox } from 'primereact/checkbox';
import { theme } from 'components/UI';
import styled from 'styled-components';
import { PlotExcel } from 'models/plot';
import { DEFAULT_DATE_FORMAT } from 'core/constants';

const ErrorText = styled(Text)`
  color: red;
`;

const LocationBody = (data: PlotExcel) => {
  return <LocationBodyContainer data={data} />;
};

const LocationBodyContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { data } = props;
  const location = `${data?.plot?.latitude}, ${data?.plot?.longitude}`;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('Location'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || location} placement="top">
      {errorMessage ? (
        <ErrorText>{location}</ErrorText>
      ) : (
        <Text>{location}</Text>
      )}
    </Tooltip>
  );
});

const AreaBody = (data: PlotExcel) => {
  return <AreaBodyContainer data={data} />;
};

const AreaBodyContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { data } = props;
  const area = data?.plot?.area
    ? `${data?.plot?.area?.val}, ${data?.plot?.area?.unit}`
    : '';

  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('Area'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || area} placement="top">
      {errorMessage ? (
        <ErrorText>{errorMessage}</ErrorText>
      ) : (
        <Text>{area}</Text>
      )}
    </Tooltip>
  );
});

const IsSelectedContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { resellersStore } = useStores();
  const { data } = props;

  return (
    <Grid
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <Checkbox
        disabled={!data.isValid}
        checked={data.selected}
        onChange={(e: any) => {
          e.stopPropagation();
          resellersStore.setExcelPlotSelected(data.plot.id, e.checked);
        }}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      />
    </Grid>
  );
});

const isSelectedBody = (data: PlotExcel) => {
  return <IsSelectedContainer data={data} />;
};

const StatusBodyContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { data } = props;

  return (
    <Grid align="center" justify="center">
      {data.isValid ? (
        <FontAwesomeIcon icon={faCheck} color={theme.color.success} />
      ) : (
        <FontAwesomeIcon icon={faTimes} color={theme.color.error} />
      )}
    </Grid>
  );
});

const StatusBody = (data: PlotExcel) => {
  return <StatusBodyContainer data={data} />;
};

const StartTimeBody = (data: PlotExcel) => {
  return <StartTimeBodyContainer data={data} />;
};

const StartTimeBodyContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { data } = props;
  const startTime = data.plot.plantTime
    ? format(new Date(data.plot.plantTime), DEFAULT_DATE_FORMAT)
    : 'N/A';

  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('Time'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || startTime} placement="top">
      {errorMessage ? (
        <ErrorText>{startTime || 'Error'}</ErrorText>
      ) : (
        <Text>{startTime}</Text>
      )}
    </Tooltip>
  );
});

const CheckSquareIconContainer = observer(() => {
  const { resellersStore } = useStores();
  const validPlots = resellersStore.plotUploadExcel.filter((el) => el.isValid);
  return (
    <Checkbox
      checked={validPlots.every((el) => el.selected)}
      onChange={(e) => {
        validPlots.forEach((el) => {
          resellersStore.setExcelPlotSelected(el.plot.id, e.checked);
        });
      }}
    />
  );
});

const CheckSquareIcon = () => <CheckSquareIconContainer />;

const CheckRoundIconContainer = () => (
  <Grid align="center" justify="center">
    <FontAwesomeIcon icon={faCircleCheck} />
  </Grid>
);

const CheckRoundIcon = () => <CheckRoundIconContainer />;

const PlotNameBody = (data: PlotExcel) => {
  return <PlotNameBodyContainer data={data} />;
};

const PlotNameBodyContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { data } = props;
  const plotName = data?.plot?.name;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('Name'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || plotName} placement="top">
      {errorMessage ? (
        <ErrorText>{plotName || errorMessage}</ErrorText>
      ) : (
        <Text>{plotName}</Text>
      )}
    </Tooltip>
  );
});

const ServiceLevelBody = (data: PlotExcel) => {
  return <ServiceLevelBodyContainer data={data} />;
};

const ServiceLevelBodyContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { data } = props;
  const serviceLevel = String(data?.plot?.serviceLevel) || 'N/A';
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('ServiceLevel'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || serviceLevel} placement="top">
      {errorMessage ? (
        <ErrorText>{serviceLevel || errorMessage}</ErrorText>
      ) : (
        <Text>{serviceLevel}</Text>
      )}
    </Tooltip>
  );
});

const CropTypeBody = (data: PlotExcel) => {
  return <CropTypeBodyContainer data={data} />;
};

const CropTypeBodyContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { data } = props;
  const cropName = data?.plot?.cropName;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('Crop'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || cropName || ''} placement="top">
      {errorMessage ? (
        <ErrorText>{cropName || errorMessage}</ErrorText>
      ) : (
        <Text>{cropName}</Text>
      )}
    </Tooltip>
  );
});

const InitialStgeDateBodyContainer: FC<{ data: PlotExcel }> = observer(
  (props) => {
    const { data } = props;
    const initialStageDate = format(
      new Date(data.plot.initialStage?.date || 0),
      'dd/MM/yyyy',
    );

    const errorMessage = data.importErrors.find((error) =>
      error.fieldName.includes('InitialStageDate'),
    )?.msg;

    return (
      <Tooltip arrow title={errorMessage || initialStageDate} placement="top">
        {errorMessage ? (
          <ErrorText>{initialStageDate || errorMessage}</ErrorText>
        ) : (
          <Text>{initialStageDate}</Text>
        )}
      </Tooltip>
    );
  },
);

const PlantDateBodyContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { data } = props;
  const plantDate = (
    differenceInDays(new Date(), Number(data.plot.plantTime) || 0) / 365
  ).toFixed(3);

  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('PlantDate'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || plantDate} placement="top">
      {errorMessage ? (
        <ErrorText>{plantDate || errorMessage}</ErrorText>
      ) : (
        <Text>{plantDate}</Text>
      )}
    </Tooltip>
  );
});

const SoilTypeBodyContainer: FC<{ data: PlotExcel }> = observer((props) => {
  const { data } = props;
  const soilName = data?.plot?.soilName;
  const errorMessage = data.importErrors.find((error) =>
    error.fieldName.includes('Soil'),
  )?.msg;

  return (
    <Tooltip arrow title={errorMessage || soilName || ''} placement="top">
      {errorMessage ? (
        <ErrorText>{soilName || errorMessage}</ErrorText>
      ) : (
        <Text>{soilName}</Text>
      )}
    </Tooltip>
  );
});

const GrowingEnvironmentBodyContainer: FC<{ data: PlotExcel }> = observer(
  (props) => {
    const { data } = props;
    const growingType = data?.plot?.growingType;
    const errorMessage = data.importErrors.find((error) =>
      error.fieldName.includes('GrowingEnvironment'),
    )?.msg;

    return (
      <Tooltip arrow title={errorMessage || growingType || ''} placement="top">
        {errorMessage ? (
          <ErrorText>{growingType || errorMessage}</ErrorText>
        ) : (
          <Text>{growingType}</Text>
        )}
      </Tooltip>
    );
  },
);

const IrrigationMethodBodyContainer: FC<{ data: PlotExcel }> = observer(
  (props) => {
    const { data } = props;
    const irrigationMethod = data?.plot?.irrigationMethod;
    const errorMessage = data.importErrors.find((error) =>
      error.fieldName.includes('IrrigationMethod'),
    )?.msg;

    return (
      <Tooltip
        arrow
        title={errorMessage || irrigationMethod || ''}
        placement="top"
      >
        {errorMessage ? (
          <ErrorText>{irrigationMethod || errorMessage}</ErrorText>
        ) : (
          <Text>{irrigationMethod}</Text>
        )}
      </Tooltip>
    );
  },
);

export {
  LocationBody,
  AreaBody,
  isSelectedBody,
  StatusBody,
  StartTimeBody,
  CheckSquareIcon,
  CheckRoundIcon,
  PlotNameBody,
  ServiceLevelBody,
  CropTypeBody,
  PlotNameBodyContainer,
  ServiceLevelBodyContainer,
  LocationBodyContainer,
  StartTimeBodyContainer,
  AreaBodyContainer,
  CropTypeBodyContainer,
  InitialStgeDateBodyContainer,
  PlantDateBodyContainer,
  SoilTypeBodyContainer,
  GrowingEnvironmentBodyContainer,
  IrrigationMethodBodyContainer,
};
