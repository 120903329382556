import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DataTable from 'components/shared/DataTable';
import { Column } from 'components/shared/DataTable/DataTable';
import Grid from 'components/UI/Grid';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from 'stores/hooks/hooks';
import { Paper } from '@mui/material';
import {
  HALF_SCREEN_TABLE_TOP_HEIGHT,
  HIGHLIGHTED_ROW_CLASS_NAME,
} from 'core/constants';
import { FilterMatchMode } from 'primereact/api';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import useInactiveRowClassName from 'hooks/datatable/useInactiveRowClassName';
import { GrowerSystem } from 'models/grower';

const DataTableContainer = styled(Paper)`
  margin: 1rem;
  overflow: hidden;
  max-height: ${HALF_SCREEN_TABLE_TOP_HEIGHT};
`;

const GrowerSystems: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { resellersStore, systemsStore } = useStores();
  const {
    growerSystems,
    getGrowerSystems,
    getGrowerSystemSensors,
    globalFilter,
    selectedRowsSystems,
    setSelectedRowsSystems,
  } = resellersStore;

  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    displayName: { value: '', matchMode: FilterMatchMode.CONTAINS },
    type: { value: '', matchMode: FilterMatchMode.CONTAINS },
    serial: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });

  const growerID = resellersStore.selectedGrowerId;

  const checkActive = useCallback((system: GrowerSystem) => system.active, []);
  const { getRowClassName } = useInactiveRowClassName({
    checkActive,
    rowClassName: HIGHLIGHTED_ROW_CLASS_NAME,
  });

  const onRowClick = useCallback(
    ({ data }: DataTableRowClickEventParams) => {
      const isSame = resellersStore.selectedRowsSystems[0]?.id === data.id;
      setSelectedRowsSystems(isSame ? [] : [data]);
      systemsStore.setSelectedSystem(data);
      getGrowerSystemSensors(growerID, data.id);
    },
    [
      getGrowerSystemSensors,
      setSelectedRowsSystems,
      growerID,
      systemsStore,
      resellersStore,
    ],
  );

  useEffect(() => {
    setFilters((prev) => {
      prev.global.value = globalFilter.trim();
      return { ...prev };
    });
  }, [globalFilter]);

  useEffect(() => {
    if (growerID) {
      getGrowerSystems(growerID);
    }
  }, [growerID, getGrowerSystems]);

  if (growerSystems.loading) {
    return <Loading />;
  }

  return (
    <Grid flex={1}>
      <DataTableContainer elevation={3}>
        <DataTable
          id="GrowerSystemsTable"
          showGridlines
          resizableColumns
          value={growerSystems.data}
          selectionMode="single"
          scrollable
          scrollHeight={HALF_SCREEN_TABLE_TOP_HEIGHT}
          dataKey="id"
          emptyMessage={t('empty_message')}
          stripedRows
          filters={filters}
          selection={selectedRowsSystems}
          onRowClick={onRowClick}
          rowClassName={getRowClassName}
          filterDisplay="row"
          globalFilterFields={['displayName', 'type', 'serial']}
        >
          <Column field="displayName" header={t('name')} sortable filter />
          <Column field="type" header={t('type')} sortable filter />
          <Column field="serial" header={t('serial')} sortable filter />
        </DataTable>
      </DataTableContainer>
    </Grid>
  );
});

export default GrowerSystems;
