import { CoefficientColumn, PhenStageColumn } from 'models/protocol';
import { useMemo } from 'react';

export default function useSelectedGrowingTypes(
  modelGrowingTypes: CoefficientColumn[],
  selectedPhenStageRow: PhenStageColumn | null,
): CoefficientColumn[] {
  return useMemo(() => {
    if (!modelGrowingTypes || !selectedPhenStageRow) {
      return [];
    }

    return modelGrowingTypes.filter((el, index) => {
      let matchedGrowingTypes;
      const modelGrowingTypesDate = new Date(el.date).getTime();
      const nextModelGrowingTypeDate =
        index < modelGrowingTypes.length - 1
          ? new Date(modelGrowingTypes[index + 1].date).getTime()
          : 0;

      const startDateSelectedPhenStage = new Date(
        selectedPhenStageRow?.date,
      ).getTime();

      const endDateSelectedPhenStage = new Date(
        selectedPhenStageRow?.dateEnd,
      ).getTime();

      if (
        (modelGrowingTypesDate < endDateSelectedPhenStage &&
          modelGrowingTypesDate >= startDateSelectedPhenStage) ||
        (endDateSelectedPhenStage === nextModelGrowingTypeDate &&
          startDateSelectedPhenStage > modelGrowingTypesDate &&
          startDateSelectedPhenStage !== endDateSelectedPhenStage) ||
        (startDateSelectedPhenStage === endDateSelectedPhenStage &&
          startDateSelectedPhenStage === modelGrowingTypesDate)
      ) {
        matchedGrowingTypes = el;
      }

      return matchedGrowingTypes;
    });
  }, [modelGrowingTypes, selectedPhenStageRow]);
}
