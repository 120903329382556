import React, { FC } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { theme } from 'components/UI';

const StyledProgressContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-svg {
    color: ${({ color }) => color};
  }
`;

export interface LoadingProps {
  color?: string;
  size?: string;
}

const Loading: FC<LoadingProps> = ({
  color = theme.color.leafGreen,
  size,
  ...rest
}) => (
  <StyledProgressContainer color={color} {...rest}>
    <CircularProgress size={size} disableShrink />
  </StyledProgressContainer>
);

export default Loading;
