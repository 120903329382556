import { observer } from 'mobx-react';
import React, { FC, useCallback } from 'react';
import Button from 'components/UI/Button';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import styled from 'styled-components';
import { useStores } from 'stores/hooks/hooks';
import { useTranslation } from 'react-i18next';

const Container = styled(Grid)`
  height: 90%;

  button {
    margin-top: 1rem;
  }
`;

const SystemsStatusCreateStep: FC = observer(() => {
  const { t } = useTranslation('system');
  const { systemsStore, resellersStore } = useStores();

  const {
    createSystemForm: { newSystemData, newSystemSensors },
  } = systemsStore;

  const isLoading = newSystemSensors.loading;
  const growerId = resellersStore.selectedGrowerId;
  const systemId = Number(newSystemData.data?.id);

  const retrySystemCreation = useCallback(() => {
    systemsStore.getNewSystemSensors(growerId, systemId);
  }, [systemsStore, growerId, systemId]);

  return (
    <Container direction="column" justify="center" align="center">
      <Text color="error">{t('system_is_offline')}</Text>
      <Text>{t('system_is_offline_help_message')}</Text>
      <Button
        onClick={retrySystemCreation}
        disabled={isLoading}
        loading={isLoading}
      >
        {t('try_again')}
      </Button>
    </Container>
  );
});

export default SystemsStatusCreateStep;
