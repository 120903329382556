import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Grid from 'components/UI/Grid';
import Loading from 'components/UI/Loading/Loading';
import { Controller } from 'react-hook-form';
import { useStores } from 'stores/hooks/hooks';
import { SystemVariant } from 'models/systems';
import { SystemsCreateFormStepProps } from '../SystemsCreateFormProperties';

const COLUMNS_NUMBER = 4;

interface TypeColumn {
  key: string;
  options: ReactElement[];
}

const sliceIntoColumns = (
  data: ReactElement[],
  columns: number,
): TypeColumn[] => {
  const result: TypeColumn[] = [];
  const chunkSize = Math.ceil(data.length / columns);
  let startCopyIndex = 0;
  for (let index = 0; index < columns; index += 1) {
    const chunk = data.slice(startCopyIndex, startCopyIndex + chunkSize);
    result.push({ key: index.toString(), options: chunk });
    startCopyIndex += chunkSize;
  }

  return result;
};

const ColumnsContainer = styled(Grid)`
  height: 70%;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormGroup-root {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const SystemsTypeCreateStep: FC<SystemsCreateFormStepProps> = observer(
  ({ control }) => {
    const { systemsStore } = useStores();
    const { systemTypes, createSystemForm } = systemsStore;

    useEffect(() => {
      if (!systemTypes.data.length) {
        systemsStore.getSystemTypes();
      }
    }, [systemsStore, systemTypes.data.length]);

    const typeOptions: TypeColumn[] = useMemo(
      () =>
        sliceIntoColumns(
          systemTypes.data.map(({ SystemType: { name, type } }) => (
            <FormControlLabel
              key={type}
              value={type}
              label={name}
              control={<Radio disabled={createSystemForm.isSystemCreated} />}
            />
          )),
          COLUMNS_NUMBER,
        ),
      [systemTypes.data, createSystemForm.isSystemCreated],
    );

    const handleTypeSelect = useCallback(
      (type: SystemVariant) => {
        systemsStore.setSystemType(type);
      },
      [systemsStore],
    );

    if (systemTypes.loading) {
      return <Loading />;
    }

    return (
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value } }) => (
          <ColumnsContainer direction="row" justify="evenly">
            <FormControl>
              <RadioGroup
                value={value}
                onChange={(event) => {
                  onChange(event);
                  handleTypeSelect(event.target.value as SystemVariant);
                }}
              >
                {typeOptions.map(({ options, key }) => (
                  <Grid key={key} align="start">
                    {options}
                  </Grid>
                ))}
              </RadioGroup>
            </FormControl>
          </ColumnsContainer>
        )}
      />
    );
  },
);

export default SystemsTypeCreateStep;
