import React, { FC } from 'react';
import {
  MultiSelect as PrimeMultiSelect,
  MultiSelectProps,
} from 'primereact/multiselect';
import styled from 'styled-components';
import { alpha } from '@mui/material';
import { MultiSelectHeader } from './MultiSelectHeader';

// primereact multiselect that uses material ui for theme.

interface CustomMultiSelectProps extends MultiSelectProps {
  label?: string;
}

const StyledMultiSelect = styled(PrimeMultiSelect) <CustomMultiSelectProps>`
  &.p-multiselect .p-multiselect-label {
    padding: 11.5px;
  }

  .p-dropdown {
    border-radius: 4px;
  }
`;

const unStyledMultiSelect: FC<CustomMultiSelectProps> = (props) => {
  const { options, virtualScrollerOptions, className, label } = props;

  return (
    <span className={label ? 'p-float-label' : ''}>
      <StyledMultiSelect
        {...props}
        inputId="multiselect"
        virtualScrollerOptions={
          virtualScrollerOptions ||
          (options && options?.length > 100 && { itemSize: 34 }) ||
          undefined
        }
        panelClassName={className}
        resetFilterOnHide
        panelHeaderTemplate={MultiSelectHeader}
      />
      {label && <label htmlFor="multiselect">{label}</label>}
    </span>
  );
};

export const MultiSelect = styled(unStyledMultiSelect)`
  &.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: inset 0 0 0 0.15rem ${(p) => p.theme.palette.primary.light};
  }

  &.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: ${(p) => alpha(p.theme.palette.primary.main, 0.2)};
  }

  &.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    box-shadow: 0 0 0 0.2rem ${(p) => alpha(p.theme.palette.primary.main, 0.5)};
  }

  &.p-inputwrapper {
    display: flex;
  }
`;
