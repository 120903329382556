import React, { ReactElement } from 'react';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { GROWER_TYPES, USER_ROLES } from 'core/constants';

export const getDataTableStatusColumnBody = <T,>(
  data: T,
  statusKey: keyof T,
): ReactElement => {
  return data?.[statusKey] ? <CheckIcon /> : <CloseIcon />;
};

export const getDataTableCheckboxFilterTemplate = (
  options: ColumnFilterElementTemplateOptions,
) => (
  <TriStateCheckbox
    value={options.value}
    onChange={({ value }) => options.filterApplyCallback(value)}
  />
);

export const getDataTableUserRoleColumnBody = <T,>(data: T, key: keyof T) => {
  return USER_ROLES.find(({ value }) => value === data?.[key])?.label ?? '';
};

export const getDataTableGrowerTypeColumnBody = <T,>(data: T, key: keyof T) => {
  return GROWER_TYPES.find(({ value }) => value === data?.[key])?.label ?? '';
};
