import React, { FC, useCallback, useState } from 'react';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import { useQuery } from '@apollo/client';
import { DEFAULT_ALERT_FILTER_CATEGORY_LIST } from 'core/constants';
import { DataTablePageParams } from 'primereact/datatable';
import { GqlPlotAlertsPaginated } from 'gql/types/plots';
import { GET_PLOTS_ALERTS_PAGINATED } from 'gql/queries/plots';
import { AlertSeverity, AlertState, AlertVariant } from 'models/alert';
import { useStores } from 'stores/hooks/hooks';
import { TABLE_ALERTS_MAX_ROWS_NUMBER } from '../AlertsTable.utils';
import AlertsTable from '../AlertsTable';
import { useAlertsTableFilters } from '../hooks/useAlertsTableFilters';

const defaultLazyParams: DataTablePageParams = {
  first: 0,
  rows: TABLE_ALERTS_MAX_ROWS_NUMBER,
  page: 0,
};

const AgronomicAlertsTable: FC = observer(() => {
  const { resellersStore, snackBarStore } = useStores();
  const [lazyParams, setLazyParams] =
    useState<DataTablePageParams>(defaultLazyParams);

  const { alertFilters, debouncedFilters, onAlertFilterChange } =
    useAlertsTableFilters();

  const growerIds = resellersStore.selectedTreeNodeGrowerIds;
  const { data: plotAlerts, loading: isPlotAlertsLoading } =
    useQuery<GqlPlotAlertsPaginated>(GET_PLOTS_ALERTS_PAGINATED, {
      variables: {
        growerIds,
        alertState: [AlertState.Active],
        alertFilterCategory: DEFAULT_ALERT_FILTER_CATEGORY_LIST,
        severity: [AlertSeverity.High],
        alertPage: lazyParams.page,
        alertSize: lazyParams.rows,
        ...debouncedFilters,
      },
      fetchPolicy: 'no-cache',
      skip: !growerIds.length,
      onError() {
        snackBarStore.showToast({
          detail: i18next.t('errors:something_went_wrong'),
        });
      },
    });

  const onPageChange = useCallback(
    (params: DataTablePageParams) => {
      if (params.page !== lazyParams.page) {
        setLazyParams(params);
      }
    },
    [lazyParams],
  );

  return (
    <AlertsTable
      alerts={plotAlerts as GqlPlotAlertsPaginated}
      tableParams={lazyParams}
      loading={isPlotAlertsLoading}
      onPageChange={onPageChange}
      alertFilters={alertFilters}
      onAlertFilterChange={onAlertFilterChange}
      variant={AlertVariant.Agronomic}
    />
  );
});

export default AgronomicAlertsTable;
