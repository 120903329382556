import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores/hooks/hooks';
import Grid from 'components/UI/Grid';
import Plots from './Plots';
import PlotSensors from './PlotSensors';

const Container = styled(Grid)`
  height: 100%;
`;

const ResellerPlots: FC = observer(() => {
  const {
    resellersStore: { selectedRowsPlots },
  } = useStores();

  return (
    <Container>
      <Plots />
      {!!selectedRowsPlots?.length && <PlotSensors />}
    </Container>
  );
});

export default ResellerPlots;
