import React, { forwardRef, useMemo } from 'react';
import { MapContainer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import styled from 'styled-components';
import MapEntitiesLayer from './MapEntitiesLayer/MapEntitiesLayer';
import { defaultMapConfig } from './Map.constants';
import { MapProps, MapRef } from './Map.types';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  height: 100%;
`;

const Map = forwardRef<MapRef, MapProps>(
  (
    { mapEntities, mapConfig = {}, googleLayerProps, mapContainerProps },
    ref,
  ) => {
    const config = useMemo(
      () => ({ ...defaultMapConfig, ...mapConfig }),
      [mapConfig],
    );

    return (
      <Container>
        <StyledMapContainer {...mapContainerProps}>
          <ReactLeafletGoogleLayer {...googleLayerProps} />
          <MapEntitiesLayer
            ref={ref}
            mapEntities={mapEntities}
            mapConfig={config}
          />
        </StyledMapContainer>
      </Container>
    );
  },
);

export default Map;
