import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import { JSONSchema } from 'stores/types/types';
import { SettingsFormInput } from './PlotSettingsManagementDialog.utils';

const PlotSettingsFormCheckbox: FC<SettingsFormInput> = (props) => {
  const { formProperty, parentKeys, value, handleChangeValue, formKey } = props;
  const { t } = useTranslation('settings_form');

  const [currentValue, setCurrentValue] = useState(
    value != null ? value : undefined,
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.checked);
    handleChangeValue(
      [...(parentKeys || []), formKey],
      event.target.checked,
      false,
    );
  };

  const handleSetDefault = useCallback(
    (property: JSONSchema) => {
      if (property?.default) {
        setCurrentValue(!!property.default);
        handleChangeValue(
          [...(parentKeys || []), formKey],
          property.default,
          false,
        );
      } else {
        setCurrentValue(false);
        handleChangeValue([...(parentKeys || []), formKey], false, false);
      }
    },
    [formKey, handleChangeValue, parentKeys],
  );

  useEffect(() => {
    if (value == null && formProperty && currentValue == null) {
      handleSetDefault(formProperty);
    }
  }, [currentValue, formProperty, handleSetDefault, value]);

  return <Checkbox onChange={handleChange} checked={!!currentValue} />;
};

export default PlotSettingsFormCheckbox;
