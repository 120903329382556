import React, { FC, useCallback, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Button from 'components/UI/Button';
import Grid from 'components/UI/Grid';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Loading from 'components/UI/Loading/Loading';
import { GrowerSystem } from 'models/grower';
import { SystemDefaultSchema, SystemVwsSchema } from 'models/systems';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import { ActionMode, DialogOnCloseReason } from 'models/shared';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import styled from 'styled-components';
import { z } from 'zod';
import { SystemsManagementDialogProps } from '../SystemsManagementDialog';
import useDynamicFields from '../SystemsCreateDialog/SystemsCreateSteps/hooks/useDynamicFields';
import useVwsDynamicFields from '../SystemsCreateDialog/SystemsCreateSteps/hooks/useVwsDynamicFields';

const StyledDialogContent = styled(DialogContent)`
  padding: 1rem 3rem;
  height: 40vh;
  overflow: hidden;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 0.5rem 1rem;
`;

const ButtonText = styled(Text)`
  color: ${({ theme }) => theme.color.white};
`;

const LoadingContainer = styled(Grid)`
  width: 100%;
`;

const RowsContainer = styled(Grid)`
  height: 38vh;
  padding: 1rem 0.5rem;
  overflow-y: auto;
`;

const editSystemSchema = z.object({
  displayName: z.string().nonempty({
    message: i18next.t<string>('validation:field_required', {
      field: 'Name',
    }),
  }),
  active: z.boolean(),
});

type EditSystemSchema = z.infer<typeof editSystemSchema>;

const SystemsEditDialog: FC<SystemsManagementDialogProps> = observer(
  ({ visible = false, onHide, title }) => {
    const { systemsStore, resellersStore } = useStores();
    const { t } = useTranslation('system');
    const { editSystemForm } = systemsStore;
    const configSchema = editSystemForm.configurationSchema;
    const {
      formState: { isValid: isFormValid, isDirty: isFormDirty, errors },
      register,
      control,
      getValues,
    } = useForm<EditSystemSchema>({
      resolver: zodResolver(editSystemSchema),
      mode: 'onChange',
      defaultValues: {
        active: editSystemForm.selectedSystem?.active,
        displayName: editSystemForm.selectedSystem?.displayName,
      },
    });

    const {
      values: schemaValues,
      fields: schemaFields,
      isValid: isSchemaValuesValid,
      isDirty: isSchemaValuesDirty,
    } = editSystemForm.isVws
      ? useVwsDynamicFields({
          schema: configSchema.data?.schema as SystemVwsSchema,
          defaultValues: configSchema.values,
          showHidden: true,
          mode: ActionMode.EDIT,
        })
      : useDynamicFields({
          schema: configSchema.data?.schema as SystemDefaultSchema,
          defaultValues: configSchema.values,
          showHidden: true,
        });

    useEffect(() => {
      systemsStore.loadSystemForEdit();
    }, [systemsStore]);

    const handleDialogClose = useCallback(
      (_event: unknown, reason: DialogOnCloseReason) => {
        if (reason !== DialogOnCloseReason.BackDropClick) {
          onHide();
        }
      },
      [onHide],
    );

    const handleDialogSubmit = useCallback(() => {
      const updatedSystem: Partial<GrowerSystem> = {
        ...getValues(),
        params: schemaValues,
      };

      systemsStore
        .updateSelectedSystem(updatedSystem)
        .then(() => {
          systemsStore.resetSystemForEdit();
          resellersStore.setSelectedRowsSystems([]);
          onHide();
        })
        .catch(() => null);
    }, [getValues, schemaValues, systemsStore, resellersStore, onHide]);

    const isDirty = isFormDirty || isSchemaValuesDirty;
    const isValid = isFormValid && isSchemaValuesValid;

    return (
      <Dialog
        open={visible}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <StyledDialogContent>
          <RowsContainer
            direction="column"
            align="start"
            justify="start"
            gap="1rem"
          >
            <TextField
              {...register('displayName')}
              label={t('name')}
              error={!!errors.displayName}
              helperText={errors.displayName?.message}
              fullWidth
            />
            {configSchema.loading ? (
              <LoadingContainer direction="row" align="center" justify="center">
                <Loading />
              </LoadingContainer>
            ) : (
              <>
                {schemaFields.baseFields}
                {schemaFields.paramsFields?.length && schemaFields.paramsFields}
              </>
            )}
            <FormGroup>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name="active"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        {...register('active')}
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                }
                label={t('active')}
              />
            </FormGroup>
          </RowsContainer>
        </StyledDialogContent>
        <Divider />
        <StyledDialogActions>
          <Button
            onClick={handleDialogSubmit}
            disabled={!isDirty || !isValid || editSystemForm.isUpdating}
            loading={editSystemForm.isUpdating}
          >
            <ButtonText size="md">{i18next.t('general:edit')}</ButtonText>
          </Button>
        </StyledDialogActions>
      </Dialog>
    );
  },
);

export default SystemsEditDialog;
