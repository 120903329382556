import React, {
  CSSProperties,
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { List } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { theme } from 'components/UI';
import Grow from 'components/UI/Grow';
import { MinimizeAble, NavigationListItem } from './Styles';
import { RouteContent } from './RouteContent';

export interface ChildRouteProps {
  label: string;
  path: string;
  icon: FontAwesomeIconProps['icon'];
  disabled?: boolean;
}

export interface ExpandableRouteProps {
  title: string;
  icon: FontAwesomeIconProps['icon'];
  childrenRoutes: ChildRouteProps[];
  isMinimized: boolean;
  pathName: string;
  onRouteClick(value: string): VoidFunction;
}

const parentIconStyle: CSSProperties = { color: theme.color.antiqueTin };

const ExpandableRoute: FC<ExpandableRouteProps> = ({
  title,
  icon,
  childrenRoutes,
  isMinimized,
  pathName,
  onRouteClick,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const handleCollapse = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const isSelected = useMemo(
    () =>
      !isCollapsed && childrenRoutes.some((route) => route.path === pathName),
    [isCollapsed, childrenRoutes, pathName],
  );

  return (
    <>
      <NavigationListItem
        onClick={handleCollapse}
        title={title}
        isSelected={isSelected}
        isMinimized={isMinimized}
      >
        <RouteContent
          label={title}
          icon={icon}
          isSelected={isSelected}
          isMinimized={isMinimized}
        />
        <Grow />
        <MinimizeAble withOpacity isMinimized={isMinimized}>
          {isCollapsed ? (
            <ExpandLess style={parentIconStyle} />
          ) : (
            <ExpandMore style={parentIconStyle} />
          )}
        </MinimizeAble>
      </NavigationListItem>
      <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childrenRoutes.map((route) => {
            const isSubRouteSelected = pathName === route.path;
            return (
              <NavigationListItem
                key={route.path}
                depth={1}
                isSelected={isSubRouteSelected}
                isMinimized={isMinimized}
                title={route.label}
                onClick={onRouteClick(route.path)}
                disabled={route.disabled}
              >
                <RouteContent
                  label={route.label}
                  icon={route.icon}
                  isSelected={isSubRouteSelected}
                  isMinimized={isMinimized}
                />
              </NavigationListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default ExpandableRoute;
