import { AxiosError } from 'axios';
import i18next from 'i18next';
import { ErrorResponse } from 'models/shared';

const HTTP_ERROR_MESSAGES: Record<string, string> = {
  SYSTEM_ALREADY_SET: i18next.t('system:system_already_set_message'),
  SYSTEM_HAS_ACTIVE_SENSORS: i18next.t(
    'system:system_has_active_sensors_message',
  ),

  SENSOR_CONNECTED_TO_PLOT: i18next.t(
    'system:sensor_connected_to_plot_message',
  ),
};

export const getAxiosErrorMessage = (error: unknown): string => {
  const responseData = (error as AxiosError)?.response?.data as ErrorResponse;
  const message = responseData?.message as string;

  if (typeof responseData === 'string') {
    return responseData;
  }

  return (
    HTTP_ERROR_MESSAGES[message] ||
    message ||
    i18next.t('errors:something_went_wrong')
  );
};
