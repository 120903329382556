import React, { FC, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import { DialogOnCloseReason } from 'models/shared';
import { useStores } from 'stores/hooks/hooks';
import ReactJson from 'react-json-view';
import FeatureCreateFormProperties, {
  FeatureCreateStep,
} from './FeatureCreateFormProperties';
import FeatureCreateDialogFooter from './FeatureCreateDialogFooter';
import { MLModelManagementDialogProps } from '../FeatureManagementDialog';

const StyledDialogContent = styled(DialogContent)`
  padding: 1rem;
  height: 40vh;
  overflow: auto;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 2rem 1rem 0.5rem;
`;

const steps: FeatureCreateStep[] = [
  FeatureCreateStep.TYPE,
  FeatureCreateStep.CONFIGURATION,
  FeatureCreateStep.DISCOVERY,
  FeatureCreateStep.SUMMARY,
];

const FeatureCreateDialog: FC<MLModelManagementDialogProps> = observer(
  ({ visible = false, onHide, title }) => {
    const [step, setStep] = useState<FeatureCreateStep>(FeatureCreateStep.TYPE);
    const { t } = useTranslation('feature');
    const { featuresStore, resellersStore } = useStores();
    const { createFeatureForm } = featuresStore;
    const { mlModelId } = createFeatureForm;

    const {
      formState: { errors },
      control,
      register,
      setValue,
      reset,
      getValues,
    } = useForm<any>({
      mode: 'onChange',
      defaultValues: {
        _step: FeatureCreateStep.TYPE,
      },
    });

    const isStepCompleted = useMemo(() => {
      switch (step) {
        case FeatureCreateStep.TYPE:
          return !!mlModelId;
        default:
          return true;
      }
    }, [mlModelId, step]);

    const handleDialogClose = useCallback(
      (_event: unknown, reason: DialogOnCloseReason) => {
        if (reason !== DialogOnCloseReason.BackDropClick) {
          onHide();
        }
      },
      [onHide],
    );

    const handleBackClick = useCallback(() => {
      const currentStepIndex = steps.findIndex((item) => item === step);
      const nextStep = steps[currentStepIndex - 1];
      if (nextStep === FeatureCreateStep.TYPE) {
        reset();
      }

      if (step === FeatureCreateStep.SUMMARY) {
        featuresStore.resetConnectedSensors();
      }

      setStep(nextStep);
      setValue('_step', Number(nextStep));
    }, [step, setValue, reset, featuresStore]);

    const handleDefaultNextClick = useCallback(() => {
      const currentStepIndex = steps.findIndex((item) => item === step);
      const nextStep = steps[currentStepIndex + 1];
      setStep(nextStep);
      setValue('_step', Number(nextStep));
    }, [step, setValue]);

    const handleNextClick = useCallback(async () => {
      if (step === FeatureCreateStep.CONFIGURATION) {
        const values = getValues();
        if (
          !values.name ||
          !values.displayName ||
          (values.plot && Number(values.plot) < 0)
        ) {
          featuresStore.errorToast(t('fields_required'));
          return;
        }

        featuresStore.setFeatureConfiguration(values);
      } else if (step === FeatureCreateStep.DISCOVERY) {
        const isConnected = await featuresStore.setConnectedSensors();
        if (!isConnected) {
          return;
        }
      } else if (step === FeatureCreateStep.SUMMARY) {
        const result = await featuresStore.createFeature(
          resellersStore.resellerPlots,
        );

        if (result) {
          onHide();
        }

        return;
      }

      handleDefaultNextClick();
    }, [
      featuresStore,
      getValues,
      handleDefaultNextClick,
      onHide,
      resellersStore.resellerPlots,
      step,
      t,
    ]);

    const dialogSubTitle = useMemo(() => {
      if (step === FeatureCreateStep.TYPE) {
        return t('select_model_type');
      }

      if (step === FeatureCreateStep.CONFIGURATION) {
        return t('feature_config_for', {
          type: featuresStore.createFeatureForm.mlModelId?.name,
        });
      }

      if (step === FeatureCreateStep.DISCOVERY) {
        return t('ml_feature_discovery', {
          feature: featuresStore.createFeatureForm.mlModelId?.name,
        });
      }

      if (step === FeatureCreateStep.SUMMARY) {
        return t('ml_feature_summary');
      }

      return '';
    }, [step, t, featuresStore.createFeatureForm.mlModelId?.name]);

    const selectSensorsSchema = useMemo(() => {
      if (step === FeatureCreateStep.DISCOVERY) {
        return featuresStore.sensorsPrerequisites.find(
          (item) =>
            item.displayName ===
            featuresStore.createFeatureForm.mlModelId?.name,
        );
      }

      return null;
    }, [
      featuresStore.createFeatureForm.mlModelId?.name,
      featuresStore.sensorsPrerequisites,
      step,
    ]);

    return (
      <Dialog
        open={visible}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="lg"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <StyledDialogContent>
          {dialogSubTitle && (
            <Text weight="bold" size="md" marginBottom="1rem">
              {dialogSubTitle}
            </Text>
          )}
          {selectSensorsSchema && (
            <ReactJson collapsed src={selectSensorsSchema} />
          )}
          <FeatureCreateFormProperties
            step={step}
            control={control}
            errors={errors}
            register={register}
            getValues={getValues}
          />
        </StyledDialogContent>
        <Divider />
        <StyledDialogActions>
          <FeatureCreateDialogFooter
            step={step}
            steps={steps}
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}
            isSubmitDisabled={!isStepCompleted}
            isLoading={false}
          />
        </StyledDialogActions>
      </Dialog>
    );
  },
);

export default FeatureCreateDialog;
