import { useMemo } from 'react';
import { add as addDays, format } from 'date-fns';
import i18next from 'i18next';
import { GrowingType, CoefficientColumn } from 'models/protocol';

export default function useModelGrowingTypes(
  phenStageWorkingDate: Date,
  selectedDropdownGrowingType: GrowingType | undefined,
): CoefficientColumn[] {
  return useMemo(() => {
    let temp: CoefficientColumn[] = [];
    if (phenStageWorkingDate) {
      selectedDropdownGrowingType?.irrigationCoefficient.reduce(
        (prev: CoefficientColumn | null, curr, index) => {
          const growingTypeItem: CoefficientColumn = {
            index: index + 1,
            duration: curr.lengthInDays,
            date: prev
              ? addDays(prev.date as Date, { days: prev.duration })
              : phenStageWorkingDate,
            min: curr.startCoefficient,
            max: curr.endCoefficient,
            phenStage: i18next.t(
              `phenStageIds:${curr.phenologicalStageId || ''}`,
            ),
          };

          temp.push(growingTypeItem);
          return growingTypeItem;
        },
        null,
      );

      temp = temp.map((el) => ({
        ...el,
        date: format(el.date as Date, 'dd-MMM-yy'),
      }));
    }

    return temp;
  }, [selectedDropdownGrowingType, phenStageWorkingDate]);
}
