import { JSONSchema } from 'stores/types/types';
import { Alert } from './alert';
import { Grower } from './grower';
import { IrrigationRecommendation, OvertimeDataType } from './models';
import { PhenologicalStage } from './protocol';
import { Sensor } from './sensor';
import { CropType, PhenologicalStages } from './shared';

export interface ServiceLevel {
  serviceLevel: string;
  count: number;
  id: string;
  name: string;
}

export interface InitialStageModel {
  stageId: string;
  date: string | Date | number;
}

export type Area = {
  __typename?: 'Area';
  val?: number | null;
  valDunam?: number | null;
  unit?: number | string | null;
};

interface Location {
  latitude: number;
  longitude: number;
}

export type WebPlot = {
  __typename?: 'Plot';
  id: number;
  name: string;
  description?: string | null;
  grower?: Grower | null;
  plantTime?: string | number | null;
  startDate?: string | number | null;
  endDate?: string | number | null;
  timezone?: string | null;
  alerts?: Alert[];
  cropType?: CropType | null;
  soilName?: string | null;
  area?: Area | null;
  cropProtocolId?: string | null;
  previousProtocolId: string | null;
  serviceLevel?: ServiceLevel | string | null;
  irrigations?: (OvertimeDataType | null)[] | null;
  recommendations?: (IrrigationRecommendation | null)[] | null;
  phenologicalStage?: PhenologicalStage | null;
  phenologicalStages?: (PhenologicalStages | null)[] | null;
  variety?: string | null;
  latitude: number | null;
  longitude: number | null;
  nominalFlow?: number | null;
  polygon?: { coordinates: [number, number, number][][] };
  location?: Location;
  geojson?: GeoJSON.Feature | null;
  sensors?: Sensor[];
  seasonStage?: { id: PlotSeasonsType } | null;
  cropName?: string | null;
  growingType?: string | null;
  growthMethod?: string | null;
  initialStage?: InitialStageModel;
  category?: string | null;
  irrigationMethod: string | null;
  soilType: string | null;
  startTime: string | number | null;
  growerId: number | null;
  soil: string | null;
  crop: number;
  sampleFrequency: string;
  status: PlotStatus;
};

export interface PlotModel {
  plotId: number;
  growerId: number;
  name: string;
  description: string;
  crop: number;
  cropProtocolId: string | null;
  category: string;
  variety: string;
  growingType: string;
  growthMethod: string;
  irrigationMethod: string;
  longitude: string | number;
  latitude: string | number;
  serviceLevel: string;
  soil: string | number;
  plantTime: number;
  startSeason?: number;
  startTime: number;
  endTime: number | string;
  area?: Area | null;
  sampleFrequency: string;
  timeZone: string;
  initialStage?: InitialStageModel;
  status: PlotStatus;
  geojson: Polygon | null;
}

export interface Plot {
  plotId: number;
  growerId: number;
  plotName: string;
  cropId: number;
  soilId: number;
  plotTimezone: string;
  plantedAt: string;
  growerName: string;
  soilType: string;
  soilSubtype: string;
  endTime?: number;
  growthMethod: string | null;
  cropProtocolId: string | null;
  cropName: string;
  growingType: string;
  soilName: string;
  longitude: string;
  latitude: string;
  serviceLevel: string | null;
  age: number;
  active: boolean;
  categoryName: string;
  phenStage: string;
  alerts: string;
  variety: string;
}

export interface PlotData {
  paginatedCollection: Plot[];
  totalCount: number;
}

export type Polygon = {
  type: string;
  features: GeoJSON.Feature[] | null;
};

export type PlotExcel = {
  isValid: boolean;
  plot: WebPlot;
  selected: boolean;
  importErrors: ParseExcelMessages[];
  importWarnings: ParseExcelMessages[];
};

export type ParseExcelMessages = {
  fieldName: string;
  msg: string;
};

export enum PlotStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Archive = 'ARCHIVE',
}

export interface PlotManagementValidation {
  location: boolean;
}

export interface TimeZone {
  timezone: string;
  count: number;
}

export enum PlotSeasonsType {
  PRE_SEASON = 'PRE_SEASON', // off-season
  SEASON_START_INIT = 'SEASON_START_INIT', // off-season
  IN_SEASON = 'IN_SEASON', // in-season
  POST_SEASON_PENDING = 'POST_SEASON_PENDING', // in-season
  POST_SEASON_POSTPONE = 'POST_SEASON_POSTPONE', // in-season
  POST_SEASON = 'POST_SEASON', // user approved pending post season
}

export type ExcelWebPlot = WebPlot & { isValid: boolean };

export interface PlotFilterResponse {
  plotsByCountry: null;
  plotsByContinent: null;
  plotsByAreaSize: null;
  plotsByTimeZone: TimeZone[];
  plotsByServiceLevel: ServiceLevel[];
  plotsBySoilType: SoilType[];
  // plotsByGrower: PlotGrower;
}

export interface SoilType {
  soilId: number;
  soilName: string;
  count: number;
  subtype: string;
  type: string;
  id: number;
  name: string;
}

export interface RecommendationParam {
  params: { [key: string]: any };
  paramsOptions: {
    soilSensor: { enum: number[]; options: { enum_titles: string[] } };
    soilSensorData: { enum: string[] };
  };
}
export interface PlotParams {
  params: { [key: string]: any };
  schema: JSONSchema;
}

interface PlotMassSettings {
  dssSettings: RecommendationParam;
  plotSettings: PlotParams;
}

export interface MassEditSettings {
  dssValidation: { schema: JSONSchema };
  massSettings: {
    plotId: number;
    plotName: string;
    settings: PlotMassSettings;
  }[];
}
