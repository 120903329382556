import api from 'Api/ApiMethods';
import { makeAutoObservable } from 'mobx';
import i18next from 'i18next';
import { ExtendedIProtocol, IProtocol } from 'models/protocol';
import { PlotData, PlotFilterResponse } from 'models/plot';
import { RootStore } from './rootStore';

export class CropModelStore {
  rootStore?: RootStore = undefined;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
  }

  get cropVarieties() {
    const varieties = new Set(
      this.protocols.map((prot) => prot.classification.cropVariety).flat(),
    );

    return Array.from(varieties.values());
  }

  get cropNames() {
    const names = new Set(
      (
        this.protocols
          .map((prot) => prot.classification.cropType?.name)
          .filter((el) => el) as string[]
      ).flat(),
    );

    return Array.from(names.values());
  }

  get cropCategories() {
    const categories = new Set(
      this.protocols
        .map((prot) =>
          prot.classification.cropCategory === null ||
          prot.classification.cropCategory === ''
            ? i18next.t('crop_models:not_available')
            : prot.classification.cropCategory,
        )
        .flat(),
    );

    return Array.from(categories.values());
  }

  get protocolVersions() {
    const versions = new Set(
      this.protocols.map((prot) => prot.protocolHeader.version).flat(),
    );

    return Array.from(versions.values());
  }

  get growerNames(): string[] {
    const names = new Set(
      (
        this.protocols
          .map((prot) => prot.protocolHeader.growerName)
          .filter((el) => el) as string[]
      ).flat(),
    );

    return Array.from(names.values());
  }

  get areBothSelectionsSimilar() {
    return (
      this.selectedRows.length === 2 &&
      this.selectedRows[0].ageRange.length ===
        this.selectedRows[1].ageRange.length &&
      this.selectedRows[0].ageRange.every(
        (el, i) =>
          el.name === this.selectedRows[1].ageRange[i].name &&
          el.growingType.length ===
            this.selectedRows[1].ageRange[i].growingType.length &&
          el.growingType.every(
            (el2, i2) =>
              el2.type ===
              this.selectedRows[1].ageRange[i].growingType[i2].type,
          ),
      )
    );
  }

  get regions() {
    return this.protocols.map((el) => el.classification.region);
  }

  protocols: ExtendedIProtocol[] = [];
  setProtocols = (protocols: ExtendedIProtocol[]) => {
    this.protocols = protocols;
  };

  /**
   * used in upload tables.
   */
  selectedRow: IProtocol | null = null;
  setSelectedRow = (selectedRow: null | IProtocol) => {
    this.selectedRow = selectedRow;
  };

  /**
   * used in cropModel tables.
   */
  selectedRows: IProtocol[] = [];
  setSelectedRows = (selectedRows: IProtocol[]) => {
    this.selectedRows = selectedRows;
  };

  // eslint-disable-next-line
  getCropProtocol = async (protocolId: string) => {
    return api.getCropProtocol(protocolId);
  };

  async initData() {
    try {
      const protocols = await api.getAllProtocols();

      if (protocols) {
        this.protocols = (
          (await this.fillCropTypes(protocols)).map((el) => ({
            ...el,
            classification: {
              ...el.classification,
              region: {
                ...el.classification.region,
                toString: () =>
                  el.classification.region.coordinates
                    ? i18next.t('crop_models:polygon')
                    : `Lat ${el.classification.region.minLatitude}°, ${el.classification.region.maxLatitude}°`,
              },
            },
          })) as ExtendedIProtocol[]
        ).sort((a, b) =>
          a.classification.cropType &&
          b.classification.cropType &&
          a.classification.cropType.name < b.classification.cropType.name
            ? -1
            : 1,
        );

        if (this.protocols.length) {
          this.setSelectedRows([this.protocols[0]]);
        }
      }
    } catch (e) {
      this.protocols = [];
      this.selectedRows = [];
    }
  }

  uploadExcelProtocols = async (
    excelFile: File,
  ): Promise<ExtendedIProtocol[]> => {
    const res = (await api.postUploadExcelProtocols(excelFile)) || [];
    const extendedProtocols = res.map((el) => ({
      ...el.protocol,
      altId: el.id,
      importWarnings: el.importWarnings.map(
        (warning) => `${warning.fieldName}-${warning.msg}`,
      ),
    })) as ExtendedIProtocol[];

    return this.fillCropTypes(extendedProtocols);
  };

  fillProtocolGrowers = async (protocols: IProtocol[]) => {
    const growers = await api.getGrowers();
    if (protocols && growers) {
      return protocols.map((el) => ({
        ...el,
        protocolHeader: {
          ...el.protocolHeader,
          growerType: growers.find(
            (el2) => el2.id === el.protocolHeader.growerId,
          ),
        },
      })) as ExtendedIProtocol[];
    }

    return [];
  };

  fillCropTypes = async (protocols: IProtocol[]) => {
    const cropTypes = await api.getCropTypes();

    if (protocols.length && cropTypes) {
      return protocols.map((el) => ({
        ...el,
        classification: {
          ...el.classification,
          cropType: cropTypes.find(
            (el2) => el2.id === el.classification.cropId,
          ),
        },
      })) as ExtendedIProtocol[];
    }

    return [];
  };

  addProtocols = async (protocols: ExtendedIProtocol[]) => {
    if (protocols && protocols.length) {
      const notUploadedModels = protocols.filter(
        (protocol) => !protocol.protocolHeader.protocolId,
      );

      if (notUploadedModels.length) {
        this.rootStore?.snackBarStore.showToast({
          detail: `Models doesn't uploaded: ${notUploadedModels.map(
            (el) => el.altId,
          )}`,
        });
      }

      this.protocols.push(...(await this.fillCropTypes(protocols)));
      this.setSelectedRows(protocols);
    }
  };

  approveProtocolsJson = async (
    protocols: ExtendedIProtocol[],
  ): Promise<ExtendedIProtocol[]> => {
    const res = await api.postUploadJSONProtocols(
      protocols.map((el) => ({ ...el, altId: undefined })), // remove altId because server doesn't expect it.
    );

    // approve succeeded, add to table
    this.addProtocols(res);
    return res;
  };

  getPlot = async ({
    serviceLevel,
    timeZone,
    modelId,
    soilTypeId,
    offset,
    cnt,
  }: {
    serviceLevel?: string[];
    timeZone?: string[];
    modelId: string;
    // plotSize: string[];
    soilTypeId?: number[];
    offset: number;
    cnt: number;
  }): Promise<PlotData> => {
    const res = await api.getPlots({
      serviceLevel,
      timeZone,
      modelId,
      soilTypeId,
      offset,
      cnt,
    });

    return res;
  };

  getPlotFilters = (protocolId: string): Promise<PlotFilterResponse> => {
    return api.getPlotFilters(protocolId);
  };

  removeModel = async (growerId: number, protocolId: string) => {
    try {
      await api.deleteCropProtocol(growerId, protocolId);
      this.setSelectedRows([]);
      this.protocols = this.protocols.filter(
        (protocol) => protocol.protocolHeader.protocolId !== protocolId,
      );

      this.rootStore?.snackBarStore.showToast({
        detail: `Protocol removed successfully`,
        severity: 'success',
        summary: 'Success',
      });
    } catch (error) {
      this.rootStore?.snackBarStore.showToast({
        detail: `Something get wrong, protocol not deleted - ${error}`,
      });
    }
  };
}
