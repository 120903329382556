import { observer } from 'mobx-react';
import React, {
  FC,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components';
import Text from 'components/UI/Text';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  FormControl,
  ListItemButton,
  ListSubheader,
  MenuItem,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PhenStageTable from 'components/pages/CropModel/ModelDetails/PhenStages/PhenStageTable';
import CoefficentTable from 'components/pages/CropModel/ModelDetails/PhenStages/CoefficentTable';
import Grid from 'components/UI/Grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useSelectedGrowingTypes from 'hooks/useSelectedGrowingTypes';
import useModelAgeRanges from 'hooks/useModelAgeRanges';
import useModelGrowingTypes from 'hooks/useModelGrowingTypes';
import {
  AgeRange,
  ExtendedIProtocol,
  GrowingType,
  CoefficientColumn,
  PhenStageColumn,
} from 'models/protocol';

const PhenStageContainer = styled(Grid)``;

const BorderedGrid = styled(Grid)`
  background-color: rgba(0, 0, 0, 0.08);
`;

const DateSelection = styled(DatePicker)`
  input {
    box-sizing: unset;
  }
`;

const StyledListItem = styled(ListItemButton)``;

interface GridTableProps {
  ageRange: AgeRange;
  selectedDropdownGrowingType: string;
  selectedAgeRange: AgeRange;
  phenStageWorkingDate: Date;
}

const Tables: FC<GridTableProps> = ({
  ageRange,
  selectedDropdownGrowingType,
  selectedAgeRange,
  phenStageWorkingDate,
}) => {
  const [selectedPhenStageRow, setSelectedPhenStageRow] =
    useState<PhenStageColumn | null>(null);

  const ref = useRef<HTMLDivElement | null>(null);

  const modelAgeRanges: PhenStageColumn[] = useModelAgeRanges(
    phenStageWorkingDate,
    ageRange,
  );

  const foundSelectedDropdownGrowingType: GrowingType | undefined =
    useMemo(() => {
      return ageRange.growingType.find(
        (growingType) => growingType.type === selectedDropdownGrowingType,
      );
    }, [selectedDropdownGrowingType, ageRange.growingType]);

  const modelGrowingTypes: CoefficientColumn[] = useModelGrowingTypes(
    phenStageWorkingDate,
    foundSelectedDropdownGrowingType,
  );

  const selectedGrowingTypes = useSelectedGrowingTypes(
    modelGrowingTypes,
    selectedPhenStageRow,
  );

  useEffect(() => {
    if (ref.current && selectedAgeRange === ageRange) {
      ref.current.scrollIntoView(false);
    }
  }, [selectedAgeRange, ageRange]);

  return (
    <Grid
      ref={ref}
      gap="1rem"
      align="start"
      style={{
        padding: '1rem',
        backgroundColor:
          selectedAgeRange === ageRange ? 'rgba(0, 0, 0, 0.08)' : undefined,
      }}
    >
      <Text color="primary" weight="bold">
        {ageRange.name}
      </Text>
      <Grid direction="row" gap="1rem">
        <PhenStageTable
          ModelAgeRanges={modelAgeRanges}
          selectedPhenStageRow={selectedPhenStageRow}
          setSelectedPhenStageRow={setSelectedPhenStageRow}
        />
        <CoefficentTable
          modelGrowingTypes={modelGrowingTypes}
          selectedGrowingTypes={selectedGrowingTypes}
        />
      </Grid>
    </Grid>
  );
};

interface IPhenStages {
  selectedRow: ExtendedIProtocol;
}

const PhenStages: FC<IPhenStages> = ({ selectedRow }) => {
  const { t } = useTranslation('crop_models');
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    new Date(new Date(new Date().setMonth(0)).setDate(1)),
  );

  const [lastWorkingDate, setLastWorkingDate] = useState<Date>(
    new Date(new Date(new Date().setMonth(0)).setDate(1)),
  );

  const growingOptions = useMemo(
    () =>
      Array.from(
        new Set(
          selectedRow.ageRange
            .map((el) => el.growingType.map((el2) => el2.type))
            .flat(),
        ).values(),
      ),
    [selectedRow],
  );

  const [selectedDropdownGrowingType, setSelectedDropdownGrowingType] =
    useState(growingOptions[0]);

  const description = useMemo(
    () => selectedRow.protocolHeader.description.toString(),
    [selectedRow],
  );

  const title = useMemo(
    () =>
      `Model:${selectedRow.altId} | ${
        selectedRow.classification.cropType?.name ||
        selectedRow.classification.cropId
      } | ${description}`,
    [selectedRow, description],
  );

  useEffect(() => {
    setSelectedDropdownGrowingType(growingOptions[0]);
  }, [selectedRow, growingOptions]);

  const availableAges = useMemo(
    () =>
      selectedRow.ageRange.filter(
        (el) =>
          el.growingType.filter(
            (val) => val.type === selectedDropdownGrowingType,
          ).length,
      ),
    [selectedDropdownGrowingType, selectedRow.ageRange],
  );

  const [selectedAgeRange, setSelectedAgeRange] = useState(availableAges[0]);
  useEffect(() => {
    setSelectedAgeRange(availableAges[0]);
  }, [availableAges]);

  const handleGrowingTypeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setSelectedDropdownGrowingType(event.target.value);
    },
    [setSelectedDropdownGrowingType],
  );

  const handleAgeChange = useCallback(
    (val: AgeRange) => {
      setSelectedAgeRange(val);
    },
    [setSelectedAgeRange],
  );

  const handleDateChange = useCallback(
    (e: unknown) => {
      setSelectedDate(e as Date);
      if (e instanceof Date && !Number.isNaN(e.getDate())) {
        setLastWorkingDate(e as Date);
      }
    },
    [setSelectedDate],
  );

  return (
    <PhenStageContainer
      align="stretch"
      overflow="hidden"
      justify="start"
      flex={1}
    >
      <Text weight="bold" color="primary" align="center">
        {title}
      </Text>
      <Grid
        direction="row"
        align="stretch"
        gap="1rem"
        overflow="hidden"
        flex={1}
      >
        <Grid align="stretch" flex={2} gap="1rem" justify="start">
          <FormControl variant="outlined" margin="normal">
            <InputLabel>{t('growing_method')}</InputLabel>
            <Select
              label={t('growing_method')}
              value={selectedDropdownGrowingType}
              onChange={handleGrowingTypeChange}
            >
              {growingOptions.map((el) => (
                <MenuItem value={el}>{el}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid align="stretch" flex={1} justify="start" overflow="hidden">
            <ListSubheader>{t('ages')}</ListSubheader>
            <BorderedGrid align="stretch" justify="start" overflow="auto">
              {availableAges.map((el, i) => (
                <>
                  <StyledListItem
                    onClick={() => handleAgeChange(el)}
                    selected={el === selectedAgeRange}
                  >
                    {el.name}
                  </StyledListItem>
                  {i !== availableAges.length - 1 && <Divider />}
                </>
              ))}
            </BorderedGrid>
          </Grid>
          <Grid align="stretch" flex={1} justify="start">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateSelection
                renderInput={(params) => <TextField {...params} />}
                value={selectedDate}
                label={t('initial_stage_date')}
                inputFormat="dd-MMM-yy"
                mask="__-___-__"
                // will show console warning because mui thinks MMM can be more than 3 characters...
                onChange={handleDateChange}
                disableMaskedInput
                PopperProps={{ placement: 'top-start' }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid flex={10} gap="1rem" overflow="auto">
          {selectedAgeRange &&
            availableAges.map((el) => (
              <Tables
                ageRange={el}
                selectedDropdownGrowingType={selectedDropdownGrowingType}
                selectedAgeRange={selectedAgeRange}
                phenStageWorkingDate={lastWorkingDate}
              />
            ))}
        </Grid>
      </Grid>
    </PhenStageContainer>
  );
};

export default observer(PhenStages);
