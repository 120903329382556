import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import Button from 'components/UI/Button';
import Text from 'components/UI/Text';
import { theme } from 'components/UI';
import { TFunction } from 'i18next';
import { Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import { IProtocol } from 'models/protocol';

interface CropRegionBodyProps {
  data: IProtocol;
  t: TFunction;
  setMapDialogOpen: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      coordinates: {
        lat: number;
        lng: number;
      }[];
      title: string;
    }>
  >;
}

const CropRegionBodyContainer: FC<{
  t: TFunction;
  setMapDialogOpen: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      coordinates: {
        lat: number;
        lng: number;
      }[];
      title: string;
    }>
  >;
  data: IProtocol;
}> = observer(({ t, setMapDialogOpen, data }) => {
  const { classification, protocolHeader } = data;
  const showPolygonButton = !(
    classification.region.minLatitude && classification.region.maxLatitude
  );

  const coordinates = useMemo(
    () =>
      classification.region.coordinates?.map((el) => ({
        lng: el.y,
        lat: el.x,
      })) || [],
    [classification.region.coordinates],
  );

  const handleShowPolygonButtonClick = useCallback(() => {
    setMapDialogOpen({
      isOpen: true,
      coordinates,
      title: t('polygon_dialog_title', {
        protocolId: protocolHeader.protocolId,
      }),
    });
  }, [setMapDialogOpen, coordinates, t, protocolHeader.protocolId]);

  const tooltipText = useMemo(
    () =>
      classification.region.minLatitude === -70 &&
      classification.region.maxLatitude === 70
        ? t('worldwide')
        : `Lat ${classification.region.minLatitude}°, ${classification.region.maxLatitude}°`,
    [t, classification.region.minLatitude, classification.region.maxLatitude],
  );

  if (showPolygonButton) {
    return (
      <Button
        variant="text"
        onClick={handleShowPolygonButtonClick}
        size="small"
      >
        <Text textcolor={theme.color.link} size="md">
          {`${t('polygon')}`}
        </Text>
      </Button>
    );
  }

  return (
    <Tooltip arrow title={tooltipText} placement="top">
      <Text singleline size="md">
        {tooltipText}
      </Text>
    </Tooltip>
  );
});

const CropRegionBody: FC<CropRegionBodyProps> = ({
  data,
  t,
  setMapDialogOpen,
}) => {
  return (
    <CropRegionBodyContainer
      data={data}
      setMapDialogOpen={setMapDialogOpen}
      t={t}
    />
  );
};

export default CropRegionBody;
