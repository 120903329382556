import DataTable from 'components/shared/DataTable';
import { Column } from 'components/shared/DataTable/DataTable';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import { Paper } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {
  DataTableFilterMeta,
  DataTableProps,
  DataTableRowClickEventParams,
} from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import Loading from 'components/UI/Loading/Loading';
import { ResellerUsers } from 'models/grower';

const Container = styled(Grid)`
  height: 100%;

  #AssignUsersTable {
    height: 100%;
    overflow-y: hidden;
  }

  .p-datatable-table {
    .column-checkbox {
      width: 4rem;
    }
  }
`;

const StyledPaper = styled(Paper)`
  overflow: hidden;
`;

const tableFilters: DataTableFilterMeta = {
  firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  lastName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  email: { value: null, matchMode: FilterMatchMode.CONTAINS },
};

interface AssignUsersTableProps
  extends Pick<DataTableProps, 'value' | 'loading'> {
  setSelection(data: ResellerUsers[]): void;
  selection: ResellerUsers[];
}

const AssignUsersTable: FC<AssignUsersTableProps> = ({
  value,
  loading,
  selection,
  setSelection,
}) => {
  const { t } = useTranslation('users');
  const selectedRowIds: Set<number> = useMemo(
    () => new Set(selection.map((user) => user.id)),
    [selection],
  );

  const updateSelection = useCallback(
    ({ data }: DataTableRowClickEventParams) => {
      if (selectedRowIds.has(data.id)) {
        setSelection(selection.filter((user) => user.id !== data.id));
      } else {
        setSelection([...selection, data]);
      }
    },
    [selection, setSelection, selectedRowIds],
  );

  const getCheckboxBody = useCallback(
    (data: ResellerUsers) => (
      <Checkbox
        checked={selectedRowIds.has(data.id)}
        onChange={() =>
          updateSelection({ data } as DataTableRowClickEventParams)
        }
      />
    ),
    [updateSelection, selectedRowIds],
  );

  if (loading) return <Loading />;

  return (
    <Container flex={1}>
      <StyledPaper elevation={1}>
        <DataTable
          id="AssignUsersTable"
          showGridlines
          resizableColumns
          value={value}
          selectionMode="multiple"
          selection={selection}
          dataKey="id"
          emptyMessage={t('empty_message')}
          stripedRows
          onRowClick={updateSelection}
          filterDisplay="row"
          filters={tableFilters}
          totalRecords={value?.length}
          paginator
          rows={11}
        >
          <Column
            header=""
            body={getCheckboxBody}
            className="column-checkbox"
          />
          <Column field="firstName" header={t('first_name')} filter sortable />
          <Column field="lastName" header={t('last_name')} filter sortable />
          <Column field="email" header={t('email')} filter sortable />
        </DataTable>
      </StyledPaper>
    </Container>
  );
};

export default AssignUsersTable;
